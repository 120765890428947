import { createInitials } from 'utils/components/userMenu/userMenuHelpers';
import { Avatar } from '@material-ui/core';
import { useUser } from '../services/auth';

export const UserInitials = () => {
    const {
        userDetails: { firstName, lastName },
    } = useUser();

    return <Avatar>{createInitials(firstName, lastName)}</Avatar>;
};
