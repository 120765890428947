import { useTranslation } from 'react-i18next';
import { eachDayOfInterval, endOfMonth, format, isThisMonth, parseISO, startOfMonth } from 'date-fns';

import { Rota } from './rota/Rota';
import ErrorMessage from 'components/ErrorMessage';
import LoadingComponent from 'components/LoadingComponent';

import { createStaffMembersList, useShopIdForDatePeriod } from './utils';
import { useTimeOffTypes } from 'services/useHoliday';
import { useSelector } from 'react-redux';
import { selectMwvDate } from 'containers/Schedule/store/scheduleSelector';
import { useStaffTimeOffForSchedule } from 'services/schedule';

export const MultiWeekView = () => {
    const { t } = useTranslation();
    const { allTimeOffTypes, isTimeOffTypesLoading, isTimeOffTypesError, isTimeOffTypesSuccess } = useTimeOffTypes();
    const mwvDate = useSelector(selectMwvDate);

    const isoWeekEnd = 7;

    const mwvStart = format(startOfMonth(parseISO(mwvDate)), 'yyyy-MM-dd');
    const mwvEnd = format(endOfMonth(parseISO(mwvDate)), 'yyyy-MM-dd');

    const marginTopStyle = { marginTop: '70px' };

    const { shopId, shopName } = useShopIdForDatePeriod(
        isThisMonth(parseISO(mwvDate)) ? format(parseISO(mwvDate), 'yyyy-MM-dd') : mwvStart,
        mwvEnd,
    );

    const { mwvShopTimeOff, isMwvError, isMwvLoading } = useStaffTimeOffForSchedule(
        shopId,
        mwvStart,
        mwvEnd,
        allTimeOffTypes,
        isTimeOffTypesSuccess,
    );

    if (isMwvLoading || isTimeOffTypesLoading) {
        return (
            <LoadingComponent style={marginTopStyle} data-testid="mwv-loading-component" title={t('common:loading')} />
        );
    }

    if (isMwvError || isTimeOffTypesError) {
        return <ErrorMessage message={t('schedule:error_something_wrong')} style={marginTopStyle} />;
    }

    if (!shopId || mwvShopTimeOff.length === 0) {
        return <ErrorMessage message={t('schedule:no_data_for_period')} style={marginTopStyle} />;
    }

    return (
        <Rota
            shopName={shopName}
            staffList={[...mwvShopTimeOff]}
            dateRangeCalendar={eachDayOfInterval({ start: parseISO(mwvStart), end: parseISO(mwvEnd) }).map((day) =>
                format(day, 'yyyy-MM-dd'),
            )}
            isoWeekEnd={isoWeekEnd}
            staffPersonalInfo={createStaffMembersList(mwvShopTimeOff)}
        />
    );
};
