import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 'calc(50vh - 85px)',
    },
});

export const UnauthorizedPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Typography variant="h2" color="primary" gutterBottom component="div">
                {t('portal:unauthorized.header')}
            </Typography>
            <Typography variant="h4" color="primary" gutterBottom component="div">
                {t('portal:unauthorized.main_msg')}
            </Typography>
            <Typography variant="h4" color="primary" gutterBottom component="div">
                {t('portal:unauthorized.sub_msg')}
            </Typography>
        </div>
    );
};
