import PropTypes from 'prop-types';
import InputLabel from 'components/typography/InputLabel';
import { TextField, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: theme.palette.grey[50],
        marginTop: '2px',
        marginBottom: '16px',
    },
}));

const TextArea = ({ handleChange, label, classesOverrides, className, variant, labelClassName, ...rest }) => {
    const classes = useStyles();

    return (
        <>
            {label && typeof label === 'string' && variant !== 'outlined' ? (
                <InputLabel className={labelClassName}>{label}</InputLabel>
            ) : (
                label
            )}
            <TextField
                multiline={rest.type !== 'password'}
                maxRows="4"
                variant={variant}
                onChange={handleChange}
                classes={{ ...classesOverrides }}
                className={classnames(classes.textField, className)}
                margin="normal"
                {...rest}
            />
        </>
    );
};

TextArea.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    classesOverrides: PropTypes.object,
    labelClassName: PropTypes.any,
};

export default TextArea;
