import moment from 'moment';
import { formats, timeOffStatusTypes as TimeoffTypes, timeOffStatusTypes } from 'utils/constants';
import { spreadNightShiftsPerDay, populateStaffAvailabilityDays, findEndTime, findShiftTense } from './utils';
import { startOfWeek, format } from 'date-fns';
import { calculateHourAndMinutesDifference } from './utils/calculateHourAndMinutesDifference';

const checkDates = (startDate, start, end) =>
    startDate.isSameOrAfter(start, formats.DAY) && startDate.isSameOrBefore(end, formats.DAY);

export const expandAllTimeOffs = (timeOffArray, start, end) =>
    timeOffArray.reduce((acc, timeOff) => {
        for (let i = 1; i <= timeOff.days; i++) {
            const newStartDate = moment(timeOff.start)
                .add(timeOff.days - i, formats.DAYS)
                .startOf('day');

            const newEndDate = moment(timeOff.start)
                .add(timeOff.days - i, formats.DAYS)
                .endOf('day');

            const isBetweenDates = checkDates(
                newStartDate,
                moment(start).format(formats.BACKEND_FORMAT),
                moment(end).format(formats.BACKEND_FORMAT),
            );

            if (isBetweenDates && timeOff.statusId === timeOffStatusTypes.approved) {
                acc.push({
                    ...timeOff,
                    start: newStartDate,
                    end: newEndDate,
                    days: 1,
                });
            }
        }

        return acc;
    }, []);

export const splitShiftsByBreak = (shiftsArray) =>
    shiftsArray.reduce((acc, shift) => {
        if (Boolean(shift.breakStart) && Boolean(shift.breakEnd)) {
            const splitShiftStartHours = calculateHourAndMinutesDifference([shift.breakStart, shift.start]);
            const splitShiftEndHours = calculateHourAndMinutesDifference([shift.end, shift.breakEnd]);
            const splitShiftUnpaidHours = shift.unpaidHours / 2;
            acc.push({
                ...shift,
                end: shift.breakStart,
                paidHours: splitShiftStartHours - splitShiftUnpaidHours,
                unpaidHours: splitShiftUnpaidHours,
            });
            acc.push({
                ...shift,
                start: shift.breakEnd,
                paidHours: splitShiftEndHours - splitShiftUnpaidHours,
                unpaidHours: splitShiftUnpaidHours,
            });
        } else {
            acc.push(shift);
        }

        return acc;
    }, []);

export const createPeriodSchedule = (listShiftsByDate, listTimeOffsByDate, start, end) => {
    const splitShiftsByNight = spreadNightShiftsPerDay(splitShiftsByBreak(listShiftsByDate));
    const splitShifts = splitShiftsByBreak(listShiftsByDate);
    const approvedTimeOffs = expandAllTimeOffs(listTimeOffsByDate, start, end);
    const bothShiftsTimeOffs = [...splitShifts, ...approvedTimeOffs];
    const bothShiftsTimeOffsSpread = [...splitShiftsByNight, ...approvedTimeOffs];

    const weekSchedule = (sched) =>
        sched
            .map((shift) => {
                let role = shift && shift.roleName ? shift.roleName : '';
                let confirmed = !!shift.worklogDayIsConfirmed;
                let isPaid = true;
                let startDate = moment(shift.start);
                let endDate = moment(shift.end);

                if (shift.type === 'day_off') {
                    role = 'Day Off';
                    startDate = startDate.startOf('day');
                    endDate = moment(startDate).endOf('day');
                    isPaid = false;
                }

                if (shift && shift.TimeOffType) {
                    role = shift.TimeOffType.name;
                    confirmed = shift.statusId === TimeoffTypes.approved;
                    isPaid = shift.TimeOffType.isPaid;
                }

                return {
                    shopName: shift.shopName,
                    departmentName: shift.departmentName || undefined,
                    shopId: shift.shopId,
                    role,
                    startDate: startDate.format('YYYY-MM-DD HH:mm'),
                    endDate: endDate.format('YYYY-MM-DD HH:mm'),
                    hoursPerDay: shift.hoursPerDay ?? shift.paidHours,
                    paidHours: shift.paidHours,
                    unpaidHours: shift.unpaidHours,
                    notes: shift.notes ? shift.notes : null,
                    timeOff: !shift.roleName,
                    days: shift.days ? shift.days : 0,
                    confirmed,
                    isPaid,
                    isTimeOff: shift.TimeOffType,
                };
            })
            .filter((s) => (s.isTimeOff ? s.confirmed : true));

    const shifts = weekSchedule(bothShiftsTimeOffs);
    const spreadShifts = weekSchedule(bothShiftsTimeOffsSpread);

    return {
        spreadShifts,
        shifts,
    };
};

export const findPercentageOfHoursWorked = (start, end) => {
    const hoursInShift = moment(end).diff(moment(start), formats.MINUTES) / 60;
    const hoursSinceShiftStarted = moment().diff(moment(start), formats.MINUTES) / 60;

    if (hoursSinceShiftStarted > 0) {
        const percentage = ((hoursSinceShiftStarted / hoursInShift) * 100).toFixed(2);

        return percentage <= 100 ? Number(percentage).toFixed() : 0;
    }

    return 0;
};

export const calcRotaStartDate = (date) => {
    if (date instanceof moment) {
        date = date.toDate();
    }
    const start = startOfWeek(date);
    return start;
};

export const formatRotaDate = (date) => {
    return format(date, 'yyyy-MM-dd');
};

export const scheduleEvents = (isStaffAvailabilityEnabled, scheduleData, unavailableDays, startDate, endDate) => {
    const schedule = isStaffAvailabilityEnabled
        ? populateStaffAvailabilityDays({
              schedule: scheduleData,
              unavailableDays,
              start: startDate,
              end: endDate,
          })
        : scheduleData;

    const cannotDisplayYourSchedule = schedule.length === 0;

    if (cannotDisplayYourSchedule) {
        return [];
    }

    return schedule.map((shift) => ({
        role: shift.role,
        shopName: shift.shopName,
        departmentName: shift.departmentName,
        start: new Date(moment(shift.startDate).format()),
        end: findEndTime(shift),
        notes: shift.notes ? shift.notes : null,
        timeOff: shift.timeOff,
        status: shift.status ? shift.status : null,
        sortedShiftAs: findShiftTense(shift),
        isStaffAvailabilityEvent: shift.isStaffAvailabilityEvent,
        paidHours: shift.paidHours,
        unpaidHours: shift.unpaidHours,
    }));
};
