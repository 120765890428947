import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid } from '@material-ui/core';

import Panel from 'components/Panel';
import ErrorMessage from 'components/ErrorMessage';
import { WeekDayRow } from './WeekDayRow';
import { WeekHourTotals } from './WeekHourTotals';
import { transformHoursWorkedData, calcWeekHourTotals } from './helpers';
import { useSettings } from 'services/useSettings';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { useAllStaffShiftsAndTimeOff } from 'services/schedule';
import { useSelector } from 'react-redux';
import { useTimeOffTypes } from 'services/useHoliday';
import moment from 'moment';
import LoadingComponent from 'components/LoadingComponent';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        padding: theme.spacing(6, 6, 0),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3, 2, 0),
        },
    },
    weekDayContainer: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            background: theme.palette.grey[100],
        },
    },
}));

export const HoursWorked = ({ id }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { settings, isSettingsLoading } = useSettings();

    const { allTimeOffTypes, isTimeOffTypesSuccess } = useTimeOffTypes();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const {
        periodSchedule = [],
        isPeriodScheduleLoading,
        isPeriodScheduleError,
    } = useAllStaffShiftsAndTimeOff(moment(startDate), moment(endDate), allTimeOffTypes, isTimeOffTypesSuccess);

    if (isPeriodScheduleError) {
        return null;
    }

    if (isPeriodScheduleLoading || isSettingsLoading) {
        return <LoadingComponent title={t('common:loading')} />;
    }

    const showHoursWithMinutes = settings?.localization?.showHoursWithMinutes;
    const weekScheduleData = transformHoursWorkedData(startDate, endDate, periodSchedule);
    const weekHourTotals = calcWeekHourTotals(periodSchedule);

    if (periodSchedule.length < 1) {
        return (
            <section className={classes.sectionContainer} id={id}>
                <Panel>
                    <div>
                        <ErrorMessage message={t('schedule:no_data_for_period')} />
                    </div>
                </Panel>
            </section>
        );
    }

    return (
        <section className={classes.sectionContainer} id={id} data-testid="hours-worked-tab-content">
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Panel>
                        <Grid container className={classes.weekDayContainer}>
                            {weekScheduleData.map((daySchedule) => (
                                <WeekDayRow
                                    key={daySchedule.dateDetails.day}
                                    daySchedule={daySchedule}
                                    showHoursWithMinutes={showHoursWithMinutes}
                                />
                            ))}
                        </Grid>
                        <WeekHourTotals weekHourTotals={weekHourTotals} showHoursWithMinutes={showHoursWithMinutes} />
                    </Panel>
                </Grid>
            </Grid>
        </section>
    );
};

HoursWorked.propTypes = {
    id: PropTypes.string.isRequired,
};
