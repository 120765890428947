import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const InactiveContractsCheckbox = ({ isChecked, setIsChecked }) => {
    const { t } = useTranslation();
    const label = t('user:show_inactive_contracts');

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={setIsChecked} name={label} color="primary" />}
                label={label}
            />
        </FormGroup>
    );
};

InactiveContractsCheckbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    setIsChecked: PropTypes.func.isRequired,
};
