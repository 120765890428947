import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/useSettings';
import { useTenant } from 'services/auth';

const useStyles = makeStyles((theme) => ({
    baseStyles: {
        width: 'auto',
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: theme.menu.appBarMinSize,
        },
    },
    imgBaseStyles: {
        width: 'auto',
        display: 'block',
        maxHeight: 41,
        [theme.breakpoints.down('xs')]: {
            maxWidth: theme.menu.appBarMinSize * 2,
        },
    },
}));

export const ClientLogo = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const tenant = useTenant();

    const { settings } = useSettings();
    if (!settings) {
        return null;
    }

    return (
        <Link to="/" className={classes.baseStyles} title={t('portal:home')}>
            <img src={tenant.data.logo} alt={settings.general.clientName} className={classes.imgBaseStyles} />
        </Link>
    );
};
