import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Grid, Typography, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SecondaryShopPlaceholder } from './SecondaryShopPlaceholder';

import { formatShiftPeriod, calcShopScheduleDisplayText, normaliseEventsForDialog } from './helpers';
import { useIsMobileOrTablet, useIsTabletOrLarger } from 'utils/components/helpers';
import { VARIABLE_UNAVAILABLE } from 'utils/constants';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { setDialogOpen, setDialogEvents } from 'containers/Schedule/store/scheduleSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    baseStyles: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    shiftCardBaseStyles: {
        border: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: 'none',
        width: 90,
        [theme.breakpoints.down('md')]: {
            width: 75,
        },
        [theme.breakpoints.down('sm')]: {
            width: 60,
        },
        [theme.breakpoints.down('xs')]: {
            width: 15,
            height: 15,
            borderRadius: '50%',
            background: theme.palette.grey[300],
        },
    },
    innerPadding: {
        padding: '5px 10px',
    },
    startdardShiftIndicatorTextStyles: {
        fontSize: 11,
        color: theme.palette.grey[600],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    currentUserBaseStyles: {
        border: `1px solid ${theme.palette.primary.main}`,
        [theme.breakpoints.down('xs')]: {
            border: `1px solid ${theme.palette.primary.main}`,
            background: theme.palette.primary.main,
        },
    },
    currentDayStyles: {
        background: theme.palette.primary.dark,
        [theme.breakpoints.down('xs')]: {
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    inverseText: {
        color: theme.palette.common.white,
    },
    lighterText: {
        color: theme.palette.grey[500],
    },
    multipleShifts: {
        margin: '5px 0px',
    },
    cursor: {
        cursor: 'pointer',
    },
}));

export const StaffShiftIndicator = ({ shiftObject, isCurrentUser, currentShop }) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const { moment } = useMoment();
    const dispatch = useDispatch();

    const isMobileOrTablet = useIsMobileOrTablet();
    const isTabletOrLarger = useIsTabletOrLarger();

    if (shiftObject.working === false) {
        return (
            <Grid container className={classes.baseStyles}>
                <Grid item>
                    <Typography className={classes.lighterText}>
                        {!isCurrentUser && shiftObject.placeholderText_mobile}
                        {isCurrentUser &&
                            (isMobileOrTablet ? t(shiftObject.placeholderText)[0] : t(shiftObject.placeholderText))}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    const openEventDialog = (shiftEvent) => {
        if (!shiftEvent) {
            return null;
        }

        dispatch(setDialogEvents(normaliseEventsForDialog(shiftEvent)));
        dispatch(setDialogOpen());
    };

    if (shiftObject.working) {
        const activitySortedByUnavailable = [...shiftObject.activity].sort((a, b) => {
            if (a.type === VARIABLE_UNAVAILABLE) {
                return -1;
            }
            if (b.type === VARIABLE_UNAVAILABLE) {
                return 1;
            }

            return 0;
        });

        return (
            <Grid
                container
                className={classNames({
                    [classes.baseStyles]: true,
                })}
            >
                <Grid item>
                    {activitySortedByUnavailable.map((shift, index) => {
                        if (shift.type === VARIABLE_UNAVAILABLE) {
                            return (
                                <Typography key={index} className={classes.lighterText}>
                                    {isMobileOrTablet ? shift.roleName[0] : shift.roleName}
                                </Typography>
                            );
                        }

                        const displayText = calcShopScheduleDisplayText(shift.roleName, shift.type);

                        const shiftTimes = formatShiftPeriod(moment(shift.start), moment(shift.end));
                        const isCurrentUserAndNotCurrentShop =
                            currentShop && isCurrentUser && currentShop.shopId !== shift.shopId;

                        if (isCurrentUserAndNotCurrentShop && shift.type === 'shift') {
                            return <SecondaryShopPlaceholder shift={shift} key={index} />;
                        }

                        return (
                            <Card
                                key={index}
                                className={classNames({
                                    [classes.shiftCardBaseStyles]: true,
                                    [classes.currentUserBaseStyles]: isCurrentUser,
                                    [classes.currentDayStyles]: shiftObject.isToday && isCurrentUser,
                                    [classes.multipleShifts]: shiftObject.activity.length > 1,
                                    [classes.cursor]: isCurrentUser && !isMobileOrTablet,
                                })}
                                onClick={() => isCurrentUser && !isMobileOrTablet && openEventDialog(shift)}
                            >
                                {isTabletOrLarger && (
                                    <div
                                        className={classNames({
                                            [classes.innerPadding]: true,
                                        })}
                                    >
                                        <Typography
                                            className={classNames({
                                                [classes.startdardShiftIndicatorTextStyles]: true,
                                                [classes.inverseText]: shiftObject.isToday && isCurrentUser,
                                            })}
                                            title={displayText.key ? t(displayText.key) : displayText}
                                        >
                                            {displayText.key ? t(displayText.key) : displayText}
                                        </Typography>
                                        {shift.type === 'shift' && (
                                            <Typography
                                                className={classNames({
                                                    [classes.startdardShiftIndicatorTextStyles]: true,
                                                    [classes.inverseText]: shiftObject.isToday && isCurrentUser,
                                                })}
                                                title={shiftTimes}
                                            >
                                                {shiftTimes}
                                            </Typography>
                                        )}
                                    </div>
                                )}
                            </Card>
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
};

StaffShiftIndicator.propTypes = {
    shiftObject: PropTypes.object.isRequired,
    isCurrentUser: PropTypes.bool.isRequired,
    currentShop: PropTypes.shape({
        shopId: PropTypes.number.isRequired,
        shopName: PropTypes.string.isRequired,
    }),
};
