import moment from 'moment';

export const isOutsideRange = (day) => day.isBefore(moment(), 'day');

export const isDayHighlighted = (day, errorInDates) => {
    if ([...errorInDates].includes(day.format('YYYY-MM-DD'))) {
        return true;
    }

    return false;
};
