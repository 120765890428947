import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Dialog, DialogContent, LinearProgress, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';

import { useIsMobileOrTablet } from 'utils/components/helpers';

import ErrorMessage from 'components/ErrorMessage';
import { SideBar } from './components/SideBar';
import { RequestContent } from './requestContent/RequestContent';
import { MobileRequestTimeOff } from './mobileRequestTimeOff/MobileRequestTimeOff';

import { useTimeOffTypes, useBlockedTimeOff, useApprovedTimeOff } from 'services/useHoliday';
import { useIsTimeOffEnabled, useSettings } from 'services/useSettings';

import { setStateToDefault, setShowTooltip } from './requestTimeOffSlice';
import { selectRequestedTimeOffType } from './requestTimeOffSelectors.js';

const useStyles = makeStyles((theme) => ({
    desktopRequestTimeOff: {
        minHeight: '100%',
        display: 'flex',
    },
    closeButton: {
        zIndex: 1000,
        position: 'absolute',
        top: 10,
        right: 10,
    },
    dialogRoot: {
        [theme.breakpoints.down('sm')]: {
            zIndex: theme.zIndex.modal + 2,
        },
    },
    removeScroll: (props) => ({
        overflowY: !props.isTimeOffType && 'hidden',
        overflowX: !props.isTimeOffType && 'hidden',
        padding: props.isTimeOffType && '0 5px 0 0',
    }),
    dialogContent: {
        padding: 0,
        '&:first-child': {
            paddingTop: 0,
        },
    },
    paperScrollPaper: {
        height: '100%',
    },
}));

export const RequestTimeOff = ({ button, onClick }) => {
    const timeOffType = useSelector(selectRequestedTimeOffType);
    const isTimeOffEnabled = useIsTimeOffEnabled();

    const classes = useStyles({ isTimeOffType: Boolean(timeOffType.id) });
    const dispatch = useDispatch();
    const isMobileOrTablet = useIsMobileOrTablet();
    const { t } = useTranslation();

    const { settings, isSettingsLoading, isSettingsError } = useSettings();
    const { isTimeOffTypesLoading, isTimeOffTypesError } = useTimeOffTypes();

    const blockedPeriodsEnabled = settings?.timeOff?.blockedPeriods?.enabled ?? false;
    const { isBlockedTimeOffLoading, isBlockedTimeOffError } = useBlockedTimeOff(blockedPeriodsEnabled);
    const { isApprovedTimeOffLoading, isApprovedTimeOffError } = useApprovedTimeOff();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    if (timeOffType) {
        dispatch(setShowTooltip(false));
    }

    if (isSettingsLoading || isTimeOffTypesLoading || isBlockedTimeOffLoading || isApprovedTimeOffLoading) {
        return <LinearProgress color="secondary" variant="query" />;
    }

    if (isSettingsError || isTimeOffTypesError || isBlockedTimeOffError || isApprovedTimeOffError) {
        return <ErrorMessage message={t('portal:generic_error_message')} />;
    }

    const buttonComponent = React.cloneElement(button, {
        onClick: () => {
            setIsDialogOpen(!isDialogOpen);

            if (onClick) {
                onClick();
            }
        },
    });

    return (
        <>
            {isTimeOffEnabled && buttonComponent}
            <Dialog
                className={classes.dialogRoot}
                fullScreen={isMobileOrTablet}
                open={isDialogOpen}
                fullWidth
                maxWidth="md"
                classes={{ paperScrollPaper: classes.paperScrollPaper }}
            >
                <DialogContent className={classes.dialogContent} classes={{ root: classes.removeScroll }}>
                    {isMobileOrTablet ? (
                        <MobileRequestTimeOff setIsDialogOpen={setIsDialogOpen} />
                    ) : (
                        <div className={classes.desktopRequestTimeOff}>
                            <SideBar />
                            <RequestContent setIsDialogOpen={setIsDialogOpen} />
                            <IconButton
                                className={classes.closeButton}
                                onClick={() => {
                                    dispatch(setStateToDefault());
                                    setIsDialogOpen(!isDialogOpen);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

RequestTimeOff.propTypes = {
    button: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};
