import { number, func, array, bool } from 'prop-types';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import { getCurrentRevision, isContractValid } from '../containers/timeOff/helpers';
import ContractTab from './ContractTab';

const useStyles = makeStyles((theme) => ({
    tabText: {
        textTransform: 'capitalize',
        fontWeight: '600',
        fontSize: '12px',
        letterSpacing: '0.5px',
        color: theme.palette.common.black,
    },
    indicator: {
        background: theme.palette.common.white,
    },
    tabRoot: {
        background: theme.palette.grey[400],
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        minHeight: '35px',
    },
    selectedTab: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        borderLeft: 'none',
    },
    tabsRoot: {
        position: 'relative',
        '& > .MuiTabs-scroller > .MuiTabs-flexContainer': {
            height: '100%',
        },
    },
    singleTabsRoot: {
        position: 'relative',

        '& > .MuiTabs-scroller > .MuiTabs-flexContainer': {
            height: '100%',
            [theme.breakpoints.only('xs')]: {
                '& > button': {
                    minWidth: '100%',
                },
            },
        },
    },
    flexContainer: {
        height: '100%',
    },
    scrollButtonsDesktop: {
        background: theme.palette.common.white,
    },
    scroller: {
        width: 1,
    },
}));

export const ContractTabs = ({ tabValue, setTabValue, contracts, withRole }) => {
    const classes = useStyles();
    return (
        <Tabs
            value={tabValue}
            onChange={(e, value) => setTabValue(value)}
            classes={{
                root: contracts.length > 1 ? classes.tabsRoot : classes.singleTabsRoot,
                indicator: classes.indicator,
                flexContainer: classes.flexContainer,
                scrollButtons: classes.scrollButtonsDesktop,
                scroller: classes.scroller,
            }}
            variant="scrollable"
        >
            {contracts.map((contract, index) => {
                const { id, positionName, shopName } = getCurrentRevision(contract);
                return (
                    <Tab
                        key={`${id}`}
                        label={
                            <ContractTab
                                positionName={positionName}
                                shopName={shopName}
                                includeRole={withRole}
                                isTerminated={!isContractValid(contract)}
                                isSelected={tabValue === index}
                            />
                        }
                        classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                        }}
                    />
                );
            })}
        </Tabs>
    );
};

ContractTabs.propTypes = {
    tabValue: number.isRequired,
    setTabValue: func.isRequired,
    contracts: array.isRequired,
    withRole: bool,
};
