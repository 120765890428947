import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    IconButton,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getExportStaffDocument } from 'api/documents';
import { setSnackBarError, setShowSnackbar } from '../../components/snackbar/eventSnackBarSlice';
import { sendGASelectContent } from 'googleAnalytics';
import { GA_EVENTS } from 'lib/consts';

const useStyles = makeStyles((theme) => ({
    headerText: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    nameCell: {
        fontWeight: 500,
    },
    dataCell: {
        fontWeight: 400,
    },
}));

export const DocumentsTable = ({ headerRows, tableContents }) => {
    const classes = useStyles();
    const isMobile = useIsMobileOrTablet();
    const dispatch = useDispatch();

    const [isExportLoading, setIsExportLoading] = useState(false);

    const handleExportFile = async (file) => {
        sendGASelectContent(GA_EVENTS.DOWNLOAD_DOCUMENT);

        setIsExportLoading(true);

        try {
            const callExportFile = await getExportStaffDocument(file.id);

            const fileExt = file.originalFilename.split('.')[file.originalFilename.split('.').length - 1];

            const blob = new Blob([callExportFile.data], { type: file.mimeType });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${file.name}.${fileExt}`);

            document.body.appendChild(link);

            link.click();
        } catch (err) {
            dispatch(setSnackBarError({ message: 'portal:generic_error_message' }));
            dispatch(setShowSnackbar({ open: true }));
        } finally {
            setIsExportLoading(false);
        }
    };

    return (
        <Table>
            <TableHead>
                {headerRows.map((headerName) => (
                    <TableCell key={headerName}>
                        <Typography className={classes.headerText}>{headerName}</Typography>
                    </TableCell>
                ))}
            </TableHead>
            <TableBody>
                {tableContents.map((row) => (
                    <TableRow key={row.name}>
                        <TableCell>
                            <Typography className={classes.nameCell}>{row.name}</Typography>
                        </TableCell>
                        {!isMobile && (
                            <>
                                <TableCell>
                                    <Typography className={classes.dataCell}>
                                        {moment(row.createdAt).format('DD/MM/YYYY')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography className={classes.dataCell}>
                                        {moment(row.expiresAt).format('DD/MM/YYYY')}
                                    </Typography>
                                </TableCell>
                            </>
                        )}
                        <TableCell>
                            <IconButton
                                onClick={() => handleExportFile(row)}
                                disabled={isExportLoading || !row.isDownloadable}
                            >
                                <GetApp color={row.isDownloadable ? 'primary' : 'default'} fontSize="large" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const documentRows = PropTypes.shape({
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
    expiresAt: PropTypes.string,
    lastExported: PropTypes.string,
});

DocumentsTable.propTypes = {
    headerRows: PropTypes.arrayOf(PropTypes.string).isRequired,
    tableContents: PropTypes.arrayOf(documentRows).isRequired,
};
