import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    card: {
        maxWidth: 'calc(100vw - 40px)',
    },
    header: {
        backgroundColor: theme.palette.primary.main,
    },
    title: {
        color: 'white',
    },
}));

export const ProfileCard = ({ header, children }) => {
    const classes = useStyle();

    return (
        <Card className={classes.card}>
            <CardHeader
                title={header}
                classes={{
                    root: classes.header,
                    title: classes.title,
                }}
            />
            <CardContent>{children}</CardContent>
        </Card>
    );
};

ProfileCard.propTypes = {
    header: PropTypes.string.isRequired,
    children: PropTypes.node,
};
