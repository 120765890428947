import { isMystaffTenant } from 'utils/tenant';

export const getTenantName = () => {
    const url = window.location.hostname;
    const staticTenant = import.meta.env.VITE_GATEWAY_TENANT;
    if (staticTenant) {
        return staticTenant;
    }
    const parsedTenant = parseTenantFromUrl(url);
    return parsedTenant;
};

export const parseTenantFromUrl = (url) => {
    if (!url.includes('.')) {
        return undefined;
    }
    const parsedTenant = url.split('.')[0];
    if (isMystaffTenant(url)) {
        return `${parsedTenant}.ie`;
    }
    return parsedTenant;
};
