import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useLocation, useHistory, Route } from 'react-router-dom';
import { ScheduleContent } from 'containers/Schedule/ScheduleContent';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '80px',
        },
    },
}));

export const SchedulePage = () => {
    const classes = useStyles();

    const { pathname } = useLocation();
    const { push } = useHistory();

    const tabValue = pathname.split('/').reverse()[0];

    useEffect(() => {
        if (tabValue === 'schedule') {
            push({ pathname: '/schedule/your-schedule' });
        }
    }, [tabValue, push]);

    return (
        <Route exact path="/schedule/:tabValue">
            <div className={classes.pageContainer}>
                <ScheduleContent />
            </div>
        </Route>
    );
};
