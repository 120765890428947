/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Snackbar, SnackbarContent, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import Close from '@material-ui/icons/Close';
import classnames from 'classnames';
import { selectShowSnackbar, selectSnackBarError, selectSnackBarMessage } from './eventSnackBarSelector';
import { setShowSnackbar } from './eventSnackBarSlice';

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: theme.palette.primary.light,
    },
    error: {
        backgroundColor: theme.palette.danger.main,
    },
    loading: {
        backgroundColor: theme.palette.secondary.light,
    },
    icon: {
        fontSize: 20,
    },
    loadingIcon: {
        marginRight: '10px',
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
    },
    root: {
        flexWrap: 'nowrap',
    },
}));

export const EventSnackBar = ({
    anchorOrigin = {
        horizontal: 'center',
        vertical: 'top',
    },
    autoHideDuration = 3000,
    SnackbarContentProps,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showSnackbar = useSelector(selectShowSnackbar);
    const snackBarError = useSelector(selectSnackBarError);
    const snackBarMessage = useSelector(selectSnackBarMessage);

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={showSnackbar}
            onClose={() => dispatch(setShowSnackbar({ open: false }))}
            autoHideDuration={autoHideDuration}
        >
            <SnackbarContent
                message={
                    <div className={classes.message}>
                        {snackBarError ? (
                            <Error className={classnames(classes.icon, classes.iconVariant)} />
                        ) : (
                            <CheckCircle className={classnames(classes.icon, classes.iconVariant)} />
                        )}
                        <div> {t(snackBarMessage)}</div>
                    </div>
                }
                className={classnames({
                    [classes.error]: snackBarError,
                    [classes.success]: !snackBarError,
                    [classes.root]: true,
                })}
                action={
                    <IconButton color="inherit" onClick={() => dispatch(setShowSnackbar({ open: false }))}>
                        <Close className={classes.icon} />
                    </IconButton>
                }
                {...SnackbarContentProps}
            />
        </Snackbar>
    );
};

EventSnackBar.propTypes = {
    SnackbarContentProps: PropTypes.object,
    autoHideDuration: PropTypes.number,
    anchorOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOf(['center', 'left', 'right']),
        vertical: PropTypes.oneOf(['bottom', 'top']),
    }),
};
