import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles, FormControlLabel, Switch, Collapse } from '@material-ui/core';
import CheckBox from 'components/checkboxes/Checkbox';
import { RotaNotificationTypePreference } from './RotaNotificationTypePreference';
import { setShowSnackbar, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';

import { useUser } from 'services/auth';
import { useUpdatePreferences } from 'services/editSelf';

const useStyles = makeStyles({
    label: {
        width: '100%',
        margin: 0,
        justifyContent: 'space-between',
    },
});

export const RotaNotificationPreferences = ({ onDoNotNotify }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { updatePreferencesMutation } = useUpdatePreferences();
    const {
        userDetails: {
            preferences: { rota },
        },
    } = useUser();

    const [shouldNotify, setShouldNotify] = useState(rota.textOnApproval || rota.emailOnApproval);
    const [notifyByEmail, setNotifyByEmail] = useState(rota.emailOnApproval);
    const [notifyByText, setNotifyByText] = useState(rota.textOnApproval);

    const onChange = (event) => {
        const key = event.target.name;
        const value = !rota[key];

        updatePreferencesMutation.mutate({ type: 'rota', key, value });
    };

    if (updatePreferencesMutation.isError) {
        dispatch(setSnackBarError({ message: 'user:error_saving_preferences' }));
        dispatch(setShowSnackbar({ open: true }));

        updatePreferencesMutation.reset();
    }

    return (
        <>
            <FormControlLabel
                classes={{ root: classes.label }}
                onChange={(event) => {
                    setShouldNotify(!shouldNotify);

                    if (!event.currentTarget.checked) {
                        onDoNotNotify();
                    }
                }}
                control={<Switch checked={shouldNotify} color="primary" />}
                label={t('user:notify_me_when_rota_approved')}
                labelPlacement="start"
                data-testid="Preferences-RotaNotificationPreferences"
            />

            <Collapse in={shouldNotify}>
                <div style={{ display: 'flex' }}>
                    <CheckBox
                        onClick={(e) => {
                            setNotifyByEmail(e.target.checked);
                            onChange(e);
                        }}
                        checked={notifyByEmail}
                        name="emailOnApproval"
                        color="primary"
                        label={t('user:by_email')}
                    />
                    <CheckBox
                        onClick={(e) => {
                            setNotifyByText(e.target.checked);
                            onChange(e);
                        }}
                        checked={notifyByText}
                        name="textOnApproval"
                        color="primary"
                        label={t('user:by_sms')}
                    />
                </div>

                <RotaNotificationTypePreference />
            </Collapse>
        </>
    );
};

RotaNotificationPreferences.propTypes = {
    onDoNotNotify: PropTypes.func.isRequired,
};
