import { useState } from 'react';
import { makeStyles, Grid, Hidden, FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { filterTimeOffData, sortTimeOffRequests } from 'components/widgets/timeOffRequest/helpers';
import TimeOffDetails from 'components/widgets/timeOffRequest/TimeOffDetails';
import { TimeOffTabButton } from 'components/widgets/timeOffRequest/TimeOffTabButton';
import { useIsMobile, useIsMobileOrTablet } from 'utils/components/helpers';
import InputLabel from 'components/typography/InputLabel';

const useStyles = makeStyles((theme) => ({
    timeOffRequestDataContainer: {
        height: 313,
        overflow: 'hidden',
        overflowY: 'scroll',
        border: `1px solid ${theme.palette.grey.light}`,
    },
    mobileDataContainer: {
        border: `1px solid ${theme.palette.grey.light}`,
        height: 'fit-content',
    },
    mobileContainer: {
        justifyContent: 'center',
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
        minWidth: 144,
    },
    selectLabel: {
        marginRight: 8,
        color: theme.palette.primary.dark,
        fontWeight: '600',
    },
    select: {
        padding: '3px 30px 3px 15px',
        minWidth: 80,
    },
    mobileCard: {
        height: 'fit-content',
    },
}));

export const TimeOffRequests = ({ userTimeOff }) => {
    const classes = useStyles();
    const isMobileOrTablet = useIsMobileOrTablet();
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const timeOffTabs = [
        {
            status: 'approved',
            text: t('entitlement:approved'),
        },
        {
            status: 'requested',
            text: t('portal:pending'),
        },
        {
            status: 'declined',
            text: t('time_off:declined_label'),
        },
        {
            status: 'cancelled',
            text: t('time_off:cancelled_label'),
        },
    ];

    const timeOffTypes = [
        {
            userTimeOffDataType: filterTimeOffData(userTimeOff, 'approved'),
        },
        {
            userTimeOffDataType: filterTimeOffData(userTimeOff, 'requested'),
        },
        {
            userTimeOffDataType: filterTimeOffData(userTimeOff, 'declined'),
        },
        {
            userTimeOffDataType: filterTimeOffData(userTimeOff, 'cancelled'),
        },
        {
            userTimeOffDataType: sortTimeOffRequests(userTimeOff),
        },
    ];

    const [selectedTab, setSelectedTab] = useState(4);

    if (isMobileOrTablet && selectedTab === 4) {
        setSelectedTab(0);
    }

    const handleSelectChange = (e) => {
        setSelectedTab(e.target.value);
    };

    return (
        <>
            <Grid container className={isMobile ? classes.mobileContainer : null}>
                <Grid item>
                    {isMobile ? (
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel className={classes.selectLabel}>Status</InputLabel>
                            <Select
                                defaultValue
                                data-testid="select"
                                classes={{ select: classes.select }}
                                value={selectedTab}
                                onChange={handleSelectChange}
                            >
                                {timeOffTabs.map((tab, index) => (
                                    <MenuItem key={tab.status} value={index}>
                                        <ListItemText primary={tab.text} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        timeOffTabs.map((tab, index) => (
                            <TimeOffTabButton
                                key={tab.status}
                                id={index}
                                classKey={tab.status}
                                number={filterTimeOffData(userTimeOff, tab.status).length}
                                text={tab.text}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                            />
                        ))
                    )}

                    <Hidden smDown>
                        <TimeOffTabButton
                            id={4}
                            text={t('time_off:history_label')}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    </Hidden>
                </Grid>
            </Grid>

            <div className={isMobile ? classes.mobileDataContainer : classes.timeOffRequestDataContainer}>
                {timeOffTypes.map(
                    (type, index) =>
                        selectedTab === index && (
                            <TimeOffDetails
                                key={index}
                                data={type.userTimeOffDataType.reverse()}
                                errorMessage={t('time_off:errors.no_data_message')}
                                cardClassName={isMobile ? classes.mobileCard : null}
                            />
                        ),
                )}
            </div>
        </>
    );
};

TimeOffRequests.propTypes = {
    userTimeOff: array.isRequired,
};
