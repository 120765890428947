import { Typography, makeStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '30vh',
    },
    iconContainer: {
        border: 'none',
        borderRadius: '50%',
        background: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        cursor: 'pointer',
        '&:hover': {
            transform: 'rotate(360deg)',
            transition: 'all 350ms linear 0s',
        },
    },
    refreshIcon: {
        height: 120,
        width: 120,
        color: theme.palette.primary.main,
    },
}));

export const ErrorPage = ({ mainMessage, subMessage, refreshButtonTitle }) => {
    const classes = useStyles();

    return (
        <div className={classes.errorContainer}>
            <Typography style={{ fontSize: 45 }}>{mainMessage}</Typography>
            <button
                className={classes.iconContainer}
                onClick={() => {
                    window.location.reload();
                }}
                title={refreshButtonTitle}
            >
                <RefreshIcon className={classes.refreshIcon} />
            </button>
            <Typography style={{ fontSize: 28 }}>{subMessage}</Typography>
        </div>
    );
};

ErrorPage.propTypes = {
    mainMessage: PropTypes.string,
    subMessage: PropTypes.string,
    refreshButtonTitle: PropTypes.string,
};
