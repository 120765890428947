import { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { DialogSW } from 'components/dialog/DialogSW';
import { VARIABLE_PRIMARY } from 'utils/constants';
import { useSendCancelRequest } from 'services/useHoliday';
import { setShowSnackbar, setSnackBarError, setSnackBarSuccess } from '../../../snackbar/eventSnackBarSlice';
import { selectDialogItem, selectIsConfirmDialogOpen } from 'containers/timeOff/timeOffSelectors';
import { toggleConfirmDialog, setDialogItem } from 'containers/timeOff/timeOffSlice';
import { useIsMobile } from 'utils/components/helpers';
import { sendGASelectContent } from 'googleAnalytics';
import { GA_EVENTS } from 'lib/consts';

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    mobileButtonContainer: {
        flexDirection: 'column-reverse',
    },
    columnButton: {
        marginBottom: '1em',
    },
});

export const TimeOffCancelConfirmDialog = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const dialogItem = useSelector(selectDialogItem);
    const isConfirmDialogOpen = useSelector(selectIsConfirmDialogOpen);

    const dispatch = useDispatch();

    const { sendCancelRequestMutation } = useSendCancelRequest();

    const [loading, setLoading] = useState(false);

    const handleSendCancelRequest = () => {
        sendGASelectContent(GA_EVENTS.CANCEL_TIME_OFF);
        setLoading(true);
        sendCancelRequestMutation.mutate(
            { dialogItemId: dialogItem.id },
            {
                onSuccess: () => {
                    dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.time_off_request_cancellation_success' }));
                },
                onError: (error) => {
                    const errorMsg = error.response.data?.errors[0]?.code || 'portal:snack_bar.error';

                    dispatch(setSnackBarError({ message: errorMsg }));
                },
                onSettled: () => {
                    dispatch(setDialogItem(null));
                    dispatch(toggleConfirmDialog(false));
                    dispatch(setShowSnackbar({ open: true }));
                },
            },
        );
        setLoading(false);
    };

    const getConfirmDialogButtons = () => (
        <div className={classNames(classes.buttonContainer, { [classes.mobileButtonContainer]: isMobile })}>
            <Button
                onClick={() => dispatch(toggleConfirmDialog(!isConfirmDialogOpen))}
                color={VARIABLE_PRIMARY}
                variant="outlined"
            >
                {t('common:cancel')}
            </Button>
            <Button
                onClick={() => handleSendCancelRequest()}
                color={VARIABLE_PRIMARY}
                variant="contained"
                className={isMobile ? classes.columnButton : null}
            >
                {t('common:confirm')}
            </Button>
        </div>
    );

    return (
        <DialogSW
            title={`${t('time_off:time_off_request')}`}
            buttons={getConfirmDialogButtons()}
            open={isConfirmDialogOpen}
            onClose={() => dispatch(toggleConfirmDialog(!isConfirmDialogOpen))}
            isLoading={loading}
        >
            <div>{t('time_off:confirm_cancelling_time_off')}</div>
        </DialogSW>
    );
};
