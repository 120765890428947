import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import PasswordResetModal from './PasswordResetModal';
import DefaultButton from 'components/buttons/DefaultButton';
import { useForgottenPassword } from 'services/auth';

const useStyles = makeStyles((theme) => ({
    buttonStyles: {
        fontSize: 12,
        color: theme.palette.secondary.light,
        padding: 0,
    },
}));

export const PasswordResetContainer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { forgottenPasswordMutation } = useForgottenPassword();

    const [emailValue, setEmail] = useState('');
    const [isError, setError] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <DefaultButton
                className={classes.buttonStyles}
                color="primary"
                onClick={() => {
                    setIsModalOpen(true);
                    setEmail('');
                    setError(false);
                    setSuccess(false);
                }}
            >
                {t('user:forgot_password')}
            </DefaultButton>

            <PasswordResetModal
                handleCloseModal={() => setIsModalOpen(!isModalOpen)}
                handleInputChange={(event) => setEmail(event.target.value)}
                handleFormSubmit={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setLoading(true);
                    setError(false);
                    setSuccess(false);

                    forgottenPasswordMutation.mutate(emailValue, {
                        onSuccess: () => {
                            setError(false);
                            setSuccess(true);
                            setLoading(false);
                        },
                        onError: () => {
                            setSuccess(false);
                            setError(true);
                            setLoading(false);
                        },
                    });
                }}
                value={emailValue}
                error={isError}
                loading={isLoading}
                success={isSuccess}
                isModalOpen={isModalOpen}
            />
        </>
    );
};
