import { createSlice } from '@reduxjs/toolkit';
import MenuStorage from 'utils/layouts/menu/store';

const menuStorage = new MenuStorage();

const initialState = {
    isMultiWeekTab: false,
    desktopMenuOpen: menuStorage.getItem('desktopMenuOpen'),
    mobileMenuOpen: false,
    userMenuOpen: false,
    menuLinks: {
        main: [],
        user: [],
    },
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setIsMultiWeekTab(state, { payload }) {
            state.isMultiWeekTab = payload.isMultiWeekTab;
        },
        setDesktopMenuOpen(state) {
            menuStorage.setItem('desktopMenuOpen', !state.desktopMenuOpen);
            state.desktopMenuOpen = !state.desktopMenuOpen;
        },
        setMobileMenuOpen(state, { payload }) {
            state.mobileMenuOpen = payload.mobileMenuOpen;
        },
        setUserMenuOpen(state, { payload }) {
            state.userMenuOpen = payload.userMenuOpen;
        },
        setMenuLinks(state, { payload }) {
            state.menuLinks = { ...payload.menuLinks };
        },
    },
});

export const {
    reducer: menuReducer,
    actions: { setIsMultiWeekTab, setDesktopMenuOpen, setMobileMenuOpen, setUserMenuOpen, setMenuLinks },
} = menuSlice;
