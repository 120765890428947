import PropTypes from 'prop-types';
import H5 from 'components/typography/H5';
import { Grid, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    errorIconStyle: {
        color: theme.palette.primary.main,
        fontSize: 80,
    },
}));

const ErrorMessage = ({ className, message, alignText, style }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container direction="column" alignItems="center" className={className} style={style && { ...style }}>
            <Grid item xs>
                <ErrorIcon title={t('common:error_title')} aria-hidden="true" className={classes.errorIconStyle} />
            </Grid>
            <Grid item xs>
                <H5 align={alignText}>{message}</H5>
            </Grid>
        </Grid>
    );
};

ErrorMessage.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
    style: PropTypes.object,
    alignText: PropTypes.string,
};

export default ErrorMessage;
