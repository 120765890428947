import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { RotaDatesPanel } from './RotaDatesPanel';
import { RotaNamesPanel } from './RotaNamesPanel';
import { RotaShiftsPanel } from './RotaShiftsPanel';
import { SHIFTS_PANEL_ID, NAMES_PANEL_ID, DATES_PANEL_ID } from './rotaIds';

import { selectDesktopMenuOpen } from 'components/layouts/menuSelector';

const useStyles = makeStyles((theme) => ({
    rotaPosition: {
        display: 'flex',
        width: '100vw',
        justifyContent: 'center',
        maxHeight: 'calc(100vh - 270px)',
        overflow: 'hidden',
    },
    drawerOpenWidth: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100vw - ${theme.menu.drawerWidth}px)`,
        },
    },
    drawerClosedWidth: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100vw - ${theme.menu.appBarMinSize}px)`,
        },
    },
    rotaContainer: {
        display: 'flex',
        flexFlow: 'column',
        overflow: 'hidden',
    },
    rotaShop: {
        padding: 14,
    },
    rotaTable: {
        display: 'flex',
        border: theme.rota.border,
        borderBottom: theme.rota.border,
        maxHeight: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    namesAndShitsContainer: {
        height: `calc(100% - ${theme.rota.daysRowHeight}px)`,
        display: 'flex',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            height: '35vh',
        },
    },
}));

export const Rota = ({ dateRangeCalendar, shopName, staffPersonalInfo, isoWeekEnd }) => {
    const classes = useStyles();
    const isDesktopMenuOpen = useSelector(selectDesktopMenuOpen);

    const updatePanels = () => {
        const container = document.querySelector(`#${SHIFTS_PANEL_ID}`);
        const leftPanel = document.querySelector(`#${NAMES_PANEL_ID}`);
        const topPanel = document.querySelector(`#${DATES_PANEL_ID}`);

        if (!container || !leftPanel) {
            return;
        }

        leftPanel.style.top = `-${container.scrollTop}px`;
        topPanel.style.left = `-${container.scrollLeft}px`;
    };

    useEffect(() => {
        let updating = false;
        const contentContainer = document.querySelector(`#${SHIFTS_PANEL_ID}`);

        if (!contentContainer) {
            return;
        }

        const handleScroll = () => {
            if (!updating) {
                window.requestAnimationFrame(() => {
                    updatePanels();
                    updating = false;
                });

                updating = true;
            }
        };

        contentContainer.addEventListener('scroll', handleScroll);

        return () => contentContainer.removeEventListener('scroll', handleScroll);
    });

    return (
        <div
            className={classNames({
                [classes.rotaPosition]: true,
                [classes.drawerOpenWidth]: isDesktopMenuOpen,
                [classes.drawerClosedWidth]: !isDesktopMenuOpen,
            })}
        >
            <div className={classes.rotaContainer}>
                <Typography classes={{ root: classes.rotaShop }} variant="h5">
                    {shopName}
                </Typography>
                <div className={classes.rotaTable}>
                    <RotaDatesPanel dateRangeCalendar={dateRangeCalendar} isoWeekEnd={isoWeekEnd} />
                    <div className={classes.namesAndShitsContainer}>
                        <RotaNamesPanel staffPersonalInfo={staffPersonalInfo} />
                        <RotaShiftsPanel isoWeekEnd={isoWeekEnd} dateRangeCalendar={dateRangeCalendar} />
                    </div>
                </div>
            </div>
        </div>
    );
};

Rota.propTypes = {
    dateRangeCalendar: PropTypes.arrayOf(PropTypes.string).isRequired,
    isoWeekEnd: PropTypes.number.isRequired,
    shopName: PropTypes.string.isRequired,
    staffPersonalInfo: PropTypes.arrayOf(
        PropTypes.exact({
            staffNumber: PropTypes.string,
            firstName: PropTypes.string,
            surname: PropTypes.string,
            employeeRoles: PropTypes.arrayOf(PropTypes.string),
        }),
    ).isRequired,
};
