import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useLoginSSO } from '../services/auth';
import { SplashScreen } from 'pages/SplashScreen';

export const LoginSSOPage = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const token = query.get('token');
    const {
        loginSSOMutation: { mutate },
    } = useLoginSSO();
    const [redirectURL, setRedirectUrl] = useState(null);

    useEffect(() => {
        mutate(
            { token },
            {
                onSuccess: () => {
                    setRedirectUrl('/');
                },
                onError: () => {
                    setRedirectUrl('/unauthorized');
                },
            },
        );
    }, [mutate, token]);

    if (redirectURL) {
        return <Redirect to={redirectURL} />;
    }

    return <SplashScreen />;
};
