import { Chip as BaseChip, styled, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

const Text = styled(Typography)(({ theme }) => ({
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: '12px',
    letterSpacing: '0.5px',
    color: theme.palette.common.black,
}));

const Chip = styled(BaseChip)(({ theme, $isSelected }: { theme: Theme; $isSelected: boolean }) => ({
    fontWeight: 500,
    ...($isSelected && {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
    }),
}));
interface Props {
    positionName: string;
    shopName: string;
    isTerminated: boolean;

    includeRole?: boolean;
    isSelected: boolean;
}

function ContractTab(props: Props) {
    const { t } = useTranslation();
    return (
        <Container>
            <Text>{getTitle(props.positionName, props.shopName, props.includeRole ?? true)}</Text>
            {props.isTerminated && <Chip label={t('entitlement:terminated')} $isSelected={props.isSelected} />}
        </Container>
    );
}

const getTitle = (positionName: string, shopName: string, includeRole: boolean): string => {
    if (includeRole) {
        return `${positionName} - ${shopName}`;
    }
    return shopName;
};

export default ContractTab;
