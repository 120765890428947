import PropTypes from 'prop-types';
import { DialogTitle as MuiDialogTitle, Typography, makeStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        padding: 10,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeButton: {
        color: theme.palette.common.white,
        padding: 0,
    },
}));

export const DialogTitle = ({ children, onClose, ...other }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <MuiDialogTitle id="dialog_title" disableTypography {...other} className={classes.title}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton title={t('common:close')} className={classes.closeButton} onClick={onClose}>
                    <Close fontSize="large" />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

DialogTitle.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.any,
    onClose: PropTypes.func,
};
