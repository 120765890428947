import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { PRIVACY_LINK } from '../../../../links';
import H6 from 'components/typography/H6';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import { UserInitials } from 'components/UserInitials';
import { useTranslation } from 'react-i18next';
import { useUser } from 'services/auth';
import LoadingComponent from 'components/LoadingComponent';

const useStyles = makeStyles((theme) => ({
    outerSpacing: {
        margin: 0,
        padding: theme.spacing(1),
        width: '100%',
    },
    spacer: {
        marginBottom: theme.spacing(2),
    },
    anchor: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.secondary.main,
        fontSize: 12,
        textDecoration: 'underline',
        '&:visited': {
            color: theme.palette.secondary.main,
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
        },
    },
    avatarSize: {
        width: theme.menu.appBarMinSize,
        height: theme.menu.appBarMinSize,
    },
}));

export const UserMenuGreeting = ({ onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { userDetails } = useUser();
    if (!userDetails) {
        return <LoadingComponent />;
    }
    const { firstName, email } = userDetails;
    return (
        <Grid container spacing={3} className={classes.outerSpacing}>
            <Grid item xs={3}>
                <UserInitials />
            </Grid>
            <Grid item xs={9}>
                <H6 noWrap>{t('portal:hey', { name: firstName })}</H6>
                <Typography noWrap variant="caption" className={classes.spacer}>
                    {email}
                </Typography>
                <br />
                <br />
                <Link
                    className={classNames(classes.spacer, classes.anchor)}
                    href={PRIVACY_LINK.url}
                    target="_blank"
                    onClick={onClick}
                    rel="noopener noreferrer"
                >
                    {t('portal:privacy')}
                </Link>
            </Grid>
        </Grid>
    );
};

UserMenuGreeting.propTypes = {
    onClick: PropTypes.func.isRequired,
};
