import { CircularProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    splashScreen: {
        marginTop: 'calc(50vh - 85px)',
        height: '100%',
        width: '100%',
        textAlign: 'center',
    },
});

export const SplashScreen = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.splashScreen}>
            <h1>{t('common:loading')}...</h1>
            <CircularProgress data-testid="progress-icon" size={100} />
        </div>
    );
};
