import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import DateRange from '@material-ui/icons/DateRange';

import { selectRequestedStartDate, selectRequestedEndDate } from '../requestTimeOffSelectors';

const useStyles = makeStyles((theme) => ({
    headerGrid: {
        minHeight: 100,
        maxHeight: 100,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    textColor: {
        color: theme.palette.primary.dark,
    },
    divider: {
        height: '20px',
        borderLeft: `2px solid ${theme.palette.grey[400]}`,
    },
}));

export const DateHeader = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { moment } = useMoment();

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);

    const selectedStartDate = startDate ? moment(startDate).format('D MMMM') : t('portal:start_date');
    const selectedEndDate = endDate ? moment(endDate).format('D MMMM') : t('portal:end_date');

    return (
        <Grid item xs className={classes.headerGrid} container justifyContent="center">
            <Grid item xs={10} container alignItems="center" style={{ marginTop: '15px' }}>
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <DateRange className={classes.textColor} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" className={classes.textColor}>
                        {selectedStartDate}
                    </Typography>
                </Grid>
                <Grid item xs={1} className={classes.divider} />
                <Grid item xs={1}>
                    <DateRange className={classes.textColor} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" className={classes.textColor}>
                        {selectedEndDate}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
