import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Collapse,
    Box,
} from '@material-ui/core';

import { statuses } from './constants';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { OfferRow } from './OfferRow';
import { OfferCard } from './OfferCard';
import { OffersHeadRow } from './OffersHeadRow';
import { useSetAcceptShiftOffers, useSetDeclineShiftOffers, useAllShiftOffers } from 'services/shiftOffer';
import { closeConfirmationDialog } from './shiftOfferSlice';
import {
    selectSelectedOfferID,
    selectIsConfirmationDialogOpen,
    selectSelectedOfferNewStatus,
} from './shiftOfferSelector';
import { setShowSnackbar, setSnackBarError, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';
import CheckBox from '../../components/checkboxes/Checkbox';

const useStyles = makeStyles((theme) => ({
    titleBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    offer: {
        margin: theme.spacing(1, 2),
    },
    dialogActions: {
        marginTop: theme.spacing(4),
        padding: '0px 16px 16px 0px',
        justifyContent: 'space-between',
    },
}));

export const ConfirmationDialog = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const isMobileOrTablet = useIsMobileOrTablet();

    const selectedOfferID = useSelector(selectSelectedOfferID);
    const isConfirmationDialogOpen = useSelector(selectIsConfirmationDialogOpen);
    const selectedOfferNewStatus = useSelector(selectSelectedOfferNewStatus);

    const dispatch = useDispatch();

    const { setAcceptShiftOffersMutation } = useSetAcceptShiftOffers();
    const { setDeclineShiftOffersMutation } = useSetDeclineShiftOffers();
    const { pendingShiftOffers } = useAllShiftOffers();

    const selectedOffer = pendingShiftOffers?.find((offer) => offer.id === selectedOfferID);

    const setShiftOfferMutation =
        selectedOfferNewStatus === statuses?.accepted ? setAcceptShiftOffersMutation : setDeclineShiftOffersMutation;

    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);

    const handleSetShiftOffer = () => {
        setLoading(true);
        setShiftOfferMutation.mutate(
            { selectedOfferID },
            {
                onSuccess: () => {
                    if (selectedOfferNewStatus === statuses?.accepted) {
                        dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.shift_offer_accepted' }));
                    } else {
                        dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.shift_offer_declined' }));
                    }
                },
                onError: (error) => {
                    if (error?.response?.status === 400) {
                        switch (error.response.data.errors[0].code) {
                            case 'INVALID_ROTA_ASSIGNMENT':
                                dispatch(setSnackBarError({ message: 'portal:snack_bar.invalid_rota_error' }));
                                break;
                            case 'OVERLAPPING_SHIFT':
                                dispatch(setSnackBarError({ message: 'portal:snack_bar.overlapping_shift_error' }));
                                break;
                            case 'OVERLAPPING_TIME_OFF':
                                dispatch(setSnackBarError({ message: 'portal:snack_bar.overlapping_time_off_error' }));
                                break;
                            case 'UNSUITABLE_ROLE':
                                dispatch(setSnackBarError({ message: 'portal:snack_bar.wrong_role_error' }));
                                break;

                            default:
                                dispatch(setSnackBarError());
                        }
                    } else if (error?.response?.status === 404) {
                        dispatch(setSnackBarError({ message: 'portal:snack_bar.offer_taken_error' }));
                    } else {
                        dispatch(setSnackBarError());
                    }
                },
                onSettled: () => {
                    dispatch(closeConfirmationDialog());
                    dispatch(setShowSnackbar({ open: true }));
                },
            },
        );
        setLoading(false);
    };

    const handleCheckbox = () => {
        setDisable((isDisabled) => !isDisabled);
    };

    const isOfferAccepted = selectedOfferNewStatus === statuses.accepted ? disable : false;

    return (
        <Dialog
            open={isConfirmationDialogOpen}
            onClose={() => dispatch(closeConfirmationDialog())}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className={classes.titleBar}>
                {selectedOfferNewStatus === statuses?.accepted
                    ? t('shift_offer:confirmation_accept_shift_offer')
                    : t('shift_offer:confirmation_decline_shift_offer')}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {selectedOfferNewStatus === statuses?.accepted
                        ? t('shift_offer:please_confirm_accept_shift_offer')
                        : t('shift_offer:please_confirm_decline_shift_offer')}
                </DialogContentText>
            </DialogContent>

            {selectedOfferID !== null &&
                (isMobileOrTablet ? (
                    <OfferCard offer={selectedOffer} hideButtons className={classes.offer} />
                ) : (
                    <table className={classes.offer}>
                        <OffersHeadRow tabValue={statuses?.pending} hideButtons />
                        <tbody>
                            <OfferRow offer={selectedOffer} hideButtons />
                        </tbody>
                    </table>
                ))}

            <DialogActions className={classes.dialogActions}>
                <Box>
                    {selectedOfferNewStatus === statuses?.accepted && (
                        <CheckBox label={t('shift_offer:available_to_work')} onClick={handleCheckbox} />
                    )}
                </Box>
                <Box style={{ marginLeft: '0px' }}>
                    <Button
                        onClick={() => dispatch(closeConfirmationDialog())}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        onClick={handleSetShiftOffer}
                        disabled={loading || isOfferAccepted}
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 8 }}
                    >
                        {t('common:confirm')}
                    </Button>
                </Box>
            </DialogActions>
            <Collapse in={loading}>
                <LinearProgress style={{ marginTop: 22 }} />
            </Collapse>
        </Dialog>
    );
};
