/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import { makeStyles, Table, TableBody, Typography } from '@material-ui/core';

import Panel from 'components/Panel';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { OfferRow } from './OfferRow';
import { OfferCard } from './OfferCard';
import { OffersHeadRow } from './OffersHeadRow';
import { statuses } from './constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: theme.spacing(4, 4, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2, 7.5),
        },
    },
    buttonCell: {
        display: 'flex',
    },
    acceptButton: {
        marginRight: theme.spacing(1),
    },
    mobileGrid: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        '@supports (grid-template-columns: repeat(auto-fill, minmax(235px, 1fr)))': {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(257px, 1fr))',
            gap: '12px',
        },
    },
}));

export const OffersList = ({ offers, tabValue }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <section className={classes.pageContainer}>
            <Panel>
                {offers.length === 0 ? (
                    <Typography>{t('shift_offer:no_offers_to_display')}</Typography>
                ) : isMobileOrTablet ? (
                    <div className={classes.mobileGrid}>
                        {offers.map((offer) => (
                            <OfferCard offer={offer} key={offer.id} />
                        ))}
                    </div>
                ) : (
                    <Table>
                        <OffersHeadRow tabValue={tabValue} />

                        <TableBody>
                            {offers.map((offer) => (
                                <OfferRow
                                    offer={offer}
                                    key={offer.id}
                                    hideButtons={offer.status === statuses.unavailable}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Panel>
        </section>
    );
};

OffersList.propTypes = {
    offers: PropTypes.any.isRequired,
    tabValue: PropTypes.oneOf([statuses.pending, statuses.accepted, statuses.declined, statuses.unavailable])
        .isRequired,
};
