const toCamel = (text) => text.replace(/_\w/g, (match) => match[1].toUpperCase());
const isObject = (element) => typeof element === 'object' && !Array.isArray(element) && element !== null;

export const keysToCamelCase = (obj) => {
    if (isObject(obj)) {
        const newObj = {};

        Object.entries(obj).forEach(([key, value]) => {
            newObj[toCamel(key)] = keysToCamelCase(value);
        });

        return newObj;
    } else if (Array.isArray(obj)) {
        return obj.map((element) => keysToCamelCase(element));
    }

    return obj;
};
