const convertObjectKeys = (algo, object) =>
    Object.keys(object).reduce((carry, key) => {
        const newKey = algo(key);

        carry[newKey] = object[key];

        if (Array.isArray(carry[newKey])) {
            carry[newKey] = carry[newKey].map((item) => {
                if (typeof item === 'object' && !Array.isArray(item)) {
                    return convertObjectKeys(algo, item);
                }

                return item;
            });
        } else if (typeof carry[newKey] === 'object' && carry[newKey] !== null) {
            carry[newKey] = convertObjectKeys(algo, carry[newKey]);
        }

        return carry;
    }, {});

export const snakeToCamel = (text) => text.replace(/_\w/g, (match) => match[1].toUpperCase());

export const snakeToCamelObjectKeys = (object) => convertObjectKeys(snakeToCamel, object);

export const camelToSnake = (text) => text.replace(/([A-Z])/g, '_$1').toLowerCase();

export const camelToSnakeObjectKeys = (object) => convertObjectKeys(camelToSnake, object);

export const responseToCamelCase = (response) => response.map((elem) => snakeToCamelObjectKeys(elem));
