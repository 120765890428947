import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import AppBar from './menu/AppBar';
import DrawerDesktop from './menu/DrawerDesktop';
import DrawerMobile from './menu/DrawerMobile';
import { MainContent } from './menu/MainContent';
import { Footer } from './Footer';
import Intercom from 'react-intercom';
import { computeRoutes } from 'routes';
import { splitRoutesByMenu } from 'utils/layouts/menu/menuHelpers';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { setMenuLinks } from './menuSlice';
import { useSettings } from '../../services/useSettings';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        top: 0,
        right: 0,
        left: 0,
    },
});

export const Menu = ({ children }) => {
    const classes = useStyles();
    const isMobileOrTablet = useIsMobileOrTablet();
    const dispatch = useDispatch();
    const { settings } = useSettings();

    const routes = useMemo(() => computeRoutes(settings), [settings]);

    useEffect(() => {
        dispatch(setMenuLinks({ menuLinks: splitRoutesByMenu(routes) }));
    }, [dispatch, routes]);

    return (
        <div className={classes.root}>
            <AppBar id="hit-point-appBar" />
            {isMobileOrTablet ? <DrawerMobile id="hit-point-menu-mobile" /> : <DrawerDesktop id="hit-point-menu" />}
            <Grid container direction="column" spacing={0}>
                <MainContent>{children}</MainContent>
                {!isMobileOrTablet && <Footer />}
            </Grid>
            <Intercom appID="wroaj7oe" />
        </div>
    );
};

Menu.propTypes = {
    children: PropTypes.node.isRequired,
};
