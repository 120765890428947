import { apiClient } from '../utils/data/api';

export const setLanguage = (language) =>
    apiClient().request({
        url: '/self',
        method: 'patch',
        data: {
            preferences: {
                locale: language,
            },
        },
    });
