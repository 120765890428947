import { string, shape } from 'prop-types';
import { ArrowForward } from '@material-ui/icons';
import { Tooltip, makeStyles, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const DEPARTMENT_LABEL = 'Department';
const SHOP_LABEL = 'Shop';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[200]}`,
        boxShadow: theme.shadows[1],
    },
    alignArrow: {
        textAlign: 'center',
        padding: '10px 0px',
    },
    otherShopShiftIcon: {
        color: theme.palette.grey[600],
    },
    tableCell: {
        padding: theme.spacing(1),
        border: 'none',
        fontSize: 14,
    },
}));

export const SecondaryShopPlaceholder = ({ shift }) => {
    const classes = useStyles();

    const TooltipTitle = () => (
        <Table>
            <TableBody>
                {shift.departmentName && (
                    <TableRow>
                        <TableCell className={classes.tableCell}>{DEPARTMENT_LABEL}</TableCell>
                        <TableCell className={classes.tableCell}>
                            <strong>{shift.departmentName}</strong>
                        </TableCell>
                    </TableRow>
                )}
                {shift.shopName && (
                    <TableRow>
                        <TableCell className={classes.tableCell}>{SHOP_LABEL}</TableCell>
                        <TableCell className={classes.tableCell}>
                            <strong>{shift.shopName}</strong>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    return (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={<TooltipTitle />}>
            <div className={classes.alignArrow}>
                <ArrowForward className={classes.otherShopShiftIcon} />
            </div>
        </Tooltip>
    );
};

SecondaryShopPlaceholder.propTypes = {
    shift: shape({
        department_name: string,
        shop_name: string,
    }),
};
