import { oneOf, string, shape, number } from 'prop-types';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { roundAndTrimNumber, useGetHoursAndMinutes } from 'utils/timeFormatters';
import BulletPoint from 'components/BulletPoint';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../services/useSettings';

const useStyles = makeStyles((theme) => ({
    holidaySegment: {
        height: 'fit-content',
        flexDirection: 'column',
        padding: 20,
        borderBottom: 'none',
        [theme.breakpoints.down('md')]: {
            padding: 10,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            borderRight: 'none',
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
        '&:last-child': {
            border: 'none',
        },
    },
    segmentElement: {
        flex: '0 0 auto',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flex: '1 0 0',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
        },
    },
    hoursStyle: {
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    smallerText: {
        fontSize: '20px',
    },
    mainText: {
        fontSize: '14px',
    },
    subText: {
        fontSize: '12px',
    },
    lieu: {
        maxWidth: '25%',
        textAlign: 'center',
        marginLeft: -25,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            marginLeft: 0,
        },
    },
}));

export const EntitlementPanelSegment = ({ segment, entitlement, units }) => {
    const classes = useStyles();
    const fullScreen = useIsMobileOrTablet();
    const { t } = useTranslation();
    const getHoursAndMinutes = useGetHoursAndMinutes();
    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();

    if (!entitlement) {
        return null;
    }

    const entitlementDays = parseFloat(Number(entitlement.days).toFixed(2));
    const entitlementHours = entitlement.hours;

    const isTimeFormat =
        showHoursWithMinutes && (units === 'hours' || units === 'both') && `${entitlementHours}`.includes('.');

    const replaceHoursWithMinutes = isTimeFormat
        ? getHoursAndMinutes(entitlementHours)
        : roundAndTrimNumber(entitlementHours);

    const segments = {
        entitlement: {
            color: 'secondary',
            name: 'entitlement:entitlement',
            nameWithUnit: 'entitlement:entitlement_with_unit',
        },
        used: { color: 'danger', name: 'entitlement:used', nameWithUnit: 'entitlement:used_with_unit' },
        booked: { color: 'disabled', name: 'entitlement:booked', nameWithUnit: 'entitlement:booked_with_unit' },
        balance: { color: 'disabled', name: 'entitlement:balance', nameWithUnit: 'entitlement:balanced_with_unit' },
        lieu: { color: 'secondary', name: 'entitlement:lieu', nameWithUnit: 'entitlement:lieu_with_unit' },
    };

    const getSegmentName = (hrsOrMins) => {
        if (hrsOrMins === 'hours' && isTimeFormat) {
            return t(segments[segment].name);
        }
        if (hrsOrMins === 'hours' && !isTimeFormat) {
            const unit = entitlementHours === 1 ? t('common:hour') : t('common:hours');

            return t(segments[segment].nameWithUnit, { unit });
        }
        if (hrsOrMins === 'days') {
            const unit = entitlementDays === 1 ? t('common:day') : t('common:days');

            return t(segments[segment].nameWithUnit, { unit });
        }
    };

    const getSubText = () => {
        const unit = entitlementDays === 1 ? t('common:day') : t('common:days');

        return `${entitlementDays} ${unit}`;
    };

    return (
        <Grid
            container
            item
            xs={12}
            md
            className={classNames({
                [classes.holidaySegment]: true,
                [classes.lieu]: segment === 'lieu' && !fullScreen,
            })}
            alignItems={segment === 'lieu' && !fullScreen ? 'flex-start' : 'center'}
        >
            <Grid container item xs={2} sm={1} md alignItems="center" className={classes.segmentElement}>
                <BulletPoint color={segments[segment].color} type="rounded" />
            </Grid>
            <Grid container item xs={2} sm={2} md className={classes.segmentElement}>
                <Typography className={isTimeFormat ? classes.smallerText : classes.hoursStyle}>
                    {units === 'both' && replaceHoursWithMinutes}
                    {units === 'hours' && replaceHoursWithMinutes}
                    {units === 'days' && roundAndTrimNumber(entitlement[units])}
                </Typography>
            </Grid>
            <Grid item xs sm md className={classes.segmentElement}>
                <Typography className={classes.mainText}>
                    {units === 'both' && getSegmentName('hours')}
                    {units === 'hours' && getSegmentName('hours')}
                    {units === 'days' && getSegmentName('days')}
                </Typography>

                {units === 'both' && !fullScreen && <Typography className={classes.subText}>{getSubText()}</Typography>}
            </Grid>
        </Grid>
    );
};

EntitlementPanelSegment.propTypes = {
    units: oneOf(['days', 'hours', 'both']),
    segment: string.isRequired,
    entitlement: shape({
        days: number.isRequired,
        hours: number.isRequired,
        seconds: number.isRequired,
    }),
};
