import { useEffect } from 'react';
import { useLocation, matchPath, Router } from 'react-router';

import { Helmet } from 'react-helmet';
import { routes as appRoutes } from 'routes';
import { sendGoogleAnalyticsEvents } from 'googleAnalytics';
import { useTranslation } from 'react-i18next';
import { useTenant } from 'services/auth';

const matchRoutes = (routes, pathname) => {
    const branch = [];

    routes.some((route) => {
        let match;

        if (route.path) {
            match = matchPath(pathname, route);
        } else if (branch.length) {
            match = branch[branch.length - 1].match;
        } else {
            match = Router.computeRootMatch(pathname);
        }

        if (match) {
            branch.push({ route, match });

            if (route.routes) {
                matchRoutes(route.routes, pathname, branch);
            }
        }

        return match;
    });

    return branch;
};

export const AppHelmet = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const tenant = useTenant();
    const name = tenant?.data?.name;

    const { pathname } = location;

    const match = matchRoutes(appRoutes, pathname);

    const pageTitle =
        (match.length > 0 && match[0].route.translationKey && t(match[0].route.translationKey)) ||
        t('portal:shopworks_portal');

    const clientName = name || t('portal:shopworks');

    useEffect(() => {
        sendGoogleAnalyticsEvents(pathname, pageTitle, clientName);
    });

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${pageTitle} | ${clientName}`}</title>
        </Helmet>
    );
};
