import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    getSelf,
    signIn,
    logout,
    updatePassword,
    submitResetPassword,
    submitForgottenPassword,
    loginSSO,
    verifyTenant as getTenant,
} from 'api/auth';
import { getTenantName } from './tenant';

export const useUser = () => {
    const { data, isLoading, isError } = useQuery('user', getSelf);
    const userDetails = data ? data.data.data : null;

    return {
        userDetails,
        isUserLoading: isLoading,
        isUserError: isError,
    };
};

export const useTenant = () => {
    const tenantName = getTenantName();
    const result = useQuery('tenant', () => getTenant(tenantName));
    return { ...result, data: result?.data?.data?.data };
};

export const useLogin = () => {
    const queryClient = useQueryClient();
    const tenant = getTenantName();

    const mutation = useMutation(({ username, password }) => signIn(tenant, username, password), {
        onSuccess: () => {
            queryClient.invalidateQueries('settings');
            queryClient.invalidateQueries('user');
        },
    });

    return { loginMutation: mutation };
};

export const useLoginSSO = () => {
    const queryClient = useQueryClient();
    const tenant = getTenantName();

    const mutation = useMutation(({ token }) => loginSSO(tenant, token), {
        onSuccess: () => {
            queryClient.invalidateQueries('settings');
            queryClient.invalidateQueries('user');
        },
    });

    return { loginSSOMutation: mutation };
};

export const useLogout = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(() => logout(), {
        onSuccess: () => {
            queryClient.removeQueries();
        },
    });

    return { logoutMutation: mutation };
};

export const useUpdatePassword = () => {
    const mutation = useMutation(({ oldPassword, newPassword }) => updatePassword(oldPassword, newPassword));

    return { updatePasswordMutation: mutation };
};

export const useResetPassword = () => {
    const tenant = getTenantName();
    const mutation = useMutation(({ userEmail, password, token, isWelcomePage }) =>
        submitResetPassword({ userEmail, password, token, isWelcomePage, tenant }),
    );

    return { resetPasswordMutation: mutation };
};

export const useForgottenPassword = () => {
    const tenant = getTenantName();
    const mutation = useMutation((email) => submitForgottenPassword(email, tenant));

    return { forgottenPasswordMutation: mutation };
};
