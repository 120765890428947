import PropTypes from 'prop-types';

import { useIsMobile } from 'utils/components/helpers';
import { MobileEvent } from './MobileEvent';
import { LeaveEvent } from './fullSizeShift/LeaveEvent';
import { FullSizeShift } from './fullSizeShift';

export const EventTile = ({ event }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return <MobileEvent event={event} />;
    }

    if (event.timeOff || event.isStaffAvailabilityEvent) {
        return <LeaveEvent event={event} />;
    }

    return <FullSizeShift event={event} />;
};

EventTile.propTypes = {
    event: PropTypes.object.isRequired,
};
