import originalMoment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(originalMoment);

export const getListOfDatesInRange = (start, end) => {
    if (!start && !end) {
        return [];
    }

    const range = moment.range(moment(start), moment(end));
    const listOfDates = Array.from(range.by('day'));

    return listOfDates.map((date) => date.format('YYYY-MM-DD'));
};
