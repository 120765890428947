import PropTypes from 'prop-types';
import { InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';

export const InputFieldAdornment = ({ status, handleSubmit, setFocusOnEditButtonClick }) => {
    if (status === 'loading') {
        return (
            <InputAdornment position="end">
                <CircularProgress size={20} data-testid="text-field-adornment-loading" />
            </InputAdornment>
        );
    }
    if (status === 'save') {
        return (
            <InputAdornment position="end">
                <IconButton tabIndex="-1" onClick={handleSubmit}>
                    <SaveIcon color="secondary" data-testid="text-field-adornment-save" />
                </IconButton>
            </InputAdornment>
        );
    }
    if (status === 'lock') {
        return (
            <InputAdornment position="end">
                <LockIcon data-testid="text-field-adornment-lock" />
            </InputAdornment>
        );
    }
    if (status === 'edit') {
        return (
            <InputAdornment position="end">
                <IconButton tabIndex="-1" onClick={setFocusOnEditButtonClick}>
                    <EditIcon data-testid="text-field-adornment-edit" />
                </IconButton>
            </InputAdornment>
        );
    }

    return null;
};

InputFieldAdornment.propTypes = {
    status: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFocusOnEditButtonClick: PropTypes.func.isRequired,
};
