import { useMediaQuery } from '@material-ui/core';
import theme from 'utils/theme';

export const isBrowser = typeof window !== 'undefined';

export const compose =
    (...functions) =>
    (component) =>
        functions.reduceRight((enhancedComponent, func) => func(enhancedComponent), component);

export const useIsMobile = () => useMediaQuery(theme.breakpoints.only('xs'));
export const useIsMobileOrTablet = () => useMediaQuery(theme.breakpoints.down('sm'));
export const useIsMediumScreenOrSmaller = () => useMediaQuery(theme.breakpoints.down('md'));
export const useIsTabletOrLarger = () => useMediaQuery(theme.breakpoints.up('sm'));
