import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TextArea from 'components/TextArea';
import { HoursInput } from './HoursInput';
import { ErrorPanel } from './ErrorPanel';
import { DaySelector } from './DaySelector';

import { getIsTimeOffTypeHoursOnly, getUnitType, getMostContractedRevision } from '../utils';
import { fromDecimalsToHours } from 'utils/timeFormatters';

import { useSettings } from 'services/useSettings';
import { useUser } from 'services/auth';

import { setNotes, setRequestedTimeOff, setSelectionList, updateUserRequest } from '../requestTimeOffSlice';
import { selectNotes, selectRequestedTimeOffType, selectRequestedDays } from '../requestTimeOffSelectors';

const useStyles = makeStyles((theme) => ({
    inputPanelStyle: {
        marginTop: '20px',
        padding: '0px 40px 10px 40px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 20px',
        },
    },
    textArea: {
        width: '100%',
        marginTop: '10px',
    },
    textAreaAttributes: {
        backgroundColor: 'transparent',
        height: '91px',
    },
    inputLabel: {
        fontSize: '16px',
    },
    errorLabel: {
        marginBottom: '10px',
        backgroundColor: theme.palette.danger.main,
        padding: '5px 10px 5px 10px',
        borderRadius: '5px',
    },
    errorText: {
        color: theme.palette.common.white,
        fontWeight: '400',
    },
}));

export const NonEntitlementForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        settings: { entitlement },
    } = useSettings();

    const {
        userDetails: { contracts },
    } = useUser();

    const currentContract = getMostContractedRevision(contracts);

    const timeOffType = useSelector(selectRequestedTimeOffType);
    const notes = useSelector(selectNotes);
    const requestedDays = useSelector(selectRequestedDays);

    useEffect(() => {
        const defaultSelectionList = [
            {
                defaultHours: currentContract.contractedHoursPerDay,
                id: currentContract.relatedContractId,
                name: `${currentContract.positionName} - ${currentContract.shopName}`,
                unitType: currentContract.contractTypeTimeOffUnit,
                units: currentContract.contractTypeTimeOffUnit,
            },
        ];

        dispatch(setSelectionList(defaultSelectionList));
    }, [
        dispatch,
        currentContract.contractedHoursPerDay,
        currentContract.relatedContractId,
        currentContract.positionName,
        currentContract.shopName,
        currentContract.contractTypeTimeOffUnit,
    ]);

    useEffect(() => {
        const defaultUserRequest = {
            daysPerDay: 1,
            hoursAndMinsPerDay: fromDecimalsToHours(timeOffType.defaultHours),
            hoursPerDay: timeOffType.defaultHours,
            relatedContractId: currentContract.relatedContractId,
            revisionName: `${currentContract.positionName} - ${currentContract.shopName}`,
            selectorIndex: 0,
            unitType: currentContract.contractTypeTimeOffUnit,
        };

        dispatch(updateUserRequest(defaultUserRequest));

        dispatch(
            setRequestedTimeOff({
                hours: timeOffType.defaultHours,
                hoursAndMinsPerDay: fromDecimalsToHours(timeOffType.defaultHours),
                days: 1,
                unitType: currentContract.contractTypeTimeOffUnit,
            }),
        );
    }, [
        timeOffType,
        dispatch,
        currentContract.relatedContractId,
        currentContract.contractTypeTimeOffUnit,
        currentContract.contractedHoursPerDay,
        currentContract.positionName,
        currentContract.shopName,
    ]);

    const unitType = getUnitType(entitlement, {}, contracts, []);

    const isTimeOffTypeHoursOnly = getIsTimeOffTypeHoursOnly(timeOffType);

    return (
        <Grid container justifyContent="space-around" className={classes.inputPanelStyle}>
            <Grid item xs={11} md={5}>
                <ErrorPanel />
            </Grid>
            <Grid item xs={11} md={5} />
            <Grid container direction="row" justifyContent="space-around">
                <Grid xs={12} md={5} item>
                    {unitType === 'hours' || isTimeOffTypeHoursOnly ? (
                        <HoursInput />
                    ) : (
                        <DaySelector
                            daysInputId="days-select-label"
                            value={requestedDays}
                            onChange={({ target }) =>
                                dispatch(
                                    setRequestedTimeOff({
                                        days: target.value,
                                        unitType: currentContract.contractTypeTimeOffUnit,
                                    }),
                                )
                            }
                        />
                    )}
                </Grid>

                <Grid xs={12} md={5} item>
                    <TextArea
                        minRows="3"
                        multiline
                        variant="outlined"
                        className={classes.textArea}
                        data-testid="notes-input"
                        label={<InputLabel className={classes.inputLabel}>{t('common:notes')}:</InputLabel>}
                        value={notes}
                        onChange={(event) => dispatch(setNotes(event.target.value))}
                        InputProps={{ className: classes.textAreaAttributes }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
