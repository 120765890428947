import { createSlice } from '@reduxjs/toolkit';

interface EntitlementState {
    hideInactivePots: boolean;
}

const initialState: EntitlementState = {
    hideInactivePots: true,
};

const slice = createSlice({
    name: 'entitlement',
    initialState,
    reducers: {
        toggleInactivePots(state) {
            state.hideInactivePots = !state.hideInactivePots;
        },
    },
});

export const {
    reducer: entitlementReducer,
    actions: { toggleInactivePots },
} = slice;
