import { statuses } from '../constants';

export const getFilteredShiftOffers = (shiftOffers) => {
    const allShiftOffers =
        shiftOffers[0] && shiftOffers[1] ? [...shiftOffers[0].data.data, ...shiftOffers[1].data.data] : null;

    const pendingShiftOffers = allShiftOffers
        ?.filter((offer) => offer.status === statuses.pending)
        .sort((offerA, offerB) => offerA.shift.startDate.localeCompare(offerB.shift.startDate));

    const acceptedShiftOffers = allShiftOffers
        ?.filter((offer) => offer.status === statuses.accepted)
        .sort((offerA, offerB) => offerB.shift.startDate.localeCompare(offerA.shift.startDate));

    const declinedShiftOffers = allShiftOffers
        ?.filter((offer) => offer.status === statuses.declined)
        .sort((offerA, offerB) => offerB.shift.startDate.localeCompare(offerA.shift.startDate));

    const unavailableShiftOffers = allShiftOffers
        ?.filter((offer) => offer.status === statuses.unavailable)
        .sort((offerA, offerB) => offerB.shift.startDate.localeCompare(offerA.shift.startDate));

    return {
        allShiftOffers,
        pendingShiftOffers,
        acceptedShiftOffers,
        declinedShiftOffers,
        unavailableShiftOffers,
    };
};
