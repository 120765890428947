import moment from 'moment';

export const fixDayOffsForShifts = (shifts) =>
    shifts.map((shift) => {
        if (shift.type === 'day_off') {
            return {
                ...shift,
                start: moment(shift.start).startOf('day').format('YYYY-MM-DD HH:mm'),
                end: moment(shift.start).endOf('day').format('YYYY-MM-DD HH:mm'),
            };
        }

        return shift;
    });
