export const fromHoursToDecimals = (value) => {
    const [hrs, mins] = `${value}`.split(':');

    if (mins) {
        const calcDecimals = `0.${mins}` * 100;
        const decimals = `${calcDecimals < 10 ? '0' : ''}${((100 / 60) * calcDecimals).toFixed()}`;

        return Number(`${hrs}.${decimals}`);
    }

    return Number(hrs);
};
