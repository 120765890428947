import { useQuery } from 'react-query';
import { getSettings } from 'api/auth';
import { keysToCamelCase } from 'utils/data';

export const useSettings = () => {
    const { data, isLoading, isError } = useQuery('settings', getSettings);
    const settings = data?.data.data ?? null;

    if (settings) {
        if (Object.prototype.hasOwnProperty.call(settings, 'localization')) {
            settings.localization.defaultLocale = settings.localization.defaultLocale.replace('_', '-');
        }
        if (Object.prototype.hasOwnProperty.call(settings, 'general')) {
            settings.general = keysToCamelCase(settings.general);
        }
        if (Object.prototype.hasOwnProperty.call(settings, 'staffAvailability')) {
            settings.staffAvailability.overallEnabled =
                settings.staffAvailability.enabled && settings.staffAvailability.staffPortalEnabled;
        }
    }

    return {
        settings,
        isSettingsLoading: isLoading,
        isSettingsError: isError,
    };
};

export const useIsTimeOffEnabled = () => {
    const settings = useSettings();
    return settings?.settings?.timeOff?.enabled ?? false;
};
