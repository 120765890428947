import { useIsMobile } from 'utils/components/helpers';

import { useSettings } from 'services/useSettings';

const SCRIPT_ID = 'jsd-widget-script';

function jiraHelpdesk(isMobile: boolean) {
    if (window.document.getElementById(SCRIPT_ID)) {
        return;
    }

    const jhdScript = document.createElement('script');

    jhdScript.type = 'text/javascript';
    jhdScript.setAttribute('id', SCRIPT_ID);
    jhdScript.setAttribute('data-jsd-embedded', 'null');
    jhdScript.setAttribute('data-key', 'e14daf66-757d-4688-a973-117d9ee5a71a');
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

    if (isMobile) {
        jhdScript.setAttribute('height', '100%');
        jhdScript.setAttribute('width', '100%');
    }

    jhdScript.onload = () => {
        window.document.dispatchEvent(
            new Event('DOMContentLoaded', {
                bubbles: true,
                cancelable: true,
            }),
        );
    };

    document.body.appendChild(jhdScript);
}

export const JiraServiceWidget = () => {
    const { settings } = useSettings();
    const jiraServiceDeskWidget = settings?.jiraServiceDeskWidget ?? { enabled: false };
    const isMobile = useIsMobile();

    if (jiraServiceDeskWidget && jiraServiceDeskWidget.enabled) {
        jiraHelpdesk(isMobile);
    }

    return null;
};
