export const mobileCountryList = [
    {
        shortName: 'AD',
        name: 'ANDORRA',
        code: '376',
    },
    {
        shortName: 'AE',
        name: 'UNITED ARAB EMIRATES',
        code: '971',
    },
    {
        shortName: 'AF',
        name: 'AFGHANISTAN',
        code: '93',
    },
    {
        shortName: 'AG',
        name: 'ANTIGUA AND BARBUDA',
        code: '1268',
    },
    {
        shortName: 'AI',
        name: 'ANGUILLA',
        code: '1264',
    },
    {
        shortName: 'AL',
        name: 'ALBANIA',
        code: '355',
    },
    {
        shortName: 'AM',
        name: 'ARMENIA',
        code: '374',
    },
    {
        shortName: 'AN',
        name: 'NETHERLANDS ANTILLES',
        code: '599',
    },
    {
        shortName: 'AO',
        name: 'ANGOLA',
        code: '244',
    },
    {
        shortName: 'AQ',
        name: 'ANTARCTICA',
        code: '672',
    },
    {
        shortName: 'AR',
        name: 'ARGENTINA',
        code: '54',
    },
    {
        shortName: 'AS',
        name: 'AMERICAN SAMOA',
        code: '1684',
    },
    {
        shortName: 'AT',
        name: 'AUSTRIA',
        code: '43',
    },
    {
        shortName: 'AU',
        name: 'AUSTRALIA',
        code: '61',
    },
    {
        shortName: 'AW',
        name: 'ARUBA',
        code: '297',
    },
    {
        shortName: 'AZ',
        name: 'AZERBAIJAN',
        code: '994',
    },
    {
        shortName: 'BA',
        name: 'BOSNIA AND HERZEGOVINA',
        code: '387',
    },
    {
        shortName: 'BB',
        name: 'BARBADOS',
        code: '1246',
    },
    {
        shortName: 'BD',
        name: 'BANGLADESH',
        code: '880',
    },
    {
        shortName: 'BE',
        name: 'BELGIUM',
        code: '32',
    },
    {
        shortName: 'BF',
        name: 'BURKINA FASO',
        code: '226',
    },
    {
        shortName: 'BG',
        name: 'BULGARIA',
        code: '359',
    },
    {
        shortName: 'BH',
        name: 'BAHRAIN',
        code: '973',
    },
    {
        shortName: 'BI',
        name: 'BURUNDI',
        code: '257',
    },
    {
        shortName: 'BJ',
        name: 'BENIN',
        code: '229',
    },
    {
        shortName: 'BL',
        name: 'SAINT BARTHELEMY',
        code: '590',
    },
    {
        shortName: 'BM',
        name: 'BERMUDA',
        code: '1441',
    },
    {
        shortName: 'BN',
        name: 'BRUNEI DARUSSALAM',
        code: '673',
    },
    {
        shortName: 'BO',
        name: 'BOLIVIA',
        code: '591',
    },
    {
        shortName: 'BR',
        name: 'BRAZIL',
        code: '55',
    },
    {
        shortName: 'BS',
        name: 'BAHAMAS',
        code: '1242',
    },
    {
        shortName: 'BT',
        name: 'BHUTAN',
        code: '975',
    },
    {
        shortName: 'BW',
        name: 'BOTSWANA',
        code: '267',
    },
    {
        shortName: 'BY',
        name: 'BELARUS',
        code: '375',
    },
    {
        shortName: 'BZ',
        name: 'BELIZE',
        code: '501',
    },
    {
        shortName: 'CA',
        name: 'CANADA',
        code: '1',
    },
    {
        shortName: 'CC',
        name: 'COCOS (KEELING) ISLANDS',
        code: '61',
    },
    {
        shortName: 'CD',
        name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
        code: '243',
    },
    {
        shortName: 'CF',
        name: 'CENTRAL AFRICAN REPUBLIC',
        code: '236',
    },
    {
        shortName: 'CG',
        name: 'CONGO',
        code: '242',
    },
    {
        shortName: 'CH',
        name: 'SWITZERLAND',
        code: '41',
    },
    {
        shortName: 'CI',
        name: 'COTE D IVOIRE',
        code: '225',
    },
    {
        shortName: 'CK',
        name: 'COOK ISLANDS',
        code: '682',
    },
    {
        shortName: 'CL',
        name: 'CHILE',
        code: '56',
    },
    {
        shortName: 'CM',
        name: 'CAMEROON',
        code: '237',
    },
    {
        shortName: 'CN',
        name: 'CHINA',
        code: '86',
    },
    {
        shortName: 'CO',
        name: 'COLOMBIA',
        code: '57',
    },
    {
        shortName: 'CR',
        name: 'COSTA RICA',
        code: '506',
    },
    {
        shortName: 'CU',
        name: 'CUBA',
        code: '53',
    },
    {
        shortName: 'CV',
        name: 'CAPE VERDE',
        code: '238',
    },
    {
        shortName: 'CX',
        name: 'CHRISTMAS ISLAND',
        code: '61',
    },
    {
        shortName: 'CY',
        name: 'CYPRUS',
        code: '357',
    },
    {
        shortName: 'CZ',
        name: 'CZECH REPUBLIC',
        code: '420',
    },
    {
        shortName: 'DE',
        name: 'GERMANY',
        code: '49',
    },
    {
        shortName: 'DJ',
        name: 'DJIBOUTI',
        code: '253',
    },
    {
        shortName: 'DK',
        name: 'DENMARK',
        code: '45',
    },
    {
        shortName: 'DM',
        name: 'DOMINICA',
        code: '1767',
    },
    {
        shortName: 'DO',
        name: 'DOMINICAN REPUBLIC',
        code: '1809',
    },
    {
        shortName: 'DZ',
        name: 'ALGERIA',
        code: '213',
    },
    {
        shortName: 'EC',
        name: 'ECUADOR',
        code: '593',
    },
    {
        shortName: 'EE',
        name: 'ESTONIA',
        code: '372',
    },
    {
        shortName: 'EG',
        name: 'EGYPT',
        code: '20',
    },
    {
        shortName: 'ER',
        name: 'ERITREA',
        code: '291',
    },
    {
        shortName: 'ES',
        name: 'SPAIN',
        code: '34',
    },
    {
        shortName: 'ET',
        name: 'ETHIOPIA',
        code: '251',
    },
    {
        shortName: 'FI',
        name: 'FINLAND',
        code: '358',
    },
    {
        shortName: 'FJ',
        name: 'FIJI',
        code: '679',
    },
    {
        shortName: 'FK',
        name: 'FALKLAND ISLANDS (MALVINAS)',
        code: '500',
    },
    {
        shortName: 'FM',
        name: 'MICRONESIA, FEDERATED STATES OF',
        code: '691',
    },
    {
        shortName: 'FO',
        name: 'FAROE ISLANDS',
        code: '298',
    },
    {
        shortName: 'FR',
        name: 'FRANCE',
        code: '33',
    },
    {
        shortName: 'GA',
        name: 'GABON',
        code: '241',
    },
    {
        shortName: 'GB',
        name: 'UNITED KINGDOM',
        code: '44',
    },
    {
        shortName: 'GD',
        name: 'GRENADA',
        code: '1473',
    },
    {
        shortName: 'GE',
        name: 'GEORGIA',
        code: '995',
    },
    {
        shortName: 'GH',
        name: 'GHANA',
        code: '233',
    },
    {
        shortName: 'GI',
        name: 'GIBRALTAR',
        code: '350',
    },
    {
        shortName: 'GL',
        name: 'GREENLAND',
        code: '299',
    },
    {
        shortName: 'GM',
        name: 'GAMBIA',
        code: '220',
    },
    {
        shortName: 'GN',
        name: 'GUINEA',
        code: '224',
    },
    {
        shortName: 'GQ',
        name: 'EQUATORIAL GUINEA',
        code: '240',
    },
    {
        shortName: 'GR',
        name: 'GREECE',
        code: '30',
    },
    {
        shortName: 'GT',
        name: 'GUATEMALA',
        code: '502',
    },
    {
        shortName: 'GU',
        name: 'GUAM',
        code: '1671',
    },
    {
        shortName: 'GW',
        name: 'GUINEA-BISSAU',
        code: '245',
    },
    {
        shortName: 'GY',
        name: 'GUYANA',
        code: '592',
    },
    {
        shortName: 'HK',
        name: 'HONG KONG',
        code: '852',
    },
    {
        shortName: 'HN',
        name: 'HONDURAS',
        code: '504',
    },
    {
        shortName: 'HR',
        name: 'CROATIA',
        code: '385',
    },
    {
        shortName: 'HT',
        name: 'HAITI',
        code: '509',
    },
    {
        shortName: 'HU',
        name: 'HUNGARY',
        code: '36',
    },
    {
        shortName: 'ID',
        name: 'INDONESIA',
        code: '62',
    },
    {
        shortName: 'IE',
        name: 'IRELAND',
        code: '353',
    },
    {
        shortName: 'IL',
        name: 'ISRAEL',
        code: '972',
    },
    {
        shortName: 'IM',
        name: 'ISLE OF MAN',
        code: '44',
    },
    {
        shortName: 'IN',
        name: 'INDIA',
        code: '91',
    },
    {
        shortName: 'IQ',
        name: 'IRAQ',
        code: '964',
    },
    {
        shortName: 'IR',
        name: 'IRAN, ISLAMIC REPUBLIC OF',
        code: '98',
    },
    {
        shortName: 'IS',
        name: 'ICELAND',
        code: '354',
    },
    {
        shortName: 'IT',
        name: 'ITALY',
        code: '39',
    },
    {
        shortName: 'JM',
        name: 'JAMAICA',
        code: '1876',
    },
    {
        shortName: 'JO',
        name: 'JORDAN',
        code: '962',
    },
    {
        shortName: 'JP',
        name: 'JAPAN',
        code: '81',
    },
    {
        shortName: 'KE',
        name: 'KENYA',
        code: '254',
    },
    {
        shortName: 'KG',
        name: 'KYRGYZSTAN',
        code: '996',
    },
    {
        shortName: 'KH',
        name: 'CAMBODIA',
        code: '855',
    },
    {
        shortName: 'KI',
        name: 'KIRIBATI',
        code: '686',
    },
    {
        shortName: 'KM',
        name: 'COMOROS',
        code: '269',
    },
    {
        shortName: 'KN',
        name: 'SAINT KITTS AND NEVIS',
        code: '1869',
    },
    {
        shortName: 'KP',
        name: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF',
        code: '850',
    },
    {
        shortName: 'KR',
        name: 'KOREA REPUBLIC OF',
        code: '82',
    },
    {
        shortName: 'KW',
        name: 'KUWAIT',
        code: '965',
    },
    {
        shortName: 'KY',
        name: 'CAYMAN ISLANDS',
        code: '1345',
    },
    {
        shortName: 'KZ',
        name: 'KAZAKSTAN',
        code: '7',
    },
    {
        shortName: 'LA',
        name: 'LAO PEOPLES DEMOCRATIC REPUBLIC',
        code: '856',
    },
    {
        shortName: 'LB',
        name: 'LEBANON',
        code: '961',
    },
    {
        shortName: 'LC',
        name: 'SAINT LUCIA',
        code: '1758',
    },
    {
        shortName: 'LI',
        name: 'LIECHTENSTEIN',
        code: '423',
    },
    {
        shortName: 'LK',
        name: 'SRI LANKA',
        code: '94',
    },
    {
        shortName: 'LR',
        name: 'LIBERIA',
        code: '231',
    },
    {
        shortName: 'LS',
        name: 'LESOTHO',
        code: '266',
    },
    {
        shortName: 'LT',
        name: 'LITHUANIA',
        code: '370',
    },
    {
        shortName: 'LU',
        name: 'LUXEMBOURG',
        code: '352',
    },
    {
        shortName: 'LV',
        name: 'LATVIA',
        code: '371',
    },
    {
        shortName: 'LY',
        name: 'LIBYAN ARAB JAMAHIRIYA',
        code: '218',
    },
    {
        shortName: 'MA',
        name: 'MOROCCO',
        code: '212',
    },
    {
        shortName: 'MC',
        name: 'MONACO',
        code: '377',
    },
    {
        shortName: 'MD',
        name: 'MOLDOVA, REPUBLIC OF',
        code: '373',
    },
    {
        shortName: 'ME',
        name: 'MONTENEGRO',
        code: '382',
    },
    {
        shortName: 'MF',
        name: 'SAINT MARTIN',
        code: '1599',
    },
    {
        shortName: 'MG',
        name: 'MADAGASCAR',
        code: '261',
    },
    {
        shortName: 'MH',
        name: 'MARSHALL ISLANDS',
        code: '692',
    },
    {
        shortName: 'MK',
        name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
        code: '389',
    },
    {
        shortName: 'ML',
        name: 'MALI',
        code: '223',
    },
    {
        shortName: 'MM',
        name: 'MYANMAR',
        code: '95',
    },
    {
        shortName: 'MN',
        name: 'MONGOLIA',
        code: '976',
    },
    {
        shortName: 'MO',
        name: 'MACAU',
        code: '853',
    },
    {
        shortName: 'MP',
        name: 'NORTHERN MARIANA ISLANDS',
        code: '1670',
    },
    {
        shortName: 'MR',
        name: 'MAURITANIA',
        code: '222',
    },
    {
        shortName: 'MS',
        name: 'MONTSERRAT',
        code: '1664',
    },
    {
        shortName: 'MT',
        name: 'MALTA',
        code: '356',
    },
    {
        shortName: 'MU',
        name: 'MAURITIUS',
        code: '230',
    },
    {
        shortName: 'MV',
        name: 'MALDIVES',
        code: '960',
    },
    {
        shortName: 'MW',
        name: 'MALAWI',
        code: '265',
    },
    {
        shortName: 'MX',
        name: 'MEXICO',
        code: '52',
    },
    {
        shortName: 'MY',
        name: 'MALAYSIA',
        code: '60',
    },
    {
        shortName: 'MZ',
        name: 'MOZAMBIQUE',
        code: '258',
    },
    {
        shortName: 'NA',
        name: 'NAMIBIA',
        code: '264',
    },
    {
        shortName: 'NC',
        name: 'NEW CALEDONIA',
        code: '687',
    },
    {
        shortName: 'NE',
        name: 'NIGER',
        code: '227',
    },
    {
        shortName: 'NG',
        name: 'NIGERIA',
        code: '234',
    },
    {
        shortName: 'NI',
        name: 'NICARAGUA',
        code: '505',
    },
    {
        shortName: 'NL',
        name: 'NETHERLANDS',
        code: '31',
    },
    {
        shortName: 'NO',
        name: 'NORWAY',
        code: '47',
    },
    {
        shortName: 'NP',
        name: 'NEPAL',
        code: '977',
    },
    {
        shortName: 'NR',
        name: 'NAURU',
        code: '674',
    },
    {
        shortName: 'NU',
        name: 'NIUE',
        code: '683',
    },
    {
        shortName: 'NZ',
        name: 'NEW ZEALAND',
        code: '64',
    },
    {
        shortName: 'OM',
        name: 'OMAN',
        code: '968',
    },
    {
        shortName: 'PA',
        name: 'PANAMA',
        code: '507',
    },
    {
        shortName: 'PE',
        name: 'PERU',
        code: '51',
    },
    {
        shortName: 'PF',
        name: 'FRENCH POLYNESIA',
        code: '689',
    },
    {
        shortName: 'PG',
        name: 'PAPUA NEW GUINEA',
        code: '675',
    },
    {
        shortName: 'PH',
        name: 'PHILIPPINES',
        code: '63',
    },
    {
        shortName: 'PK',
        name: 'PAKISTAN',
        code: '92',
    },
    {
        shortName: 'PL',
        name: 'POLAND',
        code: '48',
    },
    {
        shortName: 'PM',
        name: 'SAINT PIERRE AND MIQUELON',
        code: '508',
    },
    {
        shortName: 'PN',
        name: 'PITCAIRN',
        code: '870',
    },
    {
        shortName: 'PR',
        name: 'PUERTO RICO',
        code: '1',
    },
    {
        shortName: 'PT',
        name: 'PORTUGAL',
        code: '351',
    },
    {
        shortName: 'PW',
        name: 'PALAU',
        code: '680',
    },
    {
        shortName: 'PY',
        name: 'PARAGUAY',
        code: '595',
    },
    {
        shortName: 'QA',
        name: 'QATAR',
        code: '974',
    },
    {
        shortName: 'RO',
        name: 'ROMANIA',
        code: '40',
    },
    {
        shortName: 'RS',
        name: 'SERBIA',
        code: '381',
    },
    {
        shortName: 'RU',
        name: 'RUSSIAN FEDERATION',
        code: '7',
    },
    {
        shortName: 'RW',
        name: 'RWANDA',
        code: '250',
    },
    {
        shortName: 'SA',
        name: 'SAUDI ARABIA',
        code: '966',
    },
    {
        shortName: 'SB',
        name: 'SOLOMON ISLANDS',
        code: '677',
    },
    {
        shortName: 'SC',
        name: 'SEYCHELLES',
        code: '248',
    },
    {
        shortName: 'SD',
        name: 'SUDAN',
        code: '249',
    },
    {
        shortName: 'SE',
        name: 'SWEDEN',
        code: '46',
    },
    {
        shortName: 'SG',
        name: 'SINGAPORE',
        code: '65',
    },
    {
        shortName: 'SH',
        name: 'SAINT HELENA',
        code: '290',
    },
    {
        shortName: 'SI',
        name: 'SLOVENIA',
        code: '386',
    },
    {
        shortName: 'SK',
        name: 'SLOVAKIA',
        code: '421',
    },
    {
        shortName: 'SL',
        name: 'SIERRA LEONE',
        code: '232',
    },
    {
        shortName: 'SM',
        name: 'SAN MARINO',
        code: '378',
    },
    {
        shortName: 'SN',
        name: 'SENEGAL',
        code: '221',
    },
    {
        shortName: 'SO',
        name: 'SOMALIA',
        code: '252',
    },
    {
        shortName: 'SR',
        name: 'SURINAME',
        code: '597',
    },
    {
        shortName: 'ST',
        name: 'SAO TOME AND PRINCIPE',
        code: '239',
    },
    {
        shortName: 'SV',
        name: 'EL SALVADOR',
        code: '503',
    },
    {
        shortName: 'SY',
        name: 'SYRIAN ARAB REPUBLIC',
        code: '963',
    },
    {
        shortName: 'SZ',
        name: 'SWAZILAND',
        code: '268',
    },
    {
        shortName: 'TC',
        name: 'TURKS AND CAICOS ISLANDS',
        code: '1649',
    },
    {
        shortName: 'TD',
        name: 'CHAD',
        code: '235',
    },
    {
        shortName: 'TG',
        name: 'TOGO',
        code: '228',
    },
    {
        shortName: 'TH',
        name: 'THAILAND',
        code: '66',
    },
    {
        shortName: 'TJ',
        name: 'TAJIKISTAN',
        code: '992',
    },
    {
        shortName: 'TK',
        name: 'TOKELAU',
        code: '690',
    },
    {
        shortName: 'TL',
        name: 'TIMOR-LESTE',
        code: '670',
    },
    {
        shortName: 'TM',
        name: 'TURKMENISTAN',
        code: '993',
    },
    {
        shortName: 'TN',
        name: 'TUNISIA',
        code: '216',
    },
    {
        shortName: 'TO',
        name: 'TONGA',
        code: '676',
    },
    {
        shortName: 'TR',
        name: 'TURKEY',
        code: '90',
    },
    {
        shortName: 'TT',
        name: 'TRINIDAD AND TOBAGO',
        code: '1868',
    },
    {
        shortName: 'TV',
        name: 'TUVALU',
        code: '688',
    },
    {
        shortName: 'TW',
        name: 'TAIWAN, PROVINCE OF CHINA',
        code: '886',
    },
    {
        shortName: 'TZ',
        name: 'TANZANIA, UNITED REPUBLIC OF',
        code: '255',
    },
    {
        shortName: 'UA',
        name: 'UKRAINE',
        code: '380',
    },
    {
        shortName: 'UG',
        name: 'UGANDA',
        code: '256',
    },
    {
        shortName: 'US',
        name: 'UNITED STATES',
        code: '1',
    },
    {
        shortName: 'UY',
        name: 'URUGUAY',
        code: '598',
    },
    {
        shortName: 'UZ',
        name: 'UZBEKISTAN',
        code: '998',
    },
    {
        shortName: 'VA',
        name: 'HOLY SEE (VATICAN CITY STATE)',
        code: '39',
    },
    {
        shortName: 'VC',
        name: 'SAINT VINCENT AND THE GRENADINES',
        code: '1784',
    },
    {
        shortName: 'VE',
        name: 'VENEZUELA',
        code: '58',
    },
    {
        shortName: 'VG',
        name: 'VIRGIN ISLANDS, BRITISH',
        code: '1284',
    },
    {
        shortName: 'VI',
        name: 'VIRGIN ISLANDS, U.S.',
        code: '1340',
    },
    {
        shortName: 'VN',
        name: 'VIET NAM',
        code: '84',
    },
    {
        shortName: 'VU',
        name: 'VANUATU',
        code: '678',
    },
    {
        shortName: 'WF',
        name: 'WALLIS AND FUTUNA',
        code: '681',
    },
    {
        shortName: 'WS',
        name: 'SAMOA',
        code: '685',
    },
    {
        shortName: 'XK',
        name: 'KOSOVO',
        code: '381',
    },
    {
        shortName: 'YE',
        name: 'YEMEN',
        code: '967',
    },
    {
        shortName: 'YT',
        name: 'MAYOTTE',
        code: '262',
    },
    {
        shortName: 'ZA',
        name: 'SOUTH AFRICA',
        code: '27',
    },
    {
        shortName: 'ZM',
        name: 'ZAMBIA',
        code: '260',
    },
    {
        shortName: 'ZW',
        name: 'ZIMBABWE',
        code: '263',
    },
];
