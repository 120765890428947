import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { timeOffTypeIDs } from 'utils/constants';

const moment = extendMoment(Moment);
const currentMoment = moment();

export const getRevisionsList = (contracts, start, end) => {
    const startDate = start || currentMoment.format('YYYY-MM-DD');
    const endDate = end || currentMoment.format('YYYY-MM-DD');
    const revisions = [];

    const newContracts = contracts.reduce((list, contract) => {
        const activeRevision = contract.revisions.find((revision) => {
            const range = moment.range(revision.start, revision.end);

            return (!revision.end || range.contains(moment(endDate))) && range.contains(moment(startDate));
        });

        if (activeRevision) {
            list.push(contract);
            revisions.push({
                ...activeRevision,
                relatedContractId: contract.id,
            });
        }

        return list;
    }, []);

    return { revisions, newContracts };
};

export const fetchRevisionInfo = (entitlementByContractId, timeOffType) => {
    const emptyData = { seconds: 0, days: 0, hours: 0 };

    if (!timeOffType.id) {
        return null;
    }

    const filteredEntitlement = entitlementByContractId.filter((pot) =>
        pot.timeOffTypeIds.includes(Number(timeOffType.id)),
    );

    const isLieuTime = timeOffType.type === timeOffTypeIDs.lieuTime;

    let activeFilteredEntitlement = filteredEntitlement;

    if (isLieuTime) {
        const lieuTimeEntitlement = entitlementByContractId.filter((pot) => pot.type === 'lieu_time');
        return {
            info: {
                lieu: lieuTimeEntitlement[0].balance,
            },
            units: lieuTimeEntitlement[0].displayUnit || 'days',
        };
    }

    if (activeFilteredEntitlement.length > 0 && activeFilteredEntitlement[0].type === 'default') {
        activeFilteredEntitlement = filteredEntitlement.filter((pot) =>
            pot.periods?.some((period) => moment().isBetween(period.start, period.end)),
        );
    }

    if (activeFilteredEntitlement.length > 1) {
        activeFilteredEntitlement = [activeFilteredEntitlement[0]];
    }

    if (activeFilteredEntitlement.length === 0) {
        return null;
    }

    if (activeFilteredEntitlement.length === 1) {
        if (activeFilteredEntitlement[0].type === 'lieu_time') {
            return {
                info: {
                    lieu: activeFilteredEntitlement[0].balance,
                },
                units: activeFilteredEntitlement[0].displayUnit || 'days',
            };
        } else if (activeFilteredEntitlement[0].type === 'default') {
            const currentPotPeriod = activeFilteredEntitlement[0].periods.find((period) =>
                moment().isBetween(period.start, period.end),
            );

            return {
                info: {
                    entitlement: currentPotPeriod ? currentPotPeriod.entitlement.actual : emptyData,
                    used: currentPotPeriod ? currentPotPeriod.usage.used : emptyData,
                    booked: currentPotPeriod ? currentPotPeriod.usage.booked : emptyData,
                    balance: currentPotPeriod ? currentPotPeriod.usage.total.balances.actual : emptyData,
                },
                units: currentPotPeriod ? activeFilteredEntitlement[0].displayUnit : 'days',
            };
        }
    }
};
