import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { StaffScheduleRow } from './StaffScheduleRow';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/fp/flow';

import { normaliseWeekRota, classifyWeekRotas, filterOtherStaffShiftsByCurrentShop } from './helpers';
import { spreadApprovedTimeOffPerDay, getScheduleWithUnavailableDays } from './utils';
import { fixDayOffsForShifts, spreadNightShiftsPerDay } from 'containers/Schedule/utils';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/LoadingComponent';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { useStaffForShopSchedule } from 'services/schedule';
import { useSelector } from 'react-redux';
import { useUser } from 'services/auth';
import { useSettings } from 'services/useSettings';
import { useUnavailableDaysForUser } from 'services/availability';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: theme.spacing(10),
    },
    scrollableArea: {
        maxHeight: 'calc(100vh - 64px - 50px - 50px - 106px - 160px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        [theme.breakpoints.down('md')]: {
            maxHeight: 'calc(100vh - 64px - 50px - 50px - 82px - 150px)',
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 64px - 50px - 82px - 122px)',
        },
    },
}));

export const StaffSchedule = ({ currentShop }) => {
    const { t } = useTranslation();

    const classes = useStyles();
    const {
        userDetails: { internalStaffNumber, firstName, lastName },
    } = useUser();
    const {
        settings: {
            staffAvailability: { overallEnabled: isStaffAvailabilityEnabled },
        },
    } = useSettings();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const {
        unavailableDays = [],
        isUnavailableDaysLoading,
        isUnavailableDaysError,
    } = useUnavailableDaysForUser(startDate, endDate, isStaffAvailabilityEnabled);
    const { shopSchedule, isShopScheduleLoading, isShopScheduleError } = useStaffForShopSchedule(
        currentShop?.shopId,
        startDate,
        endDate,
    );

    if (!currentShop) {
        return (
            <Grid container direction="column" alignItems="center" className={classes.padding}>
                <Grid item xs>
                    <Typography variant="h5">{t('schedule:no_shift_for_period')}</Typography>
                </Grid>
            </Grid>
        );
    }

    if (isShopScheduleLoading || isUnavailableDaysLoading) {
        return <Loading className={classes.topSpacer} />;
    }

    if (isShopScheduleError || isUnavailableDaysError) {
        return <ErrorMessage className={classes.topSpacer} message={t('schedule:error_something_wrong')} />;
    }

    const scheduleWithUnavailableDays = getScheduleWithUnavailableDays(
        shopSchedule,
        unavailableDays,
        internalStaffNumber,
        firstName,
        lastName,
    );
    const scheduleWithNightShiftsSpread = scheduleWithUnavailableDays.map((userData) => ({
        ...userData,
        shifts: userData.shifts ? flow(fixDayOffsForShifts, spreadNightShiftsPerDay)(userData.shifts) : [],
    }));

    const scheduleWithApprovedTimeOffPerDay = spreadApprovedTimeOffPerDay(scheduleWithNightShiftsSpread);

    const filteredShopScheduleByCurrentShop = filterOtherStaffShiftsByCurrentShop(
        scheduleWithApprovedTimeOffPerDay,
        internalStaffNumber,
        currentShop,
    );

    const allStaffNormaliseWeekRota = normaliseWeekRota(startDate, endDate, filteredShopScheduleByCurrentShop);

    const sortedWeekRota = classifyWeekRotas(allStaffNormaliseWeekRota, internalStaffNumber);

    return (
        <>
            <StaffScheduleRow
                currentShop={currentShop}
                currentWeekRota={sortedWeekRota.currentUser}
                isCurrentUser={true}
            />
            <div className={classes.scrollableArea}>
                {sortedWeekRota.otherStaff.map((staffMember) => (
                    <StaffScheduleRow
                        currentShop={currentShop}
                        currentWeekRota={staffMember}
                        key={staffMember.staffID}
                        isCurrentUser={false}
                    />
                ))}
            </div>
        </>
    );
};

StaffSchedule.propTypes = {
    currentShop: PropTypes.shape({
        shopId: PropTypes.number,
        shopName: PropTypes.string.isRequired,
    }),
};
