import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
const currentMoment = moment();

export const getCurrentRevisions = (allContracts) => {
    const currentContracts = allContracts.filter((contract) => {
        const range = moment.range(contract.start, contract.end);

        return range.contains(currentMoment);
    });

    return [].concat(
        ...currentContracts.map((contract) =>
            contract.revisions
                .filter((revision) => {
                    const range = moment.range(revision.start, revision.end);

                    return range.contains(currentMoment);
                })
                .map((rev) => ({ ...rev, relatedContractId: contract.id })),
        ),
    );
};

export const getMostContractedRevision = (allContracts) =>
    getCurrentRevisions(allContracts).reduce((mostContracted, revision) =>
        mostContracted.contractedHours > revision.contractedHours ? mostContracted : revision,
    );
