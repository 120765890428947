import { useTranslation } from 'react-i18next';

export const useGetHoursAndMinutes = () => {
    const { t } = useTranslation();

    return (hours) => {
        const roundedHours = Number(hours.toFixed(2));

        if (Number.isInteger(roundedHours)) {
            return t('units:num_hours_short', { hours: roundedHours });
        }

        const wholeHours = Math.floor(roundedHours);
        const wholeMinutes = Math.round((roundedHours % 1) * 60);

        if (wholeHours === 0) {
            return t('units:num_minutes_short', { minutes: wholeMinutes });
        }

        return t('units:num_hours_minutes_short', {
            hours: wholeHours,
            minutes: wholeMinutes,
        });
    };
};
