import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card, CardContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    standardMargin: {
        marginBottom: 50,
    },
    removeMargin: {
        marginBottom: 30,
    },
    contentPadding: {
        padding: theme.spacing(4, 4),
        '& > *, & > section > *': {
            borderRadius: '5px !important',

            '&::before': {
                backgroundColor: '#fff',
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    },
    marginCorrection: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        margin: '20px 0 5px',
    },
}));

const Panel = ({ children, removeBottomGutter, className, ...rest }) => {
    const classes = useStyles();

    return (
        <Card
            className={classnames({
                [classes.standardMargin]: !removeBottomGutter,
                [classes.removeMargin]: removeBottomGutter,
                [className]: true,
            })}
            elevation={1}
            {...rest}
        >
            <CardContent className={classes.contentPadding}>{children}</CardContent>
        </Card>
    );
};

Panel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    removeBottomGutter: PropTypes.bool,
};

export default Panel;
