import { makeStyles, Grid } from '@material-ui/core';
import { ChangePassword } from 'containers/ManageAccount/changePassword/ChangePassword';
import { ProfileInformation } from 'containers/ManageAccount/profileInformation/ProfileInformation';
import { Preferences } from 'containers/ManageAccount/Preferences';
import { ProfileCard } from 'containers/ManageAccount/ProfileCard';
import { ContractInformation } from 'containers/ManageAccount/ContractInformation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    page: {
        paddingTop: 25,
        paddingBottom: 50,
    },
    profileContainer: {
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 20,
        },
    },
    mobilePadding: {
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 60,
        },
    },
}));

export const ManageAccount = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center" className={classes.page}>
            <Grid container item xs={11} justifyContent="space-between">
                <Grid item xs={12} lg={6}>
                    <Grid className={classes.profileContainer}>
                        <ProfileCard header={t('user:profile_info')}>
                            <ProfileInformation />
                        </ProfileCard>
                    </Grid>
                    <Grid className={classes.profileContainer}>
                        <ProfileCard header={t('user:contract_details')}>
                            <ContractInformation />
                        </ProfileCard>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Grid className={classes.profileContainer}>
                        <ProfileCard header={t('user:change_password_title')}>
                            <ChangePassword />
                        </ProfileCard>
                    </Grid>
                    <Grid className={classes.mobilePadding}>
                        <Preferences />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
