import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { setDefaultOptions } from 'date-fns';

import 'moment/locale/fr';
import { useSettings } from '../../services/useSettings';
moment.locale('en');

const DateLocaleContext = createContext({ moment, hasLoaded: false });

const useDateLocaleContext = () => useContext(DateLocaleContext);

export const useMoment = () => useDateLocaleContext();
export const useHasMomentLoaded = () => useDateLocaleContext().hasLoaded;

export const DateLocaleProvider = ({ children }) => {
    const { settings } = useSettings();
    const [hasLoaded, setHasLoaded] = useState(false);

    const [dateLocale, setDateLocale] = useState('en-GB');

    const shortI18NLocale = useTranslation().i18n.language.slice(0, 2);

    useEffect(() => {
        setDateLocale(shortI18NLocale);
    }, [shortI18NLocale]);

    useEffect(() => {
        if (settings) {
            setDateLocale('en-GB');
        }
    }, [settings]);

    const localisedMoment = useMemo(() => {
        setDefaultOptions({
            weekStartsOn: settings?.rota?.startDay,
        });
        moment.updateLocale(dateLocale, {
            week: {
                dow: settings?.rota?.startDay,
                doy: 1,
            },
        });
        setHasLoaded(true);

        return moment;
    }, [dateLocale, settings]);

    return (
        <DateLocaleContext.Provider
            value={{
                moment: localisedMoment,
                hasLoaded,
            }}
        >
            {children}
        </DateLocaleContext.Provider>
    );
};

DateLocaleProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
