import { FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    radioButton: {
        color: theme.palette.primary.dark,
        '&:checked': {
            color: theme.palette.primary.dark,
        },
    },
}));

const RadioButtonGroupComponent = ({
    items,
    ariaLabel,
    label,
    value,
    name,
    formControlProps = {},
    labelPlacement = 'start',
    formControlLabelProps = {},
    ...rest
}) => {
    const classes = useStyles();

    return (
        <FormControl component="fieldset" {...formControlProps}>
            {label ? <FormLabel component="legend">{label}</FormLabel> : null}
            <RadioGroup aria-label={ariaLabel} name={name} value={`${value}`} {...rest}>
                {items
                    ? items.map((item, index) => (
                          <FormControlLabel
                              value={`${item.id}`}
                              key={`${index}_radio`}
                              label={item.name}
                              labelPlacement={labelPlacement}
                              control={<Radio className={classes.radioButton} color="default" />}
                              {...formControlLabelProps}
                          />
                      ))
                    : null}
            </RadioGroup>
        </FormControl>
    );
};

RadioButtonGroupComponent.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    value: PropTypes.any,
    ariaLabel: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    labelPlacement: PropTypes.string,
    formControlLabelProps: PropTypes.object,
    formControlProps: PropTypes.object,
    label: PropTypes.string,
};

export const RadioButtonGroup = RadioButtonGroupComponent;
