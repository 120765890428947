import { useState } from 'react';
import PropTypes from 'prop-types';
import { isBrowser, useIsMobileOrTablet } from 'utils/components/helpers';
import { Typography, IconButton, Dialog, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { DatePicker } from 'components/datePicker/DatePicker';

const useStyles = makeStyles((theme) => ({
    closeIconButton: {
        position: 'fixed',
        right: 5,
        top: 5,
    },
    dateRange: {
        background: theme.palette.common.white,
        padding: '8px 12px',
        color: theme.palette.grey[600],
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 3,
        [theme.breakpoints.down('xs')]: {
            padding: '4px 5px',
            fontSize: 13,
        },
    },
}));

const ResponsiveDatePicker = ({
    endDate,
    startDate,
    displayFormat,
    onFocusChange,
    focusedInput,
    noMobileView,
    numberOfMonths,
    startDateId,
    endDateId,
    noBorder,
    isOutsideRange,
    handleDatesChange,
}) => {
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);
    const isMobileOrTablet = useIsMobileOrTablet();

    const handleOpenClose = () => setDialogOpen((prevState) => !prevState);

    const onDatesChange = (dates) => {
        setDialogOpen(false);
        handleDatesChange(dates);
    };

    return isMobileOrTablet && startDate && endDate ? (
        <>
            <Typography
                variant="subtitle1"
                className={classes.dateRange}
                onClick={noMobileView ? null : handleOpenClose}
                data-testid="ResponsiveDatePicker-mobile-control"
            >
                {`${startDate.format(displayFormat)} - ${endDate.format(displayFormat)}`}
            </Typography>

            <Dialog onClose={handleOpenClose} fullScreen={isMobileOrTablet} open={dialogOpen}>
                <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    hideKeyboardShortcutsPanel
                    showInputs={false}
                    orientation="vertical"
                    onDatesChange={onDatesChange}
                    verticalHeight={isBrowser ? window.innerHeight : null}
                    focusedInput="startDate"
                    displayFormat={displayFormat}
                    numberOfMonths={numberOfMonths}
                    startDateId={startDateId}
                    endDateId={endDateId}
                    noBorder={noBorder}
                    isOutsideRange={isOutsideRange}
                />
                <IconButton onClick={handleOpenClose} className={classes.closeIconButton}>
                    <Close />
                </IconButton>
            </Dialog>
        </>
    ) : (
        <DatePicker
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            hideKeyboardShortcutsPanel
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            displayFormat={displayFormat}
            numberOfMonths={numberOfMonths}
            startDateId={startDateId}
            endDateId={endDateId}
            noBorder={noBorder}
            isOutsideRange={isOutsideRange}
        />
    );
};

ResponsiveDatePicker.propTypes = {
    noMobileView: PropTypes.bool,
    endDate: PropTypes.object,
    startDate: PropTypes.object,
    handleDatesChange: PropTypes.func,
    displayFormat: PropTypes.string,
    onFocusChange: PropTypes.func,
    focusedInput: PropTypes.string,
    numberOfMonths: PropTypes.number,
    startDateId: PropTypes.string,
    endDateId: PropTypes.string,
    noBorder: PropTypes.bool,
    isOutsideRange: PropTypes.func,
};

export default ResponsiveDatePicker;
