export const filterTimeOffData = (userTimeOffData, status) =>
    userTimeOffData.filter((element) => element.status === status);

export const sortTimeOffRequests = (userTimeOffData) =>
    [...userTimeOffData].sort((a, b) => {
        if (a.start < b.start) {
            return -1;
        }
        if (a.start > b.start) {
            return 1;
        }

        return 0;
    });

export const dataColors = {
    approved: 'primary',
    requested: 'warning',
    declined: 'danger',
    cancelled: 'disabled',
};
