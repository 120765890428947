import { Grid, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const LoadingComponent = ({ style, title, circularProgressStyle }) => (
    <Grid container justifyContent="center" style={style}>
        <CircularProgress title={title} style={circularProgressStyle} />
    </Grid>
);

LoadingComponent.propTypes = {
    style: PropTypes.object,
    circularProgressStyle: PropTypes.object,
    title: PropTypes.string,
};

export default LoadingComponent;
