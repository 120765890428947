import { makeStyles, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DescriptionOutlined } from '@material-ui/icons';

import PanelHeadline from 'components/PanelHeadline';
import ErrorMessage from 'components/ErrorMessage';
import { DocumentsTable } from './DocumentsTable';

import { useStaffDocuments } from 'services/documents';
import LoadingComponent from 'components/LoadingComponent';
import { useIsMobileOrTablet } from 'utils/components/helpers';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: theme.spacing(6, 6, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2, 7.5),
            maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
        },
    },
    dataTablePaper: {
        padding: theme.spacing(1.5),
    },
}));

export const Documents = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useIsMobileOrTablet();
    const { staffDocuments, isStaffDocumentsLoading, isStaffDocumentsError } = useStaffDocuments();

    const title = t('portal:documents');
    const errorMessage = t('common:something_went_wrong_please_refresh');

    const LoadingOrErrorComponent = () =>
        isStaffDocumentsLoading ? <LoadingComponent /> : <ErrorMessage message={errorMessage} alignText="center" />;

    const headerRows = isMobile
        ? [t('documents:table_header.file_name'), t('documents:table_header.export')]
        : [
              t('documents:table_header.file_name'),
              t('documents:table_header.upload_date'),
              t('documents:table_header.expiry_date'),
              t('documents:table_header.export'),
          ];

    return (
        <div className={classes.pageContainer}>
            <PanelHeadline icon={<DescriptionOutlined color="primary" />}>{title}</PanelHeadline>
            <Paper className={classes.dataTablePaper}>
                {isStaffDocumentsLoading || isStaffDocumentsError ? (
                    <LoadingOrErrorComponent />
                ) : (
                    <DocumentsTable headerRows={headerRows} tableContents={staffDocuments || []} />
                )}
            </Paper>
        </div>
    );
};
