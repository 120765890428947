import { useEffect } from 'react';

export const StatusPageWidget = () => {
    useEffect(() => {
        if (import.meta.env.PROD) {
            const script = document.createElement('script');

            script.src = 'https://cfv7xrdts1dg.statuspage.io/embed/script.js';
            script.async = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, []);

    return null;
};
