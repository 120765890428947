import moment from 'moment';

export const formatShiftPeriod = (startTime, endTime) => `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;

export const createWeekDateArray = (startOfWeek, endOfWeek) => {
    const days = [];

    if (Boolean(startOfWeek) === false || Boolean(endOfWeek) === false) {
        return days;
    }

    let day = moment(startOfWeek);

    while (day.isSameOrBefore(moment(endOfWeek))) {
        days.push({
            date: day.format('D'),
            day: day.format('dddd'),
            daySmall: day.format('ddd'),
            mobileSizeDay: day.format('dd'),
            month: day.format('MMM'),
            isNewMonth: day.date() === 1,
            isToday: day.isSame(new Date(), 'day'),
            fulldate: day,
        });
        day = day.clone().add(1, 'd');
    }

    return days;
};

export const normaliseWeekRota = (startDate, endDate, staffData) => {
    const weekPeriod = createWeekDateArray(startDate, endDate);

    const sortEventsIntoWeekRota = (listOfEvents) =>
        weekPeriod.reduce((acc, period) => {
            const day = moment(period.fulldate);

            const isEventWithPeriod = listOfEvents.filter((event) => {
                const newStart = event.startDate || event.start;
                const newEnd = event.endDate || event.end;

                return day.isSame(newStart, 'day') || day.isBetween(newStart, newEnd);
            });

            if (isEventWithPeriod.length > 0) {
                acc.push({
                    isToday: moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD'),
                    working: true,
                    activity: isEventWithPeriod,
                });
            } else {
                acc.push({
                    isToday: moment().format('YYYY-MM-DD') === day.format('YYYY-MM-DD'),
                    working: false,
                    placeholderText: 'availability:available',
                    placeholderText_mobile: '-',
                    activity: null,
                });
            }

            return acc;
        }, []);

    const staffOrganisedById = staffData.reduce((acc, staffMember) => {
        const shifts = staffMember.shifts ? [...staffMember.shifts] : [];
        const timeOff = staffMember.timeOff
            ? [...staffMember.timeOff].map((holiday) => ({ ...holiday, timeOff: true }))
            : [];

        acc[staffMember.internalStaffNumber] = {
            fullName: `${staffMember.firstName} ${staffMember.surname}`,
            weekRota: sortEventsIntoWeekRota([...shifts, ...timeOff]),
        };

        return acc;
    }, {});

    return staffOrganisedById;
};

const sortStaffWithUserFirst = (staffArray, userStaffID) =>
    staffArray.sort((a, b) => {
        if (a.staffID === userStaffID) {
            return -1;
        }
        if (b.staffID === userStaffID) {
            return 1;
        }

        return a.staffID.localeCompare(b.staffID);
    });

export const classifyWeekRotas = (weekRota, userStaffNumber) => {
    const weekRotaArrayWithIDs = Object.keys(weekRota).map((staffMemberID) => ({
        ...weekRota[staffMemberID],
        staffID: staffMemberID,
    }));

    const [currentUser, ...otherStaff] = sortStaffWithUserFirst(weekRotaArrayWithIDs, userStaffNumber);

    return { currentUser, otherStaff };
};

export const getAllShopsInShifts = (shifts) => {
    if (!shifts) {
        return [];
    }

    return shifts.reduce((acc, shift) => {
        const accIncludesShop = acc.find((elem) => elem.shopId === shift.shopId);

        if (!accIncludesShop && shift.shopId) {
            acc.push({
                shopId: shift.shopId,
                shopName: shift.shopName,
            });
        }

        return acc;
    }, []);
};

export const filterOtherStaffShiftsByCurrentShop = (shopSchedule, internalStaffNumber, currentShop) =>
    shopSchedule.map((staffMember) => {
        if (staffMember.internalStaffNumber === internalStaffNumber) {
            return staffMember;
        }

        return {
            ...staffMember,
            shifts:
                currentShop && staffMember.shifts
                    ? staffMember.shifts.filter((shift) => shift.shopId === currentShop.shopId)
                    : staffMember.shifts,
        };
    });

export const calcShopScheduleDisplayText = (roleName, type) => {
    if (roleName) {
        return roleName;
    } else if (type === 'day_off') {
        return { key: 'schedule:day_off' };
    }

    return type;
};

export const normaliseEventsForDialog = (events, name) => {
    if (!events || !Object.values(events).length) {
        return [];
    }

    const eventList = Array.isArray(events) ? events : [events];

    return eventList.map((event) => ({
        userName: name,
        role: calcShopScheduleDisplayText(event.roleName, event.type),
        timeOff: event.type !== 'shift',
        status: event.status || '',
        start: event.start,
        end: event.end,
        shopName: event.shopName || '',
        departmentName: event.departmentName || '',
        paidHours: event.paidHours,
        unpaidHours: event.unpaidHours,
    }));
};
