import { Checkbox as CheckboxMUI, FormControl, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const CheckBox = ({ label, labelPlacement = 'start', onClick, ...rest }) => (
    <FormControl>
        <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            control={<CheckboxMUI {...rest} onClick={onClick} />}
        />
    </FormControl>
);

CheckBox.propTypes = {
    label: PropTypes.any,
    onClick: PropTypes.func.isRequired,
    checkedIcon: PropTypes.node,
    icon: PropTypes.node,
    labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
};

export default CheckBox;
