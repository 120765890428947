import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { settingsReducer } from './settings/settingsSlice';
import { loginReducer } from 'containers/Login/loginSlice';
import { ScheduleReducer } from 'containers/Schedule/store/scheduleSlice';
import { AvailabilityReducer } from 'containers/Schedule/store/availabilitySlice';
import { shiftOfferReducer } from 'containers/ShiftOffer/shiftOfferSlice';
import { timeOffReducer } from 'containers/timeOff/timeOffSlice';
import { requestTimeOffReducer } from 'containers/RequestTimeOff/requestTimeOffSlice';
import { eventSnackBarReducer } from 'components/snackbar/eventSnackBarSlice';
import { menuReducer } from 'components/layouts/menuSlice';
import { entitlementReducer } from 'containers/timeOff/entitlementSlice';

export const rootReducer = combineReducers({
    settings: settingsReducer,
    login: loginReducer,
    schedule: ScheduleReducer,
    availability: AvailabilityReducer,
    shiftOffer: shiftOfferReducer,
    timeOff: timeOffReducer,
    requestTimeOff: requestTimeOffReducer,
    eventSnackBar: eventSnackBarReducer,
    menu: menuReducer,
    entitlement: entitlementReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: { warnAfter: 100 },
            immutableCheck: { warnAfter: 100 },
        }),
});
