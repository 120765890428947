import { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import OldBrowser from '../pages/OldBrowser';

export const RedirectOnIe11 = ({ children }) => {
    const isIE = /Trident\/|MSIE/.test(window.navigator.userAgent);

    useEffect(() => {
        if (isIE && !window.location.href.includes('old-browser')) {
            window.location.href = '/old-browser';
        }
    }, [isIE]);

    if (isIE) {
        return (
            <Router>
                <Route path="/old-browser">
                    <OldBrowser />
                </Route>
            </Router>
        );
    }

    return children;
};

RedirectOnIe11.propTypes = {
    children: PropTypes.node.isRequired,
};
