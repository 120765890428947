/* eslint-disable */
import axios from 'axios';

import { snakeToCamelObjectKeys } from 'utils/strings/helpers';
import { isMystaffTenant } from 'utils/tenant';

export const apiClient = (options = {}) => {
    const url = window.location.hostname;
    const gatewayURL = isMystaffTenant(url)
        ? import.meta.env.VITE_MYSTAFF_GATEWAY_URL
        : import.meta.env.VITE_GATEWAY_URL;
    const client = axios.create({
        timeout: 60000,
        baseURL: gatewayURL,
        ...options,
        headers: {
            ...(options.headers ? options.headers : {}),
            'Content-Type': 'application/json',
            Accept: 'application/json',
            api_host: import.meta.env.VITE_API_HOST ?? '',
        },
        withCredentials: true,
        xsrfCookieName: '_csrf',
        xsrfHeaderName: 'X-CSRF-TOKEN',
    });

    if (!options.disableClientInterceptors) {
        client.interceptors.response.use(
            (response) => snakeToCamelObjectKeys(response),
            (error) => {
                return Promise.reject(error);
            },
        );
    }

    return client;
};
