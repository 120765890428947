import { Grid, makeStyles } from '@material-ui/core';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#333333',
        border: '1px solid #ddd',
        borderRadius: 4,
        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
    },
    header: {
        backgroundColor: theme.palette.grey[100],
        padding: '10px 15px',
        borderColor: '#ddd',
        fontSize: 15,
        borderBottom: '1px solid #ddd',
    },
    content: {
        padding: 15,
        fontSize: 13,
        background: theme.palette.common.white,
    },
    detectionNotice: {
        marginBottom: 9,
    },
    notice: {
        color: '#d9534e',
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    upgradeInfo: {
        marginBottom: 9,
    },
    footer: {
        padding: '10px 15px',
        borderTop: '1px solid #ddd',
        fontSize: 17,
        backgroundColor: theme.palette.grey[100],
        '& a': {
            color: '#01569a',
            textDecoration: 'none',
        },
    },
    iconBusy: {
        color: '#ff0000',
        fontSize: '3em',
    },
    iconGreen: {
        fontSize: '3em',
        color: '#6da820',
    },
}));

function OldBrowser() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container justifyContent="center">
            <Grid container direction="column" className={classes.root} justifyContent="center" alignItems="center">
                <Grid container className={classes.header}>
                    {t('portal:page_old_browser.header')}
                </Grid>
                <Grid container direction="column" className={classes.content} alignItems="center">
                    <div className={classes.icons}>
                        <EventBusyIcon className={classes.iconBusy} />
                        <ArrowRightAltIcon className={classes.iconGreen} />
                        <EventAvailableIcon className={classes.iconGreen} />
                    </div>
                    <div className={classes.detectionNotice}>{t('portal:page_old_browser.description.top')}</div>
                    <span className={classes.notice}>{t('portal:page_old_browser.description.middle')}</span>
                    <span className={classes.upgradeInfo}>{t('portal:page_old_browser.description.bottom')}</span>
                </Grid>
                <Grid container className={classes.footer} justifyContent="center">
                    <a href="https://support.theshopworks.com/hc/en-us/articles/360002731814">
                        {t('portal:page_old_browser.footer')}
                    </a>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OldBrowser;
