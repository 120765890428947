export const formats = {
    BACKEND_FORMAT: 'YYYY-MM-DD',
    DAY: 'day',
    DAYS: 'days',
    MINUTES: 'minutes',
    DMY_DOT: 'DD.MM.YYYY',
    DATEPICKER_HEADLINE: 'ddd DD/MM/YYYY',
    MOBILE_HEADLINE: 'ddd D MMM',
    TIME: 'HH:mm',
    LONG_DATE: 'dddd MMMM D YYYY',
};

export const VARIABLE_REQUESTED = 'requested';
export const VARIABLE_APPROVED = 'approved';
export const VARIABLE_WARNING = 'warning';
export const VARIABLE_PRIMARY = 'primary';
export const VARIABLE_ERROR = 'error';
export const VARIABLE_UNAVAILABLE = 'unavailable';
export const VARIABLE_AVAILABLE = 'available';
export const VARIABLE_ACCEPTED = 'accepted';
export const VARIABLE_REJECTED = 'rejected';

export const timeOffStatusTypes = {
    requested: 1,
    approved: 2,
    declined: 3,
    cancelled: 4,
    under: 5,
};

export const timeOffTypeIDs = {
    lieuTime: 5,
    training: 1,
    meeting: 3,
};

export const APPLE_APP_STORE_URL = 'https://apps.apple.com/sa/app/shopworks/id1631503658';
export const GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.shopworks.portal.app';
