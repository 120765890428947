import { makeStyles, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { string, number, oneOfType } from 'prop-types';
import { useIsMobile } from 'utils/components/helpers';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.secondary,
        fontSize: 14,
    },
    info: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 14,
        marginLeft: 'auto',
    },
}));

export const InfoRow = ({ title, info }) => {
    const classes = useStyles();

    const isMobile = useIsMobile();

    return isMobile ? (
        <ListItem divider>
            <ListItemText
                primary={title}
                secondary={info}
                classes={{
                    primary: classes.title,
                    secondary: classes.info,
                }}
            />
        </ListItem>
    ) : (
        <ListItem divider>
            <ListItemText secondary={title} />
            <ListItemSecondaryAction>
                <ListItemText primary={info} classes={{ primary: classes.info }} />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

InfoRow.propTypes = {
    title: string.isRequired,
    info: oneOfType([number, string]).isRequired,
};
