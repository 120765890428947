import { Grow, Paper, Typography, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    highZIndex: {
        zIndex: theme.zIndex.modal + 1,
    },
    paper: {
        position: 'absolute',
        zIndex: theme.zIndex.modal + 2,
        left: theme.spacing(1),
        top: theme.spacing(14),
        [theme.breakpoints.down('sm')]: {
            top: theme.spacing(12),
            left: theme.spacing(14),
        },
        width: 170,
    },
    leftArrow: {
        position: 'absolute',
        width: 0,
        height: 0,
        marginLeft: -9,
        marginTop: theme.spacing(4),
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderRight: '10px solid white',
    },
}));

export const Tooltip = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grow in timeout={500}>
            <Paper elevation={12} className={classnames(classes.paper, classes.highZIndex)}>
                <div className={classes.leftArrow} />
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle2">{t('requestTimeOff:time_off_type_tooltip_message')}</Typography>
                </div>
            </Paper>
        </Grow>
    );
};
