import { useUser } from 'services/auth';
import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';

export const createStaffMembersList = (staffMemberTimeOff) =>
    staffMemberTimeOff.map((event) => ({
        staffNumber: event.internalStaffNumber,
        firstName: event.firstName,
        surname: event.surname,
        employeeRoles: [],
    }));

export const useShopIdForDatePeriod = (start, end) => {
    const {
        userDetails: { contracts },
    } = useUser();

    const contractsWithinRange = contracts.filter((contract) => {
        const contractStart = parseISO(contract.start);
        const contractEnd = contract.end ? parseISO(contract.end) : null;

        if (contractEnd === null) {
            return true;
        } else {
            return isBefore(contractStart, parseISO(end)) && isAfter(contractEnd, parseISO(start));
        }
    });

    const allRevisions = contractsWithinRange.flatMap((contract) => contract.revisions);

    if (!allRevisions.length) {
        return null;
    }

    const revisionDuringPeriod = allRevisions.filter((revision) =>
        (isEqual(parseISO(revision.start), parseISO(end)) || isBefore(parseISO(revision.start), parseISO(end))) &&
        (!revision.end ||
            isEqual(parseISO(revision.end), parseISO(start)) ||
            isAfter(parseISO(revision.end), parseISO(start)))
            ? revision
            : null,
    );

    return revisionDuringPeriod.length ? revisionDuringPeriod[0] : allRevisions[0];
};
