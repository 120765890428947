/**
 * returns colours luminance between 0 and 1.
 * @param hexColor
 *
 * @returns number
 */
export const getColourLuminance = (hexColor: string): number => {
    // Remove the '#' if it exists
    hexColor = hexColor.replace('#', '');

    // Convert hex to RGB
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    const R = r / 255;
    const G = g / 255;
    const B = b / 255;

    // https://www.w3.org/TR/WCAG21/relative-luminance.html
    // Apply gamma correction (WCAG formula)
    const R_linear = R <= 0.04045 ? R / 12.92 : ((R + 0.055) / 1.055) ** 2.4;
    const G_linear = G <= 0.04045 ? G / 12.92 : ((G + 0.055) / 1.055) ** 2.4;
    const B_linear = B <= 0.04045 ? B / 12.92 : ((B + 0.055) / 1.055) ** 2.4;

    // Calculate relative luminance (WCAG formula)
    const luminance = 0.2126 * R_linear + 0.7152 * G_linear + 0.0722 * B_linear;

    // Return black or white depending on luminance threshold
    return luminance % 1 !== 0 ? parseFloat(luminance.toFixed(2)) : luminance;
};
