import moment from 'moment';

const selectRequestTimeOff = (state) => state.requestTimeOff;

export const selectRequestedTimeOffType = (state) => selectRequestTimeOff(state).timeOffType;
export const selectRequestedStartDate = (state) => selectRequestTimeOff(state).startDate;
export const selectRequestedEndDate = (state) => selectRequestTimeOff(state).endDate;
export const selectShowTooltip = (state) => selectRequestTimeOff(state).showTooltip;
export const selectIsAllButtonDisabled = (state) => selectRequestTimeOff(state).isAllButtonDisabled;
export const selectNotes = (state) => selectRequestTimeOff(state).notes;

export const selectRequestedHours = (state) => selectRequestTimeOff(state).requestedHours;
export const selectRequestedHrsAndMins = (state) => selectRequestTimeOff(state).requestedHrsAndMins;
export const selectRequestedDays = (state) => selectRequestTimeOff(state).requestedDays;
export const selectUserRequests = (state) => selectRequestTimeOff(state).userRequests;
export const selectSelectionList = (state) => selectRequestTimeOff(state).selectionList;
export const selectIsRequestValid = (state) => selectRequestTimeOff(state).isRequestValid;
export const selectFinalRequestData = (state) => {
    const timeOffType = selectRequestedTimeOffType(state);
    const startDate = selectRequestedStartDate(state);
    const endDate = selectRequestedEndDate(state);
    const notes = selectNotes(state);
    const userRequests = selectUserRequests(state);

    return {
        time_off_type_id: Number(timeOffType.id),
        start: moment(startDate).startOf('day').format('YYYY-MM-DD'),
        end: moment(endDate).startOf('day').format('YYYY-MM-DD'),
        notes: notes.trim(),
        contracts: userRequests.map((req) => {
            if (req.unitType === 'days') {
                return {
                    day_equivalent: req.daysPerDay,
                    contract_id: req.relatedContractId,
                };
            }

            return {
                hours_per_day: req.hoursPerDay,
                contract_id: req.relatedContractId,
            };
        }),
    };
};

//Error selectors
export const selectIsRequestError = (state) => selectRequestTimeOff(state).isRequestError;
export const selectRequestError = (state) => selectRequestTimeOff(state).requestError;
export const selectDatePickerBlockedOrApprovedError = (state) =>
    selectRequestTimeOff(state).datePickerBlockedOrApprovedError;
export const selectRequestErrorDetails = (state) => selectRequestTimeOff(state).requestErrorDetails;
export const selectErrorInDates = (state) => selectRequestTimeOff(state).errorInDates;
