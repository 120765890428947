/* eslint-disable camelcase */
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ICU from 'i18next-icu';
import { useEffect } from 'react';
import { composeTranslation } from 'translation/composeTranslation';

import { useUser } from 'services/auth';
import { getDefaultLanguage } from './browserLanguage';

const translationFiles = import.meta.glob('./resources/*/*.json', { eager: true });

i18n.use(initReactI18next)
    .use(ICU)
    .init({
        resources: composeTranslation(translationFiles),
        lng: 'en-GB',
        fallbackLng: 'en-GB',
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
        },
        parseMissingKeyHandler(key) {
            if (import.meta.env.DEV) {
                throw new Error(`Translation with key '${key}' was used, but no translation exists!`);
            }

            return key;
        },
    });

export { i18n as i18nInstance };

export const InitialiseTranslator = ({ children }) => {
    const { userDetails } = useUser();

    const defaultLanguage = getDefaultLanguage();
    const preferredLanguage = userDetails?.preferences.locale;

    useEffect(() => {
        const cookies = document.cookie?.split(';');
        const cookieWithLanguage = cookies?.find((item) => item.trim().startsWith('language='));
        const languageFromCookie = cookieWithLanguage?.split('=')[1];

        const newLanguage = languageFromCookie || preferredLanguage || defaultLanguage;

        i18n.changeLanguage(newLanguage);
    }, [defaultLanguage, preferredLanguage]);

    return children;
};
