import { Configuration } from 'rollbar';
import { RollbarPayload } from './lib/types';
import { safariScenario } from './rules';

const checkIgnore: Configuration['checkIgnore'] = (isUncaught, args, payload) => {
    switch (true) {
        case isUncaught && safariScenario(payload as RollbarPayload):
            return true;
        default:
            return false;
    }
};

export default checkIgnore;
