import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getFutureShiftOffers, getPastShiftOffers, acceptShiftOffers, declineShiftOffers } from 'api/shiftOffer';
import { getFilteredShiftOffers } from 'containers/ShiftOffer/utils/getFilteredShiftOffers';

export const useAllShiftOffers = () => {
    const {
        data: {
            allShiftOffers,
            pendingShiftOffers,
            acceptedShiftOffers,
            declinedShiftOffers,
            unavailableShiftOffers,
        } = {},
        isLoading,
        isError,
    } = useQuery('allShiftOffers', () =>
        Promise.all([getFutureShiftOffers(), getPastShiftOffers()]).then((responses) =>
            getFilteredShiftOffers(responses),
        ),
    );

    return {
        allShiftOffers,
        pendingShiftOffers,
        acceptedShiftOffers,
        declinedShiftOffers,
        unavailableShiftOffers,
        isAllShiftOffersLoading: isLoading,
        isAllShiftOffersError: isError,
    };
};

export const useSetAcceptShiftOffers = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ selectedOfferID }) => acceptShiftOffers(selectedOfferID), {
        onSuccess: () => {
            queryClient.invalidateQueries('allShiftOffers');
        },
    });

    return { setAcceptShiftOffersMutation: mutation };
};

export const useSetDeclineShiftOffers = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ selectedOfferID }) => declineShiftOffers(selectedOfferID), {
        onSuccess: () => {
            queryClient.invalidateQueries('allShiftOffers');
        },
    });

    return { setDeclineShiftOffersMutation: mutation };
};
