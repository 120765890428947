import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    timeLine: {
        height: '100%',
        width: '10px',
        margin: 'auto',
        backgroundColor: theme.palette.common.white,
    },
    timePassed: {
        width: '100%',
        backgroundColor: theme.palette.primary.light,
        color: 'yellow',
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 12,
    },
}));

export const TimeLine = ({ height, className, ...rest }) => {
    const classes = useStyles();

    return (
        <div className={classnames(classes.timeLine, className)} {...rest}>
            <div className={classes.timePassed} style={{ height: `${height}%` }} />
        </div>
    );
};

TimeLine.propTypes = {
    classes: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    className: PropTypes.string,
};
