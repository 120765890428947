import { useQuery } from 'react-query';
import { fetchEntitlementByContractId } from 'api/entitlement';

export const useEntitlementByContractId = (contractId) => {
    const { data, isLoading, isError } = useQuery(
        ['entitlementByContractId', contractId],
        () => fetchEntitlementByContractId(contractId),
        { enabled: Boolean(contractId) },
    );

    return {
        entitlementByContractId: data ? data.data.data : [],
        isEntitlementLoading: isLoading,
        isEntitlementError: isError,
    };
};
