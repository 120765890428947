import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, makeStyles } from '@material-ui/core';

import { RotaChip } from './RotaChip';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    cellStyle: {
        minHeight: theme.rota.rowHeight,
        minWidth: theme.rota.columnWidth,
        maxWidth: theme.rota.columnWidth,
        borderRight: theme.rota.border,
        borderBottom: theme.rota.border,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    todayStyle: {
        background: theme.palette.grey[300],
    },
    lastDayOfWeekendStyle: {
        borderRight: theme.rota.borderThick,
    },
    loggedInUserBorder: {
        borderTop: theme.rota.borderLoggedInUser,
        borderBottom: theme.rota.borderLoggedInUser,
    },
    loggedInUserLastChip: {
        borderRight: theme.rota.borderLoggedInUser,
    },
}));

export const RotaCell = ({ dayEvents, date, isoWeekEnd, isLoggedInUser }) => {
    const { moment } = useMoment();
    const classes = useStyles();

    const isToday = moment(date).isSame(new Date(), 'day');
    const isEndOfWeek = moment(date).isoWeekday() === isoWeekEnd;
    const todayDate = moment().format('YYYY-MM-DD');
    const isPastDate = moment(date).isBefore(todayDate);
    const isLastChip = date === moment().endOf('month').format('YYYY-MM-DD');
    const hasEvent = dayEvents.length > 0;
    const isDayOff = hasEvent && dayEvents.some((event) => event.type === 'dayOff');
    const isTimeOff = hasEvent && dayEvents.some((event) => event.type === 'timeOff');
    const isSickness = hasEvent && dayEvents.some((event) => event.type === 'sickness');

    const shouldDisplayTwoChips = isTimeOff && isSickness;

    const getChipType = (chipNum) => {
        if (!hasEvent) {
            return 'empty';
        }
        if (isDayOff) {
            return 'dayOff';
        }
        if (isTimeOff && isSickness) {
            return chipNum === 0 ? 'timeOff' : 'sickness';
        }
        if (isTimeOff) {
            return 'timeOff';
        }
        if (isSickness) {
            return 'sickness';
        }
    };

    const getChipStatus = () => {
        if (isTimeOff) {
            const allTimeOffRequests = dayEvents.filter((event) => event.type === 'timeOff');
            const areAllRequestsApproved = allTimeOffRequests.every((event) => event.status === 'approved');
            const isAnyRequestInPending = allTimeOffRequests.some((event) => event.status === 'pending');

            if (isAnyRequestInPending) {
                return 'pending';
            }

            if (areAllRequestsApproved) {
                return 'approved';
            }
        }

        return 'none';
    };

    if (shouldDisplayTwoChips) {
        return (
            <Grid
                item
                container
                className={classnames({
                    [classes.cellStyle]: true,
                    [classes.todayStyle]: isToday,
                    [classes.lastDayOfWeekendStyle]: isEndOfWeek,
                    [classes.loggedInUserBorder]: isLoggedInUser,
                    [classes.loggedInUserLastChip]: isLastChip && isLoggedInUser,
                })}
            >
                <RotaChip
                    chipSize="small"
                    chipColor={dayEvents[0] ? dayEvents[0].color : ''}
                    isPast={isPastDate}
                    chipType={getChipType(0)}
                    chipName={dayEvents[0]?.name ?? ''}
                    status={getChipStatus()}
                />
                {dayEvents[1] && (
                    <RotaChip
                        chipSize="small"
                        chipColor={dayEvents[1] ? dayEvents[1].color : ''}
                        isPast={isPastDate}
                        chipType={getChipType(1)}
                        chipName={dayEvents[1]?.name ?? ''}
                        status={getChipStatus()}
                    />
                )}
            </Grid>
        );
    }

    return (
        <Grid
            item
            container
            className={classnames({
                [classes.cellStyle]: true,
                [classes.todayStyle]: isToday,
                [classes.lastDayOfWeekendStyle]: isEndOfWeek,
                [classes.loggedInUserBorder]: isLoggedInUser,
                [classes.loggedInUserLastChip]: isLastChip && isLoggedInUser,
            })}
        >
            <RotaChip
                chipSize="big"
                chipColor={dayEvents[0]?.color ?? ''}
                isPast={isPastDate}
                chipType={getChipType(0)}
                chipName={dayEvents[0]?.name ?? ''}
                status={getChipStatus()}
            />
        </Grid>
    );
};

RotaCell.propTypes = {
    isoWeekEnd: PropTypes.number.isRequired,
    isLoggedInUser: PropTypes.bool.isRequired,
    dayEvents: PropTypes.arrayOf(
        PropTypes.exact({
            type: PropTypes.string.isRequired,
            status: PropTypes.oneOf(['approved', 'pending']),
            color: PropTypes.string,
            name: PropTypes.string,
        }),
    ).isRequired,
    date: PropTypes.string.isRequired,
};
