import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import { VARIABLE_AVAILABLE, VARIABLE_UNAVAILABLE } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
    availableIcon: {
        fill: theme.palette.primary.dark,
    },
    unavailableIcon: {
        fill: theme.palette.danger.dark,
    },
}));

export const AvailabilityIcon = ({ status, marginRight = false }) => {
    const classes = useStyles();

    return status === VARIABLE_AVAILABLE ? (
        <EventAvailableIcon
            className={classes.availableIcon}
            style={marginRight ? { marginRight: 8 } : {}}
            data-testid="AvailabilityIcon-available"
        />
    ) : (
        <EventBusyIcon
            className={classes.unavailableIcon}
            style={marginRight ? { marginRight: 8 } : {}}
            data-testid="AvailabilityIcon-unavailable"
        />
    );
};

AvailabilityIcon.propTypes = {
    status: PropTypes.oneOf([VARIABLE_AVAILABLE, VARIABLE_UNAVAILABLE]).isRequired,
    marginRight: PropTypes.bool,
};
