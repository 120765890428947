import { createWeekDateArray } from 'containers/Schedule/localComponents/shopSchedule/helpers.js';
import moment from 'moment';

export const transformHoursWorkedData = (startOfWeekDateString, endOfWeekDateString, yourScheduleData) => {
    const weekPeriod = createWeekDateArray(moment(startOfWeekDateString), moment(endOfWeekDateString));

    const shiftsCategorisedIntoDays = weekPeriod.map((day) => {
        const allPaidShiftsForDay = yourScheduleData.filter(
            (shift) => shift.isPaid && moment(shift.startDate).format('dddd') === day.day,
        );
        const totalPaidHoursForDay = allPaidShiftsForDay.reduce((acc, shift) => acc + shift.hoursPerDay, 0);
        const areAllShiftsConfirmed = allPaidShiftsForDay.every((shift) => shift.confirmed);
        const dateDetails = {
            day: day.day,
            daySmall: day.daySmall,
            date: day.date,
            isNewMonth: day.isNewMonth,
            month: day.month,
        };

        const transformedDayData = {
            dateDetails,
            totalPaidHoursForDay,
            confirmed: areAllShiftsConfirmed,
        };

        return transformedDayData;
    });

    return shiftsCategorisedIntoDays;
};

export const filterByIsPaid = (inpShifts) => inpShifts.filter((shift) => shift.isPaid);
export const filterIsTimeOffOrLeave = (inpShifts) => inpShifts.filter((shift) => shift.timeOff || shift.leave);
export const filterNotTimeOffOrLeave = (inpShifts) => inpShifts.filter((shift) => !shift.timeOff && !shift.leave);

export const totalHoursPerDay = (inputEvents) =>
    inputEvents.reduce((hoursTotal, event) => {
        if (typeof event.hoursPerDay === 'number') {
            return hoursTotal + event.hoursPerDay;
        }

        return hoursTotal;
    }, 0);

export const calcWeekHourTotals = (yourScheduleData) => {
    const paidEvents = filterByIsPaid(yourScheduleData);

    const paidTimeOffAndLeave = filterIsTimeOffOrLeave(paidEvents);
    const paidShifts = filterNotTimeOffOrLeave(paidEvents);

    const timeOffAndLeaveTotalHours = totalHoursPerDay(paidTimeOffAndLeave);
    const shiftsTotalHours = totalHoursPerDay(paidShifts);

    const total = timeOffAndLeaveTotalHours + shiftsTotalHours;

    return {
        shifts: shiftsTotalHours,
        timeOffAndLeave: timeOffAndLeaveTotalHours,
        total,
    };
};
