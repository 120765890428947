import moment from 'moment';

export const getAndTransformDayEvents = (staffData, date) => {
    const getStatus = (statusId) => {
        if (statusId === 1 || statusId === 5) {
            return 'pending';
        }

        if (statusId === 2) {
            return 'approved';
        }

        if (statusId === 3) {
            return 'canceled';
        }

        if (statusId === 4) {
            return 'declined';
        }
    };

    const dayTimeOff = staffData.timeOff.filter((event) => {
        const isSameAsStartDate = moment(event.startDate).isSame(date, 'day');
        const isSameAsEndDate = moment(event.endDate).isSame(date, 'day');
        const isBetween = moment(date).isBetween(event.startDate, event.endDate);
        const isCanceled = getStatus(event.statusId) === 'canceled';
        const isDeclined = getStatus(event.statusId) === 'declined';
        const isIgnored = isCanceled || isDeclined;

        return (isSameAsStartDate || isSameAsEndDate || isBetween) && !isIgnored;
    });

    const transformedDayTimeOff = dayTimeOff.map((event) => {
        let eventType = 'timeOff';

        if (event.type === 2) {
            eventType = 'sickness';
        }

        if (event.type === 'day_off') {
            eventType = 'dayOff';
        }

        return {
            type: eventType,
            name: event.name,
            status: getStatus(event.statusId),
            color: event.hexColor,
        };
    });

    return transformedDayTimeOff;
};
