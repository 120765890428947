import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillGetCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';

(async () => {
    if (shouldPolyfillLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }
    if (shouldPolyfillGetCanonical()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill');
    }

    const shouldPolyfillEn = shouldPolyfillPluralRules('en');
    const shouldPolyfillFr = shouldPolyfillPluralRules('fr');

    if (shouldPolyfillEn || shouldPolyfillFr) {
        await import('@formatjs/intl-pluralrules/polyfill');

        if (shouldPolyfillEn) {
            await import('@formatjs/intl-pluralrules/locale-data/en');
        }

        if (shouldPolyfillFr) {
            await import('@formatjs/intl-pluralrules/locale-data/fr');
        }
    }
})();
