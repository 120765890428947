import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Button } from '@material-ui/core';

import { isNewPasswordValid } from '../helpers';
import { setShowSnackbar, setSnackBarError, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';
import { sendGASelectContent } from 'googleAnalytics';
import { GA_EVENTS } from 'lib/consts';

export const ChangePasswordSubmit = ({
    mutation,
    currentPassword,
    newPassword,
    confirmPassword,
    setIsPasswordError,
    setIsNewPasswordError,
    setCurrentPassword,
    setNewPassword,
    setConfirmPassword,
    setWarningMessage,
    setFailedRules,
    setPasswordWarningMessage,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updatePasswordCall = () => {
        sendGASelectContent(GA_EVENTS.CHANGE_PASSWORD);

        const isWarning = isNewPasswordValid(currentPassword, newPassword, confirmPassword);

        if (isWarning) {
            setIsPasswordError(false);
            setIsNewPasswordError(true);

            return;
        }

        mutation.mutate({ oldPassword: currentPassword, newPassword });
    };

    if (mutation.isSuccess) {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');

        setIsPasswordError(false);
        setIsNewPasswordError(false);

        setWarningMessage('');
        setFailedRules([]);

        dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.changed_password_success' }));
        dispatch(setShowSnackbar({ open: true }));

        mutation.reset();
    }

    if (mutation.isError) {
        if (mutation.error.response) {
            const errorList = mutation.error.response.data.errors;
            const errorCode = errorList[0].code;

            let passError = false;
            let newPassError = false;

            switch (errorCode) {
                case 'INVALID_PASSWORD':
                    passError = true;
                    break;
                case 'ILLEGAL_PASSWORD':
                    newPassError = true;
                    setFailedRules(errorList[0].meta.failed_rules);
                    break;
                default:
            }

            const errorMessage = errorCode.toLowerCase();

            setPasswordWarningMessage(t(`user:internal_errors.${errorMessage}`));
            setIsPasswordError(passError);
            setIsNewPasswordError(newPassError);
            setWarningMessage(t(`user:internal_errors.${errorMessage}`));
        }

        dispatch(setSnackBarError());
        dispatch(setShowSnackbar({ open: true }));

        mutation.reset();
    }

    return (
        <Button
            color="secondary"
            disabled={
                currentPassword.length === 0 ||
                isNewPasswordValid(currentPassword, newPassword, confirmPassword) ||
                mutation.isLoading
            }
            variant="contained"
            onClick={updatePasswordCall}
        >
            {mutation.isLoading ? <CircularProgress size={20} /> : t('user:change_password')}
        </Button>
    );
};

ChangePasswordSubmit.propTypes = {
    mutation: PropTypes.shape({
        mutate: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSuccess: PropTypes.bool.isRequired,
        isError: PropTypes.bool.isRequired,
        reset: PropTypes.func.isRequired,
        error: PropTypes.object,
    }),
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    setIsPasswordError: PropTypes.func.isRequired,
    setIsNewPasswordError: PropTypes.func.isRequired,
    setCurrentPassword: PropTypes.func.isRequired,
    setNewPassword: PropTypes.func.isRequired,
    setConfirmPassword: PropTypes.func.isRequired,
    setWarningMessage: PropTypes.func.isRequired,
    setFailedRules: PropTypes.func.isRequired,
    setPasswordWarningMessage: PropTypes.func.isRequired,
};
