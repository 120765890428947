import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import FormInput from './formContent/FormInput';
import DefaultButton from 'components/buttons/DefaultButton';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router';
import { useUpdatePassword } from 'services/auth';
import { setIsPasswordUpdateRequired } from '../../loginSlice';
import { useDispatch } from 'react-redux';
import { PasswordErrorAlert } from '../../../../components/PasswordErrorAlert';

const useStyles = makeStyles((theme) => ({
    signInButton: {
        minWidth: '120px',
        fontFamily: theme.typography.fontFamily,
        height: '40px',
        '&:disabled': {
            color: theme.palette.grey.light,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.grey.light}`,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        marginLeft: 'auto',
        marginBottom: '40px',
    },
    newPasswordText: {
        marginTop: 15,
    },
    tooltipTitle: {
        fontWeight: '500',
        fontSize: '12px',
        color: theme.palette.common.white,
    },
    tooltipText: {
        fontSize: '12px',
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            marginTop: '10px',
        },
    },
    dot: {
        width: '10px',
        height: '10px',
        borderRadius: '13px',
        backgroundColor: theme.palette.error.main,
    },
    gridText: {
        marginTop: '5px',
        marginLeft: '5px',
        marginBottom: '5px',
    },
}));

export const NewPassword = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { updatePasswordMutation } = useUpdatePassword();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordResetErrors, setPasswordResetErrors] = useState([]);

    const isSubmitDisabled =
        !currentPassword || !newPassword || newPassword !== confirmNewPassword || currentPassword === newPassword;

    const handleSubmit = () => {
        updatePasswordMutation.mutate(
            { oldPassword: currentPassword, newPassword },
            {
                onError: (e) => {
                    if (e.response) {
                        const errorList = e.response.data.errors;
                        const errors = errorList.map((error) =>
                            Object.hasOwnProperty.call(error.meta, 'failed_rules')
                                ? error.meta.failed_rules
                                : error.code,
                        );
                        setPasswordResetErrors(errors);
                    }
                },
                onSuccess: () => {
                    dispatch(setIsPasswordUpdateRequired(false));
                    history.push(location.intendedUrl || '/dashboard');
                },
            },
        );
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="body2" className={classes.newPasswordText}>
                    {t('user:new_password_required')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    id="currentPassword"
                    autoFocus
                    type="password"
                    label={t('user:current_password')}
                    onChange={(event) => {
                        setCurrentPassword(event.target.value);
                    }}
                    value={currentPassword}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    id="newPassword"
                    type="password"
                    label={t('user:new_password')}
                    onChange={(event) => {
                        setNewPassword(event.target.value);
                    }}
                    value={newPassword}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    id="confirmNewPassword"
                    label={t('user:confirm_password')}
                    type="password"
                    onChange={(event) => {
                        setConfirmNewPassword(event.target.value);
                    }}
                    value={confirmNewPassword}
                />
            </Grid>
            {updatePasswordMutation.isError && passwordResetErrors.length > 0 && (
                <PasswordErrorAlert errors={passwordResetErrors} />
            )}
            <Grid container>
                <Grid item className={classes.buttonsContainer}>
                    <DefaultButton
                        color="secondary"
                        className={classNames(classes.signInButton)}
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSubmitDisabled}
                    >
                        {t('user:change_password')}
                    </DefaultButton>
                </Grid>
            </Grid>
        </>
    );
};
