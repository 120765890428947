import PropTypes from 'prop-types';
import Panel from 'components/Panel';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, makeStyles } from '@material-ui/core';
import CalendarToday from '@material-ui/icons/Work';

import PanelHeadline from 'components/PanelHeadline';
import DefaultButton from 'components/buttons/DefaultButton';
import { MiniScheduleRow } from './MiniScheduleRow';

const useStyles = makeStyles((theme) => ({
    scrollableTable: {
        border: `1px solid ${theme.palette.grey.soft}`,
        overflowX: 'auto',
        marginBottom: '24px',
        maxHeight: '368px',
    },
    scheduleButton: {
        padding: '13px 20px',
        height: '100%',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

export const MiniSchedule = ({ weekSchedule, id }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <section>
            <PanelHeadline icon={<CalendarToday color="primary" />}>{t('dashboard:upcoming_shifts')}</PanelHeadline>
            <Panel id={id} removeBottomGutter>
                <Grid className={classes.scrollableTable}>
                    {weekSchedule.map((day) => (
                        <MiniScheduleRow key={day.date} day={day} />
                    ))}
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link to="/schedule" style={{ textDecoration: 'none' }}>
                            <DefaultButton color="primary" variant="contained" className={classes.scheduleButton}>
                                {t('portal:your_schedule')}
                            </DefaultButton>
                        </Link>
                    </Grid>
                </Grid>
            </Panel>
        </section>
    );
};

MiniSchedule.propTypes = {
    weekSchedule: PropTypes.array,
    id: PropTypes.string.isRequired,
};
