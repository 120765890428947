import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLogout } from 'services/auth';
import { useHistory } from 'react-router-dom';
import { apiClient } from '../utils/data/api';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from 'components/ErrorPage';
import LoadingComponent from 'components/LoadingComponent';
import { setIsLoggedIn } from 'containers/Login/loginSlice';

export const Training = ({ logout }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { logoutMutation } = useLogout();

    const [isError, setIsError] = useState(false);

    const logoutAction = useCallback(async () => {
        await logoutMutation.mutateAsync(undefined);

        dispatch(setIsLoggedIn(false));
        history.push('/login');
    }, [logoutMutation, dispatch, history]);

    const trainingPageRedirect = useCallback(async () => {
        try {
            const {
                data: {
                    data: { url },
                },
            } = await apiClient().post('training-sso');

            window.location = url;
        } catch (err) {
            setIsError(true);
        }
    }, []);

    useEffect(() => {
        if (logout) {
            logoutAction();
        } else {
            trainingPageRedirect();
        }
    }, [logout, logoutAction, trainingPageRedirect]);

    if (logout) {
        return null;
    }

    return isError ? (
        <ErrorPage
            mainMessage={t('portal:something_wrong')}
            subMessage={t('portal:refresh_page_message')}
            refreshButtonTitle={t('portal:refresh_page_btn')}
        />
    ) : (
        <div style={{ marginTop: 200, textAlign: 'center' }}>
            <Typography variant="h6">{t('portal:training_redirection')}</Typography>
            <br />
            <LoadingComponent />
        </div>
    );
};

Training.propTypes = {
    logout: PropTypes.bool,
};
