import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { LoginPage } from './LoginPage';
import { useDispatch } from 'react-redux';
import { setResetPasswordToken } from 'containers/Login/loginSlice';

export const ResetPasswordPage = () => {
    const { push: pushToHistory } = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const urlResetToken = location.search.slice(7);

    useEffect(() => {
        if (urlResetToken) {
            dispatch(setResetPasswordToken(urlResetToken));
        } else {
            dispatch(setResetPasswordToken(''));
            pushToHistory('/login');
        }
    }, [urlResetToken, pushToHistory, location, dispatch]);

    return <LoginPage />;
};
