import type { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Configuration } from 'rollbar';
import checkIgnore from './utils/rollbar/checkIgnore';

const rollBarConfig: Configuration = {
    accessToken: import.meta.env.VITE_ROLLBAR_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: import.meta.env.VITE_APP_ENV,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: import.meta.env.VITE_ROLLBAR_CODE_VERSION,
                guess_uncaught_frames: true,
            },
        },
    },
    checkIgnore,
};

export const RollBarWrapper = ({ children }: { children: ReactNode }) => (
    <Provider config={rollBarConfig}>
        <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
);

RollBarWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};
