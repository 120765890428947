import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Tooltip, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

import { getTimeOffForDay } from '../utils';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    item: {
        margin: 'auto',
    },
    typography: {
        color: 'inherit',
    },
    errorHighlight: {
        border: `solid 1px ${theme.palette.danger.main}`,
    },
}));

const TimeOffBlockedDay = ({ day, timeOff }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { moment } = useMoment();

    return (
        <Tooltip
            title={
                <Typography variant="body2" className={classes.typography}>{`${
                    timeOff ? t('requestTimeOff:approved_leave') : t('requestTimeOff:blocked_day')
                }`}</Typography>
            }
        >
            <Grid container className={classes.container}>
                <Grid item className={classes.item}>
                    {moment(day).format('D')}
                </Grid>
            </Grid>
        </Tooltip>
    );
};

const TimeOffHighlightedDay = ({ day }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { moment } = useMoment();

    return (
        <Tooltip
            title={
                <Typography variant="body2" className={classes.typography}>
                    {t('requestTimeOff:highlighted_day')}
                </Typography>
            }
        >
            <Grid container className={classNames(classes.container, classes.errorHighlight)}>
                <Grid item className={classes.item}>
                    {moment(day).format('D')}
                </Grid>
            </Grid>
        </Tooltip>
    );
};

export const renderDayContents = (day, modifiers, listApprovedTimeOff) => {
    if (modifiers.has('highlighted-calendar')) {
        return <TimeOffHighlightedDay day={day} />;
    }

    if (modifiers.has('blocked-calendar') || modifiers.has('blocked')) {
        return <TimeOffBlockedDay day={day} timeOff={getTimeOffForDay(day, listApprovedTimeOff)} />;
    }

    return day.format('D');
};

TimeOffBlockedDay.propTypes = {
    day: PropTypes.object.isRequired,
    timeOff: PropTypes.object,
};

TimeOffHighlightedDay.propTypes = {
    day: PropTypes.object.isRequired,
};
