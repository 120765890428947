import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: '0.8em',
    },
    base: {
        background: theme.palette.grey[300],
        padding: 10,
        transition: 'all 0.2s ease',
        '&:hover': {
            background: theme.palette.grey[400],
        },
        [theme.breakpoints.down('md')]: {
            padding: 3,
        },
    },
    noBg: {
        background: 'none',
    },
}));

const IconButtonWithTooltip = ({ className, tooltipTitle, noBackground, children, ...rest }) => {
    const classes = useStyles();

    return tooltipTitle ? (
        <Tooltip classes={{ tooltip: classes.tooltip }} enterDelay={100} title={tooltipTitle}>
            <IconButton
                className={classnames({
                    [className]: true,
                    [classes.base]: true,
                    [classes.noBg]: noBackground,
                })}
                aria-label={tooltipTitle}
                {...rest}
            >
                {children}
            </IconButton>
        </Tooltip>
    ) : (
        <IconButton {...rest}>{children}</IconButton>
    );
};

IconButtonWithTooltip.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    tooltipTitle: PropTypes.string,
    noBackground: PropTypes.bool,
};

export default IconButtonWithTooltip;
