import { getIntersectingDates } from '../utils';
import { formats } from 'utils/constants';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
const formatMoment = (momentDate) => (momentDate ? moment(momentDate).format(formats.BACKEND_FORMAT) : null);

export const getBlockedErrors = ({ blockedTimeOff, approvedTimeOff, blockedPeriodsEnabled, startDate, endDate }) => {
    let datePickerBlockedOrApprovedError = '';
    let errorInDates = [];

    const blockedPeriods = blockedTimeOff.periods;

    if (startDate) {
        const selectionRange = moment.range(startDate, endDate);
        let blockedTimeOffIntersection = [];
        let approvedTimeOffIntersection = [];
        let uniqueForbiddenDays = [];

        if (blockedPeriodsEnabled && blockedPeriods.length > 0) {
            blockedTimeOffIntersection = getIntersectingDates(blockedPeriods, selectionRange);
        }

        if (approvedTimeOff.length > 0) {
            const approvedTimeOffAsFullDays = approvedTimeOff.map((timeOff) => ({
                start: formatMoment(timeOff.start),
                end: formatMoment(timeOff.end),
            }));

            approvedTimeOffIntersection = getIntersectingDates(approvedTimeOffAsFullDays, selectionRange);
        }

        if (blockedTimeOffIntersection.length > 0 || approvedTimeOffIntersection.length > 0) {
            uniqueForbiddenDays = [...new Set([...blockedTimeOffIntersection, ...approvedTimeOffIntersection])];
            if (blockedTimeOffIntersection.length > 0) {
                datePickerBlockedOrApprovedError =
                    blockedTimeOff.rejectionMessage || 'requestTimeOff:errors.overlap_blocked';
            } else {
                datePickerBlockedOrApprovedError = 'requestTimeOff:errors.overlap_approved';
            }

            errorInDates = uniqueForbiddenDays;
        }
    }

    return { datePickerBlockedOrApprovedError, errorInDates };
};
