import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import CalendarRange from 'mdi-material-ui/CalendarRange';
import { useTranslation } from 'react-i18next';

import { SideBarContent } from './SideBarContent';

const useStyles = makeStyles((theme) => ({
    sidebarBorder: {
        boxShadow: theme.shadows[5],
        minWidth: 220,
    },
    headerPosition: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 100,
        backgroundColor: theme.palette.primary.dark,
    },
    headerContainer: {
        display: 'flex',
        marginLeft: 24,
        alignItems: 'flex-end',
    },
    leaveHeader: {
        color: theme.palette.common.white,
        fontWeight: 400,
    },
    leaveHeaderText: {
        textAlign: 'center',
        width: '120px',
    },
}));

export const SideBar = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.sidebarBorder}>
            <div className={classes.headerPosition}>
                <div className={classes.headerContainer}>
                    <CalendarRange className={classes.leaveHeader} />
                    <Typography className={classNames(classes.leaveHeader, classes.leaveHeaderText)} variant="body1">
                        {t('requestTimeOff:leave_request')}
                    </Typography>
                </div>
            </div>
            <SideBarContent />
        </div>
    );
};
