import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInactivePotState } from '../useEntitlement';

interface Props {
    className?: string;
}

function ToggleEntitlementCheckbox() {
    const [isHiding, toggle] = useInactivePotState();
    return <Checkbox checked={!isHiding} onChange={toggle} color="primary" />;
}

function ToggleEntitlementCheckboxIndex({ className }: Props) {
    const { t } = useTranslation();
    const label = useMemo(() => t('entitlement:show_inactive_entitlements'), [t]);
    return <FormControlLabel control={<ToggleEntitlementCheckbox />} label={label} className={className} />;
}

export default ToggleEntitlementCheckboxIndex;
