import { useQuery } from 'react-query';
import { getAllStaffShiftsAndTimeOff, getStaffForShopFilteredByTimeOff } from 'containers/Schedule/query';
import { createPeriodSchedule } from 'containers/Schedule/helpers';
import { getStaffForShopById } from 'api/shops';

export const useAllStaffShiftsAndTimeOff = (startDate, endDate, allTimeOffTypes, isTimeOffTypesSuccess) => {
    const { data, isLoading, isError } = useQuery(
        ['staffShiftAndTimeOff', startDate, endDate],
        async () => {
            const response = await getAllStaffShiftsAndTimeOff(startDate, endDate, allTimeOffTypes);
            const [shiftData, timeOffData] = response;

            return createPeriodSchedule(shiftData.data.data, timeOffData, startDate, endDate);
        },
        {
            enabled: Boolean(isTimeOffTypesSuccess),
        },
    );

    return {
        periodSchedule: data?.shifts,
        periodScheduleSpread: data?.spreadShifts,
        isPeriodScheduleLoading: isLoading,
        isPeriodScheduleError: isError,
    };
};

export const useStaffForShopSchedule = (shopId, start, end) => {
    const { data, isLoading, isError } = useQuery(
        ['StaffForShopSchedule', shopId, start, end],
        () => getStaffForShopById(shopId, start, end),
        {
            enabled: Boolean(shopId),
        },
    );

    return {
        shopSchedule: data?.data.data,
        isShopScheduleLoading: isLoading,
        isShopScheduleError: isError,
    };
};

export const useStaffTimeOffForSchedule = (shopId, start, end, allTimeOffTypes, isTimeOffTypesSuccess) => {
    const { data, isLoading, isError } = useQuery(
        ['StaffTimeOffForSchedule', shopId, start, end],
        () => getStaffForShopFilteredByTimeOff(shopId, start, end, allTimeOffTypes),
        {
            enabled: Boolean(isTimeOffTypesSuccess),
        },
    );

    return {
        mwvShopTimeOff: data,
        isMwvLoading: isLoading,
        isMwvError: isError,
    };
};
