export const composeTranslation = (allImports) => {
    const resources = {};

    for (const [filePath, file] of Object.entries(allImports)) {
        const splitPath = filePath.split('/');
        const language = splitPath[2];
        const namespace = splitPath[3].replace('.json', '');

        resources[language] = { ...resources[language], [namespace]: file.default };
    }

    return resources;
};
