import { useTranslation } from 'react-i18next';
import { FormGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ProfileCard } from './ProfileCard';
import { ShiftOfferPreferences } from './ShiftOfferPreferences';
import { RotaNotificationPreferences } from './RotaNotificationPreferences';

import { useSettings } from 'services/useSettings';
import { useBulkUpdatePreferences } from 'services/editSelf';
import { setShowSnackbar, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';

export const Preferences = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        settings: { shiftOffer },
    } = useSettings();
    const { updateBulkPreferencesMutation } = useBulkUpdatePreferences();

    if (updateBulkPreferencesMutation.isError) {
        dispatch(setSnackBarError());
        dispatch(setShowSnackbar({ open: true }));

        updateBulkPreferencesMutation.reset();
    }

    return (
        <ProfileCard header={t('user:preference')}>
            <FormGroup row>
                <RotaNotificationPreferences
                    onDoNotNotify={() =>
                        updateBulkPreferencesMutation.mutate({
                            key: 'rota',
                            values: {
                                emailOnApproval: false,
                                textOnApproval: false,
                            },
                        })
                    }
                />

                {shiftOffer.enabled ? (
                    <ShiftOfferPreferences
                        onDoNotNotify={() =>
                            updateBulkPreferencesMutation.mutate({
                                key: 'shiftOffer',
                                values: {
                                    email: false,
                                    sms: false,
                                },
                            })
                        }
                    />
                ) : null}
            </FormGroup>
        </ProfileCard>
    );
};
