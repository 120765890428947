import DefaultButton from 'components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { makeStyles, Dialog, IconButton, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import { useIsMobile } from 'utils/components/helpers';
import BulletPoint from 'components/BulletPoint';
import { EventDetails } from './EventDetails';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { useSettings } from 'services/useSettings';
import { setDialogClosed } from 'containers/Schedule/store/scheduleSlice';
import { selectDialogEvents, selectIsEventDialogOpen } from 'containers/Schedule/store/scheduleSelector';
import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from 'components/LoadingComponent';

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: 40,
        width: 350,
    },
    topContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 2, 0),
        width: '100%',
    },
    name: {
        fontSize: 18,
        marginLeft: theme.spacing(1.5),
    },
    header: {
        paddingBottom: 30,
    },
    headerText: {
        fontSize: 20,
    },
    divider: {
        margin: '20px 0',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        width: '100%',
    },
    shopScheduleButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
    },
}));

export const EventDialog = ({ tabValue, handleTabChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { moment } = useMoment();
    const { settings, isSettingsLoading } = useSettings();
    const isShopScheduleEnabled = !!settings?.shopSchedule?.enabled;
    const dispatch = useDispatch();
    const dialogEvents = useSelector(selectDialogEvents);
    const isDialogOpen = useSelector(selectIsEventDialogOpen);
    const isMobile = useIsMobile();

    if (isSettingsLoading) {
        return <LoadingComponent />;
    }

    return (
        <Dialog fullScreen={isMobile} onClose={() => dispatch(setDialogClosed())} open={isDialogOpen}>
            {isDialogOpen && (
                <>
                    <div className={classes.topContainer}>
                        <Typography component="span" className={classes.name}>
                            {isDialogOpen ? dialogEvents[0]?.userName : ''}
                        </Typography>
                        <IconButton onClick={() => dispatch(setDialogClosed())} title={t('common:close')}>
                            <Close />
                        </IconButton>
                    </div>
                    <Grid item container xs={12} className={classes.dialog}>
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            className={classes.header}
                        >
                            <BulletPoint color="primary" type="rounded" style={{ marginRight: 10 }} />
                            <Typography className={classes.headerText}>
                                <strong>{moment([...dialogEvents][0].start).format('ddd D MMMM')}</strong>
                            </Typography>
                        </Grid>
                        {[...dialogEvents].map((elem, i) => (
                            <Grid item xs={12} key={i}>
                                <EventDetails event={elem} />
                                {dialogEvents.length > 1 && dialogEvents.length !== i - 1 && (
                                    <div className={classes.divider} />
                                )}
                            </Grid>
                        ))}
                        {tabValue === 'your-schedule' && isShopScheduleEnabled && (
                            <Grid container justifyContent="center">
                                <div>
                                    <DefaultButton
                                        variant="outlined"
                                        color="primary"
                                        className={classes.shopScheduleButton}
                                        onClick={(e) => {
                                            handleTabChange(e, 'shop-schedule');
                                            dispatch(setDialogClosed());
                                        }}
                                    >
                                        {t('schedule:shop_schedule')}
                                    </DefaultButton>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </Dialog>
    );
};

EventDialog.propTypes = {
    handleTabChange: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
};
