import { APPLE_APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from '../utils/constants';

const NativeApp = () => {
    const UAString = window.navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(UAString)) {
        window.location.replace(APPLE_APP_STORE_URL);
        return null;
    }

    if (/android/i.test(UAString)) {
        window.location.replace(GOOGLE_PLAY_STORE_URL);
        return null;
    }
    window.location.replace('/');
    return null;
};

export default NativeApp;
