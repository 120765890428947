import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';

import { useIsMobileOrTablet, useIsMobile } from 'utils/components/helpers';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    todayCell: {
        background: theme.palette.secondary.main,
        borderLeft: `solid ${theme.palette.secondary.main} 1px`,
        borderRight: `solid ${theme.palette.secondary.main} 1px`,
    },
    contrastText: {
        color: theme.palette.common.white,
    },
    smallText: {
        fontSize: '1.2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    bigText: {
        fontSize: '2.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.875rem',
            fontWeight: 'bold',
        },
    },
}));

export const DateCell = ({ date, cellClassName }) => {
    const classes = useStyles();

    const isMobileOrTablet = useIsMobileOrTablet();
    const isMobile = useIsMobile();

    const { moment } = useMoment();

    const dateMoment = moment(date);

    const isToday = dateMoment.isSame(moment(), 'day');
    const isNewMonth = dateMoment.date() === 1;

    const dayOfWeekMobile = dateMoment.format('dd');
    const dayOfWeekFull = dateMoment.format('dddd');
    const dayOfMonth = dateMoment.format('D');
    const monthName = dateMoment.format('MMM');

    return (
        <Grid
            item
            xs
            key={`${dayOfMonth} ${monthName}`}
            className={classNames({
                [cellClassName]: true,
                [classes.todayCell]: isToday,
            })}
            data-testid="DateCell-container"
        >
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Typography
                        className={classNames({
                            [classes.contrastText]: isToday,
                            [classes.smallText]: true,
                        })}
                    >
                        {isMobileOrTablet ? dayOfWeekMobile : dayOfWeekFull}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        className={classNames({
                            [classes.contrastText]: isToday,
                            [classes.bigText]: true,
                        })}
                    >
                        {isMobile ? `${dayOfMonth}` : `${dayOfMonth} ${isNewMonth ? monthName : ''}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

DateCell.propTypes = {
    date: PropTypes.string.isRequired,
    cellClassName: PropTypes.string.isRequired,
};
