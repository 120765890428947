import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';
import { statuses } from './constants';
import { setSelectedOffer } from './shiftOfferSlice';
import { sendGASelectContent } from 'googleAnalytics';
import { GA_EVENTS } from 'lib/consts';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
    },
    acceptButton: {
        marginRight: theme.spacing(1),
    },
}));

export const OfferButtons = ({ id }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const openAcceptDialog = () => {
        sendGASelectContent(GA_EVENTS.ACCEPT_SHIFT_OFFER);
        dispatch(setSelectedOffer({ selectedOfferID: id, selectedOfferNewStatus: statuses.accepted }));
    };
    const openDeclineDialog = () => {
        sendGASelectContent(GA_EVENTS.DECLINE_SHIFT_OFFER);
        dispatch(setSelectedOffer({ selectedOfferID: id, selectedOfferNewStatus: statuses.declined }));
    };

    return (
        <div className={classes.buttonContainer}>
            <Button
                onClick={openAcceptDialog}
                variant="contained"
                color="primary"
                className={classes.acceptButton}
                style={{ marginRight: 8 }}
            >
                {t('common:accept')}
            </Button>
            <Button onClick={openDeclineDialog} variant="outlined" color="primary">
                {t('common:decline')}
            </Button>
        </div>
    );
};

OfferButtons.propTypes = {
    id: PropTypes.number.isRequired,
};
