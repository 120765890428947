import { Typography, makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BulletPoint from 'components/BulletPoint';
import { TableValueField } from './TableValueField';
import { dataColors } from '../helpers';
import { DialogSW } from 'components/dialog/DialogSW';
import { InfoTable } from 'components/infoTable/InfoTable';
import { VARIABLE_PRIMARY } from 'utils/constants';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import {
    selectIsMobileTimeOffDialogOpen,
    selectDialogItem,
    selectIsConfirmDialogOpen,
} from 'containers/timeOff/timeOffSelectors';
import { setMobileTimeOffDialogOpen, toggleConfirmDialog } from 'containers/timeOff/timeOffSlice';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 800,
        padding: '0 10px',
    },
    subTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1.5),
    },
    buttonContainer: {
        display: 'flex',
    },
}));

export const TimeOffDialog = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { moment } = useMoment();
    const tomorrowDate = moment().add(1, 'days').format('YYYY-MM-DD');

    const isMobileTimeOffDialogOpen = useSelector(selectIsMobileTimeOffDialogOpen);
    const dialogItem = useSelector(selectDialogItem);
    const isConfirmDialogOpen = useSelector(selectIsConfirmDialogOpen);

    const handleOnClick = () => {
        dispatch(setMobileTimeOffDialogOpen(!isMobileTimeOffDialogOpen));
        dispatch(toggleConfirmDialog(!isConfirmDialogOpen));
    };

    if (!dialogItem) {
        return null;
    }

    const getSubTitle = () => (
        <>
            <BulletPoint color={dataColors[dialogItem.status]} type="rounded" />
            <Typography className={classes.title}>{dialogItem.TimeOffType.name}</Typography>
        </>
    );

    const getButtons = () => {
        const isPastOrPresentDate = moment(dialogItem.startDate).isBefore(tomorrowDate);
        const isAcceptedOrPending = dialogItem.status === 'requested' || dialogItem.status === 'approved';

        return (
            <div className={classes.buttonContainer}>
                <Button
                    onClick={handleOnClick}
                    disabled={isPastOrPresentDate || !isAcceptedOrPending}
                    color={VARIABLE_PRIMARY}
                    variant="contained"
                >
                    {t('time_off:request_cancellation')}
                </Button>
            </div>
        );
    };

    const getContent = () => [
        { fieldName: `${t('common:dates')}`, value: <TableValueField valueLabel="dates" itemData={dialogItem} /> },
        {
            fieldName: `${t('requestTimeOff:unit_per_day')}`,
            value: <TableValueField valueLabel="units" itemData={dialogItem} />,
        },
        {
            fieldName: `${t('requestTimeOff:duration')}`,
            value: <TableValueField valueLabel="duration" itemData={dialogItem} />,
        },
        {
            fieldName: `${t('requestTimeOff:total_taken')}`,
            value: <TableValueField valueLabel="total" itemData={dialogItem} />,
        },
        {
            fieldName: `${t('common:notes')}`,
            value: dialogItem.notes ? <Typography>{dialogItem.notes}</Typography> : null,
        },
    ];

    return (
        <DialogSW
            title={`${t('time_off:time_off_request')}`}
            buttons={getButtons()}
            open={isMobileTimeOffDialogOpen}
            onClose={() => dispatch(setMobileTimeOffDialogOpen(!isMobileTimeOffDialogOpen))}
        >
            <>
                <div className={classes.subTitle}>{getSubTitle()}</div>
                <InfoTable tableRows={getContent()}></InfoTable>
            </>
        </DialogSW>
    );
};
