import { makeStyles, Grid } from '@material-ui/core';
import { DateCell } from './DateCell';
import { getListOfDatesInRange } from 'utils/timeFormatters';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    rowStyle: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingRight: '12px',
        position: 'relative',
    },
    cellContainer: {
        borderWidth: '0 1px 0 0',
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5),
        },
    },
}));

export const DateRow = () => {
    const classes = useStyles();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    if (!startDate || !endDate) {
        return null;
    }

    const formattedDates = getListOfDatesInRange(startDate, endDate);

    return (
        <Grid container className={classes.rowStyle}>
            <Grid item xs={3} sm={2} md={2} className={classes.generalStyles} />
            {formattedDates.map((date) => (
                <DateCell key={date} date={date} cellClassName={classes.cellContainer} />
            ))}
        </Grid>
    );
};
