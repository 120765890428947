import { useMemo, useState, useEffect } from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import CalendarRange from 'mdi-material-ui/CalendarRange';
import classNames from 'classnames';

import { HolidayEntitlementWidget } from 'components/widgets/HolidayEntitlementWidget';
import PanelHeadline from 'components/PanelHeadline';
import Panel from 'components/Panel';
import { TimeOffWidget } from './TimeOffWidget';
import { EntitlementWidget } from './EntitlementWidget';
import { ContractTabs } from '../../components/ContractTabs';
import { getCurrentContractsSortedById } from './helpers';
import { useUser } from '../../services/auth';
import { useSettings } from '../../services/useSettings';
import { useIsMobile } from 'utils/components/helpers';
import ToggleEntitlementCheckbox from './entitlementComponents/ToggleEntitlementCheckbox';
import { useIsHidingInactivePots } from './useEntitlement';

const useStyles = makeStyles(() => ({
    timeOffSection: {
        marginTop: '50px',
        height: 500,
    },
    mobileTimeOffSection: {
        height: 'max-content',
    },
}));

export const TimeOff = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useIsMobile();

    const { settings } = useSettings();
    const entitlementEnabled = useMemo(() => settings?.entitlement.enabled ?? false, [settings]);
    const [tabValue, setTabValue] = useState(0);

    const {
        userDetails: { contracts },
        isUserLoading,
    } = useUser();

    const isHidden = useIsHidingInactivePots();
    const currentContractsSortedById = Array.from(getCurrentContractsSortedById(contracts, isHidden));

    useEffect(() => {
        if (isHidden) {
            setTabValue(0);
        }
    }, [isHidden]);

    if (isUserLoading) {
        return null;
    }

    return entitlementEnabled ? (
        <>
            {currentContractsSortedById.length > 1 && (
                <ContractTabs
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    contracts={currentContractsSortedById}
                    withRole
                />
            )}
            <Panel removeBottomGutter>
                <section>
                    <PanelHeadline>{t('entitlement:entitlement')}</PanelHeadline>
                    <ToggleEntitlementCheckbox />
                    <EntitlementWidget contract={currentContractsSortedById[tabValue]} />
                </section>
                <section className={classNames(classes.timeOffSection, { [classes.mobileTimeOffSection]: isMobile })}>
                    <TimeOffWidget contractId={currentContractsSortedById[tabValue]?.id} />
                </section>
            </Panel>
        </>
    ) : (
        <>
            <section>
                <PanelHeadline icon={<CalendarRange color="primary" />}>{t('portal:time_off')}</PanelHeadline>
                <Panel>
                    <HolidayEntitlementWidget />
                </Panel>
            </section>
            <section>
                <TimeOffWidget withPanel />
            </section>
        </>
    );
};

TimeOff.propTypes = {
    classes: object,
};
