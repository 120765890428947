import { useMutation, useQueryClient } from 'react-query';
import { updateEmail, updateMobile, updatePreferences, bulkUpdatePreferences } from 'api/editSelf';

export const useUpdateEmail = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ email }) => updateEmail(email), {
        onSuccess: () => {
            queryClient.invalidateQueries('user');
        },
    });

    return { updateEmailMutation: mutation };
};

export const useUpdateMobile = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ mobileCountry, mobile }) => updateMobile(mobileCountry, mobile), {
        onSuccess: () => {
            queryClient.invalidateQueries('user');
        },
    });

    return { updateMobileMutation: mutation };
};

export const useUpdatePreferences = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ type, key, value }) => updatePreferences(type, key, value), {
        onSuccess: () => {
            queryClient.invalidateQueries('user');
        },
    });

    return { updatePreferencesMutation: mutation };
};

export const useBulkUpdatePreferences = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ key, values }) => bulkUpdatePreferences(key, values), {
        onSuccess: () => {
            queryClient.invalidateQueries('user');
        },
    });

    return { updateBulkPreferencesMutation: mutation };
};
