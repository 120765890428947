import PropTypes from 'prop-types';
import { Drawer, makeStyles } from '@material-ui/core';
import MenuLinkList from './components/MenuLinkList';
import { setMobileMenuOpen, setUserMenuOpen } from '../menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuLinks, selectMobileMenuOpen } from '../menuSelector';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer - 1,
    },
    paper: {
        marginTop: theme.spacing(4),
        background: theme.palette.primary.dark,
        width: theme.menu.drawerWidth,
    },
    basStyles: {
        background: theme.palette.primary.dark,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    removeGutter: {
        [theme.breakpoints.up('sm')]: {
            padding: 0,
        },
    },
}));

const DrawerMobile = ({ id }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const mobileMenuOpen = useSelector(selectMobileMenuOpen);
    const menuLinks = useSelector(selectMenuLinks);

    const handleToggleMobileMenu = () => {
        dispatch(setMobileMenuOpen({ mobileMenuOpen: !mobileMenuOpen }));
        dispatch(setUserMenuOpen({ userMenuOpen: false }));
    };

    return (
        <Drawer
            id={id}
            anchor="right"
            open={mobileMenuOpen}
            onClose={() => handleToggleMobileMenu()}
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
        >
            <div onClick={() => handleToggleMobileMenu()}>
                <div className={classes.toolbar} />
                <MenuLinkList menuOrigin="mobile" menuLinks={menuLinks.main} className={classes.removeGutter} />
            </div>
        </Drawer>
    );
};

DrawerMobile.propTypes = {
    id: PropTypes.string.isRequired,
};

export default DrawerMobile;
