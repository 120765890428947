import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { VARIABLE_UNAVAILABLE, VARIABLE_AVAILABLE } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
    smallShift: {
        height: '100%',
        borderRadius: '4px',
    },
    futureColor: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    presentColor: {
        backgroundColor: theme.palette.primary.dark,
    },
    disabledColor: {
        backgroundColor: theme.palette.grey[500],
    },
    timeOff: {
        backgroundColor: theme.palette.primary.light,
    },
    available: {
        backgroundColor: theme.palette.primary.transparent,
        color: theme.palette.primary.dark,
    },
    unavailable: {
        backgroundColor: theme.palette.danger.transparent,
        color: theme.palette.danger.dark,
    },
    typography: {
        fontWeight: 500,
        paddingTop: '30vh',
    },
}));

export const MobileEvent = ({ event }) => {
    const classes = useStyles();
    const { timeOff, sortedShiftAs, status, role, isStaffAvailabilityEvent } = event;

    return (
        <div
            className={classnames({
                [classes.smallShift]: true,
                [classes.timeOff]: timeOff,
                [classes[`${sortedShiftAs}Color`]]: !timeOff && !isStaffAvailabilityEvent,
                [classes.available]: isStaffAvailabilityEvent && status === VARIABLE_AVAILABLE,
                [classes.unavailable]: isStaffAvailabilityEvent && status === VARIABLE_UNAVAILABLE,
            })}
        >
            {isStaffAvailabilityEvent && (
                <Typography align="center" className={classes.typography}>
                    {role[0]}
                </Typography>
            )}
        </div>
    );
};

MobileEvent.propTypes = {
    event: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
        title: PropTypes.any,
        type: PropTypes.string,
        notes: PropTypes.any,
        timeOff: PropTypes.any,
        sortedShiftAs: PropTypes.string,
        status: PropTypes.string,
        role: PropTypes.string,
        isStaffAvailabilityEvent: PropTypes.bool,
    }),
};
