import moment from 'moment';
import { VARIABLE_UNAVAILABLE, VARIABLE_AVAILABLE } from 'utils/constants';
import { getListOfDatesInRange } from 'utils/timeFormatters';

export const populateStaffAvailabilityDays = ({ schedule, unavailableDays, start, end }) => {
    const weekArray = getListOfDatesInRange(start, end);

    const availabilityEvents = weekArray.map((weekDay) => {
        const isUnavailable = unavailableDays.some((unavailableDate) => unavailableDate === weekDay);

        return {
            startDate: moment(weekDay).startOf('day').format(),
            endDate: moment(weekDay).endOf('day').format(),
            isStaffAvailabilityEvent: true,
            status: isUnavailable ? VARIABLE_UNAVAILABLE : VARIABLE_AVAILABLE,
            role: '',
        };
    });

    return [...schedule, ...availabilityEvents];
};
