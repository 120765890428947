import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { makeStyles, CircularProgress, Grid, Typography } from '@material-ui/core';
import FormInput from './formContent/FormInput';
import DefaultButton from 'components/buttons/DefaultButton';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from 'services/auth';
import { selectResetPasswordToken } from 'containers/Login/loginSelector';

import { setShowSnackbar, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';
import { reset as resetLogin } from 'containers/Login/loginSlice';

const useStyles = makeStyles((theme) => ({
    signInButton: {
        minWidth: '120px',
        fontFamily: theme.typography.fontFamily,
        height: '40px',
        '&:disabled': {
            color: theme.palette.grey.light,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.grey.light}`,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        justifyContent: 'flex-end',
    },
    newPasswordText: {
        marginTop: 15,
    },
    errorMessage: {
        marginTop: 15,
        color: theme.palette.danger.main,
    },
    failedRulesErrorMessage: {
        color: theme.palette.danger.main,
        fontSize: 11,
    },
    tooltipTitle: {
        fontWeight: '500',
        fontSize: '12px',
        color: theme.palette.common.white,
    },
    tooltipText: {
        fontSize: '12px',
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            marginTop: '10px',
        },
    },
    dot: {
        width: '10px',
        height: '10px',
        borderRadius: '13px',
        backgroundColor: theme.palette.error.main,
    },
    gridText: {
        marginTop: '5px',
        marginLeft: '5px',
        marginBottom: '5px',
    },
}));

export const ResetPassword = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const isWelcomePage = useRouteMatch('/reset-password-welcome') !== null;

    const history = useHistory();
    const { resetPasswordMutation } = useResetPassword();
    const resetPasswordToken = useSelector(selectResetPasswordToken);
    const [resetPasswordFailedRules, setResetPasswordFailedRules] = useState([]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const isEmailValid = email && email.includes('@');

    const isSubmitButtonDisabled =
        resetPasswordMutation.isError || !isEmailValid || !password || password !== confirmPassword;

    const onFormInputChange = (event, setFieldState) => {
        if (resetPasswordMutation.isError) {
            resetPasswordMutation.reset();
        }

        setFieldState(event.target.value);
    };

    const onSubmitForm = () => {
        resetPasswordMutation.mutate(
            {
                userEmail: email,
                password,
                token: resetPasswordToken,
                isWelcomePage,
            },
            {
                onError: (error) => {
                    if (error.response) {
                        const illegalPasswordError = error.response.data.errors.find(
                            (err) => err.code === 'ILLEGAL_PASSWORD',
                        );

                        if (illegalPasswordError) {
                            setResetPasswordFailedRules(illegalPasswordError.meta.failed_rules);
                        }
                    }
                },
                onSuccess: () => {
                    dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.password_reset_login' }));
                    dispatch(setShowSnackbar({ open: true }));
                    history.push('/login');
                    dispatch(resetLogin());
                },
            },
        );
    };

    const chooseMessage = () => {
        if (!resetPasswordMutation.isError && !email && !password) {
            return t('user:enter_email_and_new_password');
        }

        if (!resetPasswordMutation.isError && !isEmailValid) {
            return t('user:incorrect_email');
        }

        if (resetPasswordMutation.isError) {
            if (resetPasswordFailedRules.length) {
                return t('user:internal_errors.illegal_password');
            }

            return t('user:invalid_email_password_or_token');
        }
    };

    const inputAttributes = {
        autoComplete: 'new-password',
        form: {
            autoComplete: 'off',
        },
    };

    const capitaliseFirstWord = (string) => string[0].toUpperCase() + string.slice(1);

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    className={resetPasswordMutation.isError ? classes.errorMessage : classes.newPasswordText}
                >
                    {chooseMessage()}
                </Typography>
            </Grid>
            {resetPasswordFailedRules.length > 0 && resetPasswordMutation.isError && (
                <Grid item xs={12}>
                    {resetPasswordFailedRules.map((rule) => (
                        <Typography variant="body2" className={classes.failedRulesErrorMessage} key={rule}>
                            * {capitaliseFirstWord(rule)}
                        </Typography>
                    ))}
                </Grid>
            )}
            <Grid item xs={12}>
                <FormInput
                    id="reset_email"
                    type="email"
                    error={resetPasswordMutation.isError}
                    label={t('common:email')}
                    onChange={(event) => onFormInputChange(event, setEmail)}
                    onClick={resetPasswordMutation.reset}
                    value={email}
                    inputProps={inputAttributes}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    id="reset_password"
                    type="password"
                    label={t('user:new_password')}
                    onChange={(event) => onFormInputChange(event, setPassword)}
                    value={password}
                    onClick={resetPasswordMutation.reset}
                    error={resetPasswordMutation.isError}
                    inputProps={inputAttributes}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    id="reset_confirm"
                    type="password"
                    label={t('user:confirm_password')}
                    onChange={(event) => onFormInputChange(event, setConfirmPassword)}
                    value={confirmPassword}
                    onClick={resetPasswordMutation.reset}
                    error={resetPasswordMutation.isError}
                    inputProps={inputAttributes}
                />
            </Grid>
            <Grid item container xs={12} className={classes.buttonsContainer}>
                <DefaultButton
                    color="secondary"
                    className={classNames(classes.signInButton)}
                    variant="contained"
                    onClick={onSubmitForm}
                    disabled={isSubmitButtonDisabled}
                >
                    {resetPasswordMutation.isLoading ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : (
                        t('user:reset_password.label')
                    )}
                </DefaultButton>
            </Grid>
        </>
    );
};
