/* eslint-disable complexity */
/* eslint-disable no-unneeded-ternary */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

import { MobileNav } from './MobileNav';
import { Tooltip } from '../components/Tooltip';
import { MobileDatePicker } from './MobileDatePicker';
import { SideBarContent } from '../components/SideBarContent';
import { EntitlementForm } from '../components/EntitlementForm';
import { NonEntitlementForm } from '../components/NonEntitlementForm';
import { MultiEntitlement } from 'containers/RequestTimeOff/components/MultiEntitlement';

import { getResponseErrors } from '../utils';

import {
    selectRequestedTimeOffType,
    selectShowTooltip,
    selectUserRequests,
    selectRequestedStartDate,
    selectRequestedEndDate,
    selectDatePickerBlockedOrApprovedError,
    selectIsRequestValid,
    selectFinalRequestData,
    selectSelectionList,
} from '../requestTimeOffSelectors';
import {
    setStateToDefault,
    setShowTooltip,
    setTimeOffType,
    verifyRequest,
    setResponseErrors,
} from '../requestTimeOffSlice';
import { useSettings } from 'services/useSettings';
import { usePostRequestTimeOff, useBlockedTimeOff } from 'services/useHoliday';

import { setShowSnackbar, setSnackBarSuccess, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';

const useStyles = makeStyles((theme) => ({
    mobileTimeOffStyle: {
        backgroundColor: theme.palette.grey[200],
        height: '100%',
        paddingTop: 60,
        paddingBottom: 60,
    },
    calendarPageStyle: {
        height: '100%',
        overflowY: 'auto',
    },
    footerStyle: {
        background: 'white',
        position: 'fixed',
        height: '56px',
        bottom: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        borderTop: `1px solid ${theme.palette.grey.light}`,
    },
    nextButtonStyle: {
        width: '80%',
        margin: 'auto',
        height: 36,
        fontSize: 14,
    },
    alert: {
        margin: 20,
    },
}));

export const MobileRequestTimeOff = ({ setIsDialogOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { postTimeOffRequestMutation } = usePostRequestTimeOff();
    const [stage, setStage] = useState(0);

    const {
        settings: {
            timeOff: { blockedPeriods },
            entitlement,
        },
    } = useSettings();

    const { blockedTimeOff } = useBlockedTimeOff(blockedPeriods.enabled);

    const timeOffType = useSelector(selectRequestedTimeOffType);
    const showTooltip = useSelector(selectShowTooltip);
    const userRequests = useSelector(selectUserRequests);
    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);
    const datePickerBlockedOrApprovedError = useSelector(selectDatePickerBlockedOrApprovedError);
    const selectionList = useSelector(selectSelectionList);

    const isRequestValid = useSelector(selectIsRequestValid);
    const requestData = useSelector(selectFinalRequestData);

    useEffect(() => {
        if (stage === 1 && startDate && endDate) {
            dispatch(verifyRequest());
        }
    }, [userRequests, startDate, endDate, dispatch, stage]);

    const buttonText = stage === 1 ? t('common:submit') : t('common:apply');

    const isAnyRequest = userRequests.length > 0 && userRequests.every((request) => request.relatedContractId);

    const isZeroHours = !userRequests.every(({ daysPerDay, hoursAndMinsPerDay }) =>
        typeof hoursAndMinsPerDay === 'number' ? daysPerDay > 0 : hoursAndMinsPerDay !== '00:00',
    );

    const isButtonActive =
        (stage === 0 && Boolean(timeOffType.id)) ||
        (stage === 1 && startDate && isAnyRequest && !datePickerBlockedOrApprovedError && isRequestValid) ||
        (stage === 2 && !datePickerBlockedOrApprovedError)
            ? true
            : false;

    const onClickNavButton = () => {
        if (stage === 0) {
            setIsDialogOpen(false);
            dispatch(setStateToDefault());
        } else {
            if (stage === 1) {
                const savedTimeOffType = timeOffType;

                dispatch(setStateToDefault());
                dispatch(setTimeOffType(savedTimeOffType));
            }

            setStage(stage - 1);
        }
    };

    const onClickFooterButton = () => {
        if (stage === 1) {
            if (isRequestValid) {
                postTimeOffRequestMutation.mutate(
                    { requestData },
                    {
                        onSuccess: () => {
                            dispatch(setShowSnackbar({ open: true }));
                            dispatch(setSnackBarSuccess());

                            dispatch(setStateToDefault());
                            setIsDialogOpen(false);
                        },
                        onError: ({
                            response: {
                                data: { errors },
                            },
                        }) => {
                            const {
                                requestError = '',
                                errorInDates = [],
                                requestErrorDetails = {},
                                defaultError = false,
                            } = getResponseErrors(errors[0], blockedTimeOff, selectionList);

                            if (defaultError) {
                                dispatch(setStateToDefault());
                                setIsDialogOpen(false);

                                dispatch(setShowSnackbar({ open: true }));
                                dispatch(setSnackBarError());
                            } else {
                                dispatch(setResponseErrors({ requestError, errorInDates, requestErrorDetails }));
                            }
                        },
                    },
                );
            }
        } else {
            setStage(stage + 1);
        }
    };

    return (
        <div className={classes.mobileTimeOffStyle}>
            <MobileNav stage={stage} onClickNavButton={onClickNavButton} />

            {stage === 0 && <SideBarContent />}

            {stage === 1 && (
                <div className={classes.calendarPageStyle}>
                    <MobileDatePicker />
                    {!entitlement.enabled && <NonEntitlementForm />}
                    {entitlement.enabled && <EntitlementForm />}
                    {isZeroHours && (
                        <Alert className={classes.alert} severity="warning">
                            {t('requestTimeOff:alert_zero_hours')}
                        </Alert>
                    )}
                    {entitlement.enabled &&
                        selectionList.map((revision) => <MultiEntitlement key={revision.id} revision={revision} />)}
                </div>
            )}

            {showTooltip && <Tooltip />}

            <div
                className={classes.footerStyle}
                onMouseEnter={() => !isButtonActive && dispatch(setShowTooltip(true))}
                onMouseLeave={() => dispatch(setShowTooltip(false))}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!isButtonActive}
                    onClick={onClickFooterButton}
                    className={classes.nextButtonStyle}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

MobileRequestTimeOff.propTypes = {
    setIsDialogOpen: PropTypes.func,
};
