import { isBrowser } from 'utils/components/helpers';

const defaultStore = {
    desktopMenuOpen: true,
};

export default class MenuStorage {
    store = {};

    createNewStore() {
        if (isBrowser === false) {
            return null;
        }

        window.localStorage.setItem('mainMenu', JSON.stringify(defaultStore));
        this.store = defaultStore;
    }

    clearLocalStorage() {
        window.localStorage.removeItem('mainMenu');
        this.createNewStore();
    }

    constructor() {
        if (isBrowser === false) {
            return;
        }

        const mainMenu = window.localStorage.getItem('mainMenu');

        if (mainMenu) {
            try {
                this.store = JSON.parse(mainMenu);
            } catch (_) {
                this.clearLocalStorage();
                throw new Error(`local storage corrupted: ${mainMenu}`);
            }
        } else {
            this.createNewStore();
        }
    }

    setItem(key, value) {
        this.store[key] = value;
        window.localStorage.setItem('mainMenu', JSON.stringify(this.store));
    }

    getItem(key) {
        if (Object.prototype.hasOwnProperty.call(this.store, key)) {
            return this.store[key];
        }
    }
}
