/* eslint-disable react-hooks/rules-of-hooks */
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { HolidayEntitlementSegment } from './holidayEntitlement/HolidayEntitlementSegment';
import { useTranslation } from 'react-i18next';

import { useHolidayPeriods, useEntitlement } from 'services/useHoliday';
import { useSettings } from 'services/useSettings';
import { getDefaultDates } from 'components/widgets/helpers.js';
import LoadingComponent from 'components/LoadingComponent';

export const HolidayEntitlementWidget = () => {
    const { t } = useTranslation();
    const { holidayPeriods, isPeriodsError, isPeriodsLoading } = useHolidayPeriods(true);

    const { settings, isSettingsLoading } = useSettings();
    const units = settings?.timeOff?.units;
    const isLieuTimeEnabled = !!settings?.timeOff?.lieuTimeEnabled?.enabled;

    const { defaultIndex } = getDefaultDates(holidayPeriods) || 0;
    const selectedPeriod = holidayPeriods[defaultIndex] || holidayPeriods[0];

    const {
        isEntitlementLoading,
        isEntitlementError,
        entitlement: { approved, entitlement, lieuTime, outstanding, requested, training, used, carriedForward },
    } = useEntitlement(selectedPeriod);

    if (isSettingsLoading) {
        return <LoadingComponent />;
    }

    const emptyData = { days: 0, hours: 0 };

    let segmentsData = {
        entitlement: entitlement || emptyData,
        used: used || emptyData,
        approved: approved || emptyData,
        requested: requested || emptyData,
        outstanding: outstanding || emptyData,
    };

    if (carriedForward) {
        segmentsData = {
            entitlement: segmentsData.entitlement,
            carriedForward: carriedForward || emptyData,
            ...segmentsData,
        };
    }

    if (!isSettingsLoading && isLieuTimeEnabled) {
        segmentsData.lieuTime = lieuTime || emptyData;
    } else {
        segmentsData.training = training || emptyData;
    }

    if (isEntitlementError || isPeriodsError || holidayPeriods.length === 0) {
        return (
            <Grid container direction="column" alignItems="center" data-testid="holiday-entitlement-widget-error">
                <Grid item xs>
                    <Typography>{t('entitlement:widget_error')}</Typography>
                </Grid>
            </Grid>
        );
    }

    if (isEntitlementLoading || isPeriodsLoading) {
        return (
            <Grid container direction="column" alignItems="center">
                <Grid item xs>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container style={{ margin: '1.5rem 0 1rem' }}>
            {Object.keys(segmentsData).map((segmentKey) => (
                <HolidayEntitlementSegment
                    key={segmentKey}
                    entitlement={segmentsData[segmentKey]}
                    segment={segmentKey}
                    units={units}
                />
            ))}
        </Grid>
    );
};
