const gtag: Gtag.Gtag = window.gtag;

const gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID;
gtag && gtag('config', gaTrackingId, { debug_mode: import.meta.env.DEV });

export const sendGoogleAnalyticsEvents = (pathname: string, pageTitle: string, clientName: string) => {
    if (!gtag) return;

    gtag('set', {
        page_title: pageTitle,
        page_path: pathname,
    });

    gtag('event', 'page_view', {
        page_title: pageTitle,
        page_path: pathname,
        SW_CLIENT: clientName,
    });

    gtag('event', 'log_sw_client_dimension', {
        client: clientName,
    });

    gtag('set', 'user_properties', {
        SW_CLIENT: clientName,
    });
};

export const sendGoogleAnalyticsTabClick = (tabName: string, clickedTab: string) => {
    if (!gtag) return;

    gtag('event', `${clickedTab} Tab`, {
        tab_click: `${tabName} Tab Click`,
    });
};

export const sendGASelectContent = (contentId: string) => {
    if (!gtag) return;

    if (contentId) {
        gtag('event', 'select_content', {
            content_type: 'button',
            content_id: contentId,
        });
    }
};
