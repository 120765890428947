import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TimeLine } from './TimeLine';
import { findPercentageOfHoursWorked } from '../../../helpers';
import { useGetHoursAndMinutes } from 'utils/timeFormatters';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => {
    const green = theme.palette.primary.dark;
    const { white } = theme.palette.common;

    return {
        shiftContainer: (props) => {
            let background = props.isPresent ? green : theme.palette.grey[400];

            if (props.isFuture) {
                background = white;
            }

            return {
                backgroundColor: background,
                height: '100%',
                overflow: 'hidden',
                borderRadius: '4px',
                zIndex: 9999,
                border: props.isFuture && `1px solid ${green}`,
                padding: props.shiftHours > 2 ? '10px' : '0px 5px',
            };
        },
        typography: (props) => ({
            fontSize: '12px',
            fontWeight: 400,
            textOverflow: props.shiftHours <= 8 && 'ellipsis',
            whiteSpace: props.shiftHours <= 8 && 'nowrap',
            overflow: props.shiftHours <= 8 && 'hidden',
            color: props.isFuture ? green : white,
        }),
        divider: (props) => ({
            width: '90%',
            height: 1,
            background: props.isFuture ? green : white,
            margin: '10px 0px',
        }),
        timeline: {
            margin: 0,
            right: 6,
            top: 3,
            position: 'absolute',
            height: 'calc(100% - 6px)',
            borderRadius: '0 4px 4px 0',
        },
    };
});

export const FullSizeShift = ({
    event: { sortedShiftAs, end, start, role, departmentName, shopName, paidHours, unpaidHours },
}) => {
    const { t } = useTranslation();
    const { moment } = useMoment();

    const getHoursAndMinutes = useGetHoursAndMinutes();

    const styleType = sortedShiftAs;
    const totalHours = paidHours + unpaidHours;
    const classes = useStyles({
        isPresent: styleType === 'present',
        isFuture: styleType === 'future',
        shiftHours: totalHours,
    });

    const text = {
        role: role?.key ? t(role.key) : role,
        startEndTime: `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
        paidHours: t('schedule:time_paid', { time: getHoursAndMinutes(paidHours) }),
        unpaidHours:
            totalHours > 0
                ? t('schedule:time_unpaid_break', {
                      time: getHoursAndMinutes(unpaidHours),
                  })
                : '',
    };

    return (
        <Grid container className={classes.shiftContainer} alignItems="center">
            <Grid item xs={12}>
                <Grid item xs={11}>
                    <Typography
                        className={classes.typography}
                        title={text.role}
                        data-testid="your-schedule-fullSizeShift-role-label"
                    >
                        <strong>{text.role}</strong>
                    </Typography>
                </Grid>

                {totalHours > 1 && (
                    <Typography
                        className={classes.typography}
                        data-testid="your-schedule-fullSizeShift-time-label"
                        title={text.startEndTime}
                    >
                        {text.startEndTime}
                    </Typography>
                )}
                {totalHours > 2 && (
                    <Typography
                        className={classes.typography}
                        title={text.totalHours}
                        data-testid="your-schedule-fullSizeShift-paidHours-label"
                    >
                        {text.paidHours}
                    </Typography>
                )}
                {totalHours > 3 && unpaidHours > 0 && (
                    <Typography
                        className={classes.typography}
                        title={text.unpaidHours}
                        data-testid="your-schedule-fullSizeShift-unpaidHours-label"
                    >
                        {text.unpaidHours}
                    </Typography>
                )}

                {totalHours > 5 && (
                    <>
                        <div className={classes.divider} />
                        {departmentName && (
                            <>
                                <Typography className={classes.typography} title={t('portal:department_colon')}>
                                    {t('portal:department_colon')}
                                </Typography>
                                <Typography className={classes.typography} title={departmentName}>
                                    <strong data-testid="your-schedule-fullSizeShift-department-label">
                                        {departmentName}
                                    </strong>
                                </Typography>
                            </>
                        )}
                        {shopName && (
                            <>
                                <Typography className={classes.typography} title={t('portal:shop_colon')}>
                                    {t('portal:shop_colon')}
                                </Typography>
                                <Typography
                                    className={classes.typography}
                                    title={shopName}
                                    data-testid="your-schedule-fullSizeShift-shop-label"
                                >
                                    <strong>{shopName}</strong>
                                </Typography>
                            </>
                        )}
                    </>
                )}
            </Grid>

            {moment().isSame(start, 'day') && styleType === 'present' ? (
                <TimeLine height={findPercentageOfHoursWorked(start, end)} className={classes.timeline} />
            ) : null}
        </Grid>
    );
};

FullSizeShift.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string,
};
