import { useEffect } from 'react';

export const usePromptOnHardPageChange = (isChanged) => {
    useEffect(() => {
        const handleLeavePageWhenSomethingChanged = (event) => {
            if (isChanged) {
                (event || window.event).returnValue = true;

                return true;
            }
        };

        window.addEventListener('beforeunload', handleLeavePageWhenSomethingChanged);

        return () => {
            window.removeEventListener('beforeunload', handleLeavePageWhenSomethingChanged);
        };
    }, [isChanged]);
};
