import moment from 'moment';

export const isNewPasswordValid = (currentPassword, newPassword, confirmPassword) =>
    newPassword !== confirmPassword || newPassword.length < 8 || currentPassword === newPassword;

export const getFilteredContracts = (contracts) => {
    const isDateAfterToday = (contract) => contract.end === null || moment(contract.end).isAfter(moment());

    const activeContracts = contracts.filter((contract) => isDateAfterToday(contract));
    const inactiveContracts = contracts.filter((contract) => !isDateAfterToday(contract));

    return { activeContracts, inactiveContracts };
};

export const allEventsNotifications = 'always';
export const userEventsNotifications = 'events_for_me';
