import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { makeStyles, InputLabel, OutlinedInput, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ErrorPanel } from './ErrorPanel';
import ErrorMessage from 'components/ErrorMessage';
import { ContractSelector } from './ContractSelector';
import LoadingComponent from 'components/LoadingComponent';

import {
    selectNotes,
    selectUserRequests,
    selectRequestedTimeOffType,
    selectRequestedStartDate,
    selectRequestedEndDate,
    selectSelectionList,
} from '../requestTimeOffSelectors';
import { setNotes, updateUserRequest, setSelectionList } from '../requestTimeOffSlice';
import { getIsTimeOffTypeHoursOnly, getUnitType, getRevisionsList } from '../utils';
import { fromDecimalsToHours } from 'utils/timeFormatters';

import { useSettings } from 'services/useSettings';
import { useUserContracts } from 'services/useHoliday';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0',
        padding: '0px 45px',
        [theme.breakpoints.down('md')]: {
            padding: '0px 20px',
        },
    },
    errorRow: {
        marginTop: 20,
        marginBottom: 10,
        backgroundColor: theme.palette.danger.main,
        padding: '5px 10px 5px 10px',
        borderRadius: 5,
    },
    errorText: {
        color: theme.palette.common.white,
        fontWeight: '400',
    },
    noteRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
    },
    addButton: {
        width: 125,
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    addButtonDisable: {
        color: theme.palette.grey.main,
        cursor: 'disable',
        '&:hover': {
            color: theme.palette.grey.main,
        },
    },
    labelStyle: {
        paddingLeft: 10,
        minWidth: 85,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            minWidth: 75,
        },
    },
}));

const DEFAULT_REQUEST = {
    revisionName: '',
    hoursPerDay: 0,
    hoursAndMinsPerDay: '00:00',
    relatedContractId: 0,
    daysPerDay: 1,
    selectorIndex: 0,
};

export const EntitlementForm = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { settings } = useSettings();
    const entitlement = useMemo(() => settings.entitlement, [settings]);

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);
    const selectionList = useSelector(selectSelectionList);

    const { userContracts, isContractsLoading, isContractsError } = useUserContracts(startDate, endDate);
    const { revisions } = getRevisionsList(userContracts, startDate, endDate);

    const notes = useSelector(selectNotes);
    const userRequests = useSelector(selectUserRequests);
    const timeOffType = useSelector(selectRequestedTimeOffType);

    const isTimeOffTypeHoursOnly = useMemo(() => getIsTimeOffTypeHoursOnly(timeOffType), [timeOffType]);

    useEffect(() => {
        const mostContracted = revisions.sort((a, b) => b.contractedHours - a.contractedHours);
        let newSelectionList = mostContracted.length > 0 ? [{ id: mostContracted[0].relatedContractId }] : [];

        if (entitlement) {
            newSelectionList = revisions.map((revision) => {
                const { contractTypeTimeOffUnit: unitType, relatedContractId: id } = revision;
                const name = `${revision.positionName} - ${revision.shopName}`;
                const defaultHours = revision.contractedHoursPerDay;

                return {
                    name,
                    id,
                    units: unitType ? unitType : 'days',
                    defaultHours,
                    unitType: isTimeOffTypeHoursOnly ? 'hours' : unitType,
                };
            });
        }

        dispatch(setSelectionList(newSelectionList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revisions.length, timeOffType, dispatch, entitlement, isTimeOffTypeHoursOnly]);

    const { inputStyle } = theme.requestTimeOffForm;

    const isSingleOption = useMemo(() => selectionList.length === 1, [selectionList]);

    const displayedRequests = useMemo(() => {
        const clone = [...userRequests];
        if (clone.length === 0) {
            const entry = isSingleOption
                ? {
                      ...DEFAULT_REQUEST,
                      revisionName: selectionList[0].name,
                      relatedContractId: selectionList[0].id,
                      unitType: isTimeOffTypeHoursOnly ? 'hours' : selectionList[0].unitType,
                      hoursPerDay: selectionList[0].defaultHours,
                      hoursAndMinsPerDay: fromDecimalsToHours(selectionList[0].defaultHours),
                  }
                : { ...DEFAULT_REQUEST };
            clone.push(entry);
        }
        return clone;
    }, [isSingleOption, userRequests, selectionList, isTimeOffTypeHoursOnly]);

    useEffect(() => {
        if (isSingleOption && displayedRequests.length === 1 && userRequests.length === 0) {
            dispatch(updateUserRequest(displayedRequests[0]));
        }
    }, [isSingleOption, displayedRequests.length, userRequests.length, dispatch, displayedRequests]);

    const isNoMoreOptions = displayedRequests.length === selectionList.length;
    const isSelectorEmpty = displayedRequests[displayedRequests.length - 1].revisionName === '';
    const isAddButtonDisable = isNoMoreOptions || isSelectorEmpty;
    if (isContractsLoading) {
        return <LoadingComponent title={t('common:loading')} />;
    }

    if (isContractsError) {
        return <ErrorMessage message={t('portal:generic_error_message')} />;
    }

    if (selectionList.length === 0) {
        return null;
    }

    return (
        <form className={classes.form}>
            <div className={classes.noteRow}>
                <InputLabel htmlFor="request_time_off_note" className={classes.labelStyle}>
                    {t('common:notes')}:
                </InputLabel>
                <OutlinedInput
                    id="request_time_off_note"
                    name="note"
                    variant="outlined"
                    onChange={(event) => dispatch(setNotes(event.target.value))}
                    value={notes}
                    fullWidth
                    style={inputStyle}
                />
            </div>
            {displayedRequests.map((request, index) => {
                const { relatedContractId } = request;

                return (
                    <ContractSelector
                        key={relatedContractId}
                        selectedRequest={request}
                        index={index}
                        displayedRequestsNumber={displayedRequests.length}
                    />
                );
            })}
            {!isSingleOption && (
                <div className={classes.buttonRow}>
                    <Typography
                        role="button"
                        data-testid="add-contract-button"
                        className={classnames({
                            [classes.addButton]: true,
                            [classes.addButtonDisable]: isAddButtonDisable,
                        })}
                        onClick={() => {
                            if (!isAddButtonDisable) {
                                const unitType = isTimeOffTypeHoursOnly
                                    ? 'hours'
                                    : getUnitType(
                                          entitlement,
                                          DEFAULT_REQUEST.relatedContractId,
                                          userContracts,
                                          selectionList,
                                      );

                                dispatch(
                                    updateUserRequest({
                                        ...DEFAULT_REQUEST,
                                        selectorIndex: displayedRequests.length,
                                        unitType,
                                    }),
                                );
                            }
                        }}
                    >
                        {t('requestTimeOff:add_contract')}
                    </Typography>
                </div>
            )}
            <ErrorPanel />
        </form>
    );
};
