import moment from 'moment';

export const getCurrentRevision = (contract) => {
    let currentRevision = contract.revisions.filter((revision) => !revision.end);

    if (currentRevision.length === 0) {
        if ([...contract.revisions].length > 1) {
            currentRevision = contract.revisions.sort((revisionA, revisionB) => {
                const today = moment();
                const revisionAEndDate = moment(revisionA.end);
                const revisionBEndDate = moment(revisionB.end);

                return today.diff(revisionAEndDate, 'days') - today.diff(revisionBEndDate, 'days');
            });
        } else {
            currentRevision = [...contract.revisions];
        }
    }

    return currentRevision[0];
};

export const getCurrentContractsSortedById_s = (contracts) => {
    const removeEndedContracts = [...contracts].filter(
        (contract) => !contract.end || moment(contract.end).isAfter(moment(), 'day'),
    );
    const sortContractsById = removeEndedContracts.sort((a, b) => a.id - b.id);

    return sortContractsById;
};

export const isContractValid = (contract) => {
    if (!contract.end) {
        return true;
    }
    return moment(contract.end).isAfter(moment(), 'day');
};

export const getCurrentContractsSortedById = (contracts, filter = true) => {
    contracts = [...contracts];
    if (filter) {
        contracts = contracts.filter((contract) => isContractValid(contract));
    }
    contracts = contracts
        .sort((a, b) => {
            const isAValid = isContractValid(a);
            const isBValid = isContractValid(b);
            if (!isAValid) {
                if (!isBValid) {
                    return 0;
                }
                return 1;
            }
            return -1;
        })
        .sort((a, b) => a.id - b.id);

    return contracts;
};
