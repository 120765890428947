import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import { Typography, makeStyles, Input } from '@material-ui/core';

import { InputFieldAdornment } from './InputFieldAdornment';
import { useUser } from 'services/auth';
import { useSettings } from 'services/useSettings';
import { useUpdateEmail } from 'services/editSelf';

import { setFocusOnEditButtonClick } from './setFocusOnEditButtonClick';
import { usePromptOnHardPageChange } from './usePromptOnHardPageChange';
import { setShowSnackbar, setSnackBarError, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    emailDescriptionText: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        color: theme.palette.grey.main,
    },
    emailInputField: {
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.only('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
        },
    },
    nonEditableEmaillAddress: {
        marginLeft: 8,
        justifyContent: 'flex-end',
        fontWeight: 600,
        fontSize: 14,
    },
}));

export const EmailField = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const classes = useStyles();
    const {
        userDetails: { email },
    } = useUser();
    const {
        settings: {
            self: { canEditEmail },
        },
    } = useSettings();
    const { updateEmailMutation } = useUpdateEmail();

    const [emailValue, setEmailValue] = useState(email);

    const isChanged = emailValue !== email;
    const emailInputRef = useRef();

    usePromptOnHardPageChange(isChanged);

    if (!canEditEmail) {
        return (
            <div className={classes.container}>
                <Typography className={classes.emailDescriptionText}>{t('common:email')}</Typography>
                <Typography className={classes.nonEditableEmaillAddress}>{email}</Typography>
            </div>
        );
    }

    const getStatus = () => {
        if (updateEmailMutation.isLoading) {
            return 'loading';
        }

        if (canEditEmail === false) {
            return 'lock';
        }

        if (isChanged) {
            return 'save';
        }

        return 'edit';
    };

    if (updateEmailMutation.isSuccess) {
        dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.email_success' }));
        dispatch(setShowSnackbar({ open: true }));

        updateEmailMutation.reset();
    }

    if (updateEmailMutation.isError) {
        dispatch(setSnackBarError({ message: 'portal:snack_bar.email_error' }));
        dispatch(setShowSnackbar({ open: true }));

        updateEmailMutation.reset();
    }

    return (
        <>
            <div className={classes.container}>
                <Typography className={classes.emailDescriptionText}>{t('common:email')}</Typography>

                <Input
                    className={classes.emailInputField}
                    label={t('common:email')}
                    value={emailValue}
                    onChange={(event) => setEmailValue(event.target.value)}
                    inputRef={emailInputRef}
                    inputProps={{ 'data-testid': 'email-field-editable-input' }}
                    readOnly={!canEditEmail}
                    endAdornment={
                        <InputFieldAdornment
                            status={getStatus()}
                            handleSubmit={() => updateEmailMutation.mutate({ email: emailValue })}
                            setFocusOnEditButtonClick={() => setFocusOnEditButtonClick(emailInputRef)}
                        />
                    }
                    data-testid="email-field-editable"
                />
            </div>
            <Prompt when={isChanged} message={t('common:changes_may_not_be_saved')} />
        </>
    );
};
