import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import { useGetHoursAndMinutes, roundAndTrimNumber } from 'utils/timeFormatters';

export const useGetTotalUnits = () => {
    const { t } = useTranslation();

    const getHoursAndMinutes = useGetHoursAndMinutes();

    return (itemData, isHoursAndMinutesFormat) => {
        if (!isNil(itemData.dayEquivalent)) {
            const total = roundAndTrimNumber(itemData.dayEquivalent * itemData.days);

            return t('units:days_quantity', { days: total });
        }

        if (isHoursAndMinutesFormat) {
            return getHoursAndMinutes(itemData.days * itemData.hoursPerDay);
        }

        return t('units:hours_quantity', { hours: itemData.days * itemData.hoursPerDay });
    };
};
