import { useQuery, useMutation, useQueryClient } from 'react-query';
import { apiClient } from '../utils/data/api';
import { useUser } from './auth';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { responseToCamelCase } from 'utils/strings/helpers';
import {
    getBlockedTimeOff,
    getUserTimeOff,
    getEntitlement,
    getUserContracts,
    requestTimeOff,
    getHydratedUserTimeOff,
    sendCancellation,
} from 'api/holidays';
import { isAfter } from 'date-fns';
import { useMemo } from 'react';

const moment = extendMoment(Moment);
const currentMoment = moment();

export const useHolidayPeriods = (isLoggedIn) => {
    const getPeriods = () => apiClient().get('holiday-periods');
    const { data, isLoading, isError } = useQuery('holidayPeriods', getPeriods, {
        enabled: Boolean(isLoggedIn),
    });
    const holidayPeriods = data ? responseToCamelCase(data.data.data) : [];

    return {
        holidayPeriods,
        isPeriodsLoading: isLoading,
        isPeriodsError: isError,
    };
};

export const getTimeOffTypes = () => {
    return apiClient().get('time-off-types');
};

export const useTimeOffTypes = () => {
    const {
        userDetails: { contracts },
    } = useUser();
    const { data: response, isLoading, isError, isSuccess } = useQuery('timeOffTypes', getTimeOffTypes);
    const allTimeOffTypes = response ? responseToCamelCase(response.data.data) : [];

    const today = new Date();

    const contractTypes = contracts
        .map((c) => c.revisions)
        .flat()
        .filter((revision) => {
            const end = revision.end;
            if (!end) {
                return true;
            }
            const endDate = new Date(Date.parse(revision.end));
            const isValid = isAfter(endDate, today);
            return isValid;
        })
        .map((r) => r.contractTypeId);

    const requestTimeOffTypes = allTimeOffTypes.filter(
        (timeOff) => timeOff.canBeRequested && timeOff.contractTypeIds.some((id) => contractTypes.includes(id)),
    );

    return {
        allTimeOffTypes,
        timeOffTypes: requestTimeOffTypes,
        isTimeOffTypesSuccess: isSuccess,
        isTimeOffTypesLoading: isLoading,
        isTimeOffTypesError: isError,
    };
};

export const useBlockedTimeOff = (isBlockedTimeOffEnabled) => {
    const { data, isLoading, isError } = useQuery('blockedTimeOff', getBlockedTimeOff, {
        enabled: isBlockedTimeOffEnabled,
    });

    const blockedTimeOff = useMemo(() => {
        return data ? data.data.data : [];
    }, [data]);

    return {
        blockedTimeOff,
        isBlockedTimeOffLoading: isLoading,
        isBlockedTimeOffError: isError,
    };
};

export const useApprovedTimeOff = () => {
    const today = moment();
    const todaysDate = today.format('YYYY-MM-DD');
    const nextYear = today.add(1, 'year').format('YYYY-MM-DD');

    const { data, isLoading, isError } = useQuery(['approvedTimeOff', todaysDate, nextYear], async () => {
        const response = await getUserTimeOff(todaysDate, nextYear);

        const approvedOnlyTimeOff = response.data.data.filter((timeOff) => timeOff.statusId === 2);
        const switchStartDatesForConsistency = approvedOnlyTimeOff.map((timeOff) => ({
            ...timeOff,
            start: timeOff.startDate,
            startDate: timeOff.start,
            end: timeOff.endDate,
            endDate: timeOff.end,
        }));

        return switchStartDatesForConsistency;
    });

    return {
        approvedTimeOff: data,
        isApprovedTimeOffLoading: isLoading,
        isApprovedTimeOffError: isError,
    };
};

export const useEntitlement = (holidayPeriod) => {
    const { data, isLoading, isError } = useQuery(
        'entitlement',
        async () => {
            const entitlement = await getEntitlement(holidayPeriod.start, holidayPeriod.end);

            return entitlement;
        },
        { enabled: Boolean(holidayPeriod) },
    );

    return {
        entitlement: data ? data.data.data : [],
        isEntitlementLoading: isLoading,
        isEntitlementError: isError,
    };
};

export const useUserContracts = (start, end) => {
    const startDate = start || currentMoment.format('YYYY-MM-DD');
    const endDate = end || currentMoment.format('YYYY-MM-DD');

    const { data, isLoading, isError } = useQuery(['userContracts', startDate, endDate], () =>
        getUserContracts(startDate, endDate),
    );

    return {
        userContracts: data ? data.data.data : [],
        isContractsLoading: isLoading,
        isContractsError: isError,
    };
};

export const usePostRequestTimeOff = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation(({ requestData }) => requestTimeOff(requestData), {
        onSuccess: () => {
            queryClient.invalidateQueries('entitlement');
            queryClient.invalidateQueries('entitlementByContractId');
        },
    });

    return { postTimeOffRequestMutation: mutation };
};

export const useUserTimeOff = (start, end, contractId, allTimeOffTypes) => {
    const startDate = start || currentMoment.format('YYYY-MM-DD');
    const endDate = end || currentMoment.format('YYYY-MM-DD');

    const { data, isLoading, isError } = useQuery(
        ['userTimeOff', startDate, endDate, contractId, allTimeOffTypes],
        async () => {
            const response = await getHydratedUserTimeOff(startDate, endDate, allTimeOffTypes);

            const filteredResponseByContractId = response.filter((timeOff) => timeOff.contractId === contractId);

            return contractId ? filteredResponseByContractId : response;
        },
    );

    return {
        userTimeOff: data,
        isUserTimeOffLoading: isLoading,
        isUserTimeOffError: isError,
    };
};

export const useSendCancelRequest = () => {
    const mutation = useMutation(({ dialogItemId }) => sendCancellation(dialogItemId));

    return { sendCancelRequestMutation: mutation };
};
