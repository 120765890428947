import { apiClient } from 'utils/data/api';

export const signIn = (tenant, username, password) =>
    apiClient().post(
        'auth/session',
        {
            tenant,
            credentials: {
                username,
                password,
            },
        },
        { withCredentials: true },
    );

export const loginSSO = (tenant, token) =>
    apiClient().post(
        'auth/session',
        {
            tenant,
            credentials: {
                sso_token: token,
            },
        },
        { withCredentials: true },
    );

export const verifyTenant = (tenant) => {
    if (!tenant) {
        throw new Error('Cannot resolve tenant');
    }
    return apiClient().get(`tenant/${tenant}`);
};

export const logout = () => apiClient().delete('auth/session');

export const getSelf = () => {
    return apiClient().get('self');
};

export const getSettings = () => apiClient().get('config');

export const updatePassword = (oldPassword, newPassword) =>
    apiClient().put('self/password', {
        old_password: oldPassword,
        new_password: newPassword,
    });

export const submitForgottenPassword = (email, tenant) => apiClient().post('auth/forgot-password', { email, tenant });

export const submitResetPassword = ({ userEmail, password, token, isWelcomePage, tenant }) => {
    const type = isWelcomePage ? 'welcome' : 'staff';
    return apiClient().post('auth/reset-password', {
        email: userEmail,
        password,
        token,
        type,
        tenant,
    });
};
