export const statuses = {
    pending: 'pending',
    accepted: 'accepted',
    declined: 'rejected',
    unavailable: 'unavailable',
};

export const LONG_DATE_FORMAT = 'DD-MM-YYYY ddd';

export const offerFields = [
    {
        transKey: 'common:date',
        key: 'date',
    },
    {
        transKey: 'portal:venue',
        key: 'location',
    },
    {
        transKey: 'portal:role',
        key: 'role',
    },
    {
        transKey: 'common:start_time',
        key: 'startDate',
    },
    {
        transKey: 'common:end_time',
        key: 'endDate',
    },
    {
        transKey: 'common:notes',
        key: 'notes',
    },
];
