import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Panel from 'components/Panel';
import StyledCardComponent from 'components/widgets/timeOffRequest/sharedComponents/StyledCardComponent';
import LoadingComponent from 'components/LoadingComponent';
import ErrorMessage from 'components/ErrorMessage';

import { DateHeader } from './timeOffComponents/DateHeader';
import { TimeOffRequests } from './timeOffComponents/TimeOffRequests';

import { useTimeOffTypes, useUserTimeOff } from 'services/useHoliday';
import { setPaginationDateIndex } from './timeOffSlice';
import { selectDefaultIndex, selectStartDate, selectEndDate } from './timeOffSelectors';

export const TimeOffWidget = ({ withPanel, contractId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { allTimeOffTypes, isTimeOffTypesLoading, isTimeOffTypesError } = useTimeOffTypes();

    const defaultIndex = useSelector(selectDefaultIndex);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const { userTimeOff, isUserTimeOffLoading, isUserTimeOffError } = useUserTimeOff(
        startDate,
        endDate,
        contractId,
        allTimeOffTypes,
    );

    useEffect(() => {
        if (defaultIndex) {
            dispatch(setPaginationDateIndex(defaultIndex));
        }
    }, [defaultIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isUserTimeOffError || isTimeOffTypesError) {
        return (
            <StyledCardComponent>
                <ErrorMessage message={t('time_off:errors.widget_error_message')} />
            </StyledCardComponent>
        );
    }

    if (isUserTimeOffLoading || isTimeOffTypesLoading || !userTimeOff) {
        return (
            <StyledCardComponent>
                <LoadingComponent title={t('common:loading')} />
            </StyledCardComponent>
        );
    }

    return withPanel ? (
        <>
            <DateHeader />
            <Panel removeBottomGutter style={{ height: 469 }}>
                <TimeOffRequests userTimeOff={userTimeOff} />
            </Panel>
        </>
    ) : (
        <>
            <DateHeader />
            <TimeOffRequests userTimeOff={userTimeOff} />
        </>
    );
};

TimeOffWidget.propTypes = {
    withPanel: bool,
    contractId: number,
};
