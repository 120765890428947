import { useDispatch, useSelector } from 'react-redux';
import { toggleInactivePots } from './entitlementSlice';

function useIsHidingInactivePots() {
    return useSelector((state) => state.entitlement.hideInactivePots);
}

function useInactivePotState() {
    const dispatch = useDispatch();
    const isHiding = useIsHidingInactivePots();
    const toggleHide = () => {
        dispatch(toggleInactivePots());
    };
    return [isHiding, toggleHide];
}

export { useIsHidingInactivePots, useInactivePotState };
