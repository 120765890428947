import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import {
    ListItemText,
    makeStyles,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { InputFieldAdornment } from './InputFieldAdornment';

import { useUser } from 'services/auth';
import { useSettings } from 'services/useSettings';
import { useUpdateMobile } from 'services/editSelf';

import { setFocusOnEditButtonClick } from './setFocusOnEditButtonClick';
import { mobileCountryList } from 'utils/components/manageAccount/mobileCountryList';
import { usePromptOnHardPageChange } from './usePromptOnHardPageChange';
import { setShowSnackbar, setSnackBarError, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';

const useStyles = makeStyles((theme) => ({
    fullWidthContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start',
        },
    },
    variableWidthContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.only('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.only('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
        },
    },
    textField: {
        marginBottom: 0,
        marginTop: 0,
        flexGrow: 1,
        flexShrink: 4,
        [theme.breakpoints.only('xs')]: {
            marginTop: 6,
        },
    },
    nonEditableMobileAddress: {
        fontWeight: 600,
        fontSize: 14,
    },
    countryField: {
        marginRight: 8,
    },
}));

export const MobileField = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const classes = useStyles();
    const { updateMobileMutation } = useUpdateMobile();
    const {
        settings: {
            self: { canEditMobile },
        },
    } = useSettings();
    const {
        userDetails: { mobile, mobileCountry },
    } = useUser();

    const [mobileValue, setMobileValue] = useState(mobile);
    const [mobileCountryValue, setMobileCountryValue] = useState(mobileCountry);

    const isChanged = mobileValue !== mobile || mobileCountryValue !== mobileCountry;

    const mobileInputRef = useRef();

    usePromptOnHardPageChange(isChanged);

    if (!canEditMobile) {
        const currentCountryCodeObject = mobileCountryList.find((country) => country.shortName === mobileCountryValue);
        const { shortName, code } = currentCountryCodeObject;
        const mobileText = `${shortName} +${code}${mobileValue}`;

        return (
            <>
                <ListItemText secondary={t('common:mobile')} />
                <ListItemSecondaryAction>
                    <ListItemText primary={mobileText} classes={{ primary: classes.nonEditableMobileAddress }} />
                </ListItemSecondaryAction>
            </>
        );
    }

    const getStatus = () => {
        if (updateMobileMutation.isLoading) {
            return 'loading';
        }

        if (canEditMobile === false) {
            return 'lock';
        }

        if (isChanged) {
            return 'save';
        }

        return 'edit';
    };

    if (updateMobileMutation.isSuccess) {
        dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.mobile_success' }));
        dispatch(setShowSnackbar({ open: true }));

        updateMobileMutation.reset();
    }

    if (updateMobileMutation.isError) {
        dispatch(setSnackBarError({ message: 'portal:snack_bar.mobile_error' }));
        dispatch(setShowSnackbar({ open: true }));

        updateMobileMutation.reset();
    }

    return (
        <>
            <div className={classes.fullWidthContainer}>
                <div className={classes.variableWidthContainer}>
                    <FormControl>
                        <InputLabel id="country-code-label">{t('common:country')}</InputLabel>
                        <Select
                            className={classes.countryField}
                            value={mobileCountryValue}
                            onChange={(event) => setMobileCountryValue(event.target.value)}
                            MenuProps={{
                                style: {
                                    maxHeight: 600,
                                    width: 180,
                                },
                            }}
                            data-testid="mobile-field-editable-country"
                        >
                            {mobileCountryList.map((country) => (
                                <MenuItem value={country.shortName} key={country.shortName}>
                                    {`${country.shortName} +${country.code}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.textField}
                        label={t('common:mobile')}
                        value={mobileValue}
                        onChange={(event) => setMobileValue(event.target.value)}
                        margin="normal"
                        inputRef={mobileInputRef}
                        inputProps={{ 'data-testid': 'mobile-field-editable-number-input' }}
                        InputProps={{
                            readOnly: !canEditMobile,
                            endAdornment: (
                                <InputFieldAdornment
                                    status={getStatus()}
                                    handleSubmit={() =>
                                        updateMobileMutation.mutate({
                                            mobileCountry: mobileCountryValue,
                                            mobile: mobileValue,
                                        })
                                    }
                                    setFocusOnEditButtonClick={() => setFocusOnEditButtonClick(mobileInputRef)}
                                />
                            ),
                        }}
                        data-testid="mobile-field-editable-number"
                    />
                </div>
            </div>
            <Prompt when={isChanged} message={t('common:changes_may_not_be_saved')} />
        </>
    );
};
