import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import Pagination from 'components/datePicker/Pagination';
import ResponsiveDatePicker from 'components/datePicker/ResponsiveDatePicker';
import BackIconButton from 'components/buttons/BackIconButton';
import NextIconButton from 'components/buttons/NextIconButton';
import DefaultButton from '../buttons/DefaultButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    paginationContainer: {
        margin: '0',
        width: 'auto',
        display: 'inline-flex',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            justifyContent: 'center',
        },
    },
    mobileFullWidth: {
        [theme.breakpoints.down('xs')]: {
            width: 160,
            margin: '0 auto',
            textAlign: 'center',
        },
    },
    responsiveButton: {
        [theme.breakpoints.down('md')]: {
            padding: '4px 8px',
        },
    },
}));

export const DatePagination = ({
    refreshButtonOnClick,
    handleNext,
    handleBack,
    startDate,
    endDate,
    mwvDate,
    handleDatesChange,
    onFocusChange,
    focusedInput,
    backDisabled,
    nextDisabled,
    isCurrentPeriod,
    resetButtonText,
    noMobileView,
    period,
    id,
    isWeekTabView,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const timePeriod = period || t('common:week');

    return (
        <Pagination
            id={id}
            className={classes.paginationContainer}
            handleNext={handleNext}
            handleBack={handleBack}
            backButton={
                <BackIconButton
                    onClick={handleBack}
                    size="small"
                    disabled={backDisabled}
                    tooltipTitle={t('common:previous_period', { timePeriod })}
                />
            }
            nextButton={
                <NextIconButton
                    onClick={handleNext}
                    size="small"
                    disabled={nextDisabled}
                    tooltipTitle={t('common:next_period', { timePeriod })}
                />
            }
            title={
                isWeekTabView ? (
                    mwvDate.format('MMMM YYYY')
                ) : (
                    <ResponsiveDatePicker
                        focusedInput={focusedInput}
                        onFocusChange={onFocusChange}
                        numberOfMonths={2}
                        startDate={startDate}
                        endDate={endDate}
                        handleDatesChange={handleDatesChange}
                        startDateId="startDate"
                        endDateId="endDate"
                        noBorder
                        noMobileView={noMobileView}
                        displayFormat="DD/MM/YYYY"
                        isOutsideRange={() => false}
                    />
                )
            }
            extraComponent={
                <Grid container>
                    <Grid item className={classes.mobileFullWidth}>
                        <DefaultButton
                            color="primary"
                            variant="outlined"
                            size="large"
                            className={classes.responsiveButton}
                            disabled={isCurrentPeriod}
                            onClick={refreshButtonOnClick}
                        >
                            {resetButtonText || t('common:this_period', { timePeriod })}
                        </DefaultButton>
                    </Grid>
                </Grid>
            }
        />
    );
};

DatePagination.propTypes = {
    backDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    refreshButtonOnClick: PropTypes.func.isRequired,
    period: PropTypes.string.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    mwvDate: PropTypes.object,
    handleDatesChange: PropTypes.func.isRequired,
    onFocusChange: PropTypes.func.isRequired,
    focusedInput: PropTypes.any,
    isCurrentPeriod: PropTypes.bool.isRequired,
    isWeekPeriod: PropTypes.bool,
    noMobileView: PropTypes.bool,
    resetButtonText: PropTypes.string,
    id: PropTypes.string,
    isWeekTabView: PropTypes.bool,
};
