import { SVGProps } from 'react';
interface NLFlagIconProps {
    title?: string;
    titleId?: string;
}

const NLFlagIcon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & NLFlagIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 6"
        aria-labelledby={titleId}
        className="MuiSvgIcon-root"
        focusable="false"
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path fill="#21468B" d="M0 0h9v6H0z" />
        <path fill="#FFF" d="M0 0h9v4H0z" />
        <path fill="#AE1C28" d="M0 0h9v2H0z" />
    </svg>
);

export default NLFlagIcon;
