import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from '@material-ui/core';

import { ContractTabs } from 'components/ContractTabs';
import { InfoRow } from './InfoRow';
import { getCurrentRevision } from 'containers/timeOff/helpers';
import { InactiveContractsCheckbox } from './InactiveContractsCheckbox';
import { getFilteredContracts } from './helpers';

import { useUser } from 'services/auth';

export const ContractInformation = () => {
    const { t } = useTranslation();
    const {
        userDetails: { contracts },
    } = useUser();

    const [activeTab, setTab] = useState(0);
    const [isInactiveContractsDisplayed, setIsInactiveContractsDisplayed] = useState(false);

    const { activeContracts, inactiveContracts } = getFilteredContracts(contracts);
    const displayContracts = isInactiveContractsDisplayed
        ? [...activeContracts, ...inactiveContracts]
        : activeContracts;

    const contract = displayContracts[activeTab];

    if (!contract) {
        setTab(0);

        return null;
    }

    const revision = getCurrentRevision(contract);
    const { positionName, districtName, contractedHours, contractedDays, shopName } = revision;
    const { start } = contract;

    return (
        <>
            {Boolean(inactiveContracts.length) && (
                <InactiveContractsCheckbox
                    isChecked={isInactiveContractsDisplayed}
                    setIsChecked={() => setIsInactiveContractsDisplayed(!isInactiveContractsDisplayed)}
                />
            )}
            <ContractTabs tabValue={activeTab} setTabValue={setTab} contracts={displayContracts} />
            <List>
                <InfoRow title={t('portal:position')} info={positionName} />
                <InfoRow title={t('portal:district')} info={districtName} />
                <InfoRow title={t('portal:venue')} info={shopName} />
                <InfoRow title={t('portal:contracted_hours')} info={contractedHours} />
                <InfoRow title={t('portal:contracted_days')} info={contractedDays} />
                <InfoRow title={t('portal:start_date')} info={start} />
            </List>
        </>
    );
};
