import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ControlPanel } from 'containers/Schedule/localComponents/ControlPanel';
import { MultiWeekView } from 'containers/Schedule/localComponents/multiWeekView';
import { makeStyles, Hidden, Grid, Typography } from '@material-ui/core';
import { TimeOff } from 'containers/timeOff/TimeOff';
import { PrimaryButtonRequest } from 'components/PrimaryButtonRequest';
import { TabbedWrapper } from 'components/TabbedWrapper';

import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSettings } from 'services/useSettings';
import { sendGoogleAnalyticsTabClick } from 'googleAnalytics';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        background: theme.palette.grey.soft,
        [theme.breakpoints.down('md')]: {
            padding: 10,
        },
    },
    container: {
        padding: 20,
        height: 100,
        marginBottom: 10,
    },
    summaryMessage: {
        marginRight: 20,
    },
}));

export const TimeOffPage = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { page } = useParams();

    const { settings } = useSettings();

    const isMultiWeekView = useMemo(() => {
        if (!settings) {
            return false;
        }
        return settings.multiWeekView.enabled;
    }, [settings]);

    const tabs = useMemo(() => {
        return settings
            ? [
                  {
                      name: 'My time off',
                      value: 'summary',
                      isDisplayed: true,
                      eventName: 'Summary',
                  },
                  {
                      name: t('portal:time_off_planner'),
                      value: 'planner',
                      isDisplayed: isMultiWeekView,
                      eventName: 'Planner',
                  },
              ]
            : [];
    }, [settings, t, isMultiWeekView]);

    const handleTabChange = (_, value) => {
        const name = tabs.find((tab) => tab.value === value)?.name;

        if (name === undefined) {
            throw new Error(`Cannot find name for ${value}`);
        }
        sendGoogleAnalyticsTabClick(name, 'Time Off');
        push({ pathname: `/time-off/${value}` });
    };

    useEffect(() => {
        if (!page) {
            //If we're not on any subpage, default to "summary" page
            push({
                pathname: 'time-off/summary',
            });
        }
    }, [page, push]);

    return (
        <>
            <TabbedWrapper
                tabs={tabs}
                color="primary"
                id="hit-point-control-panel"
                tabValue={page}
                handleTabChange={handleTabChange}
            />
            <TimeOffPageContent page={page} />
        </>
    );
};

const TimeOffPageContent = ({ page }) => {
    if (page === 'summary') {
        return <TimeOffSummary />;
    } else if (page === 'planner') {
        return (
            <>
                <ControlPanel tabValue={page} multiWeekViewTabId="planner" />
                <MultiWeekView />
            </>
        );
    }

    return null;
};

TimeOffPageContent.propTypes = {
    page: PropTypes.string,
};

export const TimeOffSummary = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <div className={classes.pageContainer}>
            <Grid container justifyContent="center" alignItems="center">
                <Hidden xsDown>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.container}
                    >
                        <Grid item sm={12} md={10}>
                            <Typography variant="subtitle1" gutterBottom className={classes.summaryMessage}>
                                {t('time_off:summary_message')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={2} container justifyContent="flex-end">
                            {!isMobileOrTablet && <PrimaryButtonRequest />}
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
            <TimeOff />
        </div>
    );
};
