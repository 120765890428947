import PropTypes from 'prop-types';
import { formats } from 'utils/constants';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import { useGetUnitsPerDay, useGetTotalUnits } from '../utils';
import { useSettings } from '../../../../services/useSettings';

const useStyles = makeStyles((theme) => ({
    fixedWidth: {
        maxWidth: '100%',
        align: 'left',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100px',
        },
    },
}));

export const TableValueField = ({ valueLabel, itemData, isMobile }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { moment } = useMoment();
    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();

    const getUnitsPerDay = useGetUnitsPerDay();
    const getTotalUnits = useGetTotalUnits();

    const formatDate = (date) => moment(date).format('D MMM');
    const isSingleDay = itemData.startDate === itemData.endDate;

    let valueField;

    switch (valueLabel) {
        case 'timeOffType':
            valueField = itemData.TimeOffType === undefined ? t('portal:time_off') : itemData.TimeOffType.name;
            break;
        case 'dates':
            valueField = isSingleDay
                ? formatDate(itemData.startDate)
                : `${formatDate(itemData.startDate)} - ${formatDate(itemData.endDate)}`;
            break;
        case 'duration':
            valueField = `${itemData.days} ${itemData.days === 1 ? t('common:day') : t('common:days')}`;
            break;
        case 'units':
            valueField = getUnitsPerDay(itemData, showHoursWithMinutes, t);
            break;
        case 'total':
            valueField = getTotalUnits(itemData, showHoursWithMinutes, t);
            break;
        case 'mobile_units':
            return (
                <Typography noWrap variant="caption">
                    {getUnitsPerDay(itemData, showHoursWithMinutes, t)}
                </Typography>
            );
        case 'mobile_dates':
            return (
                <>
                    <Typography noWrap variant="caption" className={classes.fixedWidth}>
                        <strong>{t('common:from')}</strong> {moment(itemData.startDate).format(formats.DMY_DOT)}
                    </Typography>
                    <br />
                    <Typography noWrap variant="caption" className={classes.fixedWidth}>
                        <strong>{t('common:to')}</strong> {moment(itemData.endDate).format(formats.DMY_DOT)}
                    </Typography>
                </>
            );
        default:
            valueField = '';
            break;
    }

    return isMobile ? (
        valueField
    ) : (
        <Grid item xs>
            <Typography noWrap className={classes.fixedWidth}>
                {valueField}
            </Typography>
        </Grid>
    );
};

TableValueField.propTypes = {
    valueLabel: PropTypes.string.isRequired,
    itemData: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        days: PropTypes.number,
        hoursPerDay: PropTypes.number,
        TimeOffType: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    isMobile: PropTypes.bool,
};
