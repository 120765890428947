import { Redirect, useLocation } from 'react-router-dom';
import { LoginGrid } from 'containers/Login/LoginGrid';
import { useUser } from '../services/auth';

export const LoginPage = () => {
    const { userDetails } = useUser();
    const location = useLocation();

    const intendedUrl = location?.intendedUrl;

    const intendedPath = intendedUrl?.pathname ?? '/';
    const intendedSearch = intendedUrl?.search ?? '';

    if (userDetails) {
        return <Redirect to={`${intendedPath}${intendedSearch}`} />;
    }

    return <LoginGrid />;
};
