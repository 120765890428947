import { array, func, shape, string, number } from 'prop-types';
import { Grid, Typography, makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { useIsMobileOrTablet } from 'utils/components/helpers';

import DefaultButton from 'components/buttons/DefaultButton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0px 0px 20px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 0px 20px 0px',
            textAlign: 'center',
        },
    },
    label: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        marginBottom: '10px',
    },
    contained: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        marginRight: '10px',
        '&&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    outlined: {
        color: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.primary.dark}`,
        marginRight: '10px',
        '&&:hover': {
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    icon: {
        fill: theme.palette.primary.dark,
    },
}));

export const ShopButtons = ({ shops, currentShop, setCurrentShop }) => {
    const classes = useStyles();
    const isSmallScreen = useIsMobileOrTablet();

    const handleSelectOnChange = (event) => {
        const id = event.target.value;
        const chosenShop = shops.filter((shop) => shop.shopId === id);

        setCurrentShop(chosenShop[0]);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                {!isSmallScreen && <Typography className={classes.label}>{currentShop.shopName}</Typography>}
            </Grid>
            <Grid item xs={12}>
                {isSmallScreen ? (
                    <FormControl>
                        <Select
                            color="primary"
                            className={classes.label}
                            value={currentShop.shopId}
                            classes={{ icon: classes.icon }}
                            onChange={handleSelectOnChange}
                        >
                            {shops.map((shop) => (
                                <MenuItem key={shop.shopId} value={shop.shopId}>
                                    {shop.shopName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    shops.map((shop) => (
                        <DefaultButton
                            key={shop.shopId}
                            className={shop.shopId === currentShop.shopId ? classes.contained : classes.outlined}
                            variant={shop.shopId === currentShop.shopId ? 'contained' : 'outlined'}
                            onClick={() => setCurrentShop(shop)}
                        >
                            {shop.shopName}
                        </DefaultButton>
                    ))
                )}
            </Grid>
        </Grid>
    );
};

ShopButtons.propTypes = {
    shops: array,
    currentShop: shape({
        shopId: number,
        shopName: string,
    }),
    setCurrentShop: func,
};
