import ChevronRight from '@material-ui/icons/ChevronRight';
import IconButtonWithTooltip from './IconButtonWithTooltip';

const NextIconButton = (props) => (
    <IconButtonWithTooltip {...props}>
        <ChevronRight />
    </IconButtonWithTooltip>
);

export default NextIconButton;
