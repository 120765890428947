import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { HeadlineDatePicker } from './HeadlineDatePicker';
import PanelHeadline from 'components/PanelHeadline';

const useStyles = makeStyles((theme) => ({
    timeOffHeadline: {
        marginBottom: 15,
        alignItems: 'end',
    },
    dataPickerPosition: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
    },
    dataPickerSize: {
        width: 560,
        [theme.breakpoints.down('md')]: {
            width: 'auto',
        },
    },
}));

export const DateHeader = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container className={classes.timeOffHeadline}>
            <Grid item sm={12} md={12} lg={4}>
                <PanelHeadline>{t('time_off:time_off_requests_header')}</PanelHeadline>
            </Grid>
            <Grid item sm={12} md={12} lg={8} className={classes.dataPickerPosition}>
                <div className={classes.dataPickerSize}>
                    <HeadlineDatePicker />
                </div>
            </Grid>
        </Grid>
    );
};
