import StyledCardComponent from './sharedComponents/StyledCardComponent';
import { GridContainer } from './sharedComponents/GridContainer';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/ErrorMessage';

import { GridHeading } from './sharedComponents/GridHeading';
import { TimeOffDialog } from './sharedComponents/TimeOffDialog';
import { TimeOffCancelConfirmDialog } from './sharedComponents/TimeOffCancelConfirmDialog';

const TimeOffDetails = ({ data, errorMessage, cardClassName }) =>
    data.length ? (
        <>
            <GridHeading />
            <StyledCardComponent className={cardClassName}>
                {data.map((itemData, index) => (
                    <GridContainer itemData={itemData} key={`requestItem_${index}`} />
                ))}
                <TimeOffDialog />
                <TimeOffCancelConfirmDialog />
            </StyledCardComponent>
        </>
    ) : (
        <div
            style={{
                height: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <ErrorMessage message={errorMessage} alignText="center" style={{ padding: 10 }} />
        </div>
    );

TimeOffDetails.propTypes = {
    data: PropTypes.array.isRequired,
    errorMessage: PropTypes.string.isRequired,
    cardClassName: PropTypes.object,
};

export default TimeOffDetails;
