import { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PotsPagination } from './PotsPagination';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { EntitlementPanelSegment } from 'components/entitlementPanelSegment/EntitlementPanelSegment';

const useStyles = makeStyles((theme) => ({
    lieuLabel: {
        marginLeft: 25,
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0px 20px 10px',
        },
    },
    lieuText: {
        fontSize: 14,
    },
}));

export const ExpansionPanelContent = ({ currentPot }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { moment } = useMoment();

    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);

    useEffect(() => {
        if (currentPot.type === 'default') {
            const today = moment();
            [...currentPot.periods].forEach((period, index) => {
                if (
                    today.isSameOrAfter(moment(period.start, 'YYYY-MM-DD'), 'day') &&
                    today.isSameOrBefore(moment(period.end, 'YYYY-MM-DD'), 'day')
                ) {
                    setCurrentPeriodIndex(index);
                }
            });
        }
    }, [currentPot.type, currentPot.periods, moment, currentPot]);

    return (
        <>
            {currentPot.type === 'lieu_time' && (
                <Grid container alignItems="center">
                    <Grid item xs={12} md={5} className={classes.lieuLabel}>
                        <Typography className={classes.lieuText}>{t('time_off:current_banked')}</Typography>
                        <Typography className={classes.lieuText}>{t('time_off:time_balance')}</Typography>
                    </Grid>
                    <Grid item xs={12} md container alignItems="center">
                        <EntitlementPanelSegment
                            segment="lieu"
                            entitlement={currentPot.balance}
                            units={currentPot.displayUnit}
                        />
                    </Grid>
                </Grid>
            )}
            {currentPot.type === 'default' && (
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md={5}>
                        <PotsPagination
                            handleForwardPagination={() => setCurrentPeriodIndex(currentPeriodIndex + 1)}
                            handleBackPagination={() => setCurrentPeriodIndex(currentPeriodIndex - 1)}
                            startDate={[...currentPot.periods][currentPeriodIndex].start}
                            endDate={[...currentPot.periods][currentPeriodIndex].end}
                            backDisabled={currentPeriodIndex === 0}
                            forwardDisabled={currentPeriodIndex === [...currentPot.periods].length - 1}
                        />
                    </Grid>
                    <Grid item xs={12} md container alignItems="center">
                        <EntitlementPanelSegment
                            segment="entitlement"
                            entitlement={[...currentPot.periods][currentPeriodIndex].entitlement.actual}
                            units={currentPot.displayUnit}
                        />
                        <EntitlementPanelSegment
                            segment="used"
                            entitlement={[...currentPot.periods][currentPeriodIndex].usage.used}
                            units={currentPot.displayUnit}
                        />
                        <EntitlementPanelSegment
                            segment="booked"
                            entitlement={[...currentPot.periods][currentPeriodIndex].usage.booked}
                            units={currentPot.displayUnit}
                        />
                        <EntitlementPanelSegment
                            segment="balance"
                            entitlement={[...currentPot.periods][currentPeriodIndex].usage.total.balances.actual}
                            units={currentPot.displayUnit}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

ExpansionPanelContent.propTypes = {
    currentPot: object.isRequired,
};
