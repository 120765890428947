import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { useIsMobile } from 'utils/components/helpers';

export const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: theme.spacing(1),
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    mobileRightColumnCell: {
        padding: theme.spacing(1),
        display: 'flex',
        position: 'absolute',
        right: 0,
        '& > div > p': {
            marginLeft: 'auto',
        },
    },
    labelTableCell: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        whiteSpace: 'nowrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 500,
    },
    mobileContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    row: {
        display: 'flex',
    },
    mobileRow: {
        justifyContent: 'space-between',
    },
}));

export const InfoTable = ({ tableRows }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    return (
        <div className={isMobile ? classes.mobileContainer : classes.container} role="grid">
            {tableRows.map(
                ({ fieldName, value }) =>
                    value && (
                        <div className={classNames(classes.row, { [classes.mobileRow]: isMobile })} key={fieldName}>
                            <div role="gridcell" className={classNames(classes.labelTableCell, classes.tableCell)}>
                                {fieldName}
                            </div>
                            <div
                                role="gridcell"
                                className={classNames(classes.labelTableCell, {
                                    [classes.mobileRightColumnCell]: isMobile,
                                    [classes.tableCell]: !isMobile,
                                })}
                            >
                                {value}
                            </div>
                        </div>
                    ),
            )}
        </div>
    );
};

InfoTable.propTypes = {
    tableRows: PropTypes.arrayOf(
        PropTypes.exact({
            fieldName: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        }).isRequired,
    ).isRequired,
};
