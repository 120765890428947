import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        textAlign: 'center',
        width: 100,
        height: 100,
        margin: 'auto',
        cursor: 'default',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
    },
    todayContainer: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    fullDayText: {
        paddingTop: 20,
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            paddingTop: 0,
        },
    },
    dateText: {
        fontSize: '2rem',
    },
    dateTextMobile: {
        fontWeight: 'bold',
    },
    today: {
        color: 'white',
    },
    todayHeader: {
        background: theme.palette.primary.dark,
        borderRadius: '50%',
        width: 100,
        height: 100,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
    },
}));

const ScheduleWeekHeader = ({ label, date }) => {
    const classes = useStyles();
    const { moment } = useMoment();
    const isMobileOrTablet = useIsMobileOrTablet();

    const splitLabel = label.split(' ');
    const dateAndMonthString = splitLabel[1] === '1' ? `${splitLabel[1]} ${splitLabel[2].slice(0, 3)}` : splitLabel[1];
    const isToday = moment(date).isSame(moment(), 'd');

    return (
        <div
            className={classnames({
                [classes.headerContainer]: true,
                [classes.todayContainer]: isToday,
            })}
        >
            {isMobileOrTablet ? (
                <>
                    <Typography
                        className={classnames({
                            [classes.fullDayText]: true,
                            [classes.today]: isToday,
                        })}
                    >
                        {splitLabel[0].slice(0, 2)}
                    </Typography>
                    <Typography
                        className={classnames({
                            [classes.dateTextMobile]: true,
                            [classes.today]: isToday,
                        })}
                    >
                        {dateAndMonthString}
                    </Typography>
                </>
            ) : (
                <div className={isToday ? classes.todayHeader : null}>
                    <Typography
                        className={classnames({
                            [classes.fullDayText]: true,
                            [classes.today]: isToday,
                        })}
                    >
                        {splitLabel[0]}
                    </Typography>
                    <Typography
                        className={classnames({
                            [classes.dateText]: true,
                            [classes.today]: isToday,
                        })}
                    >
                        {dateAndMonthString}
                    </Typography>
                </div>
            )}
        </div>
    );
};

ScheduleWeekHeader.propTypes = {
    label: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
};

export default ScheduleWeekHeader;
