import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemText, ListItemIcon, Icon, Tooltip, Zoom, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMultiWeekTab } from '../../../menuSlice';
import { selectDesktopMenuOpen } from '../../../menuSelector';

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: 'initial',
        display: 'block',
    },
    gutters: {
        paddingLeft: 20,
        marginBottom: theme.spacing(2),
    },
    navItemStyling: {
        color: theme.palette.common.white,
    },
    linkHover: {
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    activeLink: {
        background: theme.palette.primary.main,
        '&:focus': {
            background: theme.palette.primary.main,
        },
    },
    toolTip: {
        fontSize: '0.8em',
        marginLeft: 5,
    },
}));

export const MenuItem = ({ path, nestedRoutes, icon, pathname, menuOrigin, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const desktopMenuOpen = useSelector(selectDesktopMenuOpen);

    const locationPathname = useLocation().pathname;
    const isActiveLink = nestedRoutes
        ? nestedRoutes.some((nestedRoute) => `${path}/${nestedRoute}` === locationPathname)
        : locationPathname === path;

    return (
        <Tooltip
            TransitionComponent={Zoom}
            placement="right"
            classes={{ tooltipPlacementRight: classes.toolTip }}
            disableHoverListener={menuOrigin === 'mobile' || Boolean(desktopMenuOpen) === true}
            disableTouchListener
            disableFocusListener
            title={pathname}
        >
            <Link
                to={path}
                className={classes.link}
                id={id}
                onClick={() => dispatch(setIsMultiWeekTab({ isMultiWeekTab: false }))}
            >
                <ListItem
                    button
                    className={classnames({
                        [classes.linkHover]: true,
                        [classes.gutters]: true,
                        [classes.activeLink]: isActiveLink,
                    })}
                >
                    <ListItemIcon>
                        <Icon className={classes.navItemStyling}>{`${icon}`}</Icon>
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.navItemStyling }} primary={pathname} />
                </ListItem>
            </Link>
        </Tooltip>
    );
};

MenuItem.propTypes = {
    path: PropTypes.string.isRequired,
    nestedRoutes: PropTypes.array,
    icon: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    menuOrigin: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
};
