export const getAvailableRevisions = ({ selectionList, userRequests }) =>
    selectionList.reduce((available, option) => {
        const isSelected = userRequests.some((request) => option.id === request.relatedContractId);

        if (!isSelected) {
            available.push({
                id: option.id,
                name: option.name,
            });
        }

        return available;
    }, []);
