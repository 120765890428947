import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import BulletPoint from 'components/BulletPoint';
import { Grid, Typography, makeStyles, Hidden, Tooltip } from '@material-ui/core';
import { TableValueField } from './TableValueField';
import { dataColors } from '../helpers';
import { useSettings } from 'services/useSettings';
import { setMobileTimeOffDialogOpen, setDialogItem } from 'containers/timeOff/timeOffSlice';
import { selectIsMobileTimeOffDialogOpen } from 'containers/timeOff/timeOffSelectors';

const useStyles = makeStyles((theme) => ({
    rowStyle: {
        borderBottom: `1px solid ${theme.palette.grey.light}`,
        padding: '1.5em 1em',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    notesContainer: {
        width: '100px',
    },
    tooltip: {
        color: theme.palette.common.white,
    },
    bullet: {
        marginRight: 10,
    },
}));

export const GridContainer = ({ itemData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();
    const isHoursAndMinutesFormat = showHoursWithMinutes && `${itemData.hours_per_day}`.includes('.');

    const isMobileTimeOffDialogOpen = useSelector(selectIsMobileTimeOffDialogOpen);

    const handleOnClick = () => {
        dispatch(setMobileTimeOffDialogOpen(!isMobileTimeOffDialogOpen));
        dispatch(setDialogItem(itemData));
    };

    return (
        itemData && (
            <Grid container alignItems="center" className={classes.rowStyle} onClick={handleOnClick}>
                <Hidden xsDown>
                    <Grid item>
                        <BulletPoint className={classes.bullet} color={dataColors[itemData.status]} type="rounded" />
                    </Grid>
                    <TableValueField valueLabel="timeOffType" itemData={itemData} />
                    <TableValueField valueLabel="dates" itemData={itemData} />
                    <TableValueField valueLabel="duration" itemData={itemData} />
                    <TableValueField
                        valueLabel="units"
                        itemData={itemData}
                        isHoursAndMinutesFormat={isHoursAndMinutesFormat}
                    />
                    <TableValueField
                        valueLabel="total"
                        itemData={itemData}
                        isHoursAndMinutesFormat={isHoursAndMinutesFormat}
                    />
                    <Grid item xs className={classes.notesContainer}>
                        <Tooltip title={<Typography className={classes.tooltip}>{itemData.notes}</Typography>}>
                            <Typography noWrap>{itemData.notes}</Typography>
                        </Tooltip>
                    </Grid>
                </Hidden>

                <Hidden smUp>
                    <Grid item xs={2}>
                        <BulletPoint color={dataColors[itemData.status]} type="rounded" />
                    </Grid>
                    <Grid item xs={4}>
                        <TableValueField
                            valueLabel="mobile_units"
                            itemData={itemData}
                            isHoursAndMinutesFormat={isHoursAndMinutesFormat}
                            isMobile
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TableValueField
                            valueLabel="mobile_dates"
                            itemData={itemData}
                            isHoursAndMinutesFormat={isHoursAndMinutesFormat}
                            isMobile
                        />
                    </Grid>
                </Hidden>
            </Grid>
        )
    );
};

GridContainer.propTypes = {
    itemData: PropTypes.object.isRequired,
};
