import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles, Button, Typography, Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    base: {
        width: 120,
        height: 100,
        borderRadius: '4px 4px 0 0',
        position: 'relative',
        '&:hover': {
            borderRadius: '4px 4px 0 0',
            background: theme.palette.grey[200],
        },
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 'auto',
            padding: '10px',
            borderRadius: '4px',
            margin: '10px 0',
            '&:hover': {
                borderRadius: '4px',
                background: theme.palette.grey[200],
            },
        },
    },
    childrenWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    selected: {
        boxShadow: `0 0px 3px ${theme.palette.grey.lightest}`,
        '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            height: 2,
            bottom: -2,
            background: 'white',
            zIndex: 2,
        },
        '&:hover': {
            background: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            '&:after': {
                display: 'none',
            },
        },
    },
    approved: {
        color: theme.palette.primary.main,
    },
    requested: {
        color: theme.palette.warning.main,
    },
    declined: {
        color: theme.palette.danger.main,
    },
    cancelled: {
        color: theme.palette.grey.main,
    },
    text: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 11,
        },
    },
}));

export const TimeOffTabButton = ({ id, className, number, text, classKey, selectedTab, setSelectedTab }) => {
    const classes = useStyles();

    return (
        <Button
            onClick={() => setSelectedTab(id)}
            disableRipple
            id={id}
            classes={{
                label: classes.childrenWrapper,
                root: classes.base,
            }}
            className={classnames({
                [classes.base]: true,
                [classes.selected]: selectedTab === id,
                [className]: Boolean(className),
            })}
        >
            <Hidden smDown>
                <Typography variant="h3" className={classes[classKey]}>
                    {number}
                </Typography>
            </Hidden>
            <Typography className={classes.text}>{text}</Typography>
        </Button>
    );
};

TimeOffTabButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    classKey: PropTypes.string,
    number: PropTypes.any,
    id: PropTypes.number.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
};
