import moment from 'moment';
import { timeOffStatusTypes } from '../../../../../utils/constants';

const addDaysToDate = (date, days) => moment(date).add(days, 'days').format('YYYY-MM-DD');

export const spreadApprovedTimeOffPerDay = (shopSchedule = []) =>
    shopSchedule.map((userData) => {
        const spreadTimeOff = () =>
            userData.timeOff.reduce((timeOffList, timeOff) => {
                if (timeOff.days <= 1) {
                    timeOffList.push(timeOff);

                    return timeOffList;
                }

                let count = 0;

                while (timeOff.days !== count) {
                    timeOffList.push({
                        ...timeOff,
                        days: 1,
                        startDate: addDaysToDate(timeOff.startDate, count),
                        endDate: addDaysToDate(timeOff.startDate, count + 1),
                    });

                    count = count + 1;
                }

                return timeOffList;
            }, []);

        return {
            ...userData,
            timeOff: userData.timeOff
                ? spreadTimeOff()?.filter((timeOff) => {
                      return timeOff.statusId === timeOffStatusTypes.approved;
                  })
                : [],
        };
    });
