import PropTypes from 'prop-types';
import { Route } from 'react-router';

import { LoggedInNotFound } from 'pages/LoggedInNotFound';
import { useSettings } from '../services/useSettings';

export const SettingControlledRoute = ({ children, settingString, path, ...rest }) => {
    const { settings } = useSettings();

    return (
        <Route path={path} {...rest}>
            {settings && settings[settingString].enabled ? children : <LoggedInNotFound />}
        </Route>
    );
};

SettingControlledRoute.propTypes = {
    children: PropTypes.node.isRequired,
    settingString: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};
