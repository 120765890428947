import PropTypes from 'prop-types';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import classnames from 'classnames';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { DATES_PANEL_ID } from './rotaIds';

const useStyles = makeStyles((theme) => ({
    daysPanel: {
        display: 'flex',
        height: theme.rota.daysRowHeight,
    },
    emptySpace: {
        height: '100%',
        width: theme.rota.nameColumnWidth,
        borderRight: theme.rota.border,
        borderBottom: theme.rota.border,
        [theme.breakpoints.down('md')]: {
            width: theme.rota.nameColumnWidthSmall,
        },
    },
    daysRowStyle: {
        height: '100%',
        width: `calc(100% - ${theme.rota.nameColumnWidth}px)`,
        borderBottom: theme.rota.border,
        background: theme.palette.common.white,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${theme.rota.nameColumnWidthSmall}px)`,
        },
    },
    cellsContainer: {
        display: 'flex',
        height: '100%',
        width: 'fit-content',
        position: 'relative',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            width: '200%',
        },
    },
    cellStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: theme.rota.columnWidth,
        borderRight: theme.rota.border,
        '&:last-child': {
            borderRight: 'none',
        },
    },
    textBase: {
        fontSize: 10,
    },
    todayStyle: {
        background: theme.palette.grey[300],
    },
    lastDayStyle: {
        borderRight: theme.rota.borderThick,
    },
}));

export const RotaDatesPanel = ({ dateRangeCalendar, isoWeekEnd }) => {
    const classes = useStyles();
    const { moment } = useMoment();

    return (
        <div className={classes.daysPanel}>
            <div className={classes.emptySpace} />
            <div className={classes.daysRowStyle}>
                <div className={classes.cellsContainer} id={DATES_PANEL_ID}>
                    {dateRangeCalendar.map((date) => {
                        const day = moment(date);

                        return (
                            <div
                                key={date}
                                className={classnames({
                                    [classes.cellStyle]: true,
                                    [classes.todayStyle]: day.isSame(new Date(), 'day'),
                                    [classes.lastDayStyle]: day.isoWeekday() === isoWeekEnd,
                                })}
                            >
                                <Grid item>
                                    <Typography variant="caption" style={{ fontWeight: 600 }}>
                                        {day.format('ddd')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2">
                                        <strong>{day.format('DD')}</strong>
                                    </Typography>
                                </Grid>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

RotaDatesPanel.propTypes = {
    dateRangeCalendar: PropTypes.any.isRequired,
    isoWeekEnd: PropTypes.number.isRequired,
};
