import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { RadioButtonGroup } from 'components/checkboxes/RadioButtonGroup';

import { useTranslation } from 'react-i18next';

import { useTimeOffTypes } from 'services/useHoliday';
import { selectRequestedTimeOffType } from '../requestTimeOffSelectors.js';
import { setStateToDefault, setTimeOffType } from '../requestTimeOffSlice';

const useStyles = makeStyles((theme) => ({
    radioButtonGroup: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    overflowRadioButtonGroup: {
        margin: 0,
        maxHeight: '200px',
        overflowY: 'scroll',
    },
    expansionPanel: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        border: `solid ${theme.palette.grey[400]}`,
        borderWidth: '1px 0px',
        width: '100%',
        marginBottom: '10px',
    },
    expandIcon: {
        padding: '0 20px',
    },
}));

export const SideBarContent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { timeOffTypes } = useTimeOffTypes();

    const timeOffType = useSelector(selectRequestedTimeOffType);

    const secondaryTimeOffs = timeOffTypes.length > 2 ? timeOffTypes.slice(3) : [];
    const shouldDisplayMoreOptions = secondaryTimeOffs.length > 0;

    const onClick = (event) => {
        const fullTimeOffType = timeOffTypes.find((type) => type.id === Number(event.target.value));

        dispatch(setStateToDefault());
        dispatch(setTimeOffType(fullTimeOffType));
    };

    return (
        <>
            <RadioButtonGroup
                formControlProps={{ className: classes.radioButtonGroup }}
                ariaLabel={t('requestTimeOff:time_off_types')}
                items={timeOffTypes.slice(0, 3)}
                name="time_off_type"
                labelPlacement="end"
                onChange={(event) => onClick(event)}
                value={timeOffType.id}
            />

            {shouldDisplayMoreOptions && (
                <Accordion className={classes.expansionPanel}>
                    <AccordionSummary expandIcon={<ExpandMore />} className={classes.expandIcon}>
                        <Typography>{t('requestTimeOff:other_time_off_types')}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <RadioButtonGroup
                            formControlProps={{ className: classes.overflowRadioButtonGroup }}
                            ariaLabel={t('requestTimeOff:time_off_types')}
                            items={secondaryTimeOffs}
                            name="time_off_type"
                            labelPlacement="end"
                            onChange={(event) => onClick(event)}
                            value={timeOffType.id}
                        />
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};
