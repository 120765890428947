import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { endOfMonth, format, isThisMonth, parseISO, startOfMonth } from 'date-fns';

import { RotaCell } from './RotaCell';
import { getAndTransformDayEvents } from './rotaUtils';
import { SHIFTS_PANEL_ID } from './rotaIds';
import { useSelector } from 'react-redux';
import { selectMwvDate } from 'containers/Schedule/store/scheduleSelector';
import { useStaffTimeOffForSchedule } from 'services/schedule';
import { useUser } from 'services/auth';
import { useTimeOffTypes } from 'services/useHoliday';
import { useShopIdForDatePeriod } from '../utils';

const useStyles = makeStyles({
    shiftContainer: {
        height: '100%',
        width: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
    },
    shiftContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    shiftRow: {
        display: 'flex',
    },
});

export const RotaShiftsPanel = ({ dateRangeCalendar, isoWeekEnd }) => {
    const classes = useStyles();
    const { allTimeOffTypes } = useTimeOffTypes();
    const mwvDate = useSelector(selectMwvDate);
    const {
        userDetails: { internalStaffNumber },
    } = useUser();

    const mwvStart = format(startOfMonth(parseISO(mwvDate)), 'yyyy-MM-dd');
    const mwvEnd = format(endOfMonth(parseISO(mwvDate)), 'yyyy-MM-dd');

    const { shopId } = useShopIdForDatePeriod(
        isThisMonth(parseISO(mwvDate)) ? format(parseISO(mwvDate), 'yyyy-MM-dd') : mwvStart,
        mwvEnd,
    );

    const { mwvShopTimeOff } = useStaffTimeOffForSchedule(shopId, mwvStart, mwvEnd, allTimeOffTypes);

    return (
        <div className={classes.shiftContainer} id={SHIFTS_PANEL_ID}>
            <div className={classes.shiftContent}>
                {mwvShopTimeOff.map((staff) => {
                    const isLoggedInUser = staff.internalStaffNumber === internalStaffNumber;

                    return (
                        <div
                            className={classNames({
                                [classes.shiftRow]: true,
                            })}
                            key={staff.internalStaffNumber}
                        >
                            {dateRangeCalendar.map((date) => {
                                const dayEvents = getAndTransformDayEvents(staff, date);

                                return (
                                    <RotaCell
                                        dayEvents={dayEvents}
                                        key={date}
                                        date={date}
                                        isoWeekEnd={isoWeekEnd}
                                        isLoggedInUser={isLoggedInUser}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

RotaShiftsPanel.propTypes = {
    isoWeekEnd: PropTypes.number.isRequired,
    dateRangeCalendar: PropTypes.arrayOf(PropTypes.string).isRequired,
};
