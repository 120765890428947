import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectIsMultiWeekTab } from '../menuSelector';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        background: theme.palette.grey.soft,
        height: `calc(100vh - ${theme.menu.appBarMinSize + theme.menu.footerHeight}px)`,
        marginTop: theme.menu.appBarMinSize,
        padding: 0,
        overflowY: 'auto',
    },
    whiteBackground: {
        background: theme.palette.common.white,
    },
}));

export const MainContent = ({ children }) => {
    const classes = useStyles();
    const isMultiWeekTab = useSelector(selectIsMultiWeekTab);

    return (
        <main
            id="main_content"
            className={classNames({
                [classes.content]: true,
                [classes.whiteBackground]: isMultiWeekTab,
            })}
        >
            {children}
        </main>
    );
};

MainContent.propTypes = {
    children: PropTypes.node.isRequired,
};
