import { IconButton, makeStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useSelector } from 'react-redux';
import { selectUserMenuOpen } from '../../../menuSelector';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        ...theme.menu.icon,
    },
    spacing: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            padding: 10,
        },
    },
}));

const UserMenuIcon = ({ handleUserMenuClick }) => {
    const classes = useStyles();
    const userMenuOpen = useSelector(selectUserMenuOpen);

    return (
        <IconButton
            aria-haspopup="true"
            aria-owns={userMenuOpen ? 'user-menu' : undefined}
            onClick={handleUserMenuClick}
            className={classes.spacing}
        >
            <AccountCircleIcon className={classes.icon} />
        </IconButton>
    );
};

UserMenuIcon.propTypes = {
    handleUserMenuClick: PropTypes.func.isRequired,
};

export default UserMenuIcon;
