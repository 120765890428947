import { useEffect, useState } from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';
import { languageList } from './languageList';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { FormControl, InputLabel, ListItemIcon, ListItemText, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useSetLanguagePreferences } from '../../services/translation';
import { useUser } from '../../services/auth';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    loginPageSelection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    menuSelection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loginPageDropdown: {
        minWidth: 140,
        maxWidth: 250,
    },
    menuDropdown: {
        paddingTop: theme.spacing(1),
    },
    select: {
        padding: `0 ${theme.spacing(1)}px`,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        '&:focus': {
            backgroundColor: theme.palette.common.white,
        },
    },
    iconStyle: {
        minWidth: 0,
        marginRight: theme.spacing(0.5),
    },
    label: {
        color: theme.palette.common.black,
        paddingRight: theme.spacing(2),
    },
    alignEnd: {
        textAlign: 'end',
    },
}));

export const LanguageSelection = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { pathname } = useLocation();
    const { setLanguagePreferencesMutation } = useSetLanguagePreferences();
    const { userDetails } = useUser();
    const [selectedLang, setLanguage] = useState(i18n.language.replace('_', '-'));
    const isLoginPage = pathname === '/login';
    useEffect(() => {
        const newLanguage = i18n.language.replace('_', '-');
        document.cookie = `language=${newLanguage}`;
        setLanguage(newLanguage);
    }, [i18n.language]);

    const handleChange = (event) => {
        const lang = event.target.value;
        const langBackendFormat = lang.replace('-', '_');

        if (userDetails?.firstName) {
            setLanguagePreferencesMutation.mutate({ language: langBackendFormat });
        }

        batch(() => {
            i18n.changeLanguage(lang);
            setLanguage(lang);
        });
    };

    return (
        <div className={isLoginPage ? classes.loginPageSelection : classes.menuSelection}>
            <InputLabel
                className={isLoginPage ? classnames(classes.label, classes.alignEnd) : classes.label}
                htmlFor="language-selection"
            >
                {t('portal:select_language')}
            </InputLabel>

            <FormControl variant="outlined" className={isLoginPage ? classes.loginPageDropdown : classes.menuDropdown}>
                <Select
                    data-testid="select"
                    classes={{ select: classes.select }}
                    value={selectedLang}
                    onChange={handleChange}
                    inputProps={{
                        id: 'language-selection',
                    }}
                >
                    {languageList.map(({ language, flagIcon, abbreviation }) => (
                        <MenuItem key={abbreviation} value={abbreviation}>
                            <ListItemIcon className={classes.iconStyle}>{flagIcon}</ListItemIcon>
                            <ListItemText primary={language} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
