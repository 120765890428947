import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import { useGetHoursAndMinutes, roundAndTrimNumber } from 'utils/timeFormatters';

export const useGetUnitsPerDay = () => {
    const { t } = useTranslation();

    const getHoursAndMinutes = useGetHoursAndMinutes();

    return (itemData, isHoursAndMinutesFormat) => {
        if (!isNil(itemData.dayEquivalent)) {
            if (itemData.dayEquivalent === 1) {
                return t('units:full_day');
            } else if (itemData.dayEquivalent === 0.5) {
                return t('units:half_day');
            }

            const dayEquivalent = roundAndTrimNumber(itemData.dayEquivalent);

            return t('units:days_quantity', { days: dayEquivalent });
        }

        if (isHoursAndMinutesFormat) {
            return getHoursAndMinutes(itemData.hoursPerDay);
        }

        return t('units:hours_quantity', { hours: itemData.hoursPerDay });
    };
};
