import { createTheme } from '@material-ui/core/styles';
import palette from './palette';
import { rota } from './rota';

const theme = createTheme({
    overrides: {
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    typography: {
        useNextVariants: true,
        htmlFontSize: 16,
        fontSize: 14,
        headlineBase: {
            color: palette.grey.deep,
        },
        fontFamily: ['Open Sans', 'Roboto', 'Raleway', 'Roboto Slab', 'Arial', 'sans-serif'].join(','),
        fontWeightRegular: 300,
    },
    palette,
    rota,
    menu: {
        drawerWidth: 260,
        appBarMinSize: 64,
        footerHeight: 60,
        icon: {
            fontSize: 30,
        },
    },
    link: {
        textDecoration: 'none',
        color: 'initial',
    },
    panels: {
        verticalSpace: {
            marginTop: 56,
        },
    },
    requestTimeOffForm: {
        inputStyle: {
            height: 30,
            padding: 0,
        },
        labelStyle: {
            width: 75,
        },
    },
});

export default theme;
