import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import H5 from 'components/typography/H5';

const PanelHeadline = ({ children, className, icon, ...rest }) => (
    <Grid
        container
        alignItems="center"
        spacing={2}
        style={{
            padding: '1em 0',
        }}
    >
        {icon ? <Grid item>{icon}</Grid> : null}
        <Grid item xs={icon ? false : 12}>
            <H5 style={{ marginBottom: '0.2em' }} className={className} {...rest}>
                {children}
            </H5>
        </Grid>
    </Grid>
);

PanelHeadline.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.node,
};

export default PanelHeadline;
