const selectMenuState = (state) => state.menu;

export const selectIsMultiWeekTab = (state) => selectMenuState(state).isMultiWeekTab;

export const selectDesktopMenuOpen = (state) => selectMenuState(state).desktopMenuOpen;

export const selectMobileMenuOpen = (state) => selectMenuState(state).mobileMenuOpen;

export const selectUserMenuOpen = (state) => selectMenuState(state).userMenuOpen;

export const selectMenuLinks = (state) => selectMenuState(state).menuLinks;
