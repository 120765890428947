import { makeStyles, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Close from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    mobileNavStyle: {
        height: 56,
        position: 'fixed',
        zIndex: 999,
        top: 0,
        left: 0,
        right: 0,
        boxShadow: theme.shadows[1],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    buttonStyle: {
        position: 'absolute',
        top: 4,
        left: 5,
    },
    titleStyle: {
        fontSize: '1rem',
    },
}));

export const MobileNav = ({ stage, onClickNavButton }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const IconType = stage > 0 ? <ChevronLeft /> : <Close />;

    const chooseTitle = () => {
        switch (stage) {
            case 2: {
                return t('portal:schedule');
            }
            case 1: {
                return t('common:dates');
            }
            default:
                return t('requestTimeOff:time_off_type');
        }
    };

    return (
        <div className={classes.mobileNavStyle}>
            <Typography className={classes.buttonStyle}>
                <IconButton disableRipple onClick={onClickNavButton}>
                    {IconType}
                </IconButton>
            </Typography>

            <Typography variant="caption" className={classes.titleStyle}>
                {chooseTitle()}
            </Typography>
        </div>
    );
};

MobileNav.propTypes = {
    stage: PropTypes.number.isRequired,
    onClickNavButton: PropTypes.func.isRequired,
};
