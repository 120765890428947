import { getWeekOfDatesUsingStart, normaliseErrorDetails } from 'containers/RequestTimeOff/requestContent/helpers.js';

export const getResponseErrors = (error, blockedTimeOff, selectionList) => {
    let errorData = { defaultError: true };

    switch (error.code) {
        case 'CANNOT_EXCEED_THRESHOLD':
            errorData = {
                requestError: 'requestTimeOff:errors.exceeded_balance_threshold',
                errorInDates: [],
            };
            break;
        case 'CANNOT_INTERSECT_APPROVED_OR_LOCKED_ROTA': {
            const errorStart = error.meta.wc;

            errorData = {
                errorInDates: getWeekOfDatesUsingStart(errorStart),
                requestError: 'requestTimeOff:errors.approved_or_locked_rota',
            };
            break;
        }
        case 'CANNOT_CONTAIN_DAYS_IN_BLOCKED_PERIODS':
            errorData = {
                requestError: blockedTimeOff.rejectionMessage || 'requestTimeOff:errors.blocked_periods',
            };
            break;
        case 'CANNOT_EXCEED_MINIMUM_ALLOWED_BALANCE': {
            const { meta } = error;

            errorData = {
                requestError: 'requestTimeOff:errors.minimum_allowed_balance',
                requestErrorDetails: normaliseErrorDetails(meta, selectionList),
            };
            break;
        }
        default: {
            errorData = { defaultError: true };
        }
    }

    return errorData;
};
