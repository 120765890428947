import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoadingComponent from 'components/LoadingComponent';
import ErrorMessage from 'components/ErrorMessage';
import { EntitlementExpandingPanel } from './entitlementComponents/EntitlementExpandingPanel';
import { useEntitlementByContractId } from '../../services/entitlement';

export const EntitlementWidget = ({ contract }) => {
    const { t } = useTranslation();

    const { entitlementByContractId, isEntitlementError, isEntitlementLoading } = useEntitlementByContractId(
        contract?.id,
    );

    if (isEntitlementLoading || !entitlementByContractId) {
        return (
            <div style={{ minHeight: 250 }}>
                <LoadingComponent title={t('common:loading')} />
            </div>
        );
    }

    if (isEntitlementError) {
        return <ErrorMessage message={t('time_off:errors.pots_error_message')} />;
    }

    if ([...entitlementByContractId].length === 0) {
        return <ErrorMessage message={t('time_off:errors.empty_message')} />;
    }

    return <EntitlementExpandingPanel entitlementByContractId={entitlementByContractId} />;
};

EntitlementWidget.propTypes = {
    contract: object.isRequired,
};
