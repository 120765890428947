import { useEffect } from 'react';
import { useLocation, useHistory, Route } from 'react-router-dom';
import { ShiftOfferContainer } from './ShiftOfferContainer';
import { ShiftOfferContent } from './ShiftOfferContent';
import { useAllShiftOffers } from '../../services/shiftOffer';

const useUrlParam = (param) => new URLSearchParams(useLocation().search).get(param);

export const ShiftOffer = () => {
    const { pathname } = useLocation();
    const { push } = useHistory();

    const tabValue = pathname.split('/').reverse()[0];

    const shiftOfferId = useUrlParam('id');

    const { allShiftOffers } = useAllShiftOffers();

    useEffect(() => {
        if (shiftOfferId !== null && allShiftOffers) {
            const nestedRoute =
                (allShiftOffers && allShiftOffers.find((offer) => offer.id === Number(shiftOfferId))?.status) ||
                'pending';

            push(`/shift-offers/${nestedRoute}`);
        }

        if (!shiftOfferId && tabValue === 'shift-offers') {
            push({ pathname: '/shift-offers/pending' });
        }
    }, [tabValue, push, allShiftOffers, shiftOfferId]);

    return (
        <ShiftOfferContainer>
            <Route exact path="/shift-offers/:tabValue">
                <ShiftOfferContent />
            </Route>
        </ShiftOfferContainer>
    );
};
