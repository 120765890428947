import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { TabbedWrapper } from 'components/TabbedWrapper';
import { OffersList } from './OffersList';
import { statuses } from './constants';
import { ConfirmationDialog } from './ConfirmationDialog';
import { LoggedInNotFound } from '../../pages/LoggedInNotFound';
import { useAllShiftOffers } from '../../services/shiftOffer';

export const ShiftOfferContent = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { tabValue } = useParams();

    const {
        pendingShiftOffers,
        acceptedShiftOffers,
        declinedShiftOffers,
        unavailableShiftOffers,
        isAllShiftOffersError,
    } = useAllShiftOffers();

    if (isAllShiftOffersError) {
        return null;
    }
    const handleTabChange = (event, value) => {
        push({ pathname: `/shift-offers/${value}` });
    };

    const tabs = [
        {
            name: t('shift_offer:shift_offers'),
            value: statuses.pending,
            isDisplayed: true,
        },
        {
            name: t('shift_offer:accepted_offers'),
            value: statuses.accepted,
            isDisplayed: true,
        },
        {
            name: t('shift_offer:declined_offers'),
            value: statuses.declined,
            isDisplayed: true,
        },
        {
            name: t('shift_offer:unavailable_offers'),
            value: statuses.unavailable,
            isDisplayed: true,
        },
    ];

    let offers;

    switch (tabValue) {
        case statuses.pending:
            offers = pendingShiftOffers;
            break;
        case statuses.accepted:
            offers = acceptedShiftOffers;
            break;
        case statuses.declined:
            offers = declinedShiftOffers;
            break;
        case statuses.unavailable:
            offers = unavailableShiftOffers;
            break;
        default:
            return <LoggedInNotFound />;
    }

    return (
        <>
            <TabbedWrapper tabs={tabs} tabValue={tabValue} handleTabChange={handleTabChange} color="primary" />
            {offers && <OffersList offers={offers} tabValue={tabValue} />}
            <ConfirmationDialog />
        </>
    );
};
