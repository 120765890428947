import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { useGetHoursAndMinutes } from 'utils/timeFormatters';

const useStyles = makeStyles((theme) => ({
    displayText: {
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    roleFont: {
        fontSize: 18,
    },
    spacing: {
        marginBottom: 5,
    },
}));

export const EventDetails = ({ event }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { moment } = useMoment();
    const getHoursAndMinutes = useGetHoursAndMinutes();

    const isCurrentUserTile = !event.userName;

    return (
        <>
            <Grid item xs={12} className={classes.spacing}>
                <Typography component="h1" className={classNames(classes.displayText, classes.roleFont)}>
                    <strong data-testid="event-details-role-label">
                        {event.role.key ? t(event.role.key) : event.role}
                    </strong>
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.spacing}>
                <Typography className={classes.displayText}>
                    {event.timeOff
                        ? event.status
                        : `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
                </Typography>
            </Grid>
            {!event.timeOff && (
                <>
                    {isCurrentUserTile && (
                        <Typography className={classes.displayText} style={{ marginBottom: 12 }}>
                            {event.unpaidHours > 0
                                ? t('schedule:time_paid_time_unpaid_break', {
                                      paid: getHoursAndMinutes(event.paidHours),
                                      unpaid: getHoursAndMinutes(event.unpaidHours),
                                  })
                                : t('schedule:time_paid', {
                                      time: getHoursAndMinutes(event.paidHours),
                                  })}
                        </Typography>
                    )}
                    {event.departmentName && (
                        <Grid item xs={12} className={classes.spacing}>
                            <Typography className={classes.displayText}>
                                <strong>{t('portal:department_colon')}</strong>
                                <span data-testid="event-details-department-label">{event.departmentName}</span>
                            </Typography>
                        </Grid>
                    )}

                    {event.shopName && (
                        <Grid item xs={12} className={classes.spacing}>
                            <Typography className={classes.displayText}>
                                <strong>{t('portal:shop_colon')}</strong>
                                <span data-testid="event-details-shop-label">{event.shopName}</span>
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

EventDetails.propTypes = {
    event: PropTypes.shape({
        role: PropTypes.string.isRequired,
        timeOff: PropTypes.bool.isRequired,
        status: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
        shopName: PropTypes.string,
        departmentName: PropTypes.string,
        paidHours: PropTypes.number,
        unpaidHours: PropTypes.number,
        userName: PropTypes.string,
    }),
};
