import { apiClient } from 'utils/data/api';
import { camelToSnakeObjectKeys } from 'utils/strings/helpers';

export const updatePreferences = (type, key, value) =>
    apiClient().patch(
        '/self',
        camelToSnakeObjectKeys({
            preferences: {
                [type]: {
                    [key]: value,
                },
            },
        }),
    );

export const bulkUpdatePreferences = (key, values) =>
    apiClient().patch(
        '/self',
        camelToSnakeObjectKeys({
            preferences: {
                [key]: values,
            },
        }),
    );

export const updateEmail = (email) =>
    apiClient().patch('/self', {
        email,
    });

export const updateMobile = (mobileCountry, mobile) =>
    apiClient().patch('/self', {
        mobile,
        mobile_country: mobileCountry,
    });
