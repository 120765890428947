import moment from 'moment';

const findTodaysShift = (data) =>
    data
        .filter((day) => moment(day.start).format('d') === moment().format('d'))
        .sort((a, b) => moment(a.start).diff(moment(b.start)));

const findNextShift = (data) =>
    data
        .filter((day) => moment(day.start).isAfter(moment(), 'day'))
        .sort((a, b) => moment(a.start).diff(moment(b.start)));

const findPastShift = (data) =>
    data
        .filter((day) => moment(day.start).isBefore(moment(), 'day'))
        .sort((a, b) => moment(a.start).diff(moment(b.start)));

export const calculateScrollToTime = (startDate, data) => {
    const todaysShift = findTodaysShift(data);
    const nextShift = findNextShift(data);
    const pastShift = findPastShift(data);

    if (todaysShift.length) {
        return new Date(todaysShift[0].start);
    }

    if (nextShift.length) {
        return new Date(nextShift[0].start);
    }

    if (pastShift.length) {
        return new Date(pastShift[0].start);
    }

    return new Date(startDate);
};
