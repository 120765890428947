import { apiClient } from '../utils/data/api';
import axios from 'axios';
import { timeOffStatusTypes } from 'utils/constants';
const mapById = (stack) =>
    stack.reduce((res, el) => {
        res[el.id] = el;

        return res;
    }, {});

export const getEntitlement = (start, end) => apiClient().get(`holiday-entitlement?start=${start}&end=${end}`);

export const requestTimeOff = (data) => apiClient().post('time-off', data);

export const getUserTimeOff = (start, end) => apiClient().get(`time-off?start=${start}&end=${end}`);

export const getBlockedTimeOff = () => apiClient().get('time-off-blocks');

const hydrateTimeOff = async (allTimeOffTypes, cb) => {
    const [timeOffRequests] = await axios.all([cb()]);
    const types = mapById(allTimeOffTypes);

    const status = Object.values(timeOffStatusTypes).reduce((acc, elem, index) => {
        acc[elem] = Object.keys(timeOffStatusTypes)[index];

        return acc;
    }, {});

    return timeOffRequests.data.data.map((timeOff) => ({
        ...timeOff,
        TimeOffType: types[timeOff.typeId],
        status: status[timeOff.statusId],
    }));
};

export const getHydratedUserTimeOff = (start, end, allTimeOffTypes) =>
    hydrateTimeOff(allTimeOffTypes, () => getUserTimeOff(start, end));

export const getUserContracts = (startDay, endDay) =>
    apiClient().get(`contracts/covering?start=${startDay}&end=${endDay}`);

export const sendCancellation = (timeOffId) => apiClient().post(`time-off/${timeOffId}/cancel`);
