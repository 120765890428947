import PropTypes from 'prop-types';
import { makeStyles, FormControl, InputLabel, Input, InputAdornment, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '255px',
        fontSize: '16px',
        paddingLeft: '5px',
        borderBottom: `2px solid ${theme.palette.grey.light}`,
        [theme.breakpoints.up('sm')]: {
            width: '300px',
        },
        '&:focus': {
            borderBottom: `2px solid ${theme.palette.secondary.light}`,
            fontSize: '100%',
        },
        '&:select': {
            fontSize: '100%',
        },
    },
    inputLabel: {
        fontSize: '16px',
        fontWeight: '300',
        color: theme.palette.grey.main,
    },
    error: {
        width: '255px',
        fontSize: '16px',
        paddingLeft: '5px',
        borderBottom: `2px solid ${theme.palette.danger.main}`,
        [theme.breakpoints.up('md')]: {
            width: '300px',
        },
    },
    iconButtonRoot: {
        '&:hover': {
            background: 'none',
        },
    },
    inputAdornment: {
        marginLeft: '-40px',
    },
}));

const FormInput = ({ id, onClick, error, label, ...rest }) => {
    const classes = useStyles();

    return (
        <FormControl margin="normal">
            {label && (
                <InputLabel htmlFor={id} className={classes.inputLabel} error={error}>
                    {label}
                </InputLabel>
            )}

            <Input
                disableUnderline
                id={id}
                name={id}
                classes={{ input: error ? classes.error : classes.input }}
                {...rest}
                endAdornment={
                    error && (
                        <InputAdornment className={classes.inputAdornment} position="end">
                            <IconButton
                                onClick={(event) => onClick(event, id)}
                                className={classes.iconButtonRoot}
                                disableRipple
                                tabIndex="-1"
                                data-testid="login-form-input-error-adornment"
                            >
                                <Clear color="error" />
                            </IconButton>
                        </InputAdornment>
                    )
                }
                data-testid="login-form-input-text-input"
            />
        </FormControl>
    );
};

FormInput.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    error: PropTypes.bool,
    label: PropTypes.string,
};

FormInput.defaultProps = {
    type: null,
    label: '',
};

export default FormInput;
