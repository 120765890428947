import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    baseStyles: {
        ...theme.typography.headlineBase,
    },
}));

const H5 = ({ children, className, ...rest }) => {
    const classes = useStyles();

    return (
        <Typography className={classnames(classes.baseStyles, className)} {...rest} variant="h5">
            {children}
        </Typography>
    );
};

H5.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default H5;
