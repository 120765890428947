import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    cannotBeFound: {
        fontSize: '36px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
    },
    oopsContainer: {
        position: 'relative',
    },
    oops: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '64px',
        fontWeight: 300,
        backgroundColor: theme.palette.common.white,
        padding: '15px 0',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            padding: '5px 0',
        },
    },
    background: {
        fontSize: '400px',
        fontWeight: 800,
        color: theme.palette.primary.light,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '160px',
        },
    },
    sorry: {
        fontSize: '24px',
        fontWeight: 300,
        maxWidth: '700px',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            maxWidth: '300px',
        },
    },
    sorryContainer: {
        marginTop: '40px',
    },
}));

export const ClientNotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.oopsContainer}>
                <Typography align="center" className={classes.background}>
                    {t('portal:page_client_not_found.error_code')}
                </Typography>
                <Typography align="center" className={classes.oops}>
                    {t('portal:oops')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" className={classes.cannotBeFound}>
                    {t('portal:page_client_not_found.header')}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.sorryContainer}>
                <Typography align="center" className={classes.sorry}>
                    {t('portal:page_client_not_found.error_info')}
                </Typography>
            </Grid>
        </Grid>
    );
};

ClientNotFound.propTypes = {
    classes: PropTypes.object,
};
