import moment from 'moment';
import { roundAndTrimNumber } from 'utils/timeFormatters';

const momentFormat = 'YYYY-MM-DD HH:mm';

const getHoursPerDay = (start, end) =>
    Number(roundAndTrimNumber(moment(end, momentFormat).diff(moment(start, momentFormat), 'minutes') / 60));

export const spreadNightShiftsPerDay = (shiftsArray) =>
    shiftsArray.reduce((splitShiftsArray, shift) => {
        const isDayOff = shift.type === 'day_off';

        const isNightShift = moment(shift.end, 'YYYY-MM-DD').diff(moment(shift.start, 'YYYY-MM-DD'), 'days') > 0;

        if (isNightShift && !isDayOff) {
            const endDateOfFirstHalf = moment(shift.start).endOf('day').format(momentFormat);

            splitShiftsArray.push({
                ...shift,
                end: endDateOfFirstHalf,
                hoursPerDay: getHoursPerDay(shift.start, endDateOfFirstHalf),
            });

            const startDateOfFirstHalf = moment(shift.end).startOf('day').format(momentFormat);

            splitShiftsArray.push({
                ...shift,
                start: startDateOfFirstHalf,
                hoursPerDay: getHoursPerDay(startDateOfFirstHalf, shift.end),
            });
        } else {
            splitShiftsArray.push(shift);
        }

        return splitShiftsArray;
    }, []);
