import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Grid,
    InputLabel,
    OutlinedInput,
    Select,
    FormControl,
    MenuItem,
    makeStyles,
    useTheme,
    FormHelperText,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useTranslation } from 'react-i18next';
import { DaySelector } from './DaySelector';

import { useIsMobile } from 'utils/components/helpers';
import { roundAndTrimNumber, fromHoursToDecimals } from 'utils/timeFormatters';
import { getAvailableRevisions, getIsTimeOffTypeHoursOnly } from '../utils';

import {
    selectSelectionList,
    selectUserRequests,
    selectRequestedStartDate,
    selectRequestedEndDate,
    selectRequestedTimeOffType,
} from '../requestTimeOffSelectors';
import { setContractName, removeUserRequest, setUpdateTime } from '../requestTimeOffSlice';
import { useSettings } from 'services/useSettings';

const useStyles = makeStyles((theme) => ({
    selectorRowStyle: {
        position: 'relative',
        marginTop: 10,
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
            paddingRight: 20,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 30,
            paddingRight: 0,
        },
    },
    panel: {
        backgroundColor: theme.palette.grey.lighter,
        padding: 10,
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent',
            padding: 0,
        },
    },
    hoursAndButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            justifyContent: 'flex-start',
        },
    },
    buttonArea: {
        position: 'absolute',
        display: 'flex',
        right: -50,
        top: 0,
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey.light,
        [theme.breakpoints.down('md')]: {
            right: 0,
            width: 30,
            height: 30,
        },
        [theme.breakpoints.down('sm')]: {
            top: 40,
            width: 30,
            height: 30,
            color: theme.palette.grey.lightest,
        },
    },
    labelStyle: {
        width: theme.requestTimeOffForm.labelStyle.width,
    },
    hoursLabelStyle: {
        paddingRight: 6,
        [theme.breakpoints.down('sm')]: {
            width: theme.requestTimeOffForm.labelStyle.width,
            paddingRight: 3,
        },
    },
    formControlStyle: {
        flexGrow: 1,
        maxWidth: 250,
        [theme.breakpoints.down('md')]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 193,
        },
    },
    select: {
        width: '100%',
        marginTop: '10px',
        fontSize: '16px',
        marginBottom: '16px',
        backgroundColor: theme.palette.grey[50],
    },
    durationContainer: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 146,
    },
    separator: {
        padding: 10,
    },
}));

export const ContractSelector = ({ selectedRequest, index, displayedRequestsNumber }) => {
    const [isButtonShown, toggleButton] = useState(false);
    const [hoursPerDayInput, setHoursPerDayInput] = useState('');

    const { inputStyle } = useTheme().requestTimeOffForm;
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);

    const { hoursAndMinsPerDay, hoursPerDay, relatedContractId, daysPerDay, revisionName } = selectedRequest;

    const selectionList = useSelector(selectSelectionList);
    const userRequests = useSelector(selectUserRequests);

    const availableRevisions = getAvailableRevisions({ selectionList, userRequests });
    const timeOffType = useSelector(selectRequestedTimeOffType);
    const isHoursOnly = getIsTimeOffTypeHoursOnly(timeOffType);
    const selectedUnitType = selectionList.find(({ id }) => id === relatedContractId)?.unitType || 'days';
    const unitType = isHoursOnly ? 'hours' : selectedUnitType;

    const isNameDisable = selectionList.length === 1;
    const selectedHours = showHoursWithMinutes ? hoursAndMinsPerDay : hoursPerDay;

    const contractInputId = `contract-input-${relatedContractId}`;
    const hoursInputId = `hours-input-${relatedContractId}`;
    const minutesInputId = `minutes-input-${relatedContractId}`;
    const daysInputId = `days-input-${relatedContractId}`;

    const inputProps = showHoursWithMinutes ? { style: { padding: '0px 5px' } } : { min: 0, max: 24, step: 0.01 };
    let [inputHours, inputMins] = showHoursWithMinutes && unitType === 'hours' ? selectedHours.split(':') : ['0', '0'];

    const [totalDays, setTotalDays] = useState(0);
    const [totalHours, setTotalHours] = useState(0);

    useEffect(() => {
        const days = moment(endDate).diff(moment(startDate), 'days') + 1;
        const getSelectedHours = showHoursWithMinutes ? fromHoursToDecimals(hoursAndMinsPerDay) : hoursPerDay;
        const getSelectedDays = daysPerDay;

        const getTotalHours = roundAndTrimNumber(getSelectedHours * days) || 0;
        const getTotalDays = roundAndTrimNumber(getSelectedDays * days) || 0;

        setTotalHours(getTotalHours);
        setTotalDays(getTotalDays);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, hoursPerDay, hoursAndMinsPerDay, daysPerDay, setTotalHours, setTotalDays]);

    useEffect(() => {
        if (!hoursPerDayInput) {
            setHoursPerDayInput(hoursPerDay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoursPerDay, setHoursPerDayInput]);

    const showButton = () => toggleButton(true);
    const hideButton = () => toggleButton(false);

    const handleContractName = (event) => dispatch(setContractName({ id: event.target.value, index }));
    const onTimeUpdate = (requestProps) => {
        setHoursPerDayInput(requestProps.hrs);
        dispatch(setUpdateTime({ showHoursWithMinutes, requestProps, index, selectedRequest, unitType }));
    };
    const handleDaysChange = ({ target }) => onTimeUpdate({ hrs: 0, days: target.value });

    const updateTime = (event, type) => {
        const value = Number(event.target.value);

        if (type === 'h') {
            inputHours = value > 23 ? 23 : value.toString().padStart(2, '0');
        } else {
            inputMins = value > 59 ? 59 : value.toString().padStart(2, '0');
        }

        const hoursAndMins = `${inputHours}:${inputMins}`;

        onTimeUpdate({ hrs: hoursAndMins, days: 0 });
    };

    return (
        <div className={classes.selectorRowStyle} onMouseOver={showButton} onMouseOut={hideButton}>
            <Grid container className={classes.panel}>
                <Grid item container sm={12} md={8} lg={7} alignItems="center">
                    <InputLabel htmlFor={contractInputId} className={classes.labelStyle}>
                        {t('portal:contract')}:
                    </InputLabel>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <Select
                            id={contractInputId}
                            name="contract"
                            type="text"
                            data-testid="contract-select"
                            value={relatedContractId}
                            onChange={handleContractName}
                            disabled={isNameDisable}
                            style={inputStyle}
                            input={<OutlinedInput style={inputStyle} error={!relatedContractId} />}
                        >
                            <MenuItem value={relatedContractId}>{revisionName}</MenuItem>
                            {availableRevisions.map((revision) => (
                                <MenuItem key={revision.id} value={revision.id}>
                                    {revision.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={4} lg={5} className={classes.hoursAndButton}>
                    {unitType === 'hours' && (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <InputLabel htmlFor={hoursInputId} className={classes.hoursLabelStyle}>
                                    {t('portal:hours_per_day')}:
                                </InputLabel>
                                {showHoursWithMinutes ? (
                                    <div className={classes.durationContainer}>
                                        <OutlinedInput
                                            id={hoursInputId}
                                            style={{ ...inputStyle }}
                                            onChange={(e) => updateTime(e, 'h')}
                                            error={!relatedContractId}
                                            type="number"
                                            name="hours"
                                            inputProps={{ min: 0, max: 24, step: 1 }}
                                            value={inputHours}
                                        />
                                        <div className={classes.separator}> : </div>
                                        <OutlinedInput
                                            id={minutesInputId}
                                            style={{ ...inputStyle }}
                                            onChange={(e) => updateTime(e, 'm')}
                                            error={!relatedContractId}
                                            type="number"
                                            name="mins"
                                            inputProps={{ min: 0, max: 59, step: 1 }}
                                            value={inputMins}
                                        />
                                    </div>
                                ) : (
                                    <OutlinedInput
                                        id={hoursInputId}
                                        name="hours"
                                        onChange={({ target }) => onTimeUpdate({ hrs: target.value, days: 0 })}
                                        style={{ ...inputStyle, width: '90px' }}
                                        error={!relatedContractId}
                                        type="number"
                                        value={hoursPerDayInput.toString()}
                                        inputProps={inputProps}
                                    />
                                )}
                            </div>
                            <FormHelperText>
                                {t('requestTimeOff:helper_text.total_hours', { totalHours })}
                            </FormHelperText>
                        </div>
                    )}
                    {unitType === 'days' && (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DaySelector
                                    daysInputId={daysInputId}
                                    isContractSelector
                                    value={daysPerDay}
                                    onChange={handleDaysChange}
                                />
                            </div>
                            <FormHelperText>{t('requestTimeOff:helper_text.total_days', { totalDays })}</FormHelperText>
                        </div>
                    )}
                </Grid>
            </Grid>
            {displayedRequestsNumber > 1 && (
                <div
                    className={classes.buttonArea}
                    onMouseOver={showButton}
                    onMouseOut={hideButton}
                    data-testid="remove-contract-button"
                >
                    <RemoveCircleIcon
                        style={{ cursor: 'pointer', opacity: isButtonShown || isMobile ? 1 : 0 }}
                        onClick={() => {
                            setHoursPerDayInput('');
                            dispatch(removeUserRequest({ selectorIndex: index }));
                        }}
                    />
                </div>
            )}
        </div>
    );
};

ContractSelector.propTypes = {
    index: PropTypes.number.isRequired,
    selectedRequest: PropTypes.shape({
        revisionName: PropTypes.string.isRequired,
        hoursPerDay: PropTypes.number.isRequired,
        hoursAndMinsPerDay: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
        relatedContractId: PropTypes.number.isRequired,
        daysPerDay: PropTypes.number,
    }).isRequired,
    displayedRequestsNumber: PropTypes.number.isRequired,
};
