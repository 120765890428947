import { createSlice } from '@reduxjs/toolkit';
import { fromDecimalsToHours, fromHoursToDecimals } from 'utils/timeFormatters';

const initialState = {
    showTooltip: false,
    timeOffType: {},
    startDate: null,
    endDate: null,
    isAllButtonDisabled: false,
    notes: '',
    userRequests: [],
    requestedHours: '',
    requestedHrsAndMins: '00:00',
    requestedDays: 1,
    selectionList: [],
    isRequestError: false,
    requestError: '',
    datePickerBlockedOrApprovedError: '',
    requestErrorDetails: {},
    errorInDates: [],
    isRequestValid: false,
};

const requestTimeOffSlice = createSlice({
    name: 'requestTimeOff',
    initialState,
    reducers: {
        setStateToDefault(state) {
            const clearStateKeys = Object.keys(state).filter((key) => key !== 'selectionList');

            clearStateKeys.forEach((key) => {
                state[key] = initialState[key];
            });
        },
        setTimeOffType(state, { payload }) {
            state.timeOffType = payload;
        },
        setShowTooltip(state, { payload }) {
            state.showTooltip = payload;
        },
        setDates(state, { payload }) {
            state.startDate = payload.startDate;
            state.endDate = payload.endDate ? payload.endDate : payload.startDate;
        },
        setNotes(state, { payload }) {
            state.notes = payload;
        },
        setSelectionList(state, { payload }) {
            state.selectionList = payload;
        },
        setRequestedTimeOff(state, { payload }) {
            const { hours = 0, hoursAndMinsPerDay = '00:00', days = 1, unitType } = payload;

            state.requestedHours = hours;
            state.requestedHrsAndMins = hoursAndMinsPerDay;
            state.requestedDays = days;

            state.requestError = '';
            state.isRequestError = false;
            state.requestErrorDetails = {};

            const currentRequests = [...state.userRequests];
            const alreadyExistsIndex = currentRequests.findIndex(
                (request) => request.relatedContractId === state.selectionList[0]?.id,
            );

            const newRequest = {
                revisionName: '',
                hoursPerDay: hours ? hours : 0,
                relatedContractId: state.selectionList[0]?.id,
                selectorIndex: 0,
                hoursAndMinsPerDay,
                daysPerDay: days,
                unitType: unitType || 'hours',
            };

            if (alreadyExistsIndex > -1) {
                state.userRequests[alreadyExistsIndex] = newRequest;
            } else {
                state.userRequests = [...state.userRequests, newRequest];
            }
        },
        updateUserRequest(state, { payload }) {
            const { daysPerDay, selectorIndex, hoursAndMinsPerDay, unitType } = payload;

            const editedRequests = [...state.userRequests];
            const isUnitTypeDays = unitType === 'days';

            editedRequests[selectorIndex] = {
                ...payload,
                hoursAndMinsPerDay: isUnitTypeDays ? `${daysPerDay}` : hoursAndMinsPerDay,
            };

            state.userRequests = editedRequests;

            state.requestError = '';
            state.isRequestError = false;
            state.requestErrorDetails = {};
        },
        setContractName(state, { payload }) {
            const { id, index } = payload;
            const { name, defaultHours, unitType } = state.selectionList.find((option) => option.id === id);
            const hoursAndMinsPerDay = fromDecimalsToHours(defaultHours);

            state.userRequests[index] = {
                selectorIndex: index,
                relatedContractId: id,
                revisionName: name,
                hoursPerDay: defaultHours,
                hoursAndMinsPerDay,
                daysPerDay: 1,
                unitType,
            };

            state.requestError = '';
            state.isRequestError = false;
            state.requestErrorDetails = {};
        },
        removeUserRequest(state, { payload }) {
            const { selectorIndex } = payload;
            const editedRequest = [...state.userRequests];

            editedRequest.splice(selectorIndex, 1);
            state.userRequests = editedRequest;

            state.requestError = '';
            state.isRequestError = false;
            state.requestErrorDetails = {};
        },
        setUpdateTime(state, { payload }) {
            const { showHoursWithMinutes, requestProps, index, selectedRequest, unitType } = payload;
            const { revisionName, relatedContractId } = selectedRequest;
            const { days, hrs } = requestProps;

            const hoursPerDay = showHoursWithMinutes ? fromHoursToDecimals(hrs) : Number(hrs);
            const hoursAndMinsPerDay = showHoursWithMinutes ? hrs : fromDecimalsToHours(hrs);

            state.userRequests[index] = {
                selectorIndex: index,
                relatedContractId,
                revisionName,
                hoursPerDay,
                hoursAndMinsPerDay,
                daysPerDay: days,
                unitType,
            };

            state.requestError = '';
            state.isRequestError = false;
            state.requestErrorDetails = {};
        },
        setBlockedErrors(state, { payload }) {
            state.datePickerBlockedOrApprovedError = payload;
            state.isRequestValid = false;
        },
        setErrorInDates(state, { payload }) {
            state.errorInDates = payload;
            state.isRequestValid = false;
        },
        verifyRequest(state) {
            const { maxHoursPerDay, minHoursPerDay } = state.timeOffType;

            const onFailedValidation = (errorText) => {
                state.requestError = errorText;
                state.isRequestError = true;
                state.errorInDates = [];
            };

            const totalRequestedHrs = state.userRequests.reduce((allHrs, request) => {
                if (request.relatedContractId && request.hoursPerDay > 0) {
                    return allHrs + request.hoursPerDay;
                }

                return allHrs;
            }, 0);

            const checkRequestsAreValid = state.userRequests.map((request) => {
                if (request.unitType === 'days') {
                    if (!request.daysPerDay) {
                        onFailedValidation({ label: 'requestTimeOff:errors.days_must_be_included', hours: 0 });

                        return false;
                    }
                } else {
                    if (minHoursPerDay && totalRequestedHrs < minHoursPerDay) {
                        onFailedValidation({ label: 'requestTimeOff:errors.min_hours_label', hours: minHoursPerDay });

                        return false;
                    }

                    if (maxHoursPerDay && totalRequestedHrs > maxHoursPerDay) {
                        onFailedValidation({ label: 'requestTimeOff:errors.max_hours_label', hours: maxHoursPerDay });

                        return false;
                    }
                }

                return true;
            });

            state.isRequestValid = checkRequestsAreValid.every((isRequestValid) => isRequestValid);
        },
        setResponseErrors(state, { payload }) {
            const { requestError = '', errorInDates = [], requestErrorDetails = {} } = payload;

            state.isRequestError = true;
            state.requestError = requestError;
            state.errorInDates = errorInDates;
            state.requestErrorDetails = requestErrorDetails;
        },
        clearRequestError(state) {
            state.isRequestError = false;
            state.requestError = '';
        },
    },
});

export const {
    reducer: requestTimeOffReducer,
    actions: {
        setStateToDefault,
        setTimeOffType,
        setShowTooltip,
        setDates,
        setNotes,
        setRequestedTimeOff,
        updateUserRequest,
        setSelectionList,
        setContractName,
        removeUserRequest,
        setUpdateTime,
        setBlockedErrors,
        setErrorInDates,
        verifyRequest,
        setResponseErrors,
        clearRequestError,
    },
} = requestTimeOffSlice;
