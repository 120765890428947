const validateColour = (color) => {
    const hasHash = color.startsWith('#');
    return hasHash ? color : `#${color}`;
};

export const hydrateTimeOffWithHexColour = (allTimeOff, allTimeOffTypes) =>
    allTimeOff.map((timeOff) => {
        const defaultTimeOff = allTimeOffTypes.find((type) => type.id === -1);

        if (timeOff.typeId) {
            const timeOffType = allTimeOffTypes.find((type) => timeOff.typeId === type.id);
            if (timeOffType) {
                const bgColour = timeOffType.backgroundColor ?? '';
                return {
                    ...timeOff,
                    name: timeOff.type,
                    type: timeOffType.type,
                    hexColor: bgColour.length > 0 ? validateColour(bgColour) : '#ffffff',
                };
            }

            return {
                ...timeOff,
                name: timeOff.type,
                type: '',
                hexColor: '#ffffff',
            };
        }

        return {
            ...timeOff,
            name: defaultTimeOff ? defaultTimeOff.name : timeOff.type,
            type: timeOff.type,
            hexColor: defaultTimeOff ? defaultTimeOff.backgroundColor : '#ffffff',
        };
    });
