import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import LoadingComponent from 'components/LoadingComponent';
import ErrorMessage from 'components/ErrorMessage';
import { useAllShiftOffers } from '../../services/shiftOffer';

const useStyles = makeStyles((theme) => ({
    errorMessage: {
        marginTop: '30vh',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export const ShiftOfferContainer = ({ children }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { isAllShiftOffersLoading, isAllShiftOffersError } = useAllShiftOffers();

    if (isAllShiftOffersLoading) {
        return <LoadingComponent style={{ marginTop: '30vh' }} title={t('common:loading')} />;
    }

    if (isAllShiftOffersError) {
        return (
            <ErrorMessage
                message={t('common:something_went_wrong_please_refresh')}
                className={classes.errorMessage}
                alignText="center"
            />
        );
    }

    return children;
};

ShiftOfferContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
