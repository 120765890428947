import { Link } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import DefaultButton from 'components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '80%',
        padding: '15px',
    },
    header: {
        color: theme.palette.primary.light,
        fontSize: '190px',
        fontWeight: 800,
        [theme.breakpoints.down('xs')]: {
            fontSize: '95px',
        },
    },
    subHeadingGrid: {
        maxWidth: '600px',
    },
    subHeading: {
        textAlign: 'center',
    },
    buttonGrid: {
        marginTop: '50px',
    },
    link: {
        textDecoration: 'none',
    },
    button: {
        height: '52px',
        width: '200px',
    },
}));

export const LoggedInNotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
            className={classes.rootContainer}
        >
            <Grid item>
                <Typography className={classes.header}>{t('portal:oops').toUpperCase()}</Typography>
            </Grid>
            <Grid item className={classes.subHeadingGrid}>
                <Typography className={classes.subHeading} variant="h6">
                    <strong>{t('portal:page_logged_in_not_found.header')}</strong>
                </Typography>
                <Typography className={classes.subHeading} variant="body1">
                    {t('portal:page_logged_in_not_found.error_info')}
                </Typography>
            </Grid>
            <Grid item className={classes.buttonGrid}>
                <Link to="/" className={classes.link}>
                    <DefaultButton variant="contained" color="primary" className={classes.button}>
                        {t('portal:page_logged_in_not_found.redirect_btn')}
                    </DefaultButton>
                </Link>
            </Grid>
        </Grid>
    );
};
