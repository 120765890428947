import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paginationDateIndex: null,
    defaultIndex: null,
    startDate: null,
    endDate: null,
    dialogItem: null,
    isMobileTimeOffDialogOpen: false,
    isConfirmDialogOpen: false,
};

const timeOffSlice = createSlice({
    name: 'timeOff',
    initialState,
    reducers: {
        setPaginationDateIndex(state, { payload }) {
            state.paginationDateIndex = payload;
        },
        setDefaultIndex(state, { payload }) {
            state.defaultIndex = payload;
        },
        setStartDate(state, { payload }) {
            state.startDate = payload;
        },
        setEndDate(state, { payload }) {
            state.endDate = payload;
        },
        setMobileTimeOffDialogOpen(state, { payload }) {
            state.isMobileTimeOffDialogOpen = payload;
        },
        toggleConfirmDialog(state, { payload }) {
            state.isConfirmDialogOpen = payload;
        },
        setDialogItem(state, { payload }) {
            state.dialogItem = payload;
        },
    },
});

export const {
    reducer: timeOffReducer,
    actions: {
        setPaginationDateIndex,
        setDefaultIndex,
        setStartDate,
        setEndDate,
        setMobileTimeOffDialogOpen,
        toggleConfirmDialog,
        setDialogItem,
    },
} = timeOffSlice;
