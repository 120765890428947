import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Drawer, makeStyles } from '@material-ui/core';
import MenuLinkList from './components/MenuLinkList';
import { useSelector } from 'react-redux';
import { selectMenuLinks, selectDesktopMenuOpen } from '../menuSelector';

const useStyles = makeStyles((theme) => ({
    drawerBackground: {
        marginTop: theme.spacing(4),
        background: theme.palette.primary.dark,
        border: 'none',
    },
    drawer: {
        width: theme.menu.drawerWidth,
        overflowX: 'hidden',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: theme.menu.drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.menu.appBarMinSize,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    removeGutter: {
        paddingTop: 0,
    },
}));

const DrawerDesktop = ({ id }) => {
    const classes = useStyles();

    const desktopMenuOpen = useSelector(selectDesktopMenuOpen);
    const menuLinks = useSelector(selectMenuLinks);

    return (
        <Drawer
            id={id}
            variant="permanent"
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: desktopMenuOpen,
                [classes.drawerClose]: !desktopMenuOpen,
            })}
            classes={{
                paper: classNames(classes.drawerBackground, {
                    [classes.drawerOpen]: desktopMenuOpen,
                    [classes.drawerClose]: !desktopMenuOpen,
                }),
            }}
            open={desktopMenuOpen}
        >
            <div className={classes.toolbar} />

            <MenuLinkList className={classes.removeGutter} menuOrigin="desktop" menuLinks={menuLinks.main} />
        </Drawer>
    );
};

DrawerDesktop.propTypes = {
    id: PropTypes.string.isRequired,
};

export default DrawerDesktop;
