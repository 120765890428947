import { getMostContractedRevision } from './getCurrentRevision';

export const getUnitType = (entitlement, request, allContracts, selectionList) => {
    if (!entitlement.enabled) {
        const { contractTypeTimeOffUnit } = getMostContractedRevision(allContracts);

        return contractTypeTimeOffUnit;
    }

    const { relatedContractId } = request;

    return selectionList.find(({ id }) => id === relatedContractId)?.unitType || 'days';
};
