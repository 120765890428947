import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedOfferID: '',
    selectedOfferNewStatus: '',
    isConfirmationDialogOpen: false,
};

const shiftOfferSlice = createSlice({
    name: 'shiftOffer',
    initialState,
    reducers: {
        setSelectedOffer(state, { payload }) {
            state.isConfirmationDialogOpen = true;
            state.selectedOfferID = payload.selectedOfferID;
            state.selectedOfferNewStatus = payload.selectedOfferNewStatus;
        },
        closeConfirmationDialog(state) {
            state.isConfirmationDialogOpen = false;
        },
    },
});

export const {
    reducer: shiftOfferReducer,
    actions: { setSelectedOffer, closeConfirmationDialog },
} = shiftOfferSlice;
