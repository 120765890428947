import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, makeStyles } from '@material-ui/core';
import { VARIABLE_AVAILABLE, VARIABLE_UNAVAILABLE } from 'utils/constants';
import { calcCanEditAvailability } from 'containers/Schedule/utils';
import { AvailabilityIcon } from './AvailabilityIcon';
import { useUnavailableDaysForUser } from 'services/availability';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { useDispatch, useSelector } from 'react-redux';
import { openAvailabilityDialog } from 'containers/Schedule/store/availabilitySlice';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    button: ({ canEditAvailability }) => ({
        width: '100%',
        minWidth: 'auto',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
        cursor: canEditAvailability ? 'pointer' : 'default',
        opacity: canEditAvailability ? 1 : 0.35,
        padding: 0,
        pointerEvents: 'auto !important',
    }),
}));

export const AvailabilityHeaderIcon = ({ date, scheduleEvents }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const { unavailableDays } = useUnavailableDaysForUser(startDate, endDate, true);
    const isUnavailable = unavailableDays.includes(date);

    const canEditAvailability = calcCanEditAvailability(date, scheduleEvents);

    const classes = useStyles({ canEditAvailability });

    const onClick = () => {
        if (canEditAvailability) {
            dispatch(
                openAvailabilityDialog({
                    date: moment(date).format('YYYY-MM-DD'),
                    currentStatus: isUnavailable ? VARIABLE_UNAVAILABLE : VARIABLE_AVAILABLE,
                }),
            );
        }
    };

    return (
        <Button
            className={classes.button}
            title={isUnavailable ? t('availability:unavailable') : t('availability:available')}
            onClick={onClick}
            disabled={!canEditAvailability}
            variant="text"
        >
            <AvailabilityIcon status={isUnavailable ? VARIABLE_UNAVAILABLE : VARIABLE_AVAILABLE} />
        </Button>
    );
};

AvailabilityHeaderIcon.propTypes = {
    date: PropTypes.string.isRequired,
    scheduleEvents: PropTypes.array.isRequired,
};
