import { Grid, makeStyles } from '@material-ui/core';
import FormHeader from './loginForm/FormHeader';
import { LoginFormContent } from './loginForm/LoginFormContent';
import { FormFooter } from './loginForm/FormFooter';
import { NewPassword } from './loginForm/NewPassword';
import { ResetPassword } from './loginForm/ResetPassword';
import { selectIsPasswordUpdateRequired, selectResetPasswordToken } from 'containers/Login/loginSelector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        direction: 'column',
        height: '100%',
        flexGrow: 1,
        alignContent: 'space-around',
        justifyContent: 'center',
    },
    widthRestrictions: {
        width: '255px',
        [theme.breakpoints.up('sm')]: {
            width: '300px',
        },
    },
}));

export const LoginForm = () => {
    const classes = useStyles();

    const isPasswordUpdateRequired = useSelector(selectIsPasswordUpdateRequired);
    const resetPasswordToken = useSelector(selectResetPasswordToken);

    const returnPasswordForm = () => {
        if (isPasswordUpdateRequired) {
            return <NewPassword />;
        } else if (resetPasswordToken) {
            return <ResetPassword />;
        }

        return <LoginFormContent />;
    };

    return (
        <Grid container item className={classes.inputContainer}>
            <FormHeader />

            <Grid container item className={classes.widthRestrictions}>
                {returnPasswordForm()}
            </Grid>

            <FormFooter />
        </Grid>
    );
};
