import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { List, ListItem, Divider, Hidden, Typography, makeStyles } from '@material-ui/core';
import { MenuItem } from './menuLinkList/MenuItem';
import { ABOUT_SHOPWORKS_LINK, PRIVACY_LINK, HELP_LINK } from '../../links';

const openWindowUrl = (url) => window.open(url, '_blank');

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.common.white,
        width: '100%',
    },
    pointer: {
        cursor: 'pointer',
    },
}));

const MenuLinkList = ({ className, menuOrigin, menuLinks }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <List className={className}>
                {menuLinks.map((menuLink, index) => (
                    <MenuItem
                        key={menuLink.translationKey}
                        path={menuLink.path}
                        nestedRoutes={menuLink.nestedRoutes}
                        icon={menuLink.icon}
                        pathname={t(menuLink.translationKey)}
                        menuOrigin={menuOrigin}
                        id={`hit-point-menu-item-${index}`}
                    />
                ))}
            </List>
            <Hidden mdUp>
                <List disablePadding>
                    <Divider />
                    <ListItem onClick={() => openWindowUrl(ABOUT_SHOPWORKS_LINK.url)}>
                        <Typography className={classnames(classes.link, classes.pointer)}>
                            {t(ABOUT_SHOPWORKS_LINK.translationKey)}
                        </Typography>
                    </ListItem>
                    <ListItem onClick={() => openWindowUrl(PRIVACY_LINK.url)}>
                        <Typography className={classnames(classes.link, classes.pointer)}>
                            {t(PRIVACY_LINK.translationKey)}
                        </Typography>
                    </ListItem>
                    <ListItem onClick={() => openWindowUrl(HELP_LINK.url)}>
                        <Typography className={classnames(classes.link, classes.pointer)}>
                            {t(HELP_LINK.translationKey)}
                        </Typography>
                    </ListItem>
                    <Divider />
                </List>
                <Typography
                    className={classes.link}
                    style={{
                        padding: 15,
                        opacity: 0.5,
                    }}
                >
                    {t('portal:footer.powered_by_shopworks')}
                </Typography>
            </Hidden>
        </div>
    );
};

MenuLinkList.propTypes = {
    className: PropTypes.string,
    menuOrigin: PropTypes.string.isRequired,
    menuLinks: PropTypes.array.isRequired,
};

export default MenuLinkList;
