import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { ErrorWithDetails } from './ErrorWithDetails';
import { useTranslation } from 'react-i18next';

import {
    selectIsRequestError,
    selectRequestError,
    selectDatePickerBlockedOrApprovedError,
    selectRequestErrorDetails,
} from '../requestTimeOffSelectors';

const useStyle = makeStyles((theme) => ({
    panel: {
        margin: '10px 0',
        backgroundColor: theme.palette.danger.main,
        padding: '5px 10px 5px 10px',
        borderRadius: '5',
    },
    text: {
        color: theme.palette.common.white,
        fontWeight: '400',
    },
    list: {
        color: theme.palette.common.white,
        fontWeight: '600',
        '& p': {
            margin: 0,
        },
        '& span': {
            fontWeight: '400',
        },
    },
}));

export const ErrorPanel = () => {
    const classes = useStyle();
    const { t } = useTranslation();

    const isRequestError = useSelector(selectIsRequestError);
    const requestError = useSelector(selectRequestError);
    const datePickerBlockedOrApprovedError = useSelector(selectDatePickerBlockedOrApprovedError);
    const requestErrorDetails = useSelector(selectRequestErrorDetails);

    if (!isRequestError && !datePickerBlockedOrApprovedError) {
        return null;
    }

    if (Object.keys(requestErrorDetails).length > 0) {
        return <ErrorWithDetails />;
    }

    const errorText = requestError ? requestError : datePickerBlockedOrApprovedError;

    return (
        <div className={classes.panel}>
            <Typography className={classes.text}>
                {typeof errorText === 'string' ? t(errorText) : t(errorText.label, { hours: errorText.hours })}
            </Typography>
        </div>
    );
};
