import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useIsMobile } from 'utils/components/helpers';

const useStyles = makeStyles((theme) => ({
    rowStyle: {
        borderBottom: `1px solid ${theme.palette.grey.light}`,
        padding: '1.5em 1em',
        position: 'sticky',
        top: 0,
        background: theme.palette.common.white,
        boxShadow: '0px 0.5px rgb(0 0 0 / 20%)',
    },
    clickableDiv: {
        maxWidth: '100%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100px',
        },
    },
    labels: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
    },
}));

export const GridHeading = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const labelData = isMobile
        ? ['requestTimeOff:unit_per_day', 'common:dates']
        : [
              'requestTimeOff:time_off_type',
              'common:dates',
              'requestTimeOff:duration',
              'requestTimeOff:unit_per_day',
              'requestTimeOff:total_taken',
              'common:notes',
          ];

    return (
        <Grid container alignItems="center" className={classes.rowStyle}>
            {labelData.map((label) => (
                <Grid item xs key={label} className={classes.clickableDiv}>
                    <Typography className={classes.labels}>{t(label)}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};
