import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles, Link } from '@material-ui/core';
import shopworksDarkLogo from 'images/logo/shopworks-dark.svg';
import { ABOUT_SHOPWORKS_LINK, PRIVACY_LINK, HELP_LINK } from './links';
import { useIsMobile } from 'utils/components/helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    defaultFooter: {
        height: 50,
        margin: '0 auto',
    },
    loginFooter: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
            height: 35,
            backgroundColor: theme.palette.common.white,
            paddingTop: 4,
        },
    },
    leftSideFooterPosition: {
        [theme.breakpoints.between('sm', 'md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            paddingLeft: 40,
        },
    },
}));

export const Footer = ({ isLoginPage }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const copyright = `© ${new Date().getFullYear()}`;
    const itemXSBreakpoint = isLoginPage && !isMobile ? 4 : 12;

    return (
        <Grid
            container
            className={isLoginPage ? classes.loginFooter : classes.defaultFooter}
            alignItems="center"
            justifyContent="center"
        >
            <Grid container item xs={12} md={4} className={classes.leftSideFooterPosition} spacing={1}>
                <Grid item>
                    <Typography variant="caption">{t('portal:footer.powered_by', { copyright })}</Typography>
                </Grid>
                <Grid item>
                    <img src={shopworksDarkLogo} alt="Shopworks logo" />
                </Grid>
            </Grid>
            <Grid item xs={itemXSBreakpoint} sm={3}>
                <Typography variant="caption">
                    <Link href={ABOUT_SHOPWORKS_LINK.url} target="_blank" rel="nofollow">
                        {t(ABOUT_SHOPWORKS_LINK.translationKey)}
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={itemXSBreakpoint} sm={3}>
                <Typography variant="caption">
                    <Link href={PRIVACY_LINK.url} target="_blank" rel="nofollow">
                        {t(PRIVACY_LINK.translationKey)}
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={itemXSBreakpoint} sm={2}>
                <Typography variant="caption">
                    <Link href={HELP_LINK.url} target="_blank" rel="nofollow">
                        {t(HELP_LINK.translationKey)}
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

Footer.propTypes = {
    isLoginPage: PropTypes.bool,
};
