import { useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/useSettings';

import { useGetHoursAndMinutes } from 'utils/timeFormatters';

import { selectRequestError, selectRequestErrorDetails } from '../requestTimeOffSelectors';

const useStyle = makeStyles((theme) => ({
    panel: {
        margin: '10px 0',
        backgroundColor: theme.palette.danger.main,
        padding: '5px 10px 5px 10px',
        borderRadius: '5',
    },
    boldText: {
        fontWeight: '600',
    },
    text: {
        color: theme.palette.common.white,
        fontWeight: '400',
    },
    list: {
        color: theme.palette.common.white,
        fontWeight: '600',
        '& p': {
            margin: 0,
        },
        '& span': {
            fontWeight: '400',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
    },
}));

export const ErrorWithDetails = () => {
    const classes = useStyle();
    const { t } = useTranslation();

    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();

    const requestError = useSelector(selectRequestError);
    const { entitlement, allowedBalance, currentBalance, requested, balanceAfterApproval, role } =
        useSelector(selectRequestErrorDetails);

    const splitMessage = t(requestError).split(':role');

    splitMessage.splice(1, 0, role);

    const constructMessage = splitMessage.map((piece, index) =>
        index === 1 ? (
            <span key={piece} className={classes.boldText}>
                {piece}
            </span>
        ) : (
            <span key={piece}>{piece}</span>
        ),
    );

    const getHoursAndMinutes = useGetHoursAndMinutes();
    const formatTime = (hours) =>
        showHoursWithMinutes ? getHoursAndMinutes(hours) : t('units:hours_quantity', { hours });

    const formatAmount = (amount) => {
        switch (amount.unit) {
            case 'seconds':
                return t('units:seconds_quantity', { seconds: amount.quantity });
            case 'minutes':
                return t('units:minutes_quantity', { minutes: amount.quantity });
            case 'days':
                return t('units:days_quantity', { days: amount.quantity });
            case 'weeks':
                return t('units:weeks_quantity', { weeks: amount.quantity });
            case 'hours':
            default:
                return showHoursWithMinutes
                    ? getHoursAndMinutes(amount.quantity)
                    : t('units:hours_quantity', { hours: amount.quantity });
        }
    };

    return (
        <div className={classes.panel}>
            <Typography className={classes.text} role="errorLabel">
                {constructMessage}
            </Typography>

            <ul className={classes.list}>
                <li>
                    <p>
                        {t('entitlement:entitlement')}: <span>{entitlement}</span>
                    </p>
                </li>
                <li>
                    <p>
                        {t('requestTimeOff:allowed_balance_label')}: <span>{formatAmount(allowedBalance)}</span>
                    </p>
                </li>
                <li>
                    <p>
                        {t('requestTimeOff:current_balance_label')}: <span>{formatTime(currentBalance)}</span>
                    </p>
                </li>
                <li>
                    <p>
                        {t('requestTimeOff:additionally_requested_label')}: <span>{formatTime(requested)}</span>
                    </p>
                </li>
                <li>
                    <p>
                        {t('requestTimeOff:balance_approved_label')}: <span>{formatTime(balanceAfterApproval)}</span>
                    </p>
                </li>
            </ul>
        </div>
    );
};
