import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const getIntersectingDates = (periods, selectedRange) => {
    let intersectionsDatesList = [];

    const intersectionsOfSelectedVsForbidden = periods.reduce((intersections, period) => {
        const blockedRange = moment.range(moment(period.start), moment(period.end));

        if (selectedRange.overlaps(blockedRange)) {
            return [...intersections, selectedRange.intersect(blockedRange)];
        }

        return intersections;
    }, []);

    if (intersectionsOfSelectedVsForbidden.length > 0) {
        intersectionsDatesList = intersectionsOfSelectedVsForbidden.reduce((forbiddenDates, intersection) => {
            const listOfDays = Array.from(intersection.by('day'));
            const listOfFormattedDays = listOfDays.map((day) => day.format('YYYY-MM-DD'));

            return [...forbiddenDates, ...listOfFormattedDays];
        }, []);
    }

    return intersectionsDatesList;
};
