import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Paper, TableRow, TableCell, makeStyles, Table, TableBody } from '@material-ui/core';
import { LONG_DATE_FORMAT, statuses } from './constants';
import { OfferButtons } from './OfferButtons';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    acceptButton: {
        marginRight: theme.spacing(1),
    },
    boldPrimaryText: {
        color: theme.palette.primary.dark,
        fontWeight: 'bold',
    },
    notesLabel: {
        verticalAlign: 'text-top',
    },
    paper: {
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        position: 'relative',
        '@supports (grid-template-columns: repeat(auto-fill, minmax(235px, 1fr)))': {
            marginBottom: 0,
            marginRight: 0,
        },
    },
    tableContainer: {
        height: '90%',
        marginBottom: 40,
    },
    buttonContainer: {
        bottom: 10,
        position: 'absolute',
        left: 0,
        right: 0,
    },
}));

const TableCellPlain = ({ children, ...rest }) => (
    <TableCell style={{ borderBottom: 'none', padding: 8 }} {...rest}>
        {children}
    </TableCell>
);

TableCellPlain.propTypes = {
    children: PropTypes.node.isRequired,
};

export const OfferCard = ({
    offer: {
        id,
        status,
        updatedAt,
        notes,
        shift: { startDate, endDate, location, role },
    },
    hideButtons = false,
    className = '',
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { moment } = useMoment();

    const date = moment(startDate).format(LONG_DATE_FORMAT);

    const startTime = moment(startDate).format('HH:mm');
    const endTime = moment(endDate).format('HH:mm');

    return (
        <Paper className={classnames(classes.paper, className)} data-testid="OfferCard">
            <div className={classes.tableContainer}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCellPlain className={classes.boldPrimaryText}>{t('common:date')}</TableCellPlain>
                            <TableCellPlain>
                                <b>{date}</b>
                            </TableCellPlain>
                        </TableRow>
                        <TableRow>
                            <TableCellPlain className={classes.boldPrimaryText}>{t('portal:venue')}</TableCellPlain>
                            <TableCellPlain>{location}</TableCellPlain>
                        </TableRow>
                        <TableRow>
                            <TableCellPlain className={classes.boldPrimaryText}>{t('portal:role')}</TableCellPlain>
                            <TableCellPlain>{role}</TableCellPlain>
                        </TableRow>
                        <TableRow>
                            <TableCellPlain className={classes.boldPrimaryText}>
                                {t('common:start_time')}
                            </TableCellPlain>
                            <TableCellPlain>{startTime}</TableCellPlain>
                        </TableRow>
                        <TableRow>
                            <TableCellPlain className={classes.boldPrimaryText}>{t('common:end_time')}</TableCellPlain>
                            <TableCellPlain>{endTime}</TableCellPlain>
                        </TableRow>
                        <TableRow>
                            <TableCellPlain className={classnames(classes.boldPrimaryText, classes.notesLabel)}>
                                {t('common:notes')}
                            </TableCellPlain>
                            <TableCellPlain>{notes ? notes : '-'}</TableCellPlain>
                        </TableRow>
                        {status === statuses.accepted || status === statuses.declined ? (
                            <TableRow>
                                <TableCellPlain className={classes.boldPrimaryText}>
                                    {status === statuses.accepted
                                        ? t('shift_offer:accepted_on')
                                        : t('shift_offer:declined_on')}
                                </TableCellPlain>
                                <TableCellPlain>{moment(updatedAt).format('DD-MM-YYYY')}</TableCellPlain>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </div>
            {status === statuses.pending && !hideButtons && (
                <div className={classes.buttonContainer}>
                    <OfferButtons id={id} />
                </div>
            )}
        </Paper>
    );
};

OfferCard.propTypes = {
    offer: PropTypes.exact({
        id: PropTypes.number.isRequired,
        status: PropTypes.oneOf([statuses.pending, statuses.accepted, statuses.declined]),
        updatedAt: PropTypes.string.isRequired,
        notes: PropTypes.string,
        shift: PropTypes.exact({
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
    }),
    hideButtons: PropTypes.bool,
    className: PropTypes.string,
};
