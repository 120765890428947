import { snakeToCamelObjectKeys } from 'utils/strings/helpers';
import moment from 'moment';

export const getWeekOfDatesUsingStart = (startDate) => {
    const getAllDatesBlockedByRota = [];
    let count = 0;

    while (count < 7) {
        getAllDatesBlockedByRota.push(moment(startDate).add(count, 'day').format('YYYY-MM-DD'));
        count = count + 1;
    }

    return getAllDatesBlockedByRota;
};

export const normaliseErrorDetails = (errorMeta, contracts) => {
    const { start, end, pot, currentBalance: balance, used, newBalance } = snakeToCamelObjectKeys(errorMeta);

    const entitlement = `${pot.humanName} ${start} - ${end}`;
    const allowedBalance = pot.minimumAllowedBalance;
    const currentBalance = balance.hours;
    const requested = used.hours;
    const balanceAfterApproval = newBalance.hours;
    const { name: role } = contracts.find(({ id }) => id === pot.contractId);

    return { entitlement, allowedBalance, currentBalance, requested, balanceAfterApproval, role };
};
