import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { List, ListItem, ListItemIcon, ListItemText, Icon, makeStyles, Divider } from '@material-ui/core';
import { LanguageSelection } from 'containers/languageSelection/LanguageSelection';
import { setIsLoggedIn } from 'containers/Login/loginSlice';
import { useDispatch } from 'react-redux';
import { useLogout } from 'services/auth';
import { useState } from 'react';
import { setUserMenuOpen } from 'components/layouts/menuSlice';

const useStyles = makeStyles((theme) => ({
    link: {
        ...theme.link,
    },
    removeGutter: {
        padding: 0,
    },
}));

export const UserMenuLinks = ({ menuLinks, onClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { logoutMutation } = useLogout();
    const history = useHistory();
    const [isRedirecting, setIsRedirecting] = useState(false);

    const onClickLogout = () => {
        if (isRedirecting) {
            return;
        }

        setIsRedirecting(true);

        logoutMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(setIsLoggedIn(false));
                setIsRedirecting(false);
                history.push('/login');
                dispatch(setUserMenuOpen({ userMenuOpen: false }));
            },
        });
    };

    return (
        <List className={classes.removeGutter}>
            <Divider />
            {menuLinks.map(({ icon, path, translationKey }) => (
                <Link className={classes.link} to={path} key={translationKey}>
                    <ListItem
                        divider
                        button
                        className={classnames({
                            [classes.activeLink]: true,
                        })}
                        onClick={onClick}
                    >
                        <ListItemIcon>
                            <Icon className={classes.navItemStyling}>{`${icon}`}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={t(translationKey)} />
                    </ListItem>
                </Link>
            ))}

            <ListItem divider>
                <LanguageSelection />
            </ListItem>

            <ListItem
                divider
                button
                className={classnames({
                    [classes.activeLink]: true,
                })}
                onClick={onClickLogout}
            >
                <ListItemIcon>
                    <Icon className={classes.navItemStyling}>exit_to_app</Icon>
                </ListItemIcon>
                <ListItemText primary={t('user:logout')} />
            </ListItem>
        </List>
    );
};

UserMenuLinks.propTypes = {
    onClick: PropTypes.func.isRequired,
    menuLinks: PropTypes.array.isRequired,
};
