import { shiftOffers, schedule } from './nestedRoutes';

const menus = {
    user: 'user',
    main: 'main',
    none: 'none',
};

export const settingsControlledRoutes = {
    training: {
        translationKey: 'entitlement:training',
        path: '/training',
        componentName: 'Training',
        icon: 'psychology',
        menu: menus.main,
    },
    shiftOffer: {
        translationKey: 'portal:shift_offer',
        path: '/shift-offers',
        nestedRoutes: shiftOffers,
        componentName: 'ShiftOffer',
        icon: 'emoji_people',
        menu: menus.main,
    },
    documents: {
        translationKey: 'portal:documents',
        path: '/documents',
        componentName: 'Documents',
        icon: 'description',
        menu: menus.main,
    },
    timeOff: {
        translationKey: 'portal:time_off',
        path: '/time-off',
        componentName: 'TimeOff',
        icon: 'date_range',
        menu: menus.main,
    },
};

export const routes = [
    {
        translationKey: 'portal:dashboard',
        path: '/dashboard',
        componentName: 'Dashboard',
        icon: 'dashboard',
        menu: menus.main,
    },
    {
        translationKey: 'portal:schedule',
        path: '/schedule',
        nestedRoutes: schedule,
        componentName: 'Schedule',
        icon: 'perm_contact_calendar',
        menu: menus.main,
    },
    {
        translationKey: 'portal:time_off',
        path: '/time-off',
        componentName: 'TimeOff',
        icon: 'date_range',
        menu: menus.main,
    },
    {
        translationKey: 'portal:manage_account',
        path: '/manage-account',
        componentName: 'ManageAccount',
        icon: 'list',
        menu: menus.user,
    },
    {
        translationKey: 'common:login',
        path: '/login',
        componentName: 'Login',
        menu: menus.none,
    },
    {
        translationKey: 'user:reset_password.label',
        path: '/reset-password',
        componentName: 'ResetPasswordPage',
        menu: menus.none,
    },
];

export const computeRoutes = (settings) => {
    let computed = [...routes];
    const [isShiftOfferEnabled, isTrainingEnabled, isDocumentsEnabled, isTimeOffEnabled] = [
        settings?.shiftOffer,
        settings?.training,
        settings?.staffDocuments,
        settings?.timeOff,
    ].map((module) => !!module?.enabled);

    if (isShiftOfferEnabled) {
        computed.push(settingsControlledRoutes.shiftOffer);
    }
    if (isTrainingEnabled) {
        computed.push(settingsControlledRoutes.training);
    }

    if (isDocumentsEnabled) {
        computed.push(settingsControlledRoutes.documents);
    }

    if (!isTimeOffEnabled) {
        //Remove time off route if the time off module is disabled
        computed = computed.filter((route) => route.componentName !== 'TimeOff');
    }
    return computed;
};
