import PropTypes from 'prop-types';
import {
    Button,
    Typography,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useIsMobileOrTablet } from 'utils/components/helpers';

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: 16,
        marginBottom: 10,
    },
    inputPadding: {
        padding: '10px 0',
    },
    inputText: {
        fontSize: 16,
    },
    buttonStyles: {
        fontSize: 16,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    successMessage: {
        color: theme.palette.primary.main,
        marginBottom: '30px',
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
    placeholder: {
        fontSize: 16,
    },
}));

const PasswordResetModal = ({
    isModalOpen,
    handleFormSubmit,
    handleInputChange,
    handleCloseModal,
    loading,
    success,
    error,
    value,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobileOrTablet = useIsMobileOrTablet();

    const RESET_PASSWORD = t('user:reset_password.label');
    const SUCCESS_MESSAGE = t('user:reset_password.request_successful');
    const DIALOG_LABEL = t('user:reset_password.dialog_label');
    const ERROR_MESSAGE = t('user:reset_password.dialog_error_message');

    return (
        <Dialog open={isModalOpen} onClose={handleCloseModal} fullScreen={isMobileOrTablet}>
            <DialogTitle id="form-dialog-title">{RESET_PASSWORD}</DialogTitle>
            <DialogContent>
                {success ? (
                    <div>
                        <Typography className={classes.successMessage} gutterBottom>
                            {SUCCESS_MESSAGE}
                        </Typography>
                        <Button
                            onClick={handleCloseModal}
                            color="primary"
                            size="small"
                            variant="outlined"
                            className={classes.buttonStyles}
                        >
                            {t('common:close')}
                        </Button>
                    </div>
                ) : (
                    <Typography className={classes.text}>{DIALOG_LABEL}</Typography>
                )}
                {error ? <Typography className={classes.errorMessage}>{ERROR_MESSAGE}</Typography> : null}
            </DialogContent>
            {success ? null : (
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            margin="normal"
                            id="email"
                            label={<Typography className={classes.placeholder}>{t('common:email')}</Typography>}
                            type="email"
                            fullWidth
                            disabled={loading}
                            className={classes.inputPadding}
                            onChange={handleInputChange}
                            value={value}
                            InputProps={{
                                classes: {
                                    input: classes.inputText,
                                },
                            }}
                        />
                        <div className={classes.buttonWrapper}>
                            <Button
                                onClick={handleCloseModal}
                                color="primary"
                                variant="outlined"
                                size="small"
                                className={classes.buttonStyles}
                            >
                                {t('common:cancel')}
                            </Button>
                            <Button
                                color="primary"
                                size="small"
                                type="submit"
                                className={classes.buttonStyles}
                                disabled={!value || loading}
                                variant="contained"
                            >
                                {loading ? <CircularProgress size={20} /> : t('common:submit')}
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            )}
        </Dialog>
    );
};

PasswordResetModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
};

export default PasswordResetModal;
