import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { roundAndTrimNumber, useGetHoursAndMinutes } from 'utils/timeFormatters';

const useStyles = makeStyles((theme) => ({
    gridSegment: {
        flexDirection: 'column',
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        height: '20vh',
        '&:first-child': {
            borderLeft: 'none',
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            borderLeft: 'none',
            borderTop: `2px solid ${theme.palette.common.white}`,
            padding: '2rem 1rem',
        },
    },
    spacingStructure: {
        height: '100%',
        padding: theme.spacing(0, 1.5),
    },
    dateIndicator: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-between',
        },
    },
    shiftHoursValue: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    shiftConfirmation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row-reverse',
        },
    },
    noShiftScheduled: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    disabled: {
        color: theme.palette.grey[500],
    },
    leftMargin: {
        marginLeft: 5,
    },
    verticalMargin: {
        marginTop: 5,
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
    },
    responsiveText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 11,
        },
        textAlign: 'center',
    },
    bigBoldResponsiveText: {
        fontSize: 28,
        fontWeight: 300,
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
        },
    },
    iconSize: {
        fontSize: 25,
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
        },
    },
    extraPaddingFix: {
        [theme.breakpoints.down('xs')]: {
            paddingRight: '5%!important',
        },
    },
}));

export const WeekDayRow = ({ daySchedule: { dateDetails, confirmed, totalPaidHoursForDay }, showHoursWithMinutes }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getHoursAndMinutes = useGetHoursAndMinutes();

    const isTotalHoursPositive = Boolean(totalPaidHoursForDay);

    const isHoursWithMins = showHoursWithMinutes && !Number.isInteger(totalPaidHoursForDay);

    let totalHours;

    if (isHoursWithMins) {
        totalHours = getHoursAndMinutes(totalPaidHoursForDay);
    } else {
        totalHours = totalPaidHoursForDay ? roundAndTrimNumber(totalPaidHoursForDay) : '';
    }

    return (
        <Grid item xs className={classes.gridSegment}>
            <Grid container spacing={1} className={classes.spacingStructure}>
                <Grid item xs={3} lg={12} className={classes.extraPaddingFix}>
                    <div className={classes.dateIndicator}>
                        <Typography
                            className={classnames({
                                [classes.responsiveText]: true,
                                [classes.disabled]: !isTotalHoursPositive,
                            })}
                        >
                            {dateDetails.daySmall}
                        </Typography>
                        <Typography
                            className={classnames({
                                [classes.leftMargin]: true,
                                [classes.responsiveText]: true,
                                [classes.disabled]: !isTotalHoursPositive,
                            })}
                        >
                            {`${dateDetails.date} ${dateDetails.isNewMonth ? dateDetails.month : ''}`}
                        </Typography>
                    </div>
                </Grid>
                {isTotalHoursPositive ? (
                    <>
                        <Grid item xs={3} lg={12}>
                            <div className={classes.shiftHoursValue}>
                                <Typography
                                    className={classnames(classes.bigBoldResponsiveText, classes.responsiveText)}
                                >
                                    {totalHours}
                                </Typography>
                                {!isHoursWithMins && (
                                    <Typography
                                        className={classnames({
                                            [classes.leftMargin]: true,
                                            [classes.responsiveText]: true,
                                            [classes.bigBoldResponsiveText]: showHoursWithMinutes,
                                        })}
                                    >
                                        {t('common:hours')}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6} lg={12}>
                            <div className={classes.shiftConfirmation}>
                                {confirmed ? (
                                    <AssignmentTurnedInIcon
                                        color="primary"
                                        className={classnames(classes.iconSize, classes.leftMargin)}
                                    />
                                ) : (
                                    <AssignmentLateIcon
                                        color="error"
                                        className={(classes.leftMargin, classes.iconSize)}
                                    />
                                )}
                                <Typography
                                    className={classnames({
                                        [classes.verticalMargin]: true,
                                        [classes.responsiveText]: true,
                                        [classes.disabled]: confirmed === false,
                                    })}
                                >
                                    {confirmed ? t('schedule:confirmed') : t('schedule:need_confirmation')}
                                </Typography>
                            </div>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={9} lg={12}>
                        <div className={classes.noShiftScheduled}>
                            <Typography
                                className={classnames({
                                    [classes.disabled]: !isTotalHoursPositive,
                                })}
                            >
                                -
                            </Typography>
                        </div>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

WeekDayRow.propTypes = {
    daySchedule: PropTypes.any.isRequired,
    showHoursWithMinutes: PropTypes.bool,
};
