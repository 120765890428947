import { IconButton, Hidden, Tooltip, Zoom, makeStyles } from '@material-ui/core';
import Backburger from 'mdi-material-ui/Backburger';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectDesktopMenuOpen } from '../../menuSelector';
import { setDesktopMenuOpen } from '../../menuSlice';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        ...theme.menu.icon,
    },
    toolTip: {
        fontSize: '0.8em',
    },
    spacing: {
        margin: 5,
    },
}));

const MenuToggle = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const desktopMenuOpen = useSelector(selectDesktopMenuOpen);

    const handleToggleDesktopMenu = () => dispatch(setDesktopMenuOpen());

    const { t } = useTranslation();

    return (
        <Hidden smDown>
            <Tooltip
                TransitionComponent={Zoom}
                placement="right"
                classes={{ tooltipPlacementRight: classes.toolTip }}
                title={desktopMenuOpen ? t('common:close') : t('common:open')}
            >
                <IconButton
                    aria-label="Open drawer"
                    onClick={(event) => handleToggleDesktopMenu(event)}
                    className={classes.spacing}
                >
                    {desktopMenuOpen ? <Backburger className={classes.icon} /> : <MenuIcon className={classes.icon} />}
                </IconButton>
            </Tooltip>
        </Hidden>
    );
};

export default MenuToggle;
