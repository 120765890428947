import { useEffect, useState } from 'react';
import { Grid, styled } from '@material-ui/core';
import BasePanel from 'components/Panel';
import { EntitlementWidget } from '..//timeOff/EntitlementWidget';
import { ContractTabs } from '../../components/ContractTabs';
import { PrimaryButtonRequest } from 'components/PrimaryButtonRequest';
import { getCurrentContractsSortedById } from '../timeOff/helpers';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useUser } from 'services/auth';
import ToggleEntitlementCheckbox from 'containers/timeOff/entitlementComponents/ToggleEntitlementCheckbox';
import { useIsHidingInactivePots } from 'containers/timeOff/useEntitlement';

const Panel = styled(BasePanel)({
    minHeight: 350,
});

const ToggleEntitlement = styled(ToggleEntitlementCheckbox)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const Entitlement = () => {
    const {
        userDetails: { contracts },
    } = useUser();

    const isHidden = useIsHidingInactivePots();
    const isMobileOrTablet = useIsMobileOrTablet();

    const [tabValue, setTabValue] = useState(0);
    const currentContractsSortedById = Array.from(getCurrentContractsSortedById(contracts, isHidden));

    useEffect(() => {
        if (isHidden) {
            setTabValue(0);
        }
    }, [isHidden]);

    return (
        <>
            <ToggleEntitlement />
            {contracts.length > 1 && (
                <ContractTabs
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    contracts={currentContractsSortedById}
                    withRole
                />
            )}
            <Panel removeBottomGutter>
                <EntitlementWidget contract={currentContractsSortedById[tabValue]} />
                {!isMobileOrTablet && (
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <PrimaryButtonRequest />
                        </Grid>
                    </Grid>
                )}
            </Panel>
        </>
    );
};
