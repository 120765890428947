import { useHistory } from 'react-router-dom';
import { useUser } from '../services/auth';
import { SplashScreen } from 'pages/SplashScreen';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsPasswordUpdateRequired } from 'containers/Login/loginSelector';
import { setIsLoggedIn } from 'containers/Login/loginSlice';
import { ReactElement, useEffect } from 'react';

export const PrivateRoute = ({ children }: { children: ReactElement }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isUserLoading, isUserError } = useUser();
    const isPasswordUpdateRequired = useSelector(selectIsPasswordUpdateRequired);

    useEffect(() => {
        if (isUserLoading) {
            return;
        }
        if (isUserError || isPasswordUpdateRequired) {
            return;
        }
        dispatch(setIsLoggedIn(true));
    }, [isUserLoading, isUserError, isPasswordUpdateRequired, dispatch]);

    useEffect(() => {
        if (isUserError || isPasswordUpdateRequired) {
            history.push('/login');
        }
    }, [isUserError, isPasswordUpdateRequired, history]);

    if (isUserLoading) {
        return <SplashScreen />;
    }

    if (isUserError || isPasswordUpdateRequired) {
        return null;
    }

    return children;
};
