import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, makeStyles, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getColourLuminance } from '../../../../../utils/getColourLuminence';

const useStyles = makeStyles((theme) => ({
    baseChipStyle: {
        width: 25,
        boxShadow: 'none',
        borderRadius: 4,
        cursor: 'default',
        background: 'none',
        textTransform: 'uppercase',
    },
    bigChipStyle: {
        height: 40,
    },
    smallChipStyle: {
        height: 20,
        '&:first-child': {
            marginBottom: 1,
        },
    },
    chipBGColor: {
        backgroundColor: ({ isPast, chipColor, isEmptyCell }) => {
            if (isEmptyCell) {
                return 'none';
            }
            if (isPast) {
                return theme.palette.grey.lightest;
            }
            return chipColor ?? theme.palette.grey.deep;
        },
    },
    chipTextColor: {
        color: ({
            isPast,
            isEmptyCell,
            chipColor,
            isUpcomingTimeOffApproved,
            isUpcomingTimeOffPending,
            isUpcomingTimeOffSickness,
        }) => {
            if (isEmptyCell) {
                return theme.palette.grey.lightest;
            }
            if (isPast || isUpcomingTimeOffApproved || isUpcomingTimeOffPending || isUpcomingTimeOffSickness) {
                return theme.palette.common.white;
            }
            return shouldContrastBeLight(chipColor) ? theme.palette.common.white : theme.palette.grey.lightest;
        },
        fontSize: ({ isEmptyCell }) => (isEmptyCell ? '36px' : theme.typography.caption.fontSize),
        fontWeight: ({ isEmptyCell }) =>
            isEmptyCell ? theme.typography.fontWeightLight : theme.typography.fontWeightBold,
    },
    pendingTimeOffChipStyle: {
        backgroundColor: theme.palette.warning.dark,
    },
    approvedTimeOffChipStyle: {
        backgroundColor: theme.palette.primary.main,
    },
    sickDayChipStyle: {
        backgroundColor: theme.palette.danger.main,
    },
}));

export const RotaChip = ({ chipSize, chipColor, isPast, chipType, chipName, status }) => {
    const { t } = useTranslation();
    const isEmptyCell = chipType === 'empty' || chipType === 'dayOff';
    const isContrastLight = shouldContrastBeLight(chipColor);
    const isChipInPast = isPast;
    const isUpcomingTimeOffPending = !isChipInPast && chipType === 'timeOff' && status === 'pending';
    const isUpcomingTimeOffApproved = !isChipInPast && chipType === 'timeOff' && status === 'approved';
    const isUpcomingSickness = !isChipInPast && chipType === 'sickness';
    const isChipSmall = chipSize === 'small';
    const isChipBig = chipSize === 'big';
    const isNotUpcomingDay = !isUpcomingTimeOffApproved && !isUpcomingTimeOffPending && !isUpcomingSickness;

    const classes = useStyles({
        isPast,
        chipColor,
        isEmptyCell,
        isContrastLight,
        isUpcomingTimeOffPending,
        isUpcomingTimeOffApproved,
        isUpcomingSickness,
    });

    const matchText = () => {
        if (chipType === 'empty') {
            return '-';
        }
        if (chipType.includes('dayOff')) {
            return t('schedule:abbreviations.day_off');
        }
        if (chipType) {
            if (!chipName) {
                throw Error('SW: Chip name is missing');
            }
            const chipSplit = chipName.split(' ');
            if (chipSplit.length > 1) {
                return chipSplit.map((word) => word[0]).join('');
            } else {
                return chipName[0][0];
            }
        }
    };

    return (
        <Card
            className={classNames({
                [classes.pendingTimeOffChipStyle]: isUpcomingTimeOffPending,
                [classes.approvedTimeOffChipStyle]: isUpcomingTimeOffApproved,
                [classes.sickDayChipStyle]: isUpcomingSickness,
                [classes.baseChipStyle]: true,
                [classes.chipBGColor]: isNotUpcomingDay,
                [classes.bigChipStyle]: isChipBig,
                [classes.smallChipStyle]: isChipSmall,
            })}
        >
            <Grid container alignItems="center" justifyContent="center" direction="column" style={{ height: '100%' }}>
                <Grid item>
                    <Typography align="center" variant="caption" className={classes.chipTextColor}>
                        {matchText()}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

// if luminance is less than 0.5, the contrast text should be light so return true.
const shouldContrastBeLight = (hexColor) => getColourLuminance(hexColor) < 0.5;

RotaChip.propTypes = {
    chipSize: PropTypes.string.isRequired,
    isPast: PropTypes.bool.isRequired,
    chipType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    chipColor: PropTypes.string,
    chipName: PropTypes.string.isRequired,
};
