import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showSnackbar: false,
    snackBarError: false,
    snackBarMessage: 'portal:snack_bar.success',
};

const eventSnackBarSlice = createSlice({
    name: 'eventSnackBar',
    initialState,
    reducers: {
        setShowSnackbar(state, { payload }) {
            state.showSnackbar = payload.open;
        },
        setSnackBarSuccess(state, { payload }) {
            state.snackBarError = false;
            state.snackBarMessage = payload?.message || 'portal:snack_bar.success';
        },
        setSnackBarError(state, { payload }) {
            state.snackBarError = true;
            state.snackBarMessage = payload?.message || 'portal:snack_bar.error';
        },
    },
});

export const {
    reducer: eventSnackBarReducer,
    actions: { setShowSnackbar, setSnackBarSuccess, setSnackBarError },
} = eventSnackBarSlice;
