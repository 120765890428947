import palette from './palette';

export const rota = {
    daysRowHeight: 59,
    nameColumnWidth: 200,
    nameColumnWidthSmall: 133,
    columnWidth: 40,
    rowHeight: 70,
    border: `1px solid ${palette.grey.mid}`,
    borderThick: `2px solid ${palette.grey.mid}`,
    borderLoggedInUser: `1px solid ${palette.grey.main}`,
};
