import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { EntitlementPanelSegment } from 'components/entitlementPanelSegment/EntitlementPanelSegment';
import ErrorMessage from 'components/ErrorMessage';

import { fetchRevisionInfo } from '../utils/';
import { selectRequestedTimeOffType } from '../requestTimeOffSelectors';
import { useEntitlementByContractId } from 'services/entitlement';

const useStyles = makeStyles((theme) => ({
    panelStyle: {
        margin: '1rem',
        [theme.breakpoints.down('sm')]: {
            margin: 20,
        },
    },
    rowStyle: {
        margin: '1rem 0',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        justifyContent: 'center',
    },
}));

export const MultiEntitlement = ({ revision }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { entitlementByContractId, isEntitlementError } = useEntitlementByContractId(revision.id);
    const timeOffType = useSelector(selectRequestedTimeOffType);
    const revisionInfo = fetchRevisionInfo(entitlementByContractId, timeOffType);

    if (isEntitlementError) {
        return <ErrorMessage message={t('portal:generic_error_message')} />;
    }

    if (!revisionInfo) {
        return null;
    }

    const { info, units } = revisionInfo;

    return (
        <div className={classes.panelStyle}>
            <Typography>{revision.name}</Typography>
            <Grid container className={classes.rowStyle}>
                {info.lieu ? (
                    <EntitlementPanelSegment key="lieu" entitlement={info.lieu} segment="lieu" units={units} />
                ) : (
                    Object.keys(info).map((segmentName) => (
                        <EntitlementPanelSegment
                            key={segmentName}
                            entitlement={info[segmentName]}
                            segment={segmentName}
                            units={units}
                        />
                    ))
                )}
            </Grid>
        </div>
    );
};

const infoType = PropTypes.exact({
    seconds: PropTypes.number,
    days: PropTypes.number,
});

MultiEntitlement.propTypes = {
    revision: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        units: PropTypes.oneOf(['days', 'hours', 'both']),
        info: PropTypes.exact({
            entitlement: infoType.isRequired,
            used: infoType.isRequired,
            booked: infoType.isRequired,
            balance: infoType.isRequired,
        }),
    }).isRequired,
};
