import axios from 'axios';
import { getStaffShiftsInPeriod } from '../../api/shifts';
import { getHydratedUserTimeOff } from '../../api/holidays';
import { getSelf } from 'api/auth';
import { getStaffForShopById } from '../../api/shops';
import { hydrateTimeOffWithHexColour } from './utils';

export const getAllStaffShiftsAndTimeOff = (start, end, allTimeOffTypes) =>
    axios.all([
        getStaffShiftsInPeriod(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')),
        getHydratedUserTimeOff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), allTimeOffTypes),
    ]);

const filterAndTransformDayOffShifts = (shifts) =>
    shifts
        .filter((shift) => shift.type === 'day_off')
        .map((shift) => ({
            start: shift.start,
            end: shift.end,
            status_id: shift.worklog_day_is_confirmed ? 2 : 1,
            name: shift.type,
            type: shift.type,
            type_id: null,
            hours_per_day: null,
            start_date: shift.start,
            end_date: shift.start,
            days: 1,
        }));

export const getStaffForShopFilteredByTimeOff = async (shopId, start, end, allTimeOffTypes) => {
    const getAllStaffForShop = await getStaffForShopById(shopId, start, end);
    const {
        data: {
            data: { id },
        },
    } = await getSelf();

    const sortedAllStaffForShop = getAllStaffForShop.data.data.sort((a, b) => {
        if (a.id === id) {
            return -1;
        } else if (b.id === id) {
            return 1;
        } else {
            return 0;
        }
    });

    const allStaffTimeOff = sortedAllStaffForShop.map((staffMember) => {
        const filteredDaysOff = staffMember.shifts ? filterAndTransformDayOffShifts(staffMember.shifts) : [];
        const timeOff = staffMember.timeOff ? staffMember.timeOff : [];

        const allDaysAndTimeOff = [...timeOff, ...filteredDaysOff];

        const timeOffHydratedWithTypes = hydrateTimeOffWithHexColour(allDaysAndTimeOff, allTimeOffTypes);

        delete staffMember.shifts;

        return {
            ...staffMember,
            timeOff: timeOffHydratedWithTypes,
        };
    });

    return allStaffTimeOff;
};
