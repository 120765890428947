import moment from 'moment';

export const splitOutTimeOffUsingDates = (timeOffArray) =>
    timeOffArray.reduce((acc, timeOff) => {
        let count = 0;

        if (timeOff.days > 1) {
            while (count !== timeOff.days) {
                acc.push({
                    ...timeOff,
                    start: moment(timeOff.start)
                        .add(count, 'days')

                        .format('YYYY-MM-DD'),
                });

                // eslint-disable-next-line no-plusplus
                count++;
            }
        } else {
            acc.push(timeOff);
        }

        return acc;
    }, []);
