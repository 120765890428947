import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    FormControl,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { availabilityTypes, closeAvailabilityDialog } from 'containers/Schedule/store/availabilitySlice';
import { LoadingBar } from 'components/LoadingBar';
import { AvailabilityIcon } from './AvailabilityIcon';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import {
    selectIsDialogOpen,
    selectDialogDate,
    selectDialogStatus,
} from 'containers/Schedule/store/availabilitySelector';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateAvailability } from 'services/availability';

export const AVAILABILITY = 'Availability';
export const AVAILABLE = 'Available';
export const UNAVAILABLE = 'Unavailable';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100vw',
        maxWidth: theme.spacing(50),
    },
    titleBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconButton: {
        color: theme.palette.common.white,
        padding: 0,
    },
    dateText: {
        marginBottom: theme.spacing(2),
        fontWeight: 600,
    },
    dialogActions: {
        padding: theme.spacing(1, 0),
    },
    formControl: {
        marginBottom: theme.spacing(1),
        fontSize: 14,
    },
    selectContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    select: {
        display: 'flex',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 28,
        fontSize: 14,
    },
}));

export const AvailabilityDialog = () => {
    const { t } = useTranslation();
    const { moment } = useMoment();
    const dispatch = useDispatch();
    const isDialogOpen = useSelector(selectIsDialogOpen);
    const dialogDate = useSelector(selectDialogDate);
    const dialogStatus = useSelector(selectDialogStatus);

    const classes = useStyles();
    const { updateAvailabilityMutation } = useUpdateAvailability();

    const onChange = (event) => {
        updateAvailabilityMutation.mutate(
            {
                newStatus: event.target.value,
                date: dialogDate,
            },
            {
                onSettled: () => {
                    dispatch(closeAvailabilityDialog());
                },
            },
        );
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={() => dispatch(closeAvailabilityDialog())}
            aria-labelledby="availability-dialog-title"
            aria-describedby="availability-dialog-description"
            classes={{ paper: classes.paper }}
        >
            <DialogTitle id="availability-dialog-title" className={classes.titleBar} disableTypography>
                <Typography variant="h6">{t('availability:availability')}</Typography>
                <IconButton onClick={() => dispatch(closeAvailabilityDialog())} className={classes.iconButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Typography id="availability-dialog-description" className={classes.dateText}>
                    {moment(dialogDate).format('dddd D MMMM YYYY')}
                </Typography>

                <DialogActions className={classes.dialogActions}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            value={dialogStatus}
                            onChange={onChange}
                            classes={{
                                root: classes.select,
                            }}
                        >
                            <MenuItem value={availabilityTypes.available}>
                                <AvailabilityIcon status={availabilityTypes.available} marginRight />
                                <Typography>{t('availability:available')}</Typography>
                            </MenuItem>

                            <MenuItem value={availabilityTypes.unavailable}>
                                <AvailabilityIcon status={availabilityTypes.unavailable} marginRight />
                                <Typography>{t('availability:unavailable')}</Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </DialogActions>
            </DialogContent>

            <LoadingBar isLoading={updateAvailabilityMutation.isLoading} title={t('common:loading')} />
        </Dialog>
    );
};
