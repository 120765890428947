import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { DayPickerRangeController } from 'react-dates';
import 'components/datePicker/datePicker.css';
import PropTypes from 'prop-types';

import { renderDayContents } from 'containers/RequestTimeOff/requestContent/DatePickerDayContents';
import {
    isDayBlocked,
    isOutsideRange,
    isDayHighlighted,
    splitOutTimeOffUsingDates,
    transformToMomentObject,
} from 'containers/RequestTimeOff/utils';
import { getBlockedErrors } from '../utils';
import { formats } from 'utils/constants';

import { useSettings } from 'services/useSettings';
import { useBlockedTimeOff, useApprovedTimeOff } from 'services/useHoliday';
import { selectRequestedStartDate, selectRequestedEndDate, selectErrorInDates } from '../requestTimeOffSelectors';
import { setDates, setBlockedErrors, setErrorInDates, clearRequestError } from '../requestTimeOffSlice';

const formatMoment = (momentDate) => (momentDate ? moment(momentDate).format(formats.BACKEND_FORMAT) : '');

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 5,
        top: 'calc(50vh - 20px)',
        position: 'absolute',
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
        },
        right: theme.spacing(1),
    },
}));

const NavigationButton = ({ children }) => {
    const styles = useStyles();
    return <IconButton className={styles.button}>{children}</IconButton>;
};

NavigationButton.propTypes = {
    children: PropTypes.node,
};

const PreviousButton = () => (
    <NavigationButton>
        <ChevronLeft />
    </NavigationButton>
);

const NextButton = () => (
    <NavigationButton>
        <ChevronRight />
    </NavigationButton>
);

export const MobileDatePicker = () => {
    const dispatch = useDispatch();
    const {
        settings: {
            timeOff: { blockedPeriods },
        },
    } = useSettings();
    const { blockedTimeOff } = useBlockedTimeOff(blockedPeriods.enabled);
    const { approvedTimeOff } = useApprovedTimeOff();

    const [focusedInput, setFocusedInput] = useState('startDate');

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);
    const errorInDates = useSelector(selectErrorInDates);

    useEffect(() => {
        const { datePickerBlockedOrApprovedError, errorInDates: newErrorInDates } = getBlockedErrors({
            blockedTimeOff,
            approvedTimeOff,
            blockedPeriodsEnabled: blockedPeriods.enabled,
            startDate,
            endDate,
        });

        dispatch(clearRequestError());
        dispatch(setBlockedErrors(datePickerBlockedOrApprovedError));
        dispatch(setErrorInDates(newErrorInDates));
    }, [startDate, endDate, dispatch, blockedTimeOff, approvedTimeOff, blockedPeriods]);

    const onDateChangeHandler = (event) =>
        dispatch(setDates({ startDate: formatMoment(event.startDate), endDate: formatMoment(event.endDate) }));

    const listTimeOff = splitOutTimeOffUsingDates([...approvedTimeOff]);

    const isErrorDates = [...errorInDates].length;

    return (
        <DayPickerRangeController
            navPrev={<PreviousButton />}
            navNext={<NextButton />}
            focusedInput={focusedInput || 'startDate'}
            startDate={transformToMomentObject(startDate)}
            endDate={transformToMomentObject(endDate)}
            onDatesChange={(event) => onDateChangeHandler(event)}
            isDayBlocked={(date) => isDayBlocked(date, listTimeOff, blockedTimeOff, blockedPeriods.enabled)}
            isOutsideRange={(day) => isOutsideRange(day)}
            isDayHighlighted={(day) => isErrorDates && isDayHighlighted(day, errorInDates)}
            onFocusChange={(input) => setFocusedInput(input)}
            numberOfMonths={2}
            hideKeyboardShortcutsPanel
            orientation="vertical"
            transitionDuration={0}
            noBorder
            renderDayContents={(day, modifiers) => renderDayContents(day, modifiers, listTimeOff)}
        />
    );
};
