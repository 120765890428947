import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    scrollableContent: {
        [theme.breakpoints.down('sm')]: {
            height: 331,
        },
    },
}));

const StyledCardComponent = ({ children, className }) => {
    const classes = useStyles();

    return <div className={classNames(classes.scrollableContent, { [className]: true })}>{children}</div>;
};

StyledCardComponent.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.object,
};

export default StyledCardComponent;
