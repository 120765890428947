import PropTypes from 'prop-types';
import { DatePagination } from 'components/datePicker/DatePagination';
import { PrimaryButtonRequest } from 'components/PrimaryButtonRequest';
import { makeStyles, Grid, Hidden } from '@material-ui/core';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
    handlePaginationNext,
    handlePaginationBack,
    handleDatesChange,
    handleResetDates,
    onFocusChange,
} from 'containers/Schedule/store/scheduleSlice';
import {
    selectStartDate,
    selectEndDate,
    selectMwvDate,
    selectDefaultStartDate,
    selectFocusedInput,
} from 'containers/Schedule/store/scheduleSelector';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 20,
        background: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            padding: 10,
        },
    },
    paginationTitle: {
        color: theme.palette.grey[800],
    },
}));

export const ControlPanel = ({ multiWeekViewTabId, tabValue }) => {
    const classes = useStyles();
    const { moment } = useMoment();
    const dispatch = useDispatch();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const mwvDate = useSelector(selectMwvDate);
    const defaultStartDate = useSelector(selectDefaultStartDate);
    const focusedInput = useSelector(selectFocusedInput);
    const isMultiWeekViewTab = multiWeekViewTabId === tabValue;

    const isCurrentPeriod = () =>
        isMultiWeekViewTab ? moment(mwvDate).isSame(moment(), 'month') : moment(startDate).isSame(defaultStartDate);

    return (
        <Grid container className={classes.container} justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={9}>
                <DatePagination
                    refreshButtonOnClick={() => dispatch(handleResetDates(isMultiWeekViewTab))}
                    startDate={startDate ? moment(startDate) : moment()}
                    endDate={endDate ? moment(endDate) : moment()}
                    mwvDate={moment(mwvDate)}
                    handleNext={() => dispatch(handlePaginationNext(isMultiWeekViewTab))}
                    handleBack={() => dispatch(handlePaginationBack(isMultiWeekViewTab))}
                    isCurrentPeriod={isCurrentPeriod()}
                    onFocusChange={(e) => dispatch(onFocusChange(e))}
                    focusedInput={focusedInput}
                    handleDatesChange={(event) => dispatch(handleDatesChange(event))}
                    isWeekTabView={tabValue === multiWeekViewTabId}
                    period={tabValue === multiWeekViewTabId ? 'Month' : ''}
                />
            </Grid>

            <Hidden smDown>
                <Grid item xs={12} md={3}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <PrimaryButtonRequest />
                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </Grid>
    );
};

ControlPanel.propTypes = {
    isCurrentPeriod: PropTypes.bool,
    multiWeekViewTabId: PropTypes.string,
    tabValue: PropTypes.string.isRequired,
};
