import PropTypes from 'prop-types';
import { TableRow, TableCell, makeStyles, Tooltip, Zoom } from '@material-ui/core';
import { LONG_DATE_FORMAT, statuses } from './constants';
import { OfferButtons } from './OfferButtons';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    acceptButton: {
        marginRight: theme.spacing(1),
    },
    tableCell: {
        padding: 12,
    },
    notesCell: {
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'default',
    },
}));

export const OfferRow = ({
    offer: {
        id,
        status,
        updatedAt,
        notes,
        shift: { startDate, endDate, location, role },
    },
    hideButtons = false,
}) => {
    const classes = useStyles();

    const { moment } = useMoment();

    const date = moment(startDate).format(LONG_DATE_FORMAT);

    const startTime = moment(startDate).format('HH:mm');
    const endTime = moment(endDate).format('HH:mm');

    return (
        <TableRow key={id} data-testid="OfferRow">
            <TableCell className={classes.tableCell}>
                <b>{date}</b>
            </TableCell>
            <TableCell className={classes.tableCell}>{location}</TableCell>
            <TableCell className={classes.tableCell}>{role}</TableCell>
            <TableCell className={classes.tableCell}>{startTime}</TableCell>
            <TableCell className={classes.tableCell}>{endTime}</TableCell>

            {notes ? (
                <Tooltip data-testid="notesTooltip" title={notes} TransitionComponent={Zoom}>
                    <TableCell className={classes.notesCell}>{notes}</TableCell>
                </Tooltip>
            ) : (
                <TableCell>-</TableCell>
            )}

            {!hideButtons && (
                <TableCell className={classes.tableCell}>
                    {status === statuses.pending ? <OfferButtons id={id} /> : moment(updatedAt).format('DD-MM-YYYY')}
                </TableCell>
            )}
        </TableRow>
    );
};

OfferRow.propTypes = {
    offer: PropTypes.exact({
        id: PropTypes.number.isRequired,
        status: PropTypes.oneOf([statuses.pending, statuses.accepted, statuses.declined, statuses.unavailable]),
        updatedAt: PropTypes.string.isRequired,
        createdAt: PropTypes.string,
        notes: PropTypes.string,
        shift: PropTypes.exact({
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }).isRequired,
    }),
    hideButtons: PropTypes.bool,
};
