import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FormHelperText, InputLabel, makeStyles, MenuItem, Select, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import { roundAndTrimNumber } from 'utils/timeFormatters';

import { selectRequestedStartDate, selectRequestedEndDate } from '../requestTimeOffSelectors';

const useStyle = makeStyles((theme) => ({
    select: {
        width: '100%',
        marginTop: '10px',
        fontSize: '16px',
        backgroundColor: theme.palette.grey[50],
    },
    helperText: {
        marginBottom: '16px',
    },
    hoursLabelStyle: {
        paddingRight: 6,
        [theme.breakpoints.down('sm')]: {
            width: theme.requestTimeOffForm.labelStyle.width,
            paddingRight: 3,
        },
    },
}));

export const DaySelector = ({ value, daysInputId, isContractSelector, onChange }) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const { inputStyle } = useTheme().requestTimeOffForm;

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);

    const days = moment(endDate).diff(moment(startDate), 'days') + 1;
    const totalDays = roundAndTrimNumber(value * days) || 0;

    return (
        <>
            <InputLabel id={daysInputId} className={classNames({ [classes.hoursLabelStyle]: isContractSelector })}>
                {t('common:days_label')}
            </InputLabel>
            <Select
                style={isContractSelector && { ...inputStyle, width: '150px' }}
                className={classNames({ [classes.select]: !isContractSelector })}
                variant="outlined"
                labelId={daysInputId}
                id="demo-simple-select"
                value={value || 1}
                onChange={onChange}
            >
                <MenuItem value={1}>{t('units:full_day')}</MenuItem>
                <MenuItem value={0.5}>{t('units:half_day')}</MenuItem>
            </Select>
            {!isContractSelector && (
                <FormHelperText className={classNames({ [classes.helperText]: !isContractSelector })}>
                    {t('requestTimeOff:helper_text.total_days', { totalDays })}
                </FormHelperText>
            )}
        </>
    );
};

DaySelector.propTypes = {
    daysInputId: PropTypes.string.isRequired,
    isContractSelector: PropTypes.bool,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};
