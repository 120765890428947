import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    areSettingsFetched: false,
    settingsError: false,
    settings: {},
    logo: '',
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateSetting(state, { payload }) {
            state.settings = payload;
        },
    },
});

export const {
    reducer: settingsReducer,
    actions: { updateSetting },
} = settingsSlice;
