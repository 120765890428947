import DefaultButton from 'components/buttons/DefaultButton';
import { RequestTimeOff } from '../containers/RequestTimeOff/RequestTimeOff';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '13px 20px',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const PrimaryRequestComponent = ({ ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <RequestTimeOff
            button={
                <DefaultButton size="large" color="primary" variant="contained" {...rest} className={classes.button}>
                    {t('time_off:request_time_off_button')}
                </DefaultButton>
            }
        />
    );
};

PrimaryRequestComponent.propTypes = {
    rest: PropTypes.object,
};

export const PrimaryButtonRequest = PrimaryRequestComponent;
