import { useDispatch, useSelector } from 'react-redux';
import { DatePagination } from 'components/datePicker/DatePagination';
import { useTranslation } from 'react-i18next';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

import { useHolidayPeriods } from 'services/useHoliday';

import { setStartDate, setEndDate, setPaginationDateIndex } from '../timeOffSlice';
import { selectStartDate, selectEndDate, selectPaginationDateIndex, selectDefaultIndex } from '../timeOffSelectors';

export const HeadlineDatePicker = () => {
    const { moment } = useMoment();
    const dispatch = useDispatch();

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const paginationDateIndex = useSelector(selectPaginationDateIndex);
    const defaultIndex = useSelector(selectDefaultIndex);

    const { t } = useTranslation();
    const { holidayPeriods } = useHolidayPeriods(true);

    return (
        <DatePagination
            isWeekPeriod={false}
            resetButtonText={t('time_off:this_year_button')}
            isCurrentPeriod={moment(startDate).isBefore(moment()) && moment(endDate).isAfter(moment())}
            startDate={startDate ? moment(startDate) : null}
            endDate={endDate ? moment(endDate) : null}
            backDisabled={paginationDateIndex === 0}
            showArrowsOnMobile={true}
            nextDisabled={paginationDateIndex === holidayPeriods.length - 1}
            noMobileView
            period={t('common:year')}
            refreshButtonOnClick={() => {
                dispatch(setPaginationDateIndex(defaultIndex));
                dispatch(setStartDate(holidayPeriods[defaultIndex].start));
                dispatch(setEndDate(holidayPeriods[defaultIndex].end));
            }}
            handleBack={() => {
                dispatch(setPaginationDateIndex(paginationDateIndex - 1));
                dispatch(setStartDate(holidayPeriods[paginationDateIndex - 1].start));
                dispatch(setEndDate(holidayPeriods[paginationDateIndex - 1].end));
            }}
            handleNext={() => {
                dispatch(setPaginationDateIndex(paginationDateIndex + 1));
                dispatch(setStartDate(holidayPeriods[paginationDateIndex + 1].start));
                dispatch(setEndDate(holidayPeriods[paginationDateIndex + 1].end));
            }}
            handleDatesChange={() => null}
            onFocusChange={() => null}
            focusedInput={null}
        />
    );
};
