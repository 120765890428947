import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { statuses, offerFields } from './constants';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    tableCellPadding: {
        padding: '0px 5px 5px 10px',
    },
}));

export const OffersHeadRow = ({ tabValue, hideButtons = false }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <TableHead data-testid="OffersHeadRow">
            <TableRow>
                {offerFields.map(({ transKey }) => (
                    <TableCell key={transKey} className={classes.tableCellPadding}>
                        {t(transKey)}
                    </TableCell>
                ))}

                {!hideButtons && (
                    <TableCell>
                        {(() => {
                            switch (tabValue) {
                                case statuses.accepted:
                                    return t('shift_offer:accepted_on');
                                case statuses.declined:
                                    return t('shift_offer:declined_on');
                                case statuses.pending:
                                default:
                                    return '';
                            }
                        })()}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

OffersHeadRow.propTypes = {
    tabValue: PropTypes.oneOf([statuses.pending, statuses.accepted, statuses.declined, statuses.unavailable])
        .isRequired,
    hideButtons: PropTypes.bool,
};
