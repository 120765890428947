import { func, string, bool } from 'prop-types';
import { makeStyles, Grid, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { useIsMediumScreenOrSmaller } from 'utils/components/helpers';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    datePickerText: {
        fontSize: '14px',
        margin: '10px',
        [theme.breakpoints.down('xs')]: {
            minWidth: '90px',
            fontSize: '12px',
            fontWeight: 600,
        },
    },
    iconButton: {
        background: theme.palette.grey[300],
        padding: 3,
        transition: 'all 0.2s ease',
        '&:hover': {
            background: theme.palette.grey[400],
        },
        '&:disabled': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    paginationContainer: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginTop: '20px',
            marginBottom: '20px',
        },
    },
}));

export const PotsPagination = ({
    handleBackPagination,
    handleForwardPagination,
    startDate,
    endDate,
    backDisabled,
    forwardDisabled,
}) => {
    const classes = useStyles();
    const { moment } = useMoment();

    const isMediumScreenOrSmaller = useIsMediumScreenOrSmaller();
    const format = isMediumScreenOrSmaller ? 'D MMM YY' : 'D MMMM YYYY';
    const formatDate = (date) => moment(date, 'YYYY-MM-DD').format(format);

    return (
        <Grid container alignItems="center" className={classes.paginationContainer}>
            <IconButton
                className={classes.iconButton}
                onClick={handleBackPagination}
                disabled={backDisabled}
                size="small"
            >
                <ChevronLeft />
            </IconButton>
            <Typography className={classes.datePickerText}>{`${formatDate(startDate)} - ${formatDate(
                endDate,
            )}`}</Typography>
            <IconButton
                className={classes.iconButton}
                onClick={handleForwardPagination}
                disabled={forwardDisabled}
                size="small"
            >
                <ChevronRight />
            </IconButton>
        </Grid>
    );
};

PotsPagination.propTypes = {
    handleForwardPagination: func.isRequired,
    handleBackPagination: func.isRequired,
    startDate: string.isRequired,
    endDate: string.isRequired,
    backDisabled: bool.isRequired,
    forwardDisabled: bool.isRequired,
};
