import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import DefaultButton from 'components/buttons/DefaultButton';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useTenant } from 'services/auth';

const useStyles = makeStyles({
    button: {
        width: '100%',
        textAlign: 'center',
    },
    label: {
        textAlign: 'left',
    },
});

export const FormFooter = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const tenant = useTenant()?.data;

    if (tenant === null) {
        return null;
    }
    const defaultLink = 'https://staging4.theshopworks.com/login';
    const adminUrl = tenant.adminUrl;

    return (
        <Grid container item>
            <Grid item xs={12}>
                <DefaultButton
                    color="secondary"
                    variant="text"
                    href={adminUrl || defaultLink}
                    className={classes.button}
                >
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={2}>
                            <SupervisedUserCircle fontSize="large" />
                        </Grid>
                        <Grid item xs={8} className={classes.label}>
                            {t('portal:change_to')}
                            <br />
                            {t('portal:workforce_management')}
                        </Grid>
                        <Grid item xs={2}>
                            <ArrowForward />
                        </Grid>
                    </Grid>
                </DefaultButton>
            </Grid>
        </Grid>
    );
};
