import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';

import { DatePicker } from 'components/datePicker/DatePicker';
import { renderDayContents } from './DatePickerDayContents';

import {
    isDayBlocked,
    isOutsideRange,
    isDayHighlighted,
    splitOutTimeOffUsingDates,
    transformToMomentObject,
} from 'containers/RequestTimeOff/utils';

import { useIsMobileOrTablet } from 'utils/components/helpers';
import { getBlockedErrors } from '../utils';
import { useSettings } from 'services/useSettings';
import { useBlockedTimeOff, useApprovedTimeOff } from 'services/useHoliday';

import { selectRequestedStartDate, selectRequestedEndDate, selectErrorInDates } from '../requestTimeOffSelectors';
import { setDates, setBlockedErrors, setErrorInDates, clearRequestError } from '../requestTimeOffSlice';

const useStyles = makeStyles((theme) => ({
    datePickerItem: {
        margin: '15px 0',
        height: '320px',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            padding: 'auto',
        },
    },
}));

export const DatePickerContent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isMobileOrTablet = useIsMobileOrTablet();

    const {
        settings: {
            timeOff: { blockedPeriods },
        },
    } = useSettings();
    const { blockedTimeOff } = useBlockedTimeOff(blockedPeriods.enabled);
    const { approvedTimeOff } = useApprovedTimeOff();

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);
    const errorInDates = useSelector(selectErrorInDates);

    useEffect(() => {
        const { datePickerBlockedOrApprovedError, errorInDates: newErrorInDates } = getBlockedErrors({
            blockedTimeOff,
            approvedTimeOff,
            blockedPeriodsEnabled: blockedPeriods.enabled,
            startDate,
            endDate,
        });

        dispatch(clearRequestError());
        dispatch(setBlockedErrors(datePickerBlockedOrApprovedError));
        dispatch(setErrorInDates(newErrorInDates));
    }, [startDate, endDate, blockedTimeOff, approvedTimeOff, blockedPeriods.enabled, dispatch]);

    const [focusedInput, setFocusedInput] = useState('startDate');

    const onDatesChangeHandler = (event) => {
        const end = event.endDate ? event.endDate.format('YYYY-MM-DD') : null;
        const start = event.startDate ? event.startDate.format('YYYY-MM-DD') : end;

        dispatch(setDates({ startDate: start, endDate: end }));
    };

    const listTimeOff = splitOutTimeOffUsingDates(approvedTimeOff);

    return (
        <Grid container justifyContent="center" className={classes.datePickerItem}>
            <DatePicker
                orientation="horizontal"
                onDatesChange={(event) => onDatesChangeHandler(event)}
                startDate={transformToMomentObject(startDate)}
                endDate={transformToMomentObject(endDate)}
                noBorder
                showInputs={false}
                numberOfMonths={isMobileOrTablet ? 1 : 2}
                focusedInput={focusedInput}
                isDayBlocked={(date) => isDayBlocked(date, listTimeOff, blockedTimeOff)}
                isOutsideRange={(day) => isOutsideRange(day)}
                isDayHighlighted={(day) => errorInDates.length && isDayHighlighted(day, errorInDates)}
                onFocusChange={(input) => setFocusedInput(input ? input : 'startDate')}
                renderDayContents={(day, modifiers) => renderDayContents(day, modifiers, listTimeOff)}
            />
        </Grid>
    );
};
