import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

import { DateRow } from './DateRow';
import { ShopButtons } from './ShopButtons';
import { StaffSchedule } from './StaffSchedule';
import { getAllShopsInShifts } from './helpers';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { useAllStaffShiftsAndTimeOff } from 'services/schedule';
import { useSelector } from 'react-redux';
import { useTimeOffTypes } from 'services/useHoliday';
import LoadingComponent from 'components/LoadingComponent';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    topSpacer: {
        marginTop: theme.spacing(3),
    },
    containerBg: {
        background: theme.palette.common.white,
    },
}));

export const ShopSchedule = ({ id }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [allButtons, setAllButtons] = useState([]);
    const [currentShop, setCurrentShop] = useState(null);
    const { allTimeOffTypes, isTimeOffTypesSuccess } = useTimeOffTypes();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const { periodSchedule, isPeriodScheduleLoading, isPeriodScheduleError } = useAllStaffShiftsAndTimeOff(
        moment(startDate),
        moment(endDate),
        allTimeOffTypes,
        isTimeOffTypesSuccess,
    );

    useEffect(() => {
        if (periodSchedule) {
            const allShopsInShifts = getAllShopsInShifts(periodSchedule);

            setAllButtons(allShopsInShifts);
            setCurrentShop(allShopsInShifts[0]);
        }
    }, [periodSchedule]);

    if (isPeriodScheduleError) {
        return null;
    }

    if (isPeriodScheduleLoading) {
        return <LoadingComponent title={t('common:loading')} />;
    }

    return (
        <Grid container className={classes.containerBg} id={id} data-testid="shop-schedule-tab-content">
            {allButtons.length > 1 && (
                <ShopButtons shops={allButtons} currentShop={currentShop} setCurrentShop={setCurrentShop} />
            )}
            <Grid item xs={12}>
                <DateRow />

                <StaffSchedule currentShop={currentShop} />
            </Grid>
        </Grid>
    );
};

ShopSchedule.propTypes = {
    id: PropTypes.string.isRequired,
};
