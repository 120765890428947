import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { useIsMobile } from 'utils/components/helpers';
import { useGetHoursAndMinutes, roundAndTrimNumber } from 'utils/timeFormatters';
import BulletPoint from 'components/BulletPoint';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/useSettings';

const useStyles = makeStyles((theme) => ({
    holidaySegment: {
        height: 'fit-content',
        flexDirection: 'column',
        padding: 20,
        borderRight: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: 'none',
        [theme.breakpoints.down('md')]: {
            padding: 10,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            borderRight: 'none',
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
        '&:last-child': {
            border: 'none',
        },
    },
    segmentElement: {
        flex: '0 0 auto',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            flex: '1 0 0',
            justifyContent: 'flex-start',
            textAlign: 'left',
        },
    },
    biggerText: {
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
    spacer: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
        },
    },
}));

const segments = {
    entitlement: {
        color: 'primary',
        name: 'entitlement:holiday',
        nameWithUnit: 'entitlement:holiday_with_unit',
    },
    approved: {
        color: 'disabled',
        name: 'entitlement:approved',
        nameWithUnit: 'entitlement:approved_with_unit',
    },
    used: {
        color: 'danger',
        name: 'entitlement:used',
        nameWithUnit: 'entitlement:used_with_unit',
    },
    training: {
        color: 'orange',
        name: 'entitlement:training',
        nameWithUnit: 'entitlement:training_with_unit',
    },
    requested: {
        color: 'secondary',
        name: 'entitlement:requested',
        nameWithUnit: 'entitlement:requested_with_unit',
    },
    outstanding: {
        color: 'disabled',
        name: 'entitlement:outstanding',
        nameWithUnit: 'entitlement:outstanding_with_unit',
    },
    lieuTime: {
        color: 'orange',
        name: 'entitlement:lieu',
        nameWithUnit: 'entitlement:lieu_with_unit',
        unit: 'hours',
    },
    carriedForward: {
        color: 'secondary',
        name: 'entitlement:carried_forward',
        nameWithUnit: 'entitlement:carried_forward_with_unit',
    },
};

export const HolidayEntitlementSegment = ({ segment, entitlement, units }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const getHoursAndMinutes = useGetHoursAndMinutes();
    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();

    const definedUnit = segments[segment].unit || units;

    const isHoursWithMins = showHoursWithMinutes && (units === 'hours' || units === 'both');
    const replaceHoursWithMinutes = isHoursWithMins
        ? getHoursAndMinutes(entitlement.hours)
        : roundAndTrimNumber(entitlement.hours);

    const getHoursUnit = () => (entitlement.hours === 1 ? t('common:hour') : t('common:hours'));
    const getDaysUnit = () => (entitlement.hours === 1 ? t('common:day') : t('common:days'));

    return (
        <Grid
            container
            item
            xs={12}
            md
            className={classes.holidaySegment}
            alignItems="center"
            data-testid="holiday-entitlement-segment-root"
        >
            <Grid container item xs={2} sm={1} md className={classes.segmentElement}>
                <BulletPoint className={classes.spacer} color={segments[segment].color} type="rounded" />
            </Grid>
            <Grid
                container
                item
                xs={isHoursWithMins ? 5 : 2}
                sm={isHoursWithMins ? 2 : 1}
                md
                className={classes.segmentElement}
            >
                <Typography className={classes.biggerText} data-testid="holiday-entitlement-segment-amount">
                    {definedUnit === 'both' || definedUnit === 'hours'
                        ? replaceHoursWithMinutes
                        : roundAndTrimNumber(entitlement.days)}
                </Typography>
            </Grid>
            {definedUnit === 'both' && (
                <Grid item xs sm md className={classes.segmentElement}>
                    <Typography data-testid="holiday-entitlement-segment-hours">
                        {isHoursWithMins
                            ? t(segments[segment].name)
                            : t(segments[segment].nameWithUnit, { unit: getHoursUnit() })}
                    </Typography>
                    {!isMobile && (
                        <Typography data-testid="holiday-entitlement-segment-days">
                            {`(${roundAndTrimNumber(entitlement.days)} ${getDaysUnit()})`}
                        </Typography>
                    )}
                </Grid>
            )}
            {definedUnit === 'hours' && (
                <Grid item xs sm md className={classes.segmentElement}>
                    <Typography data-testid="holiday-entitlement-segment-hours">
                        {isHoursWithMins
                            ? t(segments[segment].name)
                            : t(segments[segment].nameWithUnit, { unit: getHoursUnit() })}
                    </Typography>
                </Grid>
            )}
            {definedUnit === 'days' && (
                <Grid item xs sm md className={classes.segmentElement}>
                    <Typography data-testid="holiday-entitlement-segment-days">
                        {t(segments[segment].nameWithUnit, { unit: getDaysUnit() })}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

HolidayEntitlementSegment.propTypes = {
    segment: PropTypes.string.isRequired,
    entitlement: PropTypes.object.isRequired,
    units: PropTypes.string.isRequired,
};
