import moment from 'moment';

export const findShiftTense = ({ endDate, startDate }) => {
    if (moment(endDate).isBefore(moment())) {
        return 'disabled';
    } else if (moment(startDate).isSame(moment(), 'day')) {
        return 'present';
    }

    return 'future';
};
