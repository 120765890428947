import { createSlice } from '@reduxjs/toolkit';

export const availabilityTypes = {
    available: 'available',
    unavailable: 'unavailable',
};

const initialState = {
    isDialogOpen: false,
    dialogDate: '',
    dialogStatus: availabilityTypes.available,
};

const scheduleSlice = createSlice({
    name: 'availability',
    initialState,
    reducers: {
        openAvailabilityDialog(state, { payload: { currentStatus, date } }) {
            state.dialogDate = date;
            state.dialogStatus = currentStatus;
            state.isDialogOpen = true;
        },
        setAvailabilityNewStatus(state, { payload: { newStatus } }) {
            state.dialogStatus = newStatus;
        },
        closeAvailabilityDialog(state) {
            state.isDialogOpen = false;
        },
    },
});

export const {
    reducer: AvailabilityReducer,
    actions: { openAvailabilityDialog, setAvailabilityNewStatus, closeAvailabilityDialog },
} = scheduleSlice;
