import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { VARIABLE_UNAVAILABLE, VARIABLE_AVAILABLE } from 'utils/constants';
import { calcCanEditAvailability } from 'containers/Schedule/utils';
import { iso8601 } from 'utils/timeFormatters';
import { useSettings } from 'services/useSettings';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { scheduleEvents } from 'containers/Schedule/helpers';
import { useAllStaffShiftsAndTimeOff } from 'services/schedule';
import { useUnavailableDaysForUser } from 'services/availability';
import { useTimeOffTypes } from 'services/useHoliday';

const useStyles = makeStyles((theme) => ({
    timeOff: {
        backgroundColor: theme.palette.primary.light,
        opacity: 0.5,
    },
    unavailable: {
        backgroundColor: theme.palette.danger.transparent,
    },
    available: {
        backgroundColor: theme.palette.primary.transparent,
    },
    columnSize: {
        position: 'absolute',
        margin: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    columnLabel: {
        color: theme.palette.primary.dark,
        opacity: 1.5,
    },
    boldText: {
        fontWeight: 500,
    },
    unavailableText: {
        color: theme.palette.danger.dark,
    },
    cannotClick: {
        cursor: 'default',
    },
    canClick: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

export const LeaveEvent = ({ event: { role, timeOff, status, statusKey, isStaffAvailabilityEvent, start } }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        settings: {
            staffAvailability: { overallEnabled: isStaffAvailabilityEnabled },
        },
    } = useSettings();

    const { allTimeOffTypes, isTimeOffTypesSuccess } = useTimeOffTypes();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const { unavailableDays } = useUnavailableDaysForUser(startDate, endDate, isStaffAvailabilityEnabled);

    const { periodSchedule } = useAllStaffShiftsAndTimeOff(
        moment(startDate),
        moment(endDate),
        allTimeOffTypes,
        isTimeOffTypesSuccess,
    );

    const scheduleEventsData = scheduleEvents(
        isStaffAvailabilityEnabled,
        periodSchedule,
        unavailableDays,
        startDate,
        endDate,
    );

    if (timeOff || isStaffAvailabilityEvent) {
        const disableClick = isStaffAvailabilityEvent && !calcCanEditAvailability(iso8601(start), scheduleEventsData);

        const isAvailableEvent = isStaffAvailabilityEvent && status === VARIABLE_AVAILABLE;
        const isUnavailableEvent = isStaffAvailabilityEvent && status === VARIABLE_UNAVAILABLE;

        let title = '';

        if (isAvailableEvent) {
            title = t('availability:available');
        } else if (isUnavailableEvent) {
            title = t('availability:unavailable');
        }

        return (
            <Grid
                container
                className={classNames({
                    [classes.columnSize]: true,
                    [classes.timeOff]: timeOff,
                    [classes.unavailable]: isUnavailableEvent,
                    [classes.available]: isAvailableEvent,
                    [classes.cannotClick]: disableClick,
                    [classes.canClick]: !disableClick,
                })}
                alignItems="center"
                title={title}
            >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                align="center"
                                className={classNames({
                                    [classes.columnLabel]: true,
                                    [classes.boldText]: isStaffAvailabilityEvent,
                                    [classes.unavailableText]: isUnavailableEvent,
                                })}
                                data-testid="YourSchedule-leave-event-timeoff-role"
                            >
                                {role}
                            </Typography>
                        </Grid>
                        {!isStaffAvailabilityEvent && (
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    className={classes.columnLabel}
                                    data-testid="YourSchedule-leave-event-timeoff-status"
                                >
                                    {statusKey ? t(statusKey) : status}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return null;
};

LeaveEvent.propTypes = {
    isMobile: PropTypes.any,
    event: PropTypes.shape({
        role: PropTypes.string,
        timeOff: PropTypes.any,
        status: PropTypes.string,
        statusKey: PropTypes.string,
        isStaffAvailabilityEvent: PropTypes.bool,
        start: PropTypes.instanceOf(Date),
    }),
};
