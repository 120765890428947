import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TextArea from 'components/TextArea';
import { isNewPasswordValid } from '../helpers';
import { useUpdatePassword } from 'services/auth';
import { ChangePasswordSubmit } from './ChangePasswordSubmit';

const useStyles = makeStyles((theme) => ({
    warningMessage: {
        color: theme.palette.danger.main,
        marginBottom: '10px',
        marginRight: '5px',
    },
    labelClassName: {
        color: theme.palette.danger.main,
    },
}));

export const ChangePassword = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { updatePasswordMutation } = useUpdatePassword();

    const [showPassword, setShowPassword] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [warningMessage, setWarningMessage] = useState('');
    const [passwordWarningMessage, setPasswordWarningMessage] = useState('');

    const [isNewPasswordError, setIsNewPasswordError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [failedRules, setFailedRules] = useState([]);

    const updateNewPassword = (value) => {
        setFailedRules([]);
        setNewPassword(value);
        setIsNewPasswordError(isNewPasswordValid(currentPassword, value, confirmPassword));
        setWarningMessage(
            value.length < 8 ? t('user:password_errors.length') : t('user:password_errors.confirmation_missing'),
        );
    };

    const updateConfirmPassword = (value) => {
        setFailedRules([]);
        setConfirmPassword(value);
        setIsNewPasswordError(isNewPasswordValid(currentPassword, newPassword, value));
        setWarningMessage(t('user:password_errors.matching_passwords'));
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton tabIndex="-1" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    const warning = (message) => (
        <Grid container alignItems="flex-end">
            <Typography className={classes.warningMessage}>{message}</Typography>
        </Grid>
    );

    const submitProps = {
        mutation: updatePasswordMutation,
        currentPassword,
        newPassword,
        confirmPassword,
        setIsPasswordError,
        setIsNewPasswordError,
        setCurrentPassword,
        setNewPassword,
        setConfirmPassword,
        setWarningMessage,
        setFailedRules,
        setPasswordWarningMessage,
    };

    return (
        <>
            <TextArea
                handleChange={(event) => setCurrentPassword(event.target.value)}
                label={isPasswordError ? warning(passwordWarningMessage) : t('user:current_password')}
                variant="outlined"
                multiline={false}
                value={currentPassword}
                error={isPasswordError}
                data-testid="current-password"
                placeholder={t('user:enter_current_password')}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                InputProps={{
                    endAdornment,
                    readOnly: updatePasswordMutation.isLoading,
                }}
            />
            <TextArea
                multiline={false}
                variant="outlined"
                label={isNewPasswordError ? warning(warningMessage) : t('user:new_password')}
                error={isNewPasswordError}
                placeholder={t('user:enter_new_password')}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={newPassword}
                handleChange={(event) => updateNewPassword(event.target.value)}
                InputProps={{
                    endAdornment,
                    readOnly: updatePasswordMutation.isLoading,
                }}
            />
            <TextArea
                multiline={false}
                variant="outlined"
                label={t('user:confirm_password')}
                labelClassName={isNewPasswordError ? classes.labelClassName : null}
                placeholder={t('user:confirm_password')}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                error={isNewPasswordError}
                value={confirmPassword}
                handleChange={(event) => updateConfirmPassword(event.target.value)}
                InputProps={{
                    endAdornment,
                    readOnly: updatePasswordMutation.isLoading,
                }}
            />
            {isNewPasswordError &&
                failedRules.map((rule) => (
                    <Typography className={classes.labelClassName} key={rule}>
                        * {rule}
                    </Typography>
                ))}
            <Grid container justifyContent="flex-end">
                <ChangePasswordSubmit {...submitProps} />
            </Grid>
        </>
    );
};
