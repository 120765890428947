export const ABOUT_SHOPWORKS_LINK = {
    translationKey: 'portal:about_shopworks',
    url: 'https://www.theshopworks.com/about-us',
};
export const PRIVACY_LINK = {
    translationKey: 'portal:privacy',
    url: 'https://www.theshopworks.com/privacy-policy',
};

export const HELP_LINK = {
    translationKey: 'portal:help',
    url: 'https://theshopworks.atlassian.net/servicedesk/customer/portals',
};
