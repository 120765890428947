import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, DialogContent, DialogActions, makeStyles, LinearProgress, Collapse } from '@material-ui/core';
import { DialogTitle } from './DialogTitle';
import { useIsMobile } from 'utils/components/helpers';

const useStyles = makeStyles({
    contentContainer: {
        paddingTop: 25,
    },
    buttonContainer: {
        padding: 25,
    },
    mobileButtonContainer: {
        justifyContent: 'center',
    },
});

export const DialogSW = ({ children, title, buttons, isLoading, onClose, ...rest }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    return (
        <Dialog onClose={onClose} {...rest}>
            <DialogTitle onClose={onClose}>{title}</DialogTitle>
            <DialogContent className={classes.contentContainer}>{children}</DialogContent>
            <DialogActions
                className={classNames(classes.buttonContainer, { [classes.mobileButtonContainer]: isMobile })}
            >
                {buttons}
            </DialogActions>
            <Collapse in={isLoading}>
                <LinearProgress style={{ marginTop: 22 }} />
            </Collapse>
        </Dialog>
    );
};

DialogSW.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(Object),
    buttons: PropTypes.node,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node.isRequired,
};
