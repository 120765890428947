import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { StaffShiftIndicator } from './StaffShiftIndicator';
import { normaliseEventsForDialog } from './helpers';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { setDialogOpen, setDialogEvents } from 'containers/Schedule/store/scheduleSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    defaultGrid: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    boldText: {
        fontWeight: 'bold',
    },
    cursor: {
        cursor: 'pointer',
    },
    generalRowText: {
        color: theme.palette.grey[600],
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    divider: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    innerPadding: {
        padding: theme.spacing(2, 2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 1),
        },
    },
    currentDay: {
        borderLeft: `solid ${theme.palette.secondary.main} 1px`,
        borderRight: `solid ${theme.palette.secondary.main} 1px`,
    },
    currentUserText: {
        color: theme.palette.common.black,
    },
    currentUserRow: {
        paddingRight: '12px',
        boxShadow: `0px 3px 5px ${theme.palette.grey.light}`,
        position: 'relative',
    },
}));

export const StaffScheduleRow = ({ currentWeekRota, isCurrentUser, currentShop }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isMobileOrTablet = useIsMobileOrTablet();

    if (!currentWeekRota || Object.entries(currentWeekRota).length === 0) {
        return null;
    }

    const openEventDialog = (shiftObject) => {
        if (!shiftObject.activity) {
            return null;
        }

        const name = isCurrentUser ? '' : currentWeekRota.fullName;

        dispatch(setDialogEvents(normaliseEventsForDialog(shiftObject.activity, name)));
        dispatch(setDialogOpen());
    };

    return (
        <Grid
            container
            className={classnames(classes.defaultGrid, {
                [classes.currentUserRow]: isCurrentUser,
            })}
        >
            <Grid item xs={3} sm={2} className={classes.divider}>
                <div className={classes.innerPadding}>
                    <Grid container direction="column">
                        <Grid item xs={12} sm>
                            <Typography
                                className={classnames(classes.boldText, classes.generalRowText, {
                                    [classes.currentUserText]: isCurrentUser,
                                })}
                            >
                                {currentWeekRota.fullName}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {currentWeekRota.weekRota.map((shiftObject, index) => (
                <Grid
                    item
                    xs
                    className={classnames(classes.divider, {
                        [classes.currentDay]: shiftObject.isToday,
                        [classes.cursor]: isMobileOrTablet && shiftObject.activity,
                    })}
                    key={`shift_${index}`}
                    onClick={() => isMobileOrTablet && openEventDialog(shiftObject)}
                >
                    <StaffShiftIndicator
                        currentShop={currentShop}
                        shiftObject={shiftObject}
                        isCurrentUser={isCurrentUser}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

StaffScheduleRow.propTypes = {
    className: PropTypes.string,
    currentWeekRota: PropTypes.object,
    isCurrentUser: PropTypes.bool,
    currentShop: PropTypes.shape({
        shopId: PropTypes.number.isRequired,
        shopName: PropTypes.string.isRequired,
    }),
};
