import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteUnavailableOnDate, postUnavailableOnDate, getUserUnavailableDays } from 'api/staffAvailability';
import { setShowSnackbar, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';
import { setAvailabilityNewStatus } from 'containers/Schedule/store/availabilitySlice.js';

export const useUnavailableDaysForUser = (startDate, endDate, isStaffAvailabilityEnabled) => {
    const { data, isLoading, isError } = useQuery(
        ['unavailableDaysForUser', startDate, endDate],
        () => getUserUnavailableDays(startDate, endDate),
        {
            enabled: isStaffAvailabilityEnabled,
        },
    );

    return {
        unavailableDays: data?.data.data,
        isUnavailableDaysLoading: isLoading,
        isUnavailableDaysError: isError,
    };
};

export const useUpdateAvailability = () => {
    const requestConfig = {
        available: {
            request: deleteUnavailableOnDate,
            successMessage: 'portal:snack_bar.delete_unavailable_status_success',
            errorMessage: 'portal:snack_bar.delete_unavailable_status_error',
        },
        unavailable: {
            request: postUnavailableOnDate,
            successMessage: 'portal:snack_bar.post_unavailable_status_success',
            errorMessage: 'portal:snack_bar.post_unavailable_status_error',
        },
    };

    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const mutation = useMutation(
        ({ newStatus, date }) => {
            const { request } = requestConfig[newStatus];

            return request(date);
        },
        {
            onSuccess: (req) => {
                dispatch(
                    setSnackBarSuccess({ message: `portal:snack_bar.${req.config.method}_unavailable_status_success` }),
                );
                dispatch(setShowSnackbar({ open: true }));
                queryClient.invalidateQueries('unavailableDaysForUser');
            },

            onMutate: (req) => {
                dispatch(setAvailabilityNewStatus({ newStatus: req.newStatus }));
            },
        },
    );

    return { updateAvailabilityMutation: mutation };
};
