import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    defaultStyles: {
        width: 10,
        height: 10,
    },
    rounded: {
        borderRadius: '50%',
    },
    square: {
        borderRadius: '2px',
    },
    primary: {
        background: theme.palette.primary.main,
    },
    orange: {
        background: theme.palette.warning.dark,
    },
    secondary: {
        background: theme.palette.secondary.main,
    },
    warning: {
        background: theme.palette.warning.main,
    },
    danger: {
        background: theme.palette.danger.main,
    },
    disabled: {
        background: theme.palette.grey[400],
    },
    default: {
        background: theme.palette.common.black,
    },
}));

const BulletPoint = ({ className, color, type, ...rest }) => {
    const classes = useStyles();

    return <div className={classnames(classes.defaultStyles, classes[color], classes[type], className)} {...rest} />;
};

BulletPoint.propTypes = {
    color: PropTypes.oneOf(['primary', 'orange', 'danger', 'warning', 'disabled', 'secondary']).isRequired,
    className: PropTypes.any,
    type: PropTypes.oneOf(['rounded', 'square']),
};

export default BulletPoint;
