import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles({
    defaultStyles: {
        fontSize: 14,
    },
});

const DefaultButton = ({ className, ...rest }) => {
    const classes = useStyles();

    return <Button className={classnames(classes.defaultStyles, className)} {...rest} />;
};

DefaultButton.propTypes = {
    className: PropTypes.any,
    children: PropTypes.node.isRequired,
};

export default DefaultButton;
