import PropTypes from 'prop-types';
import { Collapse, LinearProgress } from '@material-ui/core';

export const LoadingBar = ({ isLoading, noMargin = false, title = '' }) => (
    <Collapse in={isLoading}>
        {isLoading && <LinearProgress style={{ marginTop: noMargin ? 0 : 8 }} title={title} />}
    </Collapse>
);

LoadingBar.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    noMargin: PropTypes.bool,
    title: PropTypes.string,
};
