import moment from 'moment';

export const isDayBlocked = (day, timeOffPeriods, blockedTimeOff) => {
    const isDayInListOfPeriods = (periodList) =>
        periodList.some(({ start, end }) => moment(day).isBetween(start, end, 'day', '[]'));

    if (blockedTimeOff.periods && blockedTimeOff.periods.length > 0) {
        const blockedPeriods = blockedTimeOff.periods;

        const isInBlockedPeriod = isDayInListOfPeriods(blockedPeriods);

        if (isInBlockedPeriod) {
            return true;
        }
    }

    const isInTimeOffPeriod = isDayInListOfPeriods(timeOffPeriods);

    return isInTimeOffPeriod;
};
