import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { useUser } from 'services/auth';

import { NAMES_PANEL_ID } from './rotaIds';

const useStyles = makeStyles((theme) => ({
    namesContainer: {
        height: '100%',
        minWidth: theme.rota.nameColumnWidth,
        maxWidth: theme.rota.nameColumnWidth,
        [theme.breakpoints.down('md')]: {
            minWidth: theme.rota.nameColumnWidthSmall,
            maxWidth: theme.rota.nameColumnWidthSmall,
        },
        overflow: 'hidden',
        borderRight: theme.rota.border,
    },
    namesContentStyle: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    namesRowStyle: {
        width: '100%',
        height: theme.rota.rowHeight,
        paddingLeft: 10,
        paddingRight: 5,
        borderBottom: theme.rota.border,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textColor: {
        color: theme.palette.grey[600],
    },
    loggedInUserBorder: {
        borderLeft: theme.rota.borderLoggedInUser,
        borderTop: theme.rota.borderLoggedInUser,
        borderBottom: theme.rota.borderLoggedInUser,
    },
    loggedInUserTextColor: {
        color: theme.palette.common.black,
    },
}));

export const RotaNamesPanel = ({ staffPersonalInfo }) => {
    const classes = useStyles();
    const {
        userDetails: { internalStaffNumber },
    } = useUser();

    return (
        <div className={classes.namesContainer}>
            <div className={classes.namesContentStyle} id={NAMES_PANEL_ID}>
                {staffPersonalInfo.map(({ staffNumber, firstName, surname, employeeRoles }) => (
                    <div
                        key={staffNumber}
                        className={classNames({
                            [classes.namesRowStyle]: true,
                            [classes.loggedInUserBorder]: internalStaffNumber === staffNumber,
                        })}
                    >
                        <Typography
                            variant="subtitle2"
                            noWrap
                            className={classNames({
                                [classes.textColor]: true,
                                [classes.loggedInUserTextColor]: internalStaffNumber === staffNumber,
                            })}
                        >
                            <strong>{`${firstName} ${surname}`}</strong>
                        </Typography>

                        <Typography
                            variant="caption"
                            noWrap
                            className={classNames({
                                [classes.textColor]: true,
                                [classes.loggedInUserTextColor]: internalStaffNumber === staffNumber,
                            })}
                        >
                            {employeeRoles.map((role) => role).join('/')}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    );
};

RotaNamesPanel.propTypes = {
    staffPersonalInfo: PropTypes.arrayOf(
        PropTypes.exact({
            staffNumber: PropTypes.string.isRequired,
            firstName: PropTypes.string.isRequired,
            surname: PropTypes.string.isRequired,
            employeeRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
    ).isRequired,
};
