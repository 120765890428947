import { IconButton, makeStyles } from '@material-ui/core';
import { Forwardburger } from 'mdi-material-ui';
import MenuIcon from '@material-ui/icons/Menu';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectMobileMenuOpen } from '../../menuSelector';
import { setMobileMenuOpen, setUserMenuOpen } from '../../menuSlice';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        ...theme.menu.icon,
    },
    spacing: {
        marginRight: 5,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            padding: 10,
        },
    },
}));

const MobileMenuToggle = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mobileMenuOpen = useSelector(selectMobileMenuOpen);

    const isMobileOrTablet = useIsMobileOrTablet();

    const handleToggleMobileMenu = () => {
        dispatch(setMobileMenuOpen({ mobileMenuOpen: !mobileMenuOpen }));
        dispatch(setUserMenuOpen({ userMenuOpen: false }));
    };

    return (
        isMobileOrTablet && (
            <IconButton
                aria-owns={mobileMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={() => handleToggleMobileMenu()}
                className={classes.spacing}
                title={t('portal:open_mobile_menu')}
            >
                {mobileMenuOpen ? <Forwardburger className={classes.icon} /> : <MenuIcon className={classes.icon} />}
            </IconButton>
        )
    );
};

export default MobileMenuToggle;
