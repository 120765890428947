import { APPLE_APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from '../../utils/constants';
import { Card, makeStyles, Typography, Grid, Box } from '@material-ui/core';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const useNativeBannerStyles = makeStyles((theme) => ({
    nativeBanner: {
        marginTop: 46,
        padding: 16,
        backgroundColor: theme.palette.grey.soft,
        '& .MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&  a': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& p': {
            textAlign: 'center',
        },
        '& .qr-code > div': {
            padding: '4px 4px 0 4px',
            background: theme.palette.common.white,
            borderRadius: 4,
        },
        [theme.breakpoints.down('xs')]: {
            background: 'none',
            boxShadow: 'none',
            '& .MuiGrid-container': {
                width: '100%',
            },
            '& .qr-code': {
                display: 'none',
            },
            '& img': {
                width: '100%',
                height: 'auto',
            },
        },
    },
}));

const NativeAppBanner = () => {
    const { t } = useTranslation();
    const classes = useNativeBannerStyles();

    return (
        <Card className={classes.nativeBanner}>
            <Grid container alignItems="center" justifyContent="space-evenly" spacing={1}>
                <Grid item xs={3} sm={2}>
                    <Typography>{t('portal:native_banner')}</Typography>
                </Grid>
                <Grid item xs={8} sm={8}>
                    <Grid container alignItems="center" justifyContent="center" spacing={1}>
                        <Grid item xs={6}>
                            <a href={GOOGLE_PLAY_STORE_URL}>
                                <img src="/native-app-banner/play_store.png" height="40px" />
                            </a>
                        </Grid>
                        <Grid item xs={6}>
                            <a href={APPLE_APP_STORE_URL}>
                                <img src="/native-app-banner/app_store.png" height="40px" />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={2} className="qr-code">
                    <Box>
                        <QRCodeSVG value={`${window.location.origin}/native-app`} size={75} level="Q" />
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default NativeAppBanner;
