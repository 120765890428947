import { Grid, makeStyles } from '@material-ui/core';
import CalendarRange from 'mdi-material-ui/CalendarRange';
import Panel from 'components/Panel';
import PanelHeadline from 'components/PanelHeadline';
import { HolidayEntitlementWidget } from 'components/widgets/HolidayEntitlementWidget';
import { PrimaryButtonRequest } from 'components/PrimaryButtonRequest';
import { DashboardContainer } from 'containers/Dashboard/DashboardContainer';
import { Entitlement } from '../containers/Dashboard/Entitlement';

import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useTranslation } from 'react-i18next';
import { useIsTimeOffEnabled, useSettings } from 'services/useSettings';

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        padding: theme.spacing(6, 6, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2, 7.5),
            maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
        },
    },
}));

export const Dashboard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { settings } = useSettings();
    const isTimeOffEnabled = useIsTimeOffEnabled();
    const isEntitlementEnabled = settings?.entitlement?.enabled ?? false;

    const isMobileOrTablet = useIsMobileOrTablet();

    return (
        <div className={classes.pageContainer}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <DashboardContainer />
                </Grid>

                {isTimeOffEnabled && (
                    <Grid item xs={12}>
                        <PanelHeadline icon={<CalendarRange color="primary" />}>
                            {isEntitlementEnabled ? t('entitlement:entitlement') : t('portal:time_off')}
                        </PanelHeadline>

                        {isEntitlementEnabled ? (
                            <Entitlement />
                        ) : (
                            <Panel removeBottomGutter id="hit-point-holiday-entitlement-widget">
                                <HolidayEntitlementWidget />
                                {!isMobileOrTablet && (
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <PrimaryButtonRequest />
                                        </Grid>
                                    </Grid>
                                )}
                            </Panel>
                        )}
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
