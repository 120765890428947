import { UKFlagIcon } from '../../components/icons/UKFlagIcon';
import { FRFlagIcon } from '../../components/icons/FRFlagIcon';
import NLFlagIcon from '../../components/icons/NLFlagIcon';
import { nl } from 'date-fns/locale';

export const languageList = [
    {
        language: 'English',
        abbreviation: 'en-GB',
        flagIcon: <UKFlagIcon data-testid="en_flag" />,
    },
    {
        language: 'Français',
        abbreviation: 'fr-FR',
        flagIcon: <FRFlagIcon data-testid="fr_flag" />,
    },
    {
        language: 'Dutch (Netherlands)',
        abbreviation: 'nl-NL',
        flagIcon: <NLFlagIcon data-testid="nl_flag" />,
        dateLocale: nl,
    },
];
