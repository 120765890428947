import { Grid, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';

const Pagination = ({ className, title, extraComponent, backButton, nextButton }) => (
    <Grid container spacing={2} alignItems="center" className={className}>
        {backButton && <Grid item>{backButton}</Grid>}
        {nextButton && (
            <Hidden smDown>
                <Grid item>{nextButton}</Grid>
            </Hidden>
        )}
        <Grid item>{typeof title === 'function' ? title() : title}</Grid>
        {nextButton && (
            <Hidden mdUp>
                <Grid item>{nextButton}</Grid>
            </Hidden>
        )}
        {extraComponent && (
            <Grid item xs={12} sm>
                {extraComponent}
            </Grid>
        )}
    </Grid>
);

Pagination.propTypes = {
    className: PropTypes.string,
    title: PropTypes.any.isRequired,
    extraComponent: PropTypes.node,
    backButton: PropTypes.node,
    nextButton: PropTypes.node,
};

export default Pagination;
