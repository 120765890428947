import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        marginTop: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontSize: 22,
        lineHeight: '45px',
        color: theme.palette.common.white,
    },
}));

const FormHeader = () => {
    const { t } = useTranslation();

    const classes = useStyles();

    return (
        <Grid item container className={classes.container}>
            <Grid item xs={10} sm={9}>
                <Typography variant="body1" className={classes.title}>
                    {t('portal:staff_portal')}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default FormHeader;
