import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
    VARIABLE_REQUESTED,
    VARIABLE_WARNING,
    VARIABLE_APPROVED,
    VARIABLE_PRIMARY,
    VARIABLE_ERROR,
} from 'utils/constants';

const moment = extendMoment(Moment);
const currentMoment = moment();

export const getColour = (status) => {
    if (status === VARIABLE_REQUESTED) {
        return VARIABLE_WARNING;
    } else if (status === VARIABLE_APPROVED) {
        return VARIABLE_PRIMARY;
    }

    return VARIABLE_ERROR;
};

export const getDefaultDates = (listHolidayPeriods) => {
    let newIndex;

    const filteredDates = listHolidayPeriods.filter((elem, index) => {
        if (
            moment(elem.start).isSameOrBefore(currentMoment, 'day') &&
            moment(elem.end).isSameOrAfter(moment(), 'day')
        ) {
            newIndex = index;

            return { ...elem };
        }

        return null;
    });

    return {
        ...filteredDates[0],
        defaultIndex: newIndex,
    };
};
