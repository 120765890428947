import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    baseStyles: {
        textAlign: 'left',
    },
});

const InputLabel = ({ children, className, ...rest }) => {
    const classes = useStyles();

    return (
        <Typography className={classnames(classes.baseStyles, className)} {...rest} variant="body2">
            {children}
        </Typography>
    );
};

InputLabel.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default InputLabel;
