import { VARIABLE_UNAVAILABLE } from 'utils/constants';
import { i18nInstance } from 'translation/InitialiseTranslator';

export const getScheduleWithUnavailableDays = (
    shopSchedule = [],
    unavailableDays,
    internalStaffNumber,
    firstName,
    lastName,
) => {
    const populateUnavailableDays = unavailableDays.map((unavailableDay) => ({
        type: VARIABLE_UNAVAILABLE,
        roleName: i18nInstance.t('availability:unavailable'),
        start: unavailableDay,
        end: unavailableDay,
    }));

    if (!unavailableDays.length) {
        return shopSchedule;
    }

    if (shopSchedule.length) {
        return shopSchedule.map((userData) => {
            if (userData.internalStaffNumber === internalStaffNumber) {
                return {
                    ...userData,
                    shifts: [...(userData.shifts ?? []), ...populateUnavailableDays],
                };
            }

            return userData;
        });
    }

    return [
        {
            firstName,
            surname: lastName,
            internalStaffNumber,
            timeOff: [],
            shifts: populateUnavailableDays,
        },
    ];
};
