import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { roundAndTrimNumber, useGetHoursAndMinutes } from 'utils/timeFormatters';

export const TOTAL_HOURS = 'Total hours paid';
export const TOTAL_HOURS_TIME_OFF = 'Total hours time off paid';
export const TOTAL_HOURS_WORKED = 'Total hours worked paid';

const useStyles = makeStyles((theme) => ({
    containerOrientation: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            display: 'table',
            width: '100%',
            margin: 'auto',
            border: `1px solid ${theme.palette.grey[200]}`,
        },
    },
    columnAlignment: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(8, 0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            margin: 0,
            borderTop: `1px solid ${theme.palette.grey[200]}`,
        },
    },
    fontSize: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    hoursFontSize: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
            marginRight: 10,
        },
    },
}));

export const WeekHourTotals = ({ weekHourTotals: { shifts, timeOffAndLeave, total }, showHoursWithMinutes }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getHoursAndMinutes = useGetHoursAndMinutes();

    const totalTypes = [
        {
            title: t('schedule:total_hours_worked'),
            value: shifts,
        },
        {
            title: t('schedule:total_hours_time_off'),
            value: timeOffAndLeave,
        },
        {
            title: t('schedule:total_hours_paid'),
            value: total,
        },
    ];

    return (
        <Grid container spacing={5} className={classes.containerOrientation}>
            {totalTypes.map((payment) => (
                <Grid item xs={12} md={4} className={classes.columnAlignment} key={payment.title}>
                    <Typography variant="h5" gutterBottom className={classes.fontSize}>
                        {payment.title}
                    </Typography>
                    <Typography variant="h2" className={classes.hoursFontSize}>
                        {showHoursWithMinutes ? getHoursAndMinutes(payment.value) : roundAndTrimNumber(payment.value)}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

WeekHourTotals.propTypes = {
    weekHourTotals: PropTypes.exact({
        shifts: PropTypes.number.isRequired,
        timeOffAndLeave: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
    }),
    showHoursWithMinutes: PropTypes.bool,
};
