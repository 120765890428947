export const browserLanguageToCountryCode = (input: string | undefined): string | undefined => {
    if (!input) {
        return undefined;
    } else if (input.includes('en')) {
        return 'en-GB';
    } else if (input.includes('fr')) {
        return 'fr-FR';
    }
    return undefined;
};

export const getDefaultLanguage = (): string => {
    return browserLanguageToCountryCode(window.navigator.language) ?? 'en-GB';
};
