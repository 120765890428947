import { useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar, AppBar as MuiAppBar, makeStyles } from '@material-ui/core';
import MenuToggle from './appBar/MenuToggle';
import MobileMenuToggle from './appBar/MobileMenuToggle';
import UserMenuIcon from './appBar/userMenu/UserMenuIcon';
import UserMenuPopover from './appBar/userMenu/UserMenuPopover';
import { ClientLogo } from './appBar/ClientLogo';
import { setMobileMenuOpen, setUserMenuOpen } from '../menuSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: theme.menu.appBarMinSize,
        background: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    toolbarSectionWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const AppBar = ({ id }) => {
    const classes = useStyles();
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const handleUserMenuClick = (event) => {
        dispatch(setUserMenuOpen({ userMenuOpen: true }));
        dispatch(setMobileMenuOpen({ mobileMenuOpen: false }));
        setPopoverAnchorEl(event.currentTarget);
    };

    return (
        <MuiAppBar position="fixed" className={classes.appBar} id={id}>
            <Toolbar disableGutters className={classes.toolBar}>
                <div className={classes.toolbarSectionWrapper}>
                    <MenuToggle />
                    <ClientLogo />
                </div>
                <div className={classes.toolbarSectionWrapper} id="hit-point-user-menu">
                    <UserMenuIcon handleUserMenuClick={handleUserMenuClick} />
                    <UserMenuPopover popoverAnchorEl={popoverAnchorEl} />
                    <MobileMenuToggle />
                </div>
            </Toolbar>
        </MuiAppBar>
    );
};

AppBar.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AppBar;
