import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Tooltip } from '../components/Tooltip';
import { DateHeader } from './DateHeader';
import { MultiEntitlement } from '../components/MultiEntitlement';
import { DatePickerContent } from './DatePickerContent';
import { EntitlementForm } from '../components/EntitlementForm';
import { NonEntitlementForm } from '../components/NonEntitlementForm';
import { RequestTimeOffActionButtons } from './RequestTimeOffActionButtons';
import { HolidayEntitlementWidget } from 'components/widgets/HolidayEntitlementWidget';

import { selectShowTooltip, selectRequestedTimeOffType, selectSelectionList } from '../requestTimeOffSelectors';
import { setShowTooltip } from '../requestTimeOffSlice';

import { useSettings } from 'services/useSettings';

const useStyles = makeStyles((theme) => ({
    requestContentContainer: {
        position: 'relative',
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
    },
    errorRow: {
        marginBottom: '10px',
        backgroundColor: theme.palette.danger.main,
        padding: '5px 10px 5px 10px',
        borderRadius: '5px',
    },
    errorText: {
        color: theme.palette.common.white,
        fontWeight: '400',
    },
    disabledMask: {
        backgroundColor: theme.palette.grey[600],
        opacity: 0.4,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 2,
    },
    stickyFooter: {
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        flexShrink: 0,
    },
    container: {
        display: 'fex',
        flex: '1 0 auto',
    },
}));

export const RequestContent = ({ setIsDialogOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const showTooltip = useSelector(selectShowTooltip);
    const timeOffType = useSelector(selectRequestedTimeOffType);
    const selectionList = useSelector(selectSelectionList);

    const {
        settings: { entitlement },
    } = useSettings();

    return (
        <div className={classes.requestContentContainer}>
            <DateHeader />
            <div className={classes.container}>
                <DatePickerContent />
                {entitlement.enabled && <EntitlementForm />}
                {!entitlement.enabled && <NonEntitlementForm />}
                {!entitlement.enabled && <hr className={classes.divider} />}
                {entitlement.enabled &&
                    selectionList.map((revision) => <MultiEntitlement key={revision.id} revision={revision} />)}
                {!entitlement.enabled && <HolidayEntitlementWidget />}
            </div>
            <div className={classes.stickyFooter}>
                <RequestTimeOffActionButtons setIsDialogOpen={setIsDialogOpen} />
            </div>
            {!timeOffType.id && <div className={classes.disabledMask} onClick={() => dispatch(setShowTooltip(true))} />}
            {showTooltip && <Tooltip />}
        </div>
    );
};

RequestContent.propTypes = {
    setIsDialogOpen: PropTypes.func,
};
