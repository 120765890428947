import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DefaultButton from 'components/buttons/DefaultButton';
import { getResponseErrors } from '../utils';

import { usePostRequestTimeOff, useBlockedTimeOff } from 'services/useHoliday';
import { useSettings } from 'services/useSettings';
import { setStateToDefault, setTimeOffType, verifyRequest, setResponseErrors } from '../requestTimeOffSlice';
import {
    selectRequestedTimeOffType,
    selectRequestedStartDate,
    selectRequestedEndDate,
    selectIsAllButtonDisabled,
    selectUserRequests,
    selectIsRequestError,
    selectDatePickerBlockedOrApprovedError,
    selectIsRequestValid,
    selectFinalRequestData,
    selectSelectionList,
} from '../requestTimeOffSelectors';

import { setShowSnackbar, setSnackBarSuccess, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';
import { sendGASelectContent } from 'googleAnalytics';
import { GA_EVENTS } from 'lib/consts';

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        paddingLeft: theme.spacing(4),
        width: '100%',
    },
    buttonBase: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    clearSubmitButtons: {
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(4),
    },
    clearButton: {
        marginRight: theme.spacing(3),
    },
    submitButton: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    alert: {
        margin: '15px 32px 0',
    },
}));

export const RequestTimeOffActionButtons = ({ setIsDialogOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { postTimeOffRequestMutation } = usePostRequestTimeOff();
    const {
        settings: {
            timeOff: { blockedPeriods },
        },
    } = useSettings();
    const { blockedTimeOff } = useBlockedTimeOff(blockedPeriods.enabled);

    const timeOffType = useSelector(selectRequestedTimeOffType);
    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);
    const isAllButtonDisabled = useSelector(selectIsAllButtonDisabled);

    const userRequests = useSelector(selectUserRequests);
    const selectionList = useSelector(selectSelectionList);
    const isRequestError = useSelector(selectIsRequestError);
    const datePickerBlockedOrApprovedError = useSelector(selectDatePickerBlockedOrApprovedError);

    const isRequestValid = useSelector(selectIsRequestValid);
    const requestData = useSelector(selectFinalRequestData);
    const isZeroHours = !userRequests.every(
        ({ daysPerDay, hoursAndMinsPerDay }) => daysPerDay > 0 || hoursAndMinsPerDay !== '00:00',
    );

    useEffect(() => {
        dispatch(verifyRequest());
    }, [userRequests, startDate, endDate, dispatch]);

    const isValid = Boolean(
        userRequests.length > 0 &&
            userRequests.every((request) => request.relatedContractId) &&
            timeOffType &&
            startDate &&
            endDate &&
            !isAllButtonDisabled &&
            !isRequestError &&
            !datePickerBlockedOrApprovedError,
    );

    const handleClear = () => {
        const saveTimeOffType = timeOffType;

        dispatch(setStateToDefault());
        dispatch(setTimeOffType(saveTimeOffType));
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
        dispatch(setStateToDefault());
    };

    const onSubmitClick = () => {
        sendGASelectContent(GA_EVENTS.SUBMIT_TIME_OFF);
        if (isRequestValid) {
            postTimeOffRequestMutation.mutate(
                { requestData },
                {
                    onSuccess: () => {
                        dispatch(setShowSnackbar({ open: true }));
                        dispatch(setSnackBarSuccess());

                        dispatch(setStateToDefault());
                        setIsDialogOpen(false);
                    },
                    onError: ({
                        response: {
                            data: { errors },
                        },
                    }) => {
                        const {
                            requestError = '',
                            errorInDates = [],
                            requestErrorDetails = {},
                            defaultError = false,
                        } = getResponseErrors(errors[0], blockedTimeOff, selectionList);

                        if (defaultError) {
                            dispatch(setStateToDefault());
                            setIsDialogOpen(false);

                            dispatch(setShowSnackbar({ open: true }));
                            dispatch(setSnackBarError());
                        } else {
                            dispatch(setResponseErrors({ requestError, errorInDates, requestErrorDetails }));
                        }
                    },
                },
            );
        }
    };

    return (
        <>
            {isZeroHours && (
                <Alert className={classes.alert} severity="warning">
                    {t('requestTimeOff:alert_zero_hours')}
                </Alert>
            )}

            <Grid container justifyContent="center" className={classes.buttonsContainer}>
                <Grid item xs={6} md={3} className={classes.buttonBase}>
                    <Grid container justifyContent="flex-start">
                        <DefaultButton
                            size="large"
                            color="primary"
                            disableRipple
                            disabled={isAllButtonDisabled || !timeOffType}
                            variant="text"
                            onClick={handleCancel}
                        >
                            {t('common:cancel')}
                        </DefaultButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container justifyContent="center" className={classes.clearSubmitButtons}>
                        <Grid item className={classNames(classes.buttonBase, classes.clearButton)}>
                            <DefaultButton
                                size="large"
                                color="primary"
                                disableRipple
                                disabled={isAllButtonDisabled || !timeOffType}
                                variant="text"
                                onClick={handleClear}
                            >
                                {t('common:clear')}
                            </DefaultButton>
                        </Grid>

                        <Grid item className={classes.buttonBase}>
                            <DefaultButton
                                disabled={!isValid}
                                size="large"
                                color="default"
                                disableRipple
                                variant={isValid ? 'contained' : 'outlined'}
                                className={isValid && classes.submitButton}
                                onClick={onSubmitClick}
                            >
                                {t('common:submit')}
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

RequestTimeOffActionButtons.propTypes = {
    setIsDialogOpen: PropTypes.func,
};
