import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    resetPasswordToken: '',
    isPasswordUpdateRequired: false,
    isLoggedIn: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setResetPasswordToken(state, { payload }) {
            state.resetPasswordToken = payload;
        },
        setIsPasswordUpdateRequired(state, { payload }) {
            state.isPasswordUpdateRequired = payload;
        },
        setIsLoggedIn(state, { payload }) {
            state.isLoggedIn = payload;
        },
        reset(state) {
            state.resetPasswordToken = '';
            state.isPasswordUpdateRequired = false;
            state.isLoggedIn = false;
        },
    },
});

export const {
    reducer: loginReducer,
    actions: { setResetPasswordToken, setIsPasswordUpdateRequired, setIsLoggedIn, reset },
} = loginSlice;
