import { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles, InputLabel, MenuItem, OutlinedInput, Select, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { fromDecimalsToHours, fromHoursToDecimals, roundAndTrimNumber } from 'utils/timeFormatters';
import { useSettings } from 'services/useSettings';

import {
    selectRequestedStartDate,
    selectRequestedEndDate,
    selectIsRequestError,
    selectRequestedHours,
    selectRequestedHrsAndMins,
    selectRequestedTimeOffType,
} from '../requestTimeOffSelectors';
import { setRequestedTimeOff } from '../requestTimeOffSlice';

const useStyles = makeStyles((theme) => ({
    textArea: {
        width: '100%',
        marginTop: '10px',
        fontSize: '16px',
        backgroundColor: theme.palette.grey[50],
    },
    inputLabel: {
        fontSize: '16px',
    },
    helperText: {
        marginBottom: '16px',
    },
    durationContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    separator: {
        padding: 10,
        marginTop: 10,
    },
}));

export const HoursInput = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const {
        settings: {
            localization: { showHoursWithMinutes },
        },
    } = useSettings();

    const startDate = useSelector(selectRequestedStartDate);
    const endDate = useSelector(selectRequestedEndDate);
    const isRequestError = useSelector(selectIsRequestError);
    const timeOffType = useSelector(selectRequestedTimeOffType);

    const requestedHours = useSelector(selectRequestedHours);
    const requestedHrsAndMins = useSelector(selectRequestedHrsAndMins);

    let [inputHours, inputMins] = requestedHrsAndMins.split(':');

    const hoursOptions = useMemo(
        () => (timeOffType.id ? timeOffType.hoursPerDayOptions : []),
        [timeOffType.id, timeOffType.hoursPerDayOptions],
    );
    const [totalHours, setTotalHours] = useState(0);

    useEffect(() => {
        if ((startDate || endDate) && !requestedHours && inputRef.current) {
            inputRef.current.focus();
        }
    }, [startDate, endDate, requestedHours]);

    useEffect(() => {
        if (hoursOptions.length > 0) {
            dispatch(
                setRequestedTimeOff({
                    hours: hoursOptions[0],
                    hoursAndMinsPerDay: fromDecimalsToHours(hoursOptions[0]),
                }),
            );
        }
    }, [hoursOptions, dispatch]);

    useEffect(() => {
        const days = moment(endDate).diff(moment(startDate), 'days') + 1;

        const getSelectedHours = showHoursWithMinutes ? fromHoursToDecimals(requestedHrsAndMins) : requestedHours;

        const getTotalHours = roundAndTrimNumber(getSelectedHours * days) || 0;

        setTotalHours(getTotalHours);
    }, [startDate, endDate, requestedHours, showHoursWithMinutes, requestedHrsAndMins, setTotalHours]);

    const updateTime = (event, type) => {
        const value = Number(event.target.value);

        if (type === 'h') {
            inputHours = value > 23 ? 23 : value.toString().padStart(2, '0');
        } else {
            inputMins = value > 59 ? 59 : value.toString().padStart(2, '0');
        }

        const hoursAndMinsPerDay = `${inputHours}:${inputMins}`;
        const hoursPerDay = fromHoursToDecimals(hoursAndMinsPerDay);

        dispatch(setRequestedTimeOff({ hours: hoursPerDay, hoursAndMinsPerDay }));
    };

    const handleInputOnChange = (event) => {
        const { value } = event.target;
        const format = showHoursWithMinutes ? 'time' : 'number';

        const hoursPerDay = format === 'number' ? Number(value) : fromHoursToDecimals(value);
        const hoursAndMinsPerDay = format === 'time' ? `${value}` : fromDecimalsToHours(value);

        const hours = value === '' ? '' : hoursPerDay;

        dispatch(setRequestedTimeOff({ hours, hoursAndMinsPerDay }));
    };

    return (
        <>
            <InputLabel htmlFor="request-time" className={classes.inputLabel}>
                {t('portal:hours_per_day')}
            </InputLabel>

            {(() => {
                switch (true) {
                    case hoursOptions.length > 0:
                        return (
                            <Select
                                id="request-time"
                                className={classes.textArea}
                                variant="outlined"
                                value={requestedHours}
                                onChange={handleInputOnChange}
                                error={isRequestError}
                            >
                                {hoursOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        );
                    case showHoursWithMinutes:
                        return (
                            <div className={classes.durationContainer}>
                                <OutlinedInput
                                    id="request-time"
                                    className={classes.textArea}
                                    onChange={(e) => updateTime(e, 'h')}
                                    error={isRequestError}
                                    type="number"
                                    inputProps={{ min: 0, max: 24, step: 1 }}
                                    value={inputHours}
                                />
                                <div className={classes.separator}> : </div>
                                <OutlinedInput
                                    id="request-time"
                                    className={classes.textArea}
                                    onChange={(e) => updateTime(e, 'm')}
                                    error={isRequestError}
                                    type="number"
                                    inputProps={{ min: 0, max: 59, step: 1 }}
                                    value={inputMins}
                                />
                            </div>
                        );
                    default:
                        return (
                            <OutlinedInput
                                id="request-time"
                                className={classes.textArea}
                                inputRef={inputRef}
                                onChange={handleInputOnChange}
                                error={isRequestError}
                                type="number"
                                value={requestedHours}
                                inputProps={{ min: 0, max: 24, step: 0.01 }}
                            />
                        );
                }
            })()}
            <FormHelperText className={classes.helperText}>
                {t('requestTimeOff:helper_text.total_hours', { totalHours })}
            </FormHelperText>
        </>
    );
};
