import { Popover, makeStyles } from '@material-ui/core';
import { UserMenuGreeting } from './userMenuPopover/UserMenuGreeting';
import { UserMenuLinks } from './userMenuPopover/UserMenuLinks';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuLinks, selectUserMenuOpen } from '../../../menuSelector';
import { setUserMenuOpen } from '../../../menuSlice';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    baseStyles: {
        width: 320,
    },
});

const UserMenuPopover = ({ popoverAnchorEl }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userMenuOpen = useSelector(selectUserMenuOpen);
    const menuLinks = useSelector(selectMenuLinks);

    const handleUserMenuClose = () => {
        dispatch(setUserMenuOpen({ userMenuOpen: false }));
    };

    return (
        <Popover
            className={classes.baseStyles}
            classes={{ paper: classes.baseStyles }}
            id="user-menu"
            open={userMenuOpen}
            anchorEl={popoverAnchorEl}
            onClose={handleUserMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <>
                <UserMenuGreeting onClick={handleUserMenuClose} />
                <UserMenuLinks menuLinks={menuLinks.user} onClick={handleUserMenuClose} />
            </>
        </Popover>
    );
};

UserMenuPopover.propTypes = {
    popoverAnchorEl: PropTypes.object,
};

export default UserMenuPopover;
