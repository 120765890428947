/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import classnames from 'classnames';

import ScheduleWeekHeader from './ScheduleWeekHeader';
import { calculateScrollToTime } from './helpers';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { scheduleEvents } from 'containers/Schedule/helpers';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'components/calendar/calendar.css';
import { AvailabilityDialog } from './availability/AvailabilityDialog';
import { LoadingBar } from 'components/LoadingBar';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { calcCanEditAvailability } from 'containers/Schedule/utils';
import { AvailabilityHeaderIcon } from './availability/AvailabilityHeaderIcon';
import { iso8601 } from 'utils/timeFormatters';
import { EventTile } from './EventTile';
import { useTranslation } from 'react-i18next';
import { selectStartDate, selectEndDate } from 'containers/Schedule/store/scheduleSelector';
import { useAllStaffShiftsAndTimeOff } from 'services/schedule';
import { useUnavailableDaysForUser } from 'services/availability';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeOffTypes } from 'services/useHoliday';
import { useSettings } from 'services/useSettings';
import { setDialogOpen, setDialogEvents } from 'containers/Schedule/store/scheduleSlice';
import { openAvailabilityDialog } from 'containers/Schedule/store/availabilitySlice';
import LoadingComponent from 'components/LoadingComponent';

const useStyles = makeStyles((theme) => ({
    calendarDiv: {
        overflowY: 'visible',
        background: theme.palette.common.white,
        height: 'calc(100vh - 276px)',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 256px)',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 196px)',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 230px)',
        },
    },
}));

export const YourSchedule = ({ id }) => {
    const classes = useStyles();
    const isMobileOrTablet = useIsMobileOrTablet();
    const { moment } = useMoment();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { settings, isSettingsLoading } = useSettings();
    const isStaffAvailabilityEnabled = !!settings?.staffAvailability?.overallEnabled;

    const { allTimeOffTypes, isTimeOffTypesLoading, isTimeOffTypesError, isTimeOffTypesSuccess } = useTimeOffTypes();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const { unavailableDays, isUnavailableDaysLoading, isUnavailableDaysError } = useUnavailableDaysForUser(
        startDate,
        endDate,
        isStaffAvailabilityEnabled,
    );

    const { periodScheduleSpread, isPeriodScheduleLoading, isPeriodScheduleError } = useAllStaffShiftsAndTimeOff(
        moment(startDate),
        moment(endDate),
        allTimeOffTypes,
        isTimeOffTypesSuccess,
    );

    if (isPeriodScheduleLoading || isUnavailableDaysLoading || isTimeOffTypesLoading || isSettingsLoading) {
        return <LoadingComponent title={t('common:loading')} />;
    }

    if (isPeriodScheduleError || isUnavailableDaysError || isTimeOffTypesError) {
        return null;
    }

    const scheduleEventsData = scheduleEvents(
        isStaffAvailabilityEnabled,
        periodScheduleSpread,
        unavailableDays,
        startDate,
        endDate,
    );

    const onOpen = (event) => {
        if (event.isStaffAvailabilityEvent) {
            if (calcCanEditAvailability(iso8601(event.start), scheduleEventsData)) {
                dispatch(
                    openAvailabilityDialog({
                        currentStatus: event.status,
                        date: moment(event.start).format('YYYY-MM-DD'),
                    }),
                );
            }
        } else {
            dispatch(setDialogOpen());
            dispatch(
                setDialogEvents([
                    {
                        ...event,
                        start: moment(event.start).format('YYYY-MM-DD HH:mm'),
                        end: moment(event.end).format('YYYY-MM-DD HH:mm'),
                    },
                ]),
            );
        }
    };

    return (
        <section
            className={classnames({
                [classes.calendarDiv]: true,
                loading: isPeriodScheduleLoading,
            })}
            id={id}
        >
            {isStaffAvailabilityEnabled && <AvailabilityDialog />}
            <Calendar
                localizer={momentLocalizer(moment)}
                defaultView="week"
                onNavigate={() => null}
                onDrillDown={() => null}
                date={new Date(startDate)}
                timeslots={1}
                scrollToTime={calculateScrollToTime(startDate, periodScheduleSpread)}
                events={scheduleEventsData}
                showMultiDayTimes
                step={60}
                defaultDate={new Date()}
                onSelectEvent={(event) => onOpen(event)}
                formats={{
                    weekdayFormat: 'dddd',
                    dayFormat: isMobileOrTablet ? 'dd D MMM' : 'dddd D MMM',
                    dateFormat: 'D',
                    timeGutterFormat: 'ha',
                    dayRangeHeaderFormat: ({ start, end }) =>
                        `${moment(start).format('D MMM YYYY')} - ${moment(end).format('D MMM YYYY')}`,
                }}
                components={{
                    toolbar: () => null,
                    week: {
                        event: (event) => <EventTile {...event} />,
                        header: (date) => (
                            <div>
                                <ScheduleWeekHeader fullScreen={isMobileOrTablet} label={date.label} date={date.date} />
                                {isStaffAvailabilityEnabled && (
                                    <AvailabilityHeaderIcon
                                        date={moment(date.date).format('YYYY-MM-DD')}
                                        scheduleEvents={scheduleEventsData}
                                    />
                                )}
                            </div>
                        ),
                    },
                }}
            />

            <LoadingBar isLoading={isPeriodScheduleLoading} title={t('common:loading')} noMargin />
        </section>
    );
};

YourSchedule.propTypes = {
    id: PropTypes.string.isRequired,
};
