import { array } from 'prop-types';
import { ExpandMore } from '@material-ui/icons';
import {
    Accordion as AccordionBase,
    AccordionSummary as AccordionSummaryBase,
    AccordionDetails as AccordionDetailsBase,
    Typography,
    Chip as ChipBase,
    styled,
} from '@material-ui/core';

import { ExpansionPanelContent } from './ExpansionPanelContent';
import { useIsHidingInactivePots } from '../useEntitlement';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const Accordion = styled(AccordionBase)({
    marginBottom: 10,
});

const AccordionSummary = styled(AccordionSummaryBase)(({ theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: '5px',
    '& 	.Mui-expanded': {
        height: '20px',
        maxHeight: '20px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    '& .MuiAccordionSummary-content': { display: 'flex', alignItems: 'center', gap: theme.spacing(1) },
}));

const PanelText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 14,
}));

const Icon = styled(ExpandMore)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 25,
}));

const AccordionDetails = styled(AccordionDetailsBase)(({ theme }) => ({
    padding: `0px ${theme.spacing(3)}`,
}));

const Chip = styled(ChipBase)({
    fontWeight: 500,
});

export const EntitlementExpandingPanel = ({ entitlementByContractId }) => {
    const isHiding = useIsHidingInactivePots();
    const { t } = useTranslation();
    const inactiveLabel = useMemo(() => t('entitlement:inactive'), [t]);

    return entitlementByContractId
        .filter((pot) => {
            if (isHiding) {
                return pot.isActive;
            }
            return true;
        })
        .map((pot, index) => {
            const showInactive = !pot.isActive && !isHiding;
            const periodLength = pot.periods?.length;
            const key = pot.humanName + periodLength + JSON.stringify(pot.timeOffTypeIds);

            return (
                <Accordion key={key} defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<Icon />}>
                        <PanelText>{pot.humanName}</PanelText>
                        {showInactive && <Chip label={inactiveLabel} />}
                    </AccordionSummary>
                    <AccordionDetails>
                        <ExpansionPanelContent currentPot={pot} />
                    </AccordionDetails>
                </Accordion>
            );
        });
};

EntitlementExpandingPanel.propTypes = {
    entitlementByContractId: array.isRequired,
};
