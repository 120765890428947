import { useQuery } from 'react-query';
import { getAllStaffDocuments } from 'api/documents';

export const useStaffDocuments = () => {
    const { data, isLoading, isError } = useQuery('allStaffDocuments', getAllStaffDocuments);

    return {
        staffDocuments: data?.data?.data,
        isStaffDocumentsLoading: isLoading,
        isStaffDocumentsError: isError,
    };
};
