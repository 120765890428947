import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, makeStyles } from '@material-ui/core';
import FormInput from './formContent/FormInput';
import { PasswordResetContainer } from './formContent/PasswordResetContainer';
import DefaultButton from 'components/buttons/DefaultButton';
import { useLogin } from 'services/auth';
import { useDispatch } from 'react-redux';
import { setIsPasswordUpdateRequired } from '../../loginSlice';
import LoadingComponent from '../../../../components/LoadingComponent';

const useStyles = makeStyles((theme) => ({
    signInButton: {
        minWidth: '120px',
        fontFamily: theme.typography.fontFamily,
        height: '40px',
        '&:disabled': {
            color: theme.palette.grey.lightest,
            backgroundColor: theme.palette.common.white,
            border: `solid 1px ${theme.palette.grey.lightest}`,
        },
    },
    buttonsContainer: {
        marginTop: '30px',
        marginBottom: '80px',
    },
    tooltipTitle: {
        fontWeight: '500',
        fontSize: '12px',
        color: theme.palette.common.white,
    },
    tooltipText: {
        fontSize: '12px',
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            marginTop: '10px',
        },
    },
    dot: {
        width: '10px',
        height: '10px',
        borderRadius: '13px',
        backgroundColor: theme.palette.error.main,
    },
    gridText: {
        marginTop: '5px',
        marginLeft: '5px',
        marginBottom: '5px',
    },
    sessionExpired: {
        color: theme.palette.error.main,
        marginTop: '20px',
        fontSize: '14px',
        padding: '5px',
    },
}));

export const LoginFormContent = () => {
    const classes = useStyles();
    const { loginMutation } = useLogin();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const disabled = loginMutation.isError || loginMutation.isLoading || !username || !password;

    const onFormInputChange = (event, setFieldState) => {
        if (loginMutation.isError) {
            loginMutation.reset();
        }

        setFieldState(event.target.value);
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (!loginMutation.isLoading) {
            loginMutation.mutate(
                { username, password },
                {
                    onSuccess: ({ data: response }) => {
                        dispatch(setIsPasswordUpdateRequired(response.data.requiresPasswordChange));
                    },
                },
            );
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <FormInput
                    id="username"
                    error={loginMutation.isError}
                    label={loginMutation.isError ? t('user:unable_to_login_try_again') : t('user:username')}
                    onChange={(event) => onFormInputChange(event, setUsername)}
                    onClick={loginMutation.reset}
                    value={username}
                    data-testid="login-form-username-field"
                    autoComplete="off"
                />
            </Grid>
            <Grid item xs={12}>
                <FormInput
                    id="password"
                    error={loginMutation.isError}
                    label={loginMutation.isError ? t('user:unable_to_login_try_again') : t('user:password')}
                    type="password"
                    onChange={(event) => onFormInputChange(event, setPassword)}
                    onClick={loginMutation.reset}
                    value={password}
                    data-testid="login-form-password-field"
                    autoComplete="off"
                />
            </Grid>

            <Grid
                item
                xs={12}
                container
                spacing={0}
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.buttonsContainer}
            >
                <Grid item>
                    <PasswordResetContainer />
                </Grid>
                <Grid item>
                    <DefaultButton
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={disabled}
                        className={classes.signInButton}
                        data-testid="login-form-submit-button"
                        id="login-button"
                    >
                        {loginMutation.isLoading ? (
                            <LoadingComponent
                                style={{ width: 20, height: 20 }}
                                circularProgressStyle={{ width: 20, height: 20 }}
                            />
                        ) : (
                            t('user:sign_in')
                        )}
                    </DefaultButton>
                </Grid>
            </Grid>
        </form>
    );
};
