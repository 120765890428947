import { timeOffTypeIDs } from 'utils/constants';

export const getIsTimeOffTypeHoursOnly = (timeOffType) => {
    if (!timeOffType.id) {
        return false;
    }

    const { lieuTime, training, meeting } = timeOffTypeIDs;

    return timeOffType.type === lieuTime || timeOffType.type === training || timeOffType.type === meeting;
};
