import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Grid, Typography, Hidden, makeStyles } from '@material-ui/core';
import BulletPoint from 'components/BulletPoint';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';

const useStyles = makeStyles((theme) => ({
    defaultRow: {
        borderBottom: `1px solid ${theme.palette.grey.soft}`,
        width: '100%',
        padding: 10,
        '&:last-child': {
            borderBottom: 0,
        },
    },
    todayRow: {
        border: `1px solid ${theme.palette.primary.main}`,
        width: '100%',
        padding: 10,
    },
    shiftInDayRow: {
        boxSizing: 'border-box',
        '&:first-child': {
            paddingBottom: 6,
        },
    },
    mobileHeaderPadding: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 10,
        },
    },
    shiftDetails: {
        [theme.breakpoints.up('md')]: {
            padding: '0px 5px',
        },
    },
    subsequentShift: {
        [theme.breakpoints.up('md')]: {
            paddingTop: 5,
            borderTop: `1px solid ${theme.palette.grey.soft}`,
        },
    },
    subsequentShiftMobile: {
        paddingTop: 5,
        [theme.breakpoints.up('md')]: {
            borderTop: `1px solid ${theme.palette.grey.soft}`,
        },
        [theme.breakpoints.down('sm')]: {
            borderTop: `1px solid ${theme.palette.grey.soft}`,
        },
    },
}));

export const MiniScheduleRow = ({ day }) => {
    const classes = useStyles();
    const { moment } = useMoment();

    const { t } = useTranslation();

    return (
        <Grid
            container
            className={classNames({
                [classes.defaultRow]: true,
                [classes.todayRow]: day.isToday,
            })}
        >
            <Grid item xs={12} sm={3} className={classes.mobileHeaderPadding}>
                <Grid container alignItems="center">
                    <Grid item xs={1} sm={2}>
                        <BulletPoint color="primary" type="rounded" />
                    </Grid>
                    <Grid item xs>
                        <Typography style={{ fontWeight: 600 }} data-testid="miniScheduleRow-date">
                            {moment(day.date).format('dddd D MMM')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={9}>
                <Grid container>
                    <Hidden smUp>
                        <Grid item xs={1} />
                    </Hidden>

                    <Grid item xs={11} md={12}>
                        {day.hasShift ? (
                            day.shifts.map((shift, index) => (
                                <Grid
                                    key={`${shift.startDate}${shift.endDate}${index}`}
                                    container
                                    className={classes.shiftInDayRow}
                                >
                                    <Grid item xs>
                                        <Typography className={index > 0 ? classes.subsequentShiftMobile : ''}>
                                            {shift.role}
                                        </Typography>
                                    </Grid>
                                    {!shift.timeOff && (
                                        <>
                                            {shift.departmentName && (
                                                <Grid item xs={12} md>
                                                    <Typography
                                                        data-testid="miniScheduleRow-shift-department"
                                                        className={classNames({
                                                            [classes.subsequentShift]: index > 0,
                                                            [classes.shiftDetails]: true,
                                                        })}
                                                    >
                                                        {shift.departmentName}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {shift.shopName && (
                                                <Grid item xs={12} md>
                                                    <Typography
                                                        data-testid="miniScheduleRow-shift-shop-name"
                                                        className={classNames({
                                                            [classes.subsequentShift]: index > 0,
                                                            [classes.shiftDetails]: true,
                                                        })}
                                                    >
                                                        {shift.shopName}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs>
                                                <Typography
                                                    data-testid="miniScheduleRow-shift-start-date"
                                                    className={index > 0 ? classes.subsequentShift : ''}
                                                >
                                                    {t('dashboard:start_time_colon')}
                                                    <strong>{moment(shift.startDate).format('HH:mm')}</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography
                                                    data-testid="miniScheduleRow-shift-end-date"
                                                    className={index > 0 ? classes.subsequentShift : ''}
                                                >
                                                    {t('dashboard:end_time_colon')}
                                                    <strong>{moment(shift.endDate).format('HH:mm')}</strong>
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            ))
                        ) : (
                            <Typography data-testid="miniScheduleRow-no-shift-text">
                                {t('dashboard:no_shift')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MiniScheduleRow.propTypes = {
    classes: PropTypes.object,
    day: PropTypes.shape({
        date: PropTypes.string.isRequired,
        hasShift: PropTypes.bool.isRequired,
        isToday: PropTypes.bool.isRequired,
        shiftCount: PropTypes.number.isRequired,
        shifts: PropTypes.arrayOf(
            PropTypes.shape({
                confirmed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
                days: PropTypes.number,
                endDate: PropTypes.PropTypes.string,
                hoursPerDay: PropTypes.number,
                isPaid: PropTypes.bool,
                notes: PropTypes.string,
                role: PropTypes.string,
                shopName: PropTypes.string,
                startDate: PropTypes.PropTypes.string,
                timeOff: PropTypes.bool,
            }),
        ),
    }),
};
