import origMoment from 'moment';
import LoadingComponent from 'components/LoadingComponent';
import { MiniSchedule } from 'containers/MiniSchedule/MiniSchedule';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { useTranslation } from 'react-i18next';

import { useTimeOffTypes } from 'services/useHoliday';
import { useAllStaffShiftsAndTimeOff } from 'services/schedule';

const startDate = origMoment();
const endDate = origMoment().add(6, 'd');

export const DashboardContainer = () => {
    const { moment } = useMoment();
    const { t } = useTranslation();
    const { allTimeOffTypes, isTimeOffTypesError, isTimeOffTypesLoading, isTimeOffTypesSuccess } = useTimeOffTypes();
    const { periodSchedule, isPeriodScheduleLoading, isPeriodScheduleError } = useAllStaffShiftsAndTimeOff(
        startDate,
        endDate,
        allTimeOffTypes,
        isTimeOffTypesSuccess,
    );

    if (isPeriodScheduleError || isTimeOffTypesError) {
        return null;
    }

    if (isPeriodScheduleLoading || isTimeOffTypesLoading) {
        return <LoadingComponent title={t('common:loading')} />;
    }

    const createDaySchedule = (day) => {
        const getDayShifts = periodSchedule.filter((shift) =>
            moment(shift.startDate).isSame(moment(startDate).add(day, 'd'), 'day'),
        );
        const sortShifts = getDayShifts.sort((a, b) => a.startDate - b.startDate);

        return sortShifts;
    };

    const createWeekSchedule = () => {
        const weekSchedule = [];
        const periodRangeNumber = moment(endDate).diff(startDate, 'days');
        let day = 0;

        while (day <= periodRangeNumber) {
            const daySchedule = createDaySchedule(day);

            const date = moment(startDate).add(day, 'days').format('YYYY-MM-DD');

            weekSchedule.push({
                date,
                isToday: date === moment(startDate).format('YYYY-MM-DD'),
                shiftCount: daySchedule.length,
                hasShift: daySchedule.length !== 0,
                shifts: daySchedule,
            });
            day += 1;
        }

        return weekSchedule;
    };

    return <MiniSchedule id="hit-point-your-schedule-widget" weekSchedule={createWeekSchedule()} />;
};
