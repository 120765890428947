import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setIsLoggedIn } from 'containers/Login/loginSlice';
import { useEffect } from 'react';

export function useRedirectToLogin() {
    const dispatch = useDispatch();
    const history = useHistory();

    const redirect = () => {
        dispatch(setIsLoggedIn(false));
        history.push('/login');
    };
    return redirect;
}

export const AuthError = () => {
    const redirect = useRedirectToLogin();

    useEffect(() => {
        redirect();
    }, [redirect]);

    return null;
};
