import { StrictMode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrivateRoute } from 'components/PrivateRoute';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { Menu } from 'components/layouts/Menu';
import { EventSnackBar } from 'components/snackbar/EventSnackBar';
import { ShiftOffer } from 'containers/ShiftOffer/ShiftOffer';
import { Documents } from 'containers/Documents/Documents';
import { FabMobileDialog } from 'components/FabMobileDialog';
import { ConfigAuthCheck } from 'components/ConfigAuthCheck';
import { AppHelmet } from 'components/AppHelmet';
import { StatusPageWidget } from 'StatusPageWidget';
import { JiraServiceWidget } from './JiraServiceWidget';

import { TimeOffPage } from './pages/TimeOffPage';
import { SchedulePage } from './pages/SchedulePage';
import { Dashboard } from './pages/Dashboard';
import { AuthError } from './pages/AuthError';
import { LoginPage } from './pages/LoginPage';
import { LoginSSOPage } from './pages/LoginSSOPage';
import { UnauthorizedPage } from './pages/UnauthorizedPage';
import { ManageAccount } from 'pages/ManageAccount';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { LoggedInNotFound } from 'pages/LoggedInNotFound';
import { Training } from 'pages/Training';
import { SettingControlledRoute } from 'components/SettingControlledRoute';
import { InitialiseTranslator } from 'translation/InitialiseTranslator';
import { DateLocaleProvider } from 'containers/languageSelection/DateLocaleProvider';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from 'components/ErrorPage';
import { SplashScreen } from 'pages/SplashScreen';
import { getDefaultDates } from 'components/widgets/helpers';

import { useHolidayPeriods } from 'services/useHoliday';
import { selectIsLoggedIn } from 'containers/Login/loginSelector';
import { setDefaultIndex, setPaginationDateIndex, setStartDate, setEndDate } from 'containers/timeOff/timeOffSlice';
import OldBrowser from 'pages/OldBrowser';
import NativeApp from './pages/nativeApp';

export const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const { holidayPeriods, isPeriodsLoading, isPeriodsError } = useHolidayPeriods(isLoggedIn);

    useEffect(() => {
        if (isLoggedIn && !isPeriodsLoading && !isPeriodsError) {
            const defaultDates = getDefaultDates(holidayPeriods);

            dispatch(setPaginationDateIndex(defaultDates.defaultIndex));
            dispatch(setDefaultIndex(defaultDates.defaultIndex));
            dispatch(setStartDate(defaultDates.start));
            dispatch(setEndDate(defaultDates.end));
        }
    }, [dispatch, holidayPeriods, isLoggedIn, isPeriodsError, isPeriodsLoading]);

    if (isPeriodsLoading) {
        return <SplashScreen />;
    } else if (isPeriodsError) {
        return (
            <ErrorPage
                mainMessage={t('portal:something_wrong')}
                subMessage={t('portal:refresh_page_message')}
                refreshButtonTitle={t('portal:refresh_page_btn')}
            />
        );
    }

    return (
        <StrictMode>
            <DateLocaleProvider>
                <InitialiseTranslator>
                    <Router>
                        <ConfigAuthCheck>
                            <AppHelmet />
                            <JiraServiceWidget />
                            <Switch>
                                <Route exact path="/login">
                                    <LoginPage />
                                </Route>
                                <Route path="/login/sso">
                                    <LoginSSOPage />
                                </Route>
                                <Route path="/old-browser">
                                    <OldBrowser />
                                </Route>
                                <Route path="/unauthorized">
                                    <UnauthorizedPage />
                                </Route>
                                <Route path={['/reset-password', '/reset-password-welcome']}>
                                    <ResetPasswordPage />
                                </Route>
                                <Route path="/authentication-error">
                                    <AuthError />
                                </Route>
                                <Route exact path="/native-app">
                                    <NativeApp />
                                </Route>
                                <PrivateRoute>
                                    <Menu>
                                        <StatusPageWidget />
                                        <Switch>
                                            <Route exact path="/">
                                                <Redirect to="/dashboard" />
                                            </Route>
                                            <Route path="/dashboard">
                                                <Dashboard />
                                            </Route>
                                            <Route path="/schedule">
                                                <SchedulePage />
                                            </Route>
                                            <Route path="/manage-account">
                                                <ManageAccount />
                                            </Route>
                                            <Route path="/time-off/:page?">
                                                <TimeOffPage />
                                            </Route>
                                            <SettingControlledRoute path="/shift-offers" settingString="shiftOffer">
                                                <ShiftOffer />
                                            </SettingControlledRoute>
                                            <SettingControlledRoute path="/training" settingString="training">
                                                <Training />
                                            </SettingControlledRoute>
                                            <SettingControlledRoute
                                                path="/training-logout"
                                                settingString="training"
                                                exact={true}
                                            >
                                                <Training logout={true} />
                                            </SettingControlledRoute>
                                            <SettingControlledRoute path="/documents" settingString="staffDocuments">
                                                <Documents />
                                            </SettingControlledRoute>
                                            <Route>
                                                <LoggedInNotFound />
                                            </Route>
                                        </Switch>
                                        <FabMobileDialog />
                                    </Menu>
                                </PrivateRoute>
                            </Switch>
                            <EventSnackBar />
                        </ConfigAuthCheck>
                    </Router>
                </InitialiseTranslator>
            </DateLocaleProvider>
        </StrictMode>
    );
};
